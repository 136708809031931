export const languagesHelper = {
  set,
  get
};

const LANGUAGE_KEY = 'language';
const DEFAULT_LOCALE = 'en';

function existLS(){
  return typeof localStorage !== 'undefined';
}

function set(locale){
  existLS() && localStorage.setItem(LANGUAGE_KEY, locale);
  reload();
}

function get(){
  let result = DEFAULT_LOCALE;
  if(existLS()){
    result = localStorage.getItem(LANGUAGE_KEY);
  }
  return result;
}

function reload(){
  document.location.reload(true);
}
