/*
 * Marketplace specific configuration..
 */

export const k12 = [
  'math',
  'philosophy',
  'philosophy',
];

export const university = [
  'coding',
  'psychology',
];

export const graduate = [
  'languages',
];

export const backgroundCheck = [
  { key: '', label: 'Yes/No', hideFromFilters: true, hideFromListingInfo: true },
  { key: 'true', label: 'Yes' },
  { key: 'false', label: 'No' },
];

export const lessonType = [
  { key: 'online', label: 'On-line session' },
  { key: 'inperson', label: 'In-person session' },
];

export const sessionType = [
  { key: 'private', label: 'Private' },
  { key: 'group', label: 'Group' }
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};
