import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingFavouriteCard } from '../../components';
import css from './SearchFavouriteResultsPanel.css';

const SearchFavouriteResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    search,
    setActiveListing,
    allSubjects,
    onContactUser,
    onSaveFavourites,
    currentUser,
    saveFavouriteRequest,
    saveFavouriteSuccess,
    saveFavouriteError,
    getFavoriteListings,
    favoriteListingsRequest,
    requestInProgress,
    setInProgress,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {listings.map(l => (
          <ListingFavouriteCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
            allSubjects={allSubjects}
            onContactUser={onContactUser}
            onSaveFavourites={onSaveFavourites}
            currentUser={currentUser}
            saveFavouriteRequest={saveFavouriteRequest}
            saveFavouriteSuccess={saveFavouriteSuccess}
            saveFavouriteError={saveFavouriteError}
            getFavoriteListings={getFavoriteListings}
            favoriteListingsRequest={favoriteListingsRequest}
            requestInProgress={requestInProgress}
            setInProgress={setInProgress}
          />
        ))}
        {props.children}
      </div>
    </div>
  );
};

SearchFavouriteResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchFavouriteResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchFavouriteResultsPanel;
