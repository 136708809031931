import React from 'react';
import PropTypes from 'prop-types';
import css from './SectionHowItWorks.css';

const SectionHowItWorksIconReview = props => {
  const { className } = props;

  return (
    <svg
      className={css.icons}
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="64"
      fill="none"
      viewBox="0 0 63 64"
    >
      <path
        fill="#E77B20"
        d="M16.362 8.707l-2.136-4.328-2.136 4.328-4.776.694 3.456 3.369-.816 4.756 4.272-2.246 4.271 2.246-.815-4.756 3.456-3.37-4.776-.693zm-.318 5.442l-1.818-.956-1.818.956.347-2.025-1.47-1.433 2.032-.296.909-1.841.909 1.841 2.032.296-1.47 1.433.347 2.025zM34.221 8.707l-2.136-4.328-2.136 4.328-4.776.694 3.456 3.369-.816 4.756 4.272-2.246 4.272 2.246-.816-4.757 3.456-3.368-4.776-.694zm-.318 5.442l-1.818-.956-1.818.956.347-2.025-1.47-1.433 2.032-.296.909-1.841.909 1.841 2.032.296-1.47 1.433.347 2.025zM48.713 4.379l-2.136 4.328L41.8 9.4l3.456 3.368-.816 4.757 4.272-2.246 4.272 2.246-.816-4.757 3.455-3.368-4.775-.694-2.136-4.328zm1.817 9.77l-1.817-.956-1.819.956.348-2.025-1.471-1.433 2.033-.296.909-1.841.908 1.841 2.033.296-1.47 1.433.346 2.025z"
      ></path>
      <path
        fill="#614983"
        d="M59.551 0H48.208v1.848H59.55c.85 0 1.54.69 1.54 1.54v16.01c0 .85-.69 1.54-1.54 1.54H17.613v6.531L8.36 20.94H3.387c-.849 0-1.54-.691-1.54-1.54v-8.047H0V19.4a3.39 3.39 0 003.387 3.387h4.388l11.686 8.25v-8.25h40.09a3.39 3.39 0 003.387-3.387V3.387A3.39 3.39 0 0059.551 0z"
      ></path>
      <path
        fill="#614983"
        d="M1.848 3.387c0-.849.69-1.54 1.54-1.54H50V0H3.387A3.39 3.39 0 000 3.387V12h1.848V3.387zM16 63l-.978-.21A1 1 0 0016 64v-1zm32.5 0v1a1 1 0 00.974-1.226L48.5 63zm-31.522.21c.476-2.224 1.67-5.55 4.086-8.3C23.452 52.191 27.06 50 32.5 50v-2c-6.06 0-10.202 2.475-12.939 5.59-2.708 3.083-4.015 6.758-4.539 9.2l1.956.42zM32.5 50c5.434 0 8.888 2.186 11.138 4.89 2.282 2.742 3.361 6.067 3.888 8.336l1.948-.452c-.557-2.398-1.727-6.073-4.3-9.164C42.572 50.48 38.567 48 32.5 48v2zm16 12H16v2h32.5v-2z"
      ></path>
      <circle
        cx="32"
        cy="39.5"
        r="8.5"
        stroke="#614983"
        strokeWidth="2"
      ></circle>
    </svg>
  );
};

const { string } = PropTypes;

SectionHowItWorksIconReview.defaultProps = {
  className: null,
};

SectionHowItWorksIconReview.propTypes = {
  className: string,
};

export default SectionHowItWorksIconReview;
