import axios from 'axios';

const REACT_APP_RAILS_API_URL = process.env.REACT_APP_RAILS_API_URL;

// ================ Action types ================ //

export const DISABLE_SCROLLING = 'app/UI/DISABLE_SCROLLING';
export const SUBSCRIBE_REQUEST = 'app/UI/SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS = 'app/UI/SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_ERROR = 'app/UI/SUBSCRIBE_ERROR';

// ================ Reducer ================ //

const initialState = {
  disableScrollRequests: [],
  subscribeRequest: false,
  subscribeSuccess: false,
  subscribeError: false,
  data: {}
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case DISABLE_SCROLLING: {
      const { componentId, disableScrolling } = payload;
      const disableScrollRequests = state.disableScrollRequests;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [
        ...disableScrollRequests,
        { componentId, disableScrolling },
      ];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    }
    case SUBSCRIBE_REQUEST:
      return {
        ...state, subscribeRequest: true, subscribeSuccess: false, subscribeError: false
      };
    case SUBSCRIBE_SUCCESS:
      return {
        ...state, subscribeRequest: false, subscribeSuccess: true, subscribeError: false, data: payload
      };
    case SUBSCRIBE_ERROR:
      return {
        ...state, subscribeRequest: false, subscribeSuccess: false, subscribeError: true, data: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

export const subscribeSuccess = payload => ({
  type: SUBSCRIBE_SUCCESS,
  payload
});
export const subscribeRequest = () => ({
  type: SUBSCRIBE_REQUEST
});
export const subscribeError = payload => ({
  type: SUBSCRIBE_ERROR,
  payload
});

// ================ Selectors ================ //

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.UI;
  return disableScrollRequests.some(r => r.disableScrolling);
};

export const subscribe = email => {
  return dispatch => {
    dispatch(subscribeRequest());

    const data = {
      email: email
    };

    return axios.post(`${REACT_APP_RAILS_API_URL}/api/v1/users/mailchimp_subscribe`, data)
      .then(response => {
        if (response.data.status !== 'pending') {
          dispatch(subscribeError(response.data.title))
        } else {
          dispatch(subscribeSuccess('Successfully sent'))
        }
      })
      .catch( error => dispatch(subscribeError('Unknown error')))
  };
};
