export const tutorsStatus = user => {
  if (!user){
    return undefined;
  }

  const isTutor = user.attributes &&
    user.attributes.profile &&
    user.attributes.profile.protectedData &&
    user.attributes.profile.protectedData.userType &&
    user.attributes.profile.protectedData.userType.toLowerCase() === 'tutor';
  
  const status = user.attributes &&
      user.attributes.profile &&
      user.attributes.profile.privateData &&
      user.attributes.profile.privateData.tutorsAppStatus;

  const tutorApproved = status === 'approved';

  if (!isTutor) {
    return 'student'
  } else if (!status){
    return 'nostatus'
  } else if (!!status && !tutorApproved){
    return 'pending'
  } else if (tutorApproved){
    return 'approved'
  }
}