import React, { Component } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  getApps,
  getTutor,
  approveTutor,
  declineTutor,
  getTutorAppByDate,
  getTutorChangeDates
} from '../../../containers/AdminPanelPage/AdminPanelPage.duck'
import { Modal, IconSpinner } from '../..'
import { TutorsAppForm } from '../../../forms';

import css from './AdminTutorsApps.css';

class AdminTutorsAppsComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      flex_id: null,
      histories: []
    };
  }

  componentDidMount(){
    this.props.onGetApps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tutorChangeDates !== this.props.tutorChangeDates) {
      this.setState({ histories: this.props.tutorChangeDates })
    }
  }

  showTutorsApp(flex_id){
    this.setState({flex_id}, () => {this.props.onGetTutor(flex_id)});
  }

  showTutorsAppHistory(flex_id) {
    this.props.onGetTutorChangeDates(flex_id);
  }

  getTutorsAppByDate(date) {
    this.props.onGetTutorAppByDate(date);
  }

  handleClose(){
    this.setState({flex_id: null, histories: []});
  }

  handleApprove(id){
    const {onApproveTutor} = this.props;

    onApproveTutor(id);
  }

  handleDecline(id){
    const {onDeclineTutor} = this.props;

    onDeclineTutor(id);
  }

  renderActionButton(t){
    const { tutorsStatusRequest } = this.props;
    const {flex_id, tutor: {status}} = t;

    const approved = status === 'approved';

    const action = approved ? id => this.handleDecline({id}) : id => this.handleApprove({id});
    const buttonTitle = approved ?
                        <FormattedMessage id="AdminPanelTutorsApps.buttonDecline"/> :
                        <FormattedMessage id="AdminPanelTutorsApps.buttonApprove"/>;

    return (
      <button
        onClick={() => action(flex_id)}
        disabled={tutorsStatusRequest}
      >
        {buttonTitle}
      </button>
    )
  }

  render() {
    const {
      tutorsApps,
      tutor,
      tutorProgress,
      tutorChangeDates,
      tutorChangeDatesProgress,
      onManageDisableScrolling,
      tutorAppByDate,
      tutorAppByDateProgress,
    } = this.props;

    const showModal = !!this.state.flex_id;
    const showHistoryModal = this.state.histories.length > 0;

    const email =  <FormattedMessage id={"AdminPanelTutorsApps.email"}/>
    const fullName =  <FormattedMessage id={"AdminPanelTutorsApps.fullName"}/>
    const status =  <FormattedMessage id={"AdminPanelTutorsApps.status"}/>
    const createdAt =  <FormattedMessage id={"AdminPanelTutorsApps.createdAt"}/>
    const tutorsAppsList = tutorsApps
      .filter(t => t.tutor)
      .sort((t1, t2) => new Date(t2.tutor.created_at).getTime() - new Date(t1.tutor.created_at).getTime());

    return (
      <div>
        <h3  className={css.transactionTableTitle}>
          <FormattedMessage id={"AdminPanelTutorsApps.title"}/>
        </h3>
        <table className={css.transactionTable}>
          <thead>
            <tr>
              <th>#</th>
              <th>{email}</th>
              <th>{fullName}</th>
              <th>{createdAt}</th>
              <th>{status}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {tutorsAppsList
           .map((t, i) =>
            <tr className={css.tdMargin} key={t.id}>
              <td>{i + 1}</td>
              <td>
                {t.tutor.email}
              </td>
              <td>
                {t.tutor.full_name}
              </td>
              <td>
                {t.tutor.created_at}
              </td>
              <td>
                {t.tutor.status}
              </td>
              <td>
                <button
                  onClick={() => this.showTutorsApp(t.flex_id)}
                >
                  <FormattedMessage id="AdminPanelTutorsApps.buttonShow"/>
                </button>
              </td>
              <td>
                <button onClick={() => this.showTutorsAppHistory(t.flex_id)}>
                  <FormattedMessage id="AdminPanelTutorsApps.buttonShowHistory"/>
                </button>
              </td>
              <td>
                {this.renderActionButton(t)}
              </td>
            </tr>
           )}
          </tbody>
        </table>

        <Modal
          id="AdminTutorsApps.tutorsAppPreview"
          isOpen={showModal}
          onClose={() => this.handleClose()}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {tutorProgress && (
            <div className={css.spinner}>
              <IconSpinner/>
            </div>
          )}

          {!tutorProgress && tutor && (
            <TutorsAppForm
              formId="tutorsAppFormPreview"
              initialValues={tutor.tutor}
              isPreview={true}
              attachments={[]}
              initialAttachments={tutor.tutor_application_attachment || []}
            />
          )}
        </Modal>

        <Modal
          id="AdminTutorsApps.tutorsAppHistoryByDate"
          isOpen={showHistoryModal}
          onClose={() => this.handleClose()}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {tutorChangeDatesProgress && (
            <div className={css.spinner}>
              <IconSpinner/>
            </div>
          )}

          <div className={css.dates}>
            {tutorChangeDates.length > 0 && tutorChangeDates.map(date =>
              <button className={css.dateElement} key={date[0]} onClick={() => this.getTutorsAppByDate(date[0])}>
                {new Date(date[1]).toString()}
              </button>
            )}
          </div>

          {!tutorAppByDateProgress && tutorAppByDate && (
            <TutorsAppForm
              formId="tutorsAppFormPreview"
              initialValues={tutorAppByDate}
              isPreview={true}
              attachments={[]}
              initialAttachments={[]}
            />
          )}
        </Modal>
      </div>
    );
  }
}

AdminTutorsAppsComponent.propTypes = {
  onManageDisableScrolling: func.isRequired
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    tutorsApps,
    tutorsAppsProgress,
    tutorsAppsError,
    tutor,
    tutorProgress,
    tutorError,
    tutorsStatusRequest,
    tutorsStatusError,
    tutorChangeDates,
    tutorChangeDatesProgress,
    tutorChangeDatesError,
    tutorAppByDate,
    tutorAppByDateProgress,
    tutorAppByDateError
  }  = state.AdminPanelPage;

  return {
    currentUser,
    tutorsApps,
    tutorsAppsProgress,
    tutorsAppsError,
    tutor,
    tutorProgress,
    tutorError,
    tutorsStatusRequest,
    tutorsStatusError,
    tutorChangeDates,
    tutorChangeDatesProgress,
    tutorChangeDatesError,
    tutorAppByDate,
    tutorAppByDateProgress,
    tutorAppByDateError
  };
};

const mapDispatchToProps = dispatch => ({
  onGetApps: () => dispatch(getApps()),
  onGetTutor: id => dispatch(getTutor(id)),
  onGetTutorChangeDates: id => dispatch(getTutorChangeDates(id)),
  onGetTutorAppByDate: id => dispatch(getTutorAppByDate(id)),
  onApproveTutor: id => dispatch(approveTutor(id)),
  onDeclineTutor: id => dispatch(declineTutor(id))
})

const AdminTutorsApps = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AdminTutorsAppsComponent);

export default AdminTutorsApps;
