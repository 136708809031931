import React, { useState, useEffect } from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  ChangeImageEditor,
} from '../../components';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { FaqComponentStudent, FaqComponentTeacher } from '../../components/FaqContentComponents';
import {
  getFaq,
  createFaq,
  editFaq,
  createReset,
  editReset,
  getImagesData,
  saveImages,
  editImages,
  saveImagesFaqReset,
  editImagesFaqReset,
} from './FAQPage.duck';
import { FormattedMessage } from '../../util/reactIntl';

import css from './FAQPage.css';
import image from './faq-page.jpg';
import { connect } from 'react-redux';

const FAQPage = props => {
  const {
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    onGetFaq,
    onGetImagesData,
    faqList,
    isLoading,
    error,
    onCreateFaq,
    onEditFaq,
    isCreating,
    createSuccess,
    onCreateReset,
    onEditReset,
    editingSuccess,
    createError,
    editingError,
    isImagesLoading,
    images,
    imagesError,
    saveImagesInProgress,
    saveImagesSuccess,
    saveImagesError,
    isEditImagesInProgress,
    editImagesSuccess,
    editImagesError,
    onSaveImagesFaqReset,
    onEditImagesFaqReset,
    onSaveImages,
    onEditImages,
  } = props;

  const userType =
    isAuthenticated &&
    currentUser &&
    currentUser.attributes.profile.protectedData.userType.toLowerCase();

  const [modalChangeImageOpen, setModalChangeImageOpen] = useState(false);
  const [typeSection, setTypeSection] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [firstImage, setFirstImage] = useState('');

  useEffect(() => {
    onGetFaq();
    onGetImagesData();
  }, []);

  useEffect(() => {
    if (saveImagesSuccess) {
      setModalChangeImageOpen(false);
    }
  }, [saveImagesSuccess]);

  useEffect(() => {
    if (editImagesSuccess) {
      setModalChangeImageOpen(false);
    }
  }, [editImagesSuccess]);

  useEffect(() => {
    if (images.length > 0) {
      images.map(item => {
        if (item.section && item.section === 'faq') {
          setFirstImage(item.image_file_name);
        }
      });
    }
  }, [images]);

  const imageChangeModal = type => {
    setTypeSection(type);
    const image =
      images && images.length > 0
        ? images.filter(img => img.section && img.section === type.split('_')[1])
        : null;
    setSelectedImage(image && image.length > 0 ? image[0] : null);
    setModalChangeImageOpen(true);
  };

  const handleChangeImageModalClose = () => {
    setModalChangeImageOpen(false);
    setTypeSection('');
    setSelectedImage(null);
    onSaveImagesFaqReset();
    onEditImagesFaqReset();
  };

  const handleChangeImage = data => {
    const isNewImage = !!!selectedImage;
    isNewImage ? onSaveImages(data) : onEditImages(selectedImage.id, data);
  };

  const createData = data => {
    onCreateFaq(data);
  };

  const editData = data => {
    onEditFaq(data);
  };

  // prettier-ignore
  return (
    <StaticPage
      title="FAQ"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        description: 'FAQ',
        name: 'FAQ page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <Modal
            id="FAQPage.changeimage"
            isOpen={modalChangeImageOpen}
            onClose={handleChangeImageModalClose}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <ChangeImageEditor
              editorTitle="AboutPage.changeTitleText"
              makeRequestFunction={data => handleChangeImage(data)}
              createError={saveImagesError}
              editingError={editImagesError}
              typeSection={typeSection}
              item={selectedImage}
              edit={false}
              inProgress={saveImagesInProgress || isEditImagesInProgress}
              modalChangeImageOpen={modalChangeImageOpen}
            />
          </Modal>
          <h1 className={css.pageTitle}>
            <FormattedMessage id="FaqPage.title" />
          </h1>
          {firstImage ? (
            <div className={css.imgContainer}>
              <img
                className={css.coverImage}
                src={firstImage ? firstImage : ''}
                alt="My first ice cream."
              />
              {userType === 'superadmin' && (
                <button className={css.changeBtn} onClick={() => imageChangeModal('faq_faq')}>
                  <FormattedMessage id="AboutPage.changeImage" />
                </button>
              )}
            </div>
          ) : userType === 'superadmin' ? (
            <div className={css.noImg}>
              <button className={css.changeBtn} onClick={() => imageChangeModal('faq_faq')}>
                <FormattedMessage id="AboutPage.changeImage" />
              </button>
            </div>
          ) : null}
          {error ? (
            <div className={css.errorMsqFaq}>
              <FormattedMessage id="FaqPage.error" />
            </div>
          ) : null}
          {isLoading && !error ? (
            <div className={css.loadingMsqFaq}>
              <FormattedMessage id="FaqPage.loading" />
            </div>
          ) : !error ? (
            <div className={css.contentWrapper}>
              {/*<div className={css.contentSide}>*/}
              {/*  <p>Yoga was listed by UNESCO as an intangible cultural heritage.</p>*/}
              {/*</div>*/}

              {userType === 'student' && (
                <FaqComponentStudent
                  onManageDisableScrolling={onManageDisableScrolling}
                  userType={userType}
                  createData={createData}
                  faqList={faqList}
                  editData={editData}
                  isCreating={isCreating}
                  createSuccess={createSuccess}
                  onCreateReset={onCreateReset}
                  onEditReset={onEditReset}
                  editingSuccess={editingSuccess}
                  createError={createError}
                  editingError={editingError}
                />
              )}
              {userType === 'tutor' && (
                <FaqComponentTeacher
                  onManageDisableScrolling={onManageDisableScrolling}
                  userType={userType}
                  createData={createData}
                  faqList={faqList}
                  editData={editData}
                  isCreating={isCreating}
                  createSuccess={createSuccess}
                  onCreateReset={onCreateReset}
                  onEditReset={onEditReset}
                  editingSuccess={editingSuccess}
                  createError={createError}
                  editingError={editingError}
                />
              )}
              {userType === 'superadmin' || !isAuthenticated ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3 className={css.subtitle}>FAQ for student</h3>
                  <FaqComponentStudent
                    onManageDisableScrolling={onManageDisableScrolling}
                    userType={userType}
                    createData={createData}
                    faqList={faqList}
                    editData={editData}
                    isCreating={isCreating}
                    createSuccess={createSuccess}
                    onCreateReset={onCreateReset}
                    onEditReset={onEditReset}
                    editingSuccess={editingSuccess}
                    createError={createError}
                    editingError={editingError}
                  />
                  <h3 className={css.subtitle}>FAQ for Teacher</h3>
                  <FaqComponentTeacher
                    onManageDisableScrolling={onManageDisableScrolling}
                    userType={userType}
                    createData={createData}
                    faqList={faqList}
                    editData={editData}
                    isCreating={isCreating}
                    createSuccess={createSuccess}
                    onCreateReset={onCreateReset}
                    onEditReset={onEditReset}
                    editingSuccess={editingSuccess}
                    createError={createError}
                    editingError={editingError}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const {
    faqList,
    isLoading,
    error,
    isCreating,
    createSuccess,
    createError,
    editingError,
    editingSuccess,
    isImagesLoading,
    images,
    imagesError,
    saveImagesInProgress,
    saveImagesSuccess,
    saveImagesError,
    isEditImagesInProgress,
    editImagesSuccess,
    editImagesError,
  } = state.FAQPage;
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    faqList,
    isLoading,
    error,
    isCreating,
    currentUser,
    isAuthenticated,
    createSuccess,
    createError,
    editingError,
    editingSuccess,
    isImagesLoading,
    images,
    imagesError,
    saveImagesInProgress,
    saveImagesSuccess,
    saveImagesError,
    isEditImagesInProgress,
    editImagesSuccess,
    editImagesError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onGetFaq: () => dispatch(getFaq()),
  onGetImagesData: () => dispatch(getImagesData()),
  onCreateFaq: data => dispatch(createFaq(data)),
  onEditFaq: data => dispatch(editFaq(data)),
  onCreateReset: () => dispatch(createReset()),
  onEditReset: () => dispatch(editReset()),
  onSaveImages: data => dispatch(saveImages(data)),
  onSaveImagesFaqReset: () => dispatch(saveImagesFaqReset()),
  onEditImages: (id, data) => dispatch(editImages(id, data)),
  onEditImagesFaqReset: () => dispatch(editImagesFaqReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQPage);
