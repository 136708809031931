import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  editAnnouncement,
  deleteAnnouncement,
  editReset,
  deleteReset,
} from '../../containers/AnnouncementsPage/AnnouncementsPage.duck';
import { AnnouncementEditor, Modal } from '../index';
import css from './AnnouncementsItem.css';
import { FormattedMessage } from '../../util/reactIntl';

const AnnouncementsItemComponent = props => {
  const {
    item,
    isAuthenticated,
    currentUser,
    onManageDisableScrolling,
    onEditAnnouncement,
    onDeleteAnnouncement,
    editingError,
    editingSuccess,
    onEditReset,
    deletingError,
    deletingSuccess,
    onDeleteReset,
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (editingSuccess) {
      setModalOpen(false);
    }
  }, [editingSuccess]);

  useEffect(() => {
    if (deletingSuccess) {
      setConfirmModalOpen(false);
    }
  }, [deletingSuccess]);

  const handleClose = () => {
    setModalOpen(false);
    onEditReset();
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    onDeleteReset();
    setConfirmModalOpen(false);
  };

  const handleDeleteBtn = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmButton = e => {
    e.persist();
    onDeleteReset();
    const confirm = e.currentTarget.value;
    if (confirm === 'true') {
      onDeleteAnnouncement(item.id);
    } else {
      setConfirmModalOpen(false);
    }
  };

  const userType =
    isAuthenticated &&
    currentUser &&
    currentUser.attributes.profile.protectedData.userType.toLowerCase();
  const editBtn = userType === 'superadmin' && (
    <button onClick={handleOpen} className={css.editButton}>
      <FormattedMessage id="AnnouncementsPage.editBtnText" />
    </button>
  );
  const deleteBtn = userType === 'superadmin' && (
    <button onClick={handleDeleteBtn} className={css.deleteButton}>
      <FormattedMessage id="AnnouncementsPage.deleteBtnText" />
    </button>
  );

  const formatdate = date => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

    return `${da}/${mo}/${ye}`;
  };

  const handleSave = data => {
    onEditReset();
    onEditAnnouncement(item.id, data);
  };

  return (
    <div className={css.announcementWrap}>
      <div className={css.editBtnsWrap}>
        {editBtn}
        {deleteBtn}
      </div>
      <div className={css.topRow}>
        <span className={css.title}>{item.title}</span>
      </div>
      <div className={css.bottomRow} dangerouslySetInnerHTML={{ __html: item.description }}></div>
      <span className={css.date}>{formatdate(item.created_at)}</span>
      <Modal
        id="AnnouncementsPage.addNewAnnouncement"
        isOpen={isModalOpen}
        onClose={handleClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <AnnouncementEditor
          editorTitle="AnnouncementsEditor.editTitleText"
          makeRequestFunction={data => handleSave(data)}
          item={item}
          editingError={editingError}
        />
      </Modal>
      <Modal
        id="AnnouncementsPage.confirmDelete"
        isOpen={isConfirmModalOpen}
        onClose={handleConfirmModalClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.confirmDeleteWrap}>
          <span className={css.confirmTitle}>
            <FormattedMessage id="AnnouncementsPage.confirmDeletePostMessage" />
          </span>
          <div className={css.confirmDeleteBtnsWrap}>
            <button value={true} className={css.deleteButton} onClick={handleConfirmButton}>
              <FormattedMessage id="ConfirmModal.yesBtn" />
            </button>
            <button value={false} className={css.editButton} onClick={handleConfirmButton}>
              <FormattedMessage id="ConfirmModal.noBtn" />
            </button>
          </div>
          {deletingError ? (
            <p className={css.deleteError}>
              <FormattedMessage id="ConfirmModal.errorDeleting" />
            </p>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onEditAnnouncement: (id, data) => {
    dispatch(editAnnouncement(id, data));
  },
  onDeleteAnnouncement: id => {
    dispatch(deleteAnnouncement(id));
  },
  onEditReset: () => {
    dispatch(editReset());
  },
  onDeleteReset: () => {
    dispatch(deleteReset());
  },
});

const AnnouncementsItem = compose(connect(mapStateToProps, mapDispatchToProps))(
  AnnouncementsItemComponent
);

export default AnnouncementsItem;
