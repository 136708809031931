import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionRulesPoliciesMaybe = props => {
  const { publicData } = props;
  
  if (!publicData.rulesPolicies) return null;

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.rulesPoliciesTitle" />
      </h2>
      <p>{publicData.rulesPolicies}</p>
    </div>
  );
};

export default SectionRulesPoliciesMaybe;
