import React from 'react';

import css from './TopbarSearchForm.css';

const IconHourGlass = () => (
  <svg
    className={css.keywordSearchIcon}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="#fff"
        d="M15.805 14.863l-4.55-4.55a6.302 6.302 0 001.411-3.979A6.34 6.34 0 006.333.001 6.34 6.34 0 000 6.334a6.34 6.34 0 006.333 6.333c1.507 0 2.89-.53 3.979-1.412l4.55 4.55a.665.665 0 00.943 0 .666.666 0 000-.942zm-9.472-3.529c-2.757 0-5-2.243-5-5s2.243-5 5-5c2.758 0 5 2.243 5 5s-2.242 5-5 5z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default IconHourGlass;
