import React, { Component } from 'react';
import css from './BookingTimeForm.css';
import { bool, func, node, object, string, array, number, oneOfType } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import Switch from "react-switch";
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { timestampToDate, getDefaultTimeZoneOnBrowser, calculateQuantityFromHours } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, PrimaryButton, FieldCheckbox } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      showSwitchBtn: false,
      lessonsItems: [],
      defaulLessonsType: null,
      group: [],
      private: [],
      maxDuration: 0,
      currentDuration: 0,
      localTimeZone: getDefaultTimeZoneOnBrowser()
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  componentDidMount() {
    const { listing } = this.props;
    const { publicData } = listing.attributes;
    const { sessionType, subjectItems } = publicData;

    const groupItems =
      sessionType.indexOf('group') !== -1 && subjectItems
        ? subjectItems.map(e => {
            return {
              [`${e}_group`]: new Money(
                publicData[`${e}_group`] ? publicData[`${e}_group`].amount : 0,
                publicData[`${e}_group`]
                  ? publicData[`${e}_group`].currency
                  : config.currencyConfig.currency
              ),
            };
          })
        : [];

    const privateItems =
      sessionType.indexOf('private') !== -1 && subjectItems
        ? subjectItems.map(e => {
            return {
              [`${e}_private`]: new Money(
                publicData[`${e}_private`] ? publicData[`${e}_private`].amount : 0,
                publicData[`${e}_private`]
                  ? publicData[`${e}_private`].currency
                  : config.currencyConfig.currency
              ),
            };
          })
        : [];

    this.setState(() => {
      return {
        group: groupItems,
        private: privateItems,
      }
    });

    this.manageSessionType(sessionType);
  }

  manageSessionType(sessionType){
    const {defaultSessionType} = this.props;

    if (!!defaultSessionType){
      this.setState({defaulLessonsType: defaultSessionType});
      return;
    }

    if (sessionType.length > 1) { // we have "group" and "private" lessons types
      this.setState(() => {
        return {
          showSwitchBtn: true,
          defaulLessonsType: 'group'
        }
      })
    } else { // we have only one type of lessons ("group" or "private")
      this.setState(() => {
        return {
          showSwitchBtn: false,
          defaulLessonsType: sessionType[0],
        }
      })
    }
  }

  onChangeDate(start, end) {
    const duration = (end - start)/60/60/1000;
    this.setState({maxDuration: duration, services: [], currentDuration: 0})
  }

  handleFormSubmit(e) {
    const { services } = this.state;
    const { onSubmit } = this.props;
    e.lessonsOrder = services;
    e.additionalItems = services;
    onSubmit(e, services);
  }

  onCheckedAdditionalItem(duration, serviceKey, e) {
    const { currentDuration } = this.state;
    if (e.currentTarget.checked) {
      this.setState((prev) => {
        return {
          services: [...prev.services, serviceKey],
          currentDuration: currentDuration + duration
        }
      })
    } else {
      this.props.useBonusDisabled();
      this.setState((prev) => {
        return {
          services: prev.services.filter(service => service !== serviceKey),
          currentDuration: currentDuration - duration
        }
      });
    }
  }

  handleSwitch() {
    this.props.useBonusDisabled();
    this.setState((prev) => {
      return {
        defaulLessonsType: prev.defaulLessonsType === 'private' ? 'group' : 'private',
        services: [],
        currentDuration: 0
      }
    })
  }

  onSubmit() {
    this.props.handleSubmit()
  }

  filterMonthlyTimeSlots(slots, listing, lessonsType){
    if (!slots || !(Object.keys(slots).length)){
      return {};
    }

    const isPrivate = lessonsType === 'private';
    const seats = listing.attributes.publicData.seats;

    const result = {};
    Object.keys(slots).forEach(time => {
      result[time] = {...slots[time]};

      if (result[time].timeSlots && !!result[time].timeSlots.length){
        result[time].timeSlots = result[time].timeSlots.filter(slot => {
          return (
            // remove all time slots where seats !== listings seats when selected private lesson type
            !(isPrivate && slot.attributes.seats !== null && slot.attributes.seats != seats) &&
            // remove all time slots what smaller then 1 hour
            Math.abs(calculateQuantityFromHours(slot.attributes.end, slot.attributes.start)) >= 1 ||
            this.props.additionalSession
          )
        })
      }
    });

    return result;
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      listing,
      bonusCustomer,
      onUpdateBonusCustomer,
      initialValues,
      disabledFields,
      timeStep,
      showSessionSwitch,
      additionalSession,
      children,
      allSubjects,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const {localTimeZone} = this.state;

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        keepDirtyOnReinitialize={!!initialValues}
        initialValues={initialValues}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            formId,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            currentUser,
            timeZone,
            onChangeUseBonus,
            useBonus,
            useBonusCheckbox,
            submitButtonTitle,
            isBonusesAvailable,
            isUpdateBooking,
            diffBetweenStartAndEnd,
            minutesFromDiffRange,
            updateInProgress,
          } = fieldRenderProps;
          const {showSwitchBtn, defaulLessonsType} = this.state;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });

          const switchLeftLabel = intl.formatMessage({
            id: 'BookingTimeForm.groupLessons',
          });
          const switchRightLabel = intl.formatMessage({
            id: 'BookingTimeForm.privateLessons',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          const res = this.state.services ? {} : null;
          if (this.state.services) {
            const formaArray = [...this.state.private, ...this.state.group];
            this.state.services.forEach((itemSelect) => {
              formaArray.forEach((item) => {
                if (Object.keys(item) == itemSelect) {
                  res[itemSelect] = item[Object.keys(item)];
                }
              })
            });
          }

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  unitPrice,
                  startDate,
                  endDate,
                  quantity: this.state.defaulLessonsType === 'private' ? parseInt(listing.attributes.publicData.seats) : 1,
                  timeZone: localTimeZone,
                  lessonOrder: this.state.services,
                  ...res
                }
              : null;
          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                {!isUpdateBooking && <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />}
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                selectedItems={this.state.services}
                listing={listing}
                lessonType={this.state.defaulLessonsType}
                bonusCustomer={bonusCustomer}
                useBonusCheckbox={useBonusCheckbox}
                onUpdateBonusCustomer={onUpdateBonusCustomer}
              />
            </div>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const isPrivate = listing.attributes.publicData.sessionType.length === 1 && listing.attributes.publicData.sessionType[0] === 'private';

          const isGroupSession = this.state.defaulLessonsType === 'group';
          const isPrivateSession = this.state.defaulLessonsType === 'private';

          const getDuration =  key => parseFloat((
            listing.attributes.publicData[key].hours * 60 +
            listing.attributes.publicData[key].minutes) / 60);

          const renderList = (additionalItems) => {
            return additionalItems.map((item, i) => {
              const key = Object.keys(item);
              let splt = key[0].split('_');
              const formattedKey = splt.slice(0, splt.length - 1).join('_')
              const duration =
                !isPrivate && !isPrivateSession && listing.attributes.publicData[formattedKey]
                  ? getDuration(formattedKey)
                  : 0;
              const disabled = !(additionalSession && !this.state.services.length) && this.state.services.indexOf(key[0]) === -1 &&
                                (this.state.services.length > 0 ||
                                  (duration > this.state.maxDuration || duration + this.state.currentDuration > this.state.maxDuration));
              const addClass = disabled ? css.blockService : 'activeService';
              const service = allSubjects && allSubjects.find(s => s.key === formattedKey);
              return (
                <div className={css.cleaningFee} key={key[0]}>
                  {service && service.label ?
                    (<FieldCheckbox
                      className={`${addClass} additionalItems`}
                      id={`${formId}.${key[0]}`}
                      label={intl.formatMessage(
                        { id: 'BookingDatesForm.service' },
                        { label: service && service.label }
                      )}
                      name="additionalItems"
                      value={key[0]}
                      checked={this.state.services.indexOf(key[0]) !== -1}
                      servicekey={key[0]}
                      duration={isGroupSession ? duration : 0}
                      hours={
                        isGroupSession && listing.attributes.publicData[formattedKey]
                          ? listing.attributes.publicData[formattedKey].hours
                          : 0
                      }
                      minutes={
                        isGroupSession && listing.attributes.publicData[formattedKey]
                          ? listing.attributes.publicData[formattedKey].minutes
                          : 0
                      }
                      disabled={disabled}
                      onChange={e =>
                        this.onCheckedAdditionalItem(
                          parseFloat(e.currentTarget.getAttribute('duration')),
                          e.currentTarget.getAttribute('servicekey'),
                          e
                        )
                      }
                    />) : null }
                  {service && service.label ?
                    (<span className={css.cleaningFeeAmount}>{`${formatMoney(
                      intl,
                      additionalItems[i][key]
                    )} ${
                      this.state.defaulLessonsType === 'group' ? 'per session' : 'per hour'
                    }`}</span>) : null }
                </div>
              );
            });
          };

          const additionalItemsExist = this.state.services.length === 0;

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const userTypes = currentUser !== null ?  currentUser.attributes.profile.privateData.userType : null;

          const buttonTitle = () => {
            if (!!submitButtonTitle){
              return typeof submitButtonTitle !== 'string' ? submitButtonTitle : <FormattedMessage id={submitButtonTitle} />
            }

            return <FormattedMessage id="BookingTimeForm.requestToBook" />;
          }

          const showRequestToBookButton = userTypes === 'Student' || isUpdateBooking ? (
              <div className={submitButtonClasses}>
                <PrimaryButton
                  type="submit"
                  inProgress={updateInProgress}
                  disabled={isUpdateBooking && values.updateBookingEndDate && values.updateBookingEndDate.date ? false : additionalItemsExist}>
                  {buttonTitle()}
                </PrimaryButton>
              </div>
            ) : null;

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {showSessionSwitch && showSwitchBtn &&
                <div className={css.lessonsSwitcherWrap}>
                  <label>{switchLeftLabel}</label>
                    <Switch
                      onChange={(e) => this.handleSwitch(e)}
                      checked={defaulLessonsType === 'private'}
                      className={css.bookingTimeFormSwitch}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor='#614983'
                      activeBoxShadow="0 0 2px 3px #614983"
                    />
                  <label>{switchRightLabel}</label>
                </div>
              }
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  listing={this.props.listing}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={this.filterMonthlyTimeSlots(monthlyTimeSlots, listing, defaulLessonsType)}
                  values={values}
                  onChangeDate={this.onChangeDate}
                  currentDuration={this.state.currentDuration}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  lessonType={this.state.defaulLessonsType}
                  initialValues={initialValues}
                  disabledFields={disabledFields}
                  timeStep={timeStep}
                  localTimeZone={localTimeZone}
                  isUpdateBooking={isUpdateBooking}
                  diffBetweenStartAndEnd={diffBetweenStartAndEnd}
                  minutesFromDiffRange={minutesFromDiffRange}
                />
              ) : null}
              {defaulLessonsType === 'private' && Object.keys(this.state.private).length !== 0 && !isUpdateBooking ? <label className={css.switchLabel}>{switchRightLabel}</label>: null}
              {defaulLessonsType === 'private' && Object.keys(this.state.private).length !== 0 && !isUpdateBooking ? renderList(this.state.private) : null}
              {defaulLessonsType === 'group' && Object.keys(this.state.group).length !== 0 && !isUpdateBooking ? <label className={css.switchLabel}>{switchLeftLabel}</label>: null}
              {defaulLessonsType === 'group' && Object.keys(this.state.group).length !== 0 && !isUpdateBooking ? renderList(this.state.group) : null}
              {isBonusesAvailable && !isUpdateBooking &&
                <>
                  <hr className={css.hr}/>
                  <FieldCheckbox
                  className={css.bonusCheckbox}
                  id="useBonus"
                  name="useBonus"
                  label="Use bonus"
                  disabled={!useBonus}
                  defaultValue={useBonusCheckbox}
                  onChange={e => onChangeUseBonus(e)}
                  />
                </>
              }
              {bookingInfo}

              {children}
              
              <p className={css.smallPrint}>
                {!isUpdateBooking && <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />}
              </p>
              {showRequestToBookButton}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  currentUser: null,
  initialValues: {},
  showSessionSwitch: true
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  submitButtonTitle: oneOfType([string, node]),

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  currentUser: propTypes.currentUser,
  initialValues: object,
  disabledFields: array,
  timeStep: propTypes.timeStep,
  defaultSessionType: string,
  showSessionSwitch: bool,
  additionalSession: bool,
  isUpdateBooking: bool,
  diffBetweenStartAndEnd: number,
  minutesFromDiffRange: number,
  updateInProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};


const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
