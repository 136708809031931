import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_PROVIDER_UPDATE_AFTER_ACCEPT,
  TRANSITION_CUSTOMER_UPDATE_AFTER_PREAUTHORIZED,
  TRANSITION_CANCEL,
  TRANSITION_PROVIDER_UPDATE_CHAINED,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsAwaitUpdate,
  txIsAcceptedAfterUpdate
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
  getFormatedDisplayName
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  FieldCheckbox,
  Form,
} from '../../components';
import { SendMessageForm, TransactionAttachmentForm, BookingTimeForm } from '../../forms';
import config from '../../config';
import { Form as FinalForm } from 'react-final-form';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import ChainedTransaction from './ChainedTransaction';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_UPDATE_REQUESTED
} from './PanelHeading';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import {
  monthIdStringInTimeZone,
  formatDateStringToUTC,
  isSameDate,
  getDefaultTimeZoneOnBrowser,
  calculateQuantityFromHours,
  isInRange,
  getMonthStartInTimeZone, timestampToDate,
} from '../../util/dates';

import css from './TransactionPanel.css';
import { parse, stringify } from '../../util/urlHelpers';
import omit from 'lodash/omit';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = getFormatedDisplayName(currentProvider);
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = getFormatedDisplayName(currentCustomer);
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const getTimeSlots = (timeSlots, start, end, timeZone) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter(t =>
      moment(start)
        .tz(timeZone)
        .isBetween(
          moment(t.attributes.start).tz(timeZone),
          moment(t.attributes.end).tz(timeZone),
          'minute',
          '[)' ) ||
      moment(end)
        .tz(timeZone)
        .isBetween(
          moment(t.attributes.start).tz(timeZone),
          moment(t.attributes.end).tz(timeZone),
          'minute',
          '(]' )

    )
    : [];
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
    ? monthlyTimeSlots[monthId].timeSlots
    : [];
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      bonusCustomer: null,
      useBonus: false,
      useBonusCheckbox: false,
      sendCheckBonusRequest: false,
      percentageSum: 0,
      additionalTimeBorder: 3600000, //one hour in ms
      additionalTimeStep: 600000, // 10 minutes in ms
      isAvailableToExtend: false,
      localTimeZone: getDefaultTimeZoneOnBrowser(),
      isEditBookingActive: false,
      editWithAdditionalTime: true,
      currentMonth: null,
      selectedMonth: null
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.onUpdateBonusCustomer = this.onUpdateBonusCustomer.bind(this);
    this.onChangeUseBonus = this.onChangeUseBonus.bind(this);
    this.useBonusDisabled = this.useBonusDisabled.bind(this);
    this.clickSubmit = this.clickSubmit.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
    const {transaction} = this.props;
    const timeZone = transaction && transaction.listing &&
      transaction.listing.attributes.availabilityPlan && transaction.listing.attributes.availabilityPlan.timezone;
    const bookingStart = transaction && transaction.booking &&
                         transaction.booking.attributes &&
                         transaction.booking.attributes.start;
      this.setState({
      isAvailableToExtend: this.checkAvailableToExtend(transaction),
      currentMonth: getMonthStartInTimeZone(Date.now(), timeZone),
      selectedMonth: bookingStart ? getMonthStartInTimeZone(bookingStart, timeZone) : null
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {currentUser, onCheckBonus, monthlyTimeSlots, transaction} = this.props;
    const {sendCheckBonusRequest, useBonus} = this.state;

    if ((currentUser !== null && prevProps.currentUser === null) || (prevState.bonusCustomer === null && currentUser && !sendCheckBonusRequest)) {
      this.setState({sendCheckBonusRequest: true});
      onCheckBonus(currentUser.id.uuid, 'customer')
        .then(res => this.setState({bonusCustomer: parseFloat(res) || 0}))
    }

    if (useBonus !== prevState.useBonus && !useBonus) {
      this.setState({useBonusCheckbox: false})
    }

    if (monthlyTimeSlots != prevProps.monthlyTimeSlots){
      this.setState({isAvailableToExtend: this.checkAvailableToExtend(transaction)})
    }
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  async clickSubmit(values) {
    const { onFetchTransaction, transaction } = this.props;

    const id = transaction && transaction.id;
    await onFetchTransaction(id, 'costumer').then(res => {
      const reviewsFromListing =
        res.data.data.attributes.publicData.studentsReviews ?
        res.data.data.attributes.publicData.studentsReviews : null;
      this.onSubmitReview(values, reviewsFromListing);
    });
  }

  onSubmitReview(values, studentsReviews) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);

    const listingId = currentTransaction.listing.id;

    const totalRating = studentsReviews && studentsReviews.totalRating;
    const totalVotes = studentsReviews && studentsReviews.totalVotes;

    const newTotalVotes = totalVotes ? totalVotes + 1 : 1;
    const newRating = totalRating
      ? (Math.round(totalRating * totalVotes + rating) / newTotalVotes).toFixed(1)
      : rating;

    const newStudentsReviews = { totalRating: newRating, totalVotes: newTotalVotes };

    onSendReview(
      transactionRole,
      currentTransaction,
      rating,
      reviewContent,
      listingId,
      newStudentsReviews
    )
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  getBookedEndTime(t){
    const {booking} = t;

    if (!booking || !booking.attributes || (!booking.attributes.displayEnd && !booking.attributes.end)){
      return false;
    }

    return booking.attributes.displayEnd || booking.attributes.end;
  }

  getAdditionalSlots(start, end){
    const {monthlyTimeSlots, transaction} = this.props;
    const {selectedMonth, currentMonth} = this.state;
    const timeZone = transaction && transaction.listing &&
      transaction.listing.attributes.availabilityPlan && transaction.listing.attributes.availabilityPlan.timezone;

    const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      selectedMonth || currentMonth,
      timeZone
    );

    const additionalSlots = getTimeSlots(
      timeSlotsOnSelectedMonth,
      start,
      end,
      timeZone
    );

    if (
      additionalSlots && additionalSlots.length &&
      moment(additionalSlots[additionalSlots.length - 1].attributes.end).isAfter(end)) {

      additionalSlots[additionalSlots.length - 1].attributes.end = moment(end).tz(timeZone).toDate()
    }

    return additionalSlots;
  }

  checkAvailableToExtend(t){
    const {currentUser} = this.props;
    const currentListing = t.listing;
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    if (isOwnListing){
      return false;
    }

    const start = this.getBookedEndTime(t);

    if (!start){
      return false;
    }

    return +moment(start) > Date.now() && this.hasAvailableTimeSlot(start);
  }

  hasAvailableTimeSlot(start){
    const {transaction} = this.props;
    const seats = this.getCtListingSeats(transaction);
    const end = +moment(start) + this.state.additionalTimeBorder;

    const slots = this.getAdditionalSlots(
      start,
      end
    ).filter(item => item && item.attributes && item.attributes.seats == seats);

    return !!(slots && slots.length);
  }

  useBonusDisabled() {
    this.setState({useBonusCheckbox: false})
  }

  onUpdateBonusCustomer(bool, percentageSum) {
    if (this.state.useBonus !== bool || this.state.percentageSum !== percentageSum) {
      this.setState({useBonus: bool, percentageSum});
    }
  }

  onChangeUseBonus(e) {
    this.setState({useBonusCheckbox: !!e.currentTarget.checked})
  }

  createCustomSlots(ct, timeZone){
    const {monthlyTimeSlots} = this.props;

    const seats = this.getCtListingSeats(ct);
    const start = this.getBookedEndTime(ct);
    const dateString = monthIdStringInTimeZone(start, timeZone);

    // const nextTimeSlot = monthlyTimeSlots[dateString] &&
    //   !monthlyTimeSlots[dateString].fetchTimeSlotsError &&
    //   !monthlyTimeSlots[dateString].fetchTimeSlotsInProgress &&
    //   !!monthlyTimeSlots[dateString].timeSlots &&
    //   monthlyTimeSlots[dateString].timeSlots.find(
    //     item => item && item.attributes && item.attributes.seats == seats && isSameDate(item.attributes.start, time)
    //   );

    const end = +moment(start) + this.state.additionalTimeBorder;

    const slots = this.getAdditionalSlots(
      start,
      end
    ).filter(item => item && item.attributes && item.attributes.seats == seats);

    if (!slots){
      return {};
    }

    // const nextTimeslotEndTimeMaybe = moment(time)
    //   .clone()
    //   .tz(timeZone)
    //   .add(1, 'hour')
    //   .startOf('hour');

    // const nextSlotDurationLessThenHour = Math.abs(calculateQuantityFromHours(nextTimeSlot.attributes.end, nextTimeSlot.attributes.start)) < 1;

    // const start = formatDateStringToUTC(time);
    // const end = nextSlotDurationLessThenHour ?
    //   formatDateStringToUTC(+nextTimeslotEndTimeMaybe) :
    //   formatDateStringToUTC(+moment(time) + this.state.additionalTimeBorder);

    return {
      [dateString]: {
        "fetchTimeSlotsError": null,
        "fetchTimeSlotsInProgress": false,
        "timeSlots": slots
        // [{
        //     "id": {
        //         "_sdkType": "UUID",
        //         "uuid": "custom"
        //     },
        //     "type": "timeSlot",
        //     "attributes": {
        //         "type": "time-slot/time",
        //         seats,
        //         start,
        //         end
        //     }
        // }]

      }
    }
  }

  getCtListingSeats(ct){
    return !!ct && !!ct.listing && ct.listing.attributes && !!ct.listing.attributes.publicData && ct.listing.attributes.publicData.seats || null;
  }

  onSubmit(values, transactionAccepted, parentTransactionId){
    const {isAvailableToExtend, useBonusCheckbox, percentageSum} = this.state;

    this.props.onSubmitBookingRequest({
      ...values,
      ...(isAvailableToExtend && transactionAccepted ? {
        additionalSession: true,
        useBonusCheckbox,
        percentageSum,
        parentTransactionId
      } : {}),
    })
  }

  onSubmitAttachment(e) {}

  setEditBookigPanelActive = () => {
    this.setState((prev) => {
      return { isEditBookingActive: !prev.isEditBookingActive }
    })
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      transactionChained,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onUpdateSale,
      onDeclineUpdateSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      monthlyTimeSlots,
      nextTransitions,
      onRequestToUpdateSale,
      updateInProgress,
      allSubjects,
      onAcceptUpdateSale
    } = this.props;

    const { isAvailableToExtend, editWithAdditionalTime } = this.state;

    const ct = ensureTransaction(transaction); // current Transaction
    const currentListing = ensureListing(ct.listing);
    const currentProvider = ensureUser(ct.provider);
    const currentCustomer = ensureUser(ct.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';


    const timeZone =
      currentListing.attributes.availabilityPlan && currentListing.attributes.availabilityPlan.timezone;
    const {localTimeZone} = this.state;
    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
          showEditBtn: isCustomer,
          beforeAccept: true
        };
      } else if (txIsAccepted(tx) || txIsAcceptedAfterUpdate(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showEditBtn: isProvider && !isCustomerBanned,
          isAccepted: true,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsAwaitUpdate(tx)) {
        return {
          headingState: HEADING_UPDATE_REQUESTED,
          showAddress: isCustomer,
          showDetailCardHeadings: isCustomer,
          showAwaitButtons: isCustomer
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(ct);

    const transactionAccepted = [HEADING_DELIVERED, HEADING_ACCEPTED].includes(stateData.headingState);
    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => {onAcceptSale(ct.id, ct.provider, ct.customer, ct, currentUser)}}
        onDeclineSale={() => onDeclineSale(ct.id, ct.customer, ct.attributes.protectedData.customerBonus, ct.provider, ct.attributes.protectedData.providerBonus)}
      />
    );

    const awaitUpdateButtons = (
      <SaleActionButtonsMaybe
        showButtons={!!stateData.showAwaitButtons}
        acceptInProgress={updateInProgress}
        declineInProgress={updateInProgress}
        // acceptSaleError={acceptSaleError}
        // declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptUpdateSale(ct, true)}
        onDeclineSale={() => onDeclineUpdateSale(ct)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const additionalId = ct.attributes.protectedData && ct.attributes.protectedData.additionalTimeTransactionId;
    const parentId = ct.attributes.protectedData && ct.attributes.protectedData.parentTransactionId;

    const renderBookingPanel = transactionAccepted => {
      if ((!stateData.showBookingPanel && !(isAvailableToExtend && transactionAccepted)) || this.state.isEditBookingActive || (!!additionalId || !!parentId)){
        return null;
      }

      let title = null;
      let subTitle = null;
      let additionalTimeData = {};

      if (stateData.showBookingPanel) {
        title = listingTitle;
        subTitle = bookingSubTitle;
      }

      if (isAvailableToExtend){
        const customMonthlySlots = this.createCustomSlots(ct, timeZone);

        additionalTimeData = {
          submitButtonTitle: 'TransactionPanel.additionalTimeButtonTitle',
          disabledFields: [
            'bookingStartDate',
            'bookingEndDate'
          ],
          initialValues: {bookingStartDate: {date: this.getBookedEndTime(ct)}, useBonus: false},
          timeStep: this.state.additionalTimeStep,
          defaultSessionType: 'private',
          showSessionSwitch: false,
          additionalSession: true,
          monthlyTimeSlots: customMonthlySlots
        }

        title = intl.formatMessage({
          id: 'TransactionPanel.getAdditionalTime',
        });
      }

      return (
        <BookingPanel
          className={css.bookingPanel}
          listing={currentListing}
          isOwnListing={false}
          titleClassName={css.bookingTitle}

          onUpdateBonusCustomer={this.onUpdateBonusCustomer}
          bonusCustomer={this.state.bonusCustomer}
          useBonus={this.state.useBonus}
          useBonusCheckbox={this.state.useBonusCheckbox}
          onChangeUseBonus={this.onChangeUseBonus}
          useBonusDisabled={this.useBonusDisabled}

          onSubmit={values => this.onSubmit(values, transactionAccepted, ct.id.uuid)}
          title={title}
          subTitle={subTitle}
          authorDisplayName={authorDisplayName}
          onManageDisableScrolling={onManageDisableScrolling}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          currentUser={currentUser}
          allSubjects={allSubjects}
          {...additionalTimeData}
        />
      )
    }


    const awaitData = ct.attributes.protectedData &&
                      ct.attributes.protectedData.awaitData ||
                      transactionChained &&
                      transactionChained.attributes &&
                      transactionChained.attributes.protectedData &&
                      transactionChained.attributes.protectedData.awaitData ||
                      null;
    const chainedData = ct.attributes.protectedData &&
                        ct.attributes.protectedData.chainedData &&
                        ct.attributes.protectedData.chainedData.params ||
                        transactionChained &&
                        transactionChained.attributes &&
                        transactionChained.attributes.protectedData &&
                        transactionChained.attributes.protectedData.chainedData &&
                        transactionChained.attributes.protectedData.chainedData.params ||
                        null;
    const hasCancelData = ct.attributes.protectedData &&
                        !!ct.attributes.protectedData.cancelData ||
                        transactionChained &&
                        transactionChained.attributes &&
                        transactionChained.attributes.protectedData &&
                        !!transactionChained.attributes.protectedData.cancelData ||
                        null;

    const isAdditionalTransaction = !!parentId;
    const hasAdditionalTransaction = !!additionalId;
    const cancelMain = isAdditionalTransaction && hasCancelData;
    const cancelChained = hasAdditionalTransaction && hasCancelData;
    const isParentTransaction = hasAdditionalTransaction || !isAdditionalTransaction;

    const renderBookingPanelForUpdate = transactionAccepted => {
      if (!this.state.isEditBookingActive) {
        return null;
      }
      const { isEditBookingActive } = this.state;
      const isAccepted = !stateData.beforeAccept || stateData.isAccepted;

      const bookingStart = ct.booking.attributes.start;
      const bookingEnd = ct.booking.attributes.end;
      let diffBetweenStartAndEnd = moment(bookingEnd).diff(bookingStart, 'hours', true);
      let minutesFromDiffRange = (moment(bookingEnd).diff(bookingStart, 'minutes', true)) % 60;
      let chainedDiffBetweenStartAndEnd = 0;
      let chainedMinutesFromDiffRange = 0;
      let chainedOffsetParent = 0;
      let chainedOffset = 0;

      const withChained = editWithAdditionalTime && transactionChained && hasAdditionalTransaction;
      const cancelChained = !editWithAdditionalTime && transactionChained && hasAdditionalTransaction;

      if (withChained) {
        const chainedBookingStart = transactionChained.booking.attributes.start;
        const chainedBookingEnd = transactionChained.booking.attributes.end;
        chainedOffsetParent = moment(chainedBookingStart).diff(bookingEnd, 'minutes', true);
        chainedOffset = moment(chainedBookingStart).diff(bookingEnd, 'hours', true);
        chainedDiffBetweenStartAndEnd = moment(chainedBookingEnd).diff(chainedBookingStart, 'hours', true);
        chainedMinutesFromDiffRange = moment(chainedBookingEnd).diff(chainedBookingStart, 'minutes', true);
      }

      diffBetweenStartAndEnd += chainedDiffBetweenStartAndEnd + chainedOffset;
      minutesFromDiffRange = withChained ? 10 : minutesFromDiffRange;

      let title = null;
      let subTitle = null;
      let editTimeData = {};

      if (stateData.showEditBtn) {
        // title = listingTitle;
        subTitle = bookingSubTitle;
      }

      editTimeData = {
        submitButtonTitle: "TransactionPanel.editTimeButtonTitle", //updateInProgress ? 'TransactionPanel.updateTimeButtonTitle' :
        disabledFields: [
          'bookingEndDate',
          'bookingEndTime'
        ],
        defaultSessionType: 'private',
        showSessionSwitch: false,
        isUpdateBooking: true,
        diffBetweenStartAndEnd,
        minutesFromDiffRange,

      }

      title = intl.formatMessage({
        id: 'TransactionPanel.editTime',
      });

      const createUpdateStructure = (startTime, endDate, startDate) => {
        const start = new Date(moment(Number(startTime)).toString());
        const end = new Date(moment(endDate).toString());

        return {
          bookingStart: start,
          bookingEnd: end,
          displayBookingStart: start,
          displayBookingEnd: end,
          protectedData: {
            updatedAt: {
              year: moment().utc().add(6, 'days').year(),
              month: moment().utc().add(6, 'days').month() + 1,  // because moment return 0..11 months
              day: moment().utc().add(6, 'days').date(),
              hours: moment().utc().add(6, 'days').hours(),
              minutes: moment().utc().add(6, 'days').minutes(),
              seconds: moment().utc().add(6, 'days').seconds(),
              milliseconds: 0
            },
            start: moment(startDate).toString()
          }
        }
      };

      const submit = values => {
        const startTime = values.bookingStartTime;
        const startDate = values.bookingStartDate.date;
        let endDate = values.bookingEndDate.date;

        onUpdateSale(
          ct.id,
          ct.provider,
          ct.customer,
          ct,
          createUpdateStructure(startTime, endDate, startDate),
          false,
        )
        .then(() => {
          this.setState({
            isEditBookingActive: false
          });
        }).catch(e => {
          //TODO error handler need to be added
          // window.location.reload(false);
        })
      }

      const submitAccepted = values => {
        const seats = values.seats || 0;
        const startTime = values.bookingStartTime;
        const startDate = values.bookingStartDate.date;
        let endDate = values.bookingEndDate.date;

        let chainedData = null;
        let cancelData = null;

        if (withChained) {
          const startTime = Number(moment(values.bookingEndDate.date).subtract(chainedMinutesFromDiffRange, 'minutes'));
          const startDate = moment(startTime).toDate();
          const chainedEndTime = values.bookingEndDate.date;

          endDate = moment(values.bookingEndDate.date).subtract(chainedOffsetParent + chainedMinutesFromDiffRange, 'minutes');

          chainedData = {
            id: transactionChained.id.uuid,
            transition: TRANSITION_PROVIDER_UPDATE_CHAINED,
            params: createUpdateStructure(startTime, chainedEndTime, startDate)
          };
        }

        if (cancelChained) {
          cancelData = {
            id: transactionChained.id.uuid,
            transition: TRANSITION_CANCEL,
            params: {}
          };
        }

        const exceptionStart = moment(Number(values.bookingStartTime)).toString();
        const exceptionEnd = moment(values.bookingEndDate.date).toString();

        const isBefore = moment(Number(startTime)).isBefore(moment(ct.booking.attributes.start));
        const expireDate = isBefore ? exceptionStart : bookingStart;
        const exceptionSeats = seats - (withChained ? transactionChained.booking.attributes.seats : ct.booking.attributes.seats);


        onRequestToUpdateSale(
          ct.id,
          ct.provider,
          ct.customer,
          ct.listing,
          {awaitData: createUpdateStructure(startTime, endDate, startDate)},
          isBefore,
          expireDate,
          ct.listing.id.uuid,
          exceptionStart,
          exceptionEnd,
          exceptionSeats < 0 ? 0 : exceptionSeats,
          chainedData,
          cancelData
        )
        .then(() => {
          this.setState({
            isEditBookingActive: false,
            editWithAdditionalTime: true
          });
        }).catch(e => {
          //TODO error handler need to be added
          // window.location.reload(false);
        })
      }

      const filtMonthlySlots = slots => {
        if (!hasAdditionalTransaction || !editWithAdditionalTime) {
          return slots;
        }
        const seats = currentListing.attributes.publicData.seats;

        const _slots = {...slots};
        for (let month in _slots) {
          _slots[month] = {
            ..._slots[month],
            timeSlots: _slots[month].timeSlots && _slots[month].timeSlots.filter(slot => slot.attributes.seats == seats)} || null;
        }

        return _slots;
      };

      return (
        <BookingPanel
          className={css.bookingPanel}
          listing={currentListing}
          isOwnListing={false}
          titleClassName={css.bookingTitle}
          onUpdateBonusCustomer={this.onUpdateBonusCustomer}
          bonusCustomer={this.state.bonusCustomer}
          useBonus={this.state.useBonus}
          useBonusCheckbox={this.state.useBonusCheckbox}
          onChangeUseBonus={this.onChangeUseBonus}
          useBonusDisabled={this.useBonusDisabled}
          onSubmit={isAccepted ? submitAccepted : submit}
          title={title}
          subTitle={subTitle}
          authorDisplayName={authorDisplayName}
          onManageDisableScrolling={onManageDisableScrolling}
          monthlyTimeSlots={filtMonthlySlots(monthlyTimeSlots)}
          onFetchTimeSlots={onFetchTimeSlots}
          currentUser={currentUser}
          isEditBookingActive={isEditBookingActive}
          updateInProgress={updateInProgress}
          {...editTimeData}
        >
          {hasAdditionalTransaction && (
            <>
              <FinalForm
                onSubmit={() => {}}
                render={() => {
                  return (
                    <FieldCheckbox
                      id="withAdditionalTime"
                      label={intl.formatMessage({ id: 'TransactionPanel.withAdditionalTransactionCheckboxLabel' })}
                      name="withAdditionalTime"
                      checked={editWithAdditionalTime}
                      onChange={() => this.setState({editWithAdditionalTime: !editWithAdditionalTime})}
                    />
                  )
                }}
              />

              <p className={css.editWithAdditionalTimeNote}>
                <FormattedMessage id="TransactionPanel.withAdditionalTransactionNote"/>
              </p>
            </>
          )}
        </BookingPanel>
      )
    }

    const isCanBeUpdated =
      ct.booking && ct.booking.attributes ? moment(ct.booking.attributes.start).diff(moment(), 'hours', true) > 0 : true;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
              listingId={currentListing.id && currentListing.id.uuid}
              listingDeleted={listingDeleted}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                transaction={ct}
                transactionRole={transactionRole}
                localTimeZone={localTimeZone}
                awaitToCancel={cancelMain}
                updateData={isParentTransaction ? awaitData : chainedData}
              />

              <ChainedTransaction
                transaction={transactionChained}
                transactionRole={transactionRole}
                localTimeZone={localTimeZone}
                awaitToCancel={cancelChained}
                updateData={isParentTransaction ? chainedData : awaitData}
              />

              {!isAdditionalTransaction && stateData.showEditBtn && isCanBeUpdated && (
                <div className={css.editDatesContainerMobile}>
                  <button onClick={this.setEditBookigPanelActive} className={css.editDatesBtn}>
                    <FormattedMessage id="TransactionPanel.editDatesButtonTitle" />
                  </button>
                </div>
              )}
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={ct}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(ct.id)}
              totalMessagePages={totalMessagePages}
            />
            {showSendMessageForm ? (
              <>
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                />
                <TransactionAttachmentForm
                  onSubmit={this.onSubmitAttachment}
                  transaction={ct}
                  currentUser={currentUser}
                  onMessageSubmit={this.onMessageSubmit}
                />
              </>
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}

            {!!stateData.showAwaitButtons ? (
              <div className={css.mobileActionButtons}>{awaitUpdateButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />

              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={ct}
                transactionRole={transactionRole}
                localTimeZone={localTimeZone}
                awaitToCancel={cancelMain}
                updateData={isParentTransaction ? awaitData : chainedData}
              />

              <ChainedTransaction
                className={css.breakdownContainer}
                transaction={transactionChained}
                transactionRole={transactionRole}
                localTimeZone={localTimeZone}
                awaitToCancel={cancelChained}
                updateData={isParentTransaction ? chainedData : awaitData}
              />

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}

              {!!stateData.showAwaitButtons ? (
                <div className={css.desktopActionButtons}>{awaitUpdateButtons}</div>
              ) : null}

              {!isAdditionalTransaction && stateData.showEditBtn && isCanBeUpdated && (
                <div className={css.editDatesContainer}>
                  <button onClick={this.setEditBookigPanelActive} className={css.editDatesBtn}>
                    <FormattedMessage id="TransactionPanel.editDatesButtonTitle" />
                  </button>
                </div>
              )}

              {renderBookingPanel(transactionAccepted)}
              {renderBookingPanelForUpdate(ct)}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.clickSubmit}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  transactionChained: propTypes.transaction,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,
  onCheckBonus: func.isRequired,
  onRequestToUpdateSale: func.isRequired,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  onAcceptUpdateSale: func,
  onDeclineUpdateSale: func,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
