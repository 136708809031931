import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { getBonuses, updateBonusesStatus } from '../../../containers/AdminPanelPage/AdminPanelPage.duck'
import { Button } from '../../../components';

import css from './AdminBonusesComponent.css';

const AdminBonusesComponent = ({onGetBonusesAvailability, onUpdateBonusesAvailability, bonuses, isLoading, error}) => {
  const [isAvailable, setAvailable] = useState(bonuses);

  useEffect(() => {
    onGetBonusesAvailability()
  }, [])

  useEffect(() => {
    setAvailable(bonuses)
  }, [bonuses])

  const handleRadioBtn = () => {
    setAvailable(!isAvailable)
  }
  const saveBounusesAvailability = () => {
    onUpdateBonusesAvailability({
      "use_referral_system" : isAvailable
    })
  }

  return (
    <div className={css.bonusesContentWrap}>
      <h3>Edit bonuses availability</h3>
      {!isLoading && !error && (
        <>
          <div className={css.radioWrap}>
            <label htmlFor="bonuses">Are bonuses available?</label>
            <input
              type="checkbox"
              name="bonuses"
              id="bonuses"
              checked={isAvailable}
              onChange={handleRadioBtn}
            />
          </div>
        </>
      )}
      {isLoading && <div className={css.radioWrap}>Loading data...</div>}
      {error && (
        <div className={css.radioWrap}>Error during data loading occurs. Please try again.</div>
      )}
      <Button onClick={saveBounusesAvailability} disabled={error || isLoading}>
        Save
      </Button>
    </div>
  );
};

const mapStateToProps = state => {
  const { bonuses, isLoading, error, } = state.AdminPanelPage;
  return {
    bonuses,
    isLoading,
    error,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetBonusesAvailability: () => {dispatch(getBonuses())},
  onUpdateBonusesAvailability: (data) => {dispatch(updateBonusesStatus(data))}
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminBonusesComponent);
