import axios from 'axios';
import moment from 'moment';
import {
  INITIATE_BLOG_POSTS_ERROR,
  INITIATE_BLOG_POSTS_REQUEST,
  INITIATE_BLOG_POSTS_SUCCESS,
} from '../BlogPage/BlogPage.duck';

async function makeRequest(method, url, data) {

  const options = {
    method: method,
    url: url,
    data
  }

  let res = await axios(options)
  try {
    return res
  } catch (e) {
    return new Error(e)
  }

}

const RAILS_API_URL = process.env.REACT_APP_RAILS_API_URL;

// ================ Action types ================ //

export const INITIATE_ANNOUNCEMENTS_REQUEST = 'app/AnnouncementsPage/INITIATE_ANNOUNCEMENTS_REQUEST';
export const INITIATE_ANNOUNCEMENTS_SUCCESS = 'app/AnnouncementsPage/INITIATE_ANNOUNCEMENTS_SUCCESS';
export const INITIATE_ANNOUNCEMENTS_ERROR = 'app/AnnouncementsPage/INITIATE_ANNOUNCEMENTS_ERROR';

export const CREATE_ANNOUNCEMENTS_REQUEST = 'app/AnnouncementsPage/CREATE_ANNOUNCEMENTS_REQUEST';
export const CREATE_ANNOUNCEMENTS_SUCCESS = 'app/AnnouncementsPage/CREATE_ANNOUNCEMENTS_SUCCESS';
export const CREATE_ANNOUNCEMENTS_ERROR = 'app/AnnouncementsPage/CREATE_ANNOUNCEMENTS_ERROR';
export const CREATE_ANNOUNCEMENTS_RESET = 'app/AnnouncementsPage/CREATE_ANNOUNCEMENTS_RESET';

export const EDIT_ANNOUNCEMENTS_REQUEST = 'app/AnnouncementsPage/EDIT_ANNOUNCEMENTS_REQUEST';
export const EDIT_ANNOUNCEMENTS_SUCCESS = 'app/AnnouncementsPage/EDIT_ANNOUNCEMENTS_SUCCESS';
export const EDIT_ANNOUNCEMENTS_ERROR = 'app/AnnouncementsPage/EDIT_ANNOUNCEMENTS_ERROR';
export const EDIT_ANNOUNCEMENTS_RESET = 'app/AnnouncementsPage/EDIT_ANNOUNCEMENTS_RESET';

export const DELETE_ANNOUNCEMENTS_REQUEST = 'app/AnnouncementsPage/DELETE_ANNOUNCEMENTS_REQUEST';
export const DELETE_ANNOUNCEMENTS_SUCCESS = 'app/AnnouncementsPage/DELETE_ANNOUNCEMENTS_SUCCESS';
export const DELETE_ANNOUNCEMENTS_ERROR = 'app/AnnouncementsPage/DELETE_ANNOUNCEMENTS_ERROR';
export const DELETE_ANNOUNCEMENTS_RESET = 'app/AnnouncementsPage/DELETE_ANNOUNCEMENTS_RESET';

// ================ Reducer ================ //

const initialState = {
  announcements: [],
  isLoading: false,
  error: null,
  isCreating: false,
  createSuccess: false,
  createError: null,
  isEditing: false,
  editingSuccess: false,
  editingError: null,
  isDeleting: false,
  deletingSuccess: false,
  deletingError: null,
};

export default function announcementsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_ANNOUNCEMENTS_REQUEST:
      return { ...state, isLoading: true };
    case INITIATE_ANNOUNCEMENTS_SUCCESS:
      return { ...state, isLoading: false, error: null, announcements: payload };
    case INITIATE_ANNOUNCEMENTS_ERROR:
      return { ...state, isLoading: false, error: payload };

    case CREATE_ANNOUNCEMENTS_REQUEST:
      return { ...state, isCreating: true, createSuccess: false, createError: null };
    case CREATE_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createSuccess: true,
        createError: null,
        announcements: [...state.announcements, payload],
      };
    case CREATE_ANNOUNCEMENTS_ERROR:
      return { ...state, isCreating: false, createSuccess: false, createError: payload };
    case CREATE_ANNOUNCEMENTS_RESET:
      return { ...state, isCreating: false, createSuccess: false, createError: null };

    case EDIT_ANNOUNCEMENTS_REQUEST:
      return { ...state, isEditing: true, editingSuccess: false };
    case EDIT_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editingSuccess: true,
        editingError: null,
      };
    case EDIT_ANNOUNCEMENTS_ERROR:
      return { ...state, isEditing: false, editingSuccess: false, editingError: payload };
    case EDIT_ANNOUNCEMENTS_RESET:
      return { ...state, isEditing: false, editingSuccess: false, editingError: null };

    case DELETE_ANNOUNCEMENTS_REQUEST:
      return { ...state, isDeleting: true, deletingSuccess: false, deletingError: null };
    case DELETE_ANNOUNCEMENTS_SUCCESS:
      return { ...state, isDeleting: false, deletingSuccess: true, deletingError: null };
    case DELETE_ANNOUNCEMENTS_ERROR:
      return { ...state, isDeleting: false, deletingSuccess: false, deletingError: payload };
    case DELETE_ANNOUNCEMENTS_RESET:
      return { ...state, isDeleting: false, deletingSuccess: false, deletingError: null };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const initiateAnnouncementsRequest = () => ({ type: INITIATE_ANNOUNCEMENTS_REQUEST });
const initiateAnnouncementsSuccess = (response) => ({ type: INITIATE_ANNOUNCEMENTS_SUCCESS, payload: response.data });
const initiateAnnouncementsError = (error) => ({ type: INITIATE_ANNOUNCEMENTS_ERROR, payload: error });

const createAnnouncementRequest = () => ({ type: CREATE_ANNOUNCEMENTS_REQUEST });
const createAnnouncementSuccess = (response) => ({ type: CREATE_ANNOUNCEMENTS_SUCCESS, payload: response });
const createAnnouncementError = (error) => ({ type: CREATE_ANNOUNCEMENTS_ERROR, payload: error });
const createAnnouncementReset = () => ({ type: CREATE_ANNOUNCEMENTS_RESET });

const editAnnouncementRequest = () => ({ type: EDIT_ANNOUNCEMENTS_REQUEST });
const editAnnouncementSuccess = () => ({ type: EDIT_ANNOUNCEMENTS_SUCCESS });
const editAnnouncementError = (error) => ({ type: EDIT_ANNOUNCEMENTS_ERROR, payload: error });
const editAnnouncementReset = () => ({ type: EDIT_ANNOUNCEMENTS_RESET });

const deleteAnnouncementRequest = () => ({ type: DELETE_ANNOUNCEMENTS_REQUEST });
const deleteAnnouncementSuccess = () => ({ type: DELETE_ANNOUNCEMENTS_SUCCESS });
const deleteAnnouncementError = error => ({ type: DELETE_ANNOUNCEMENTS_ERROR, payload: error });
const deleteAnnouncementReset = () => ({ type: DELETE_ANNOUNCEMENTS_RESET });


/* ================ Thunks ================ */

export const getAnnouncements = () => (dispatch) => {
  dispatch(initiateAnnouncementsRequest())

  makeRequest('get', `${RAILS_API_URL}/api/v1/announcements`)
    .then(response => {
      dispatch(initiateAnnouncementsSuccess(response))
    }).catch(e => {
      const error = new Error('something went wrong, test error')
      dispatch(initiateAnnouncementsError(error))
    })
}

export const createAnnouncement = data => (dispatch) => {
  dispatch(createAnnouncementRequest())
  makeRequest('post', `${RAILS_API_URL}/api/v1/announcements`, data)
    .then(response => {
      dispatch(createAnnouncementSuccess(response.data))
    }).catch(e => {
    dispatch(createAnnouncementError(e))
  })
}

export const editAnnouncement = (id, data) => (dispatch) => {
  dispatch(editAnnouncementRequest())
  makeRequest('put', `${RAILS_API_URL}/api/v1/announcements/${id}`, data)
    .then(response => {
      dispatch(editAnnouncementSuccess());
      dispatch(getAnnouncements());
    })
    .catch(e => {
      dispatch(editAnnouncementError(e));
    });
}

export const deleteAnnouncement = data => (dispatch) => {
  dispatch(deleteAnnouncementRequest())
  makeRequest('delete', `${RAILS_API_URL}/api/v1/announcements/${data}`)
    .then(response => {
      dispatch(deleteAnnouncementSuccess())
      dispatch(getAnnouncements());
    }).catch(e => {
    dispatch(deleteAnnouncementError(e))
  })
}

export const createReset = () => dispatch => {
  dispatch(createAnnouncementReset());
}

export const editReset = () => dispatch => {
  dispatch(editAnnouncementReset());
};

export const deleteReset = () => dispatch => {
  dispatch(deleteAnnouncementReset());
};
