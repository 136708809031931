import { storableError } from '../util/errors';
import * as log from '../util/log';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// ================ Action types ================ //

export const GET_REVIEWS = 'app/Reviews/GET_REVIEWS';
export const GET_REVIEWS_IN_PROGRESS = 'app/Reviews/GET_REVIEWS_IN_PROGRESS';
export const GET_REVIEWS_ERROR = 'app/Reviews/GET_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  reviews: [],
  getReviewsInProress: false,
  getReviewsError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_REVIEWS:
      return {
        ...state,
        reviews: payload,
      };
    case GET_REVIEWS_IN_PROGRESS:
      return {
        ...state,
        getReviewsInProress: payload,
      };
    case GET_REVIEWS_ERROR:
      return {
        ...state,
        getReviewsError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getReviews = (data) => ({ type: GET_REVIEWS , payload: data});
export const getReviewsInProgress = (data) => ({ type: GET_REVIEWS_IN_PROGRESS , payload: data });
export const getReviewsError = (data) => ({ type: GET_REVIEWS_ERROR , payload: data });

// ================ Thunks ================ //

export const getUsersReviews = () => (dispatch) => {
  const url = `${API_URL}/api/get-reviews`;

  dispatch(getReviewsInProgress(true));
  dispatch(getReviewsError(false));

  return fetch(url)
  .then((response) => {
    return response.json();
  })
  .then((data) => {
    dispatch(getReviewsInProgress(false));
    dispatch(getReviews(data));
  })
  .catch ((e) => {
    log.error(storableError(e), 'get-users-reviews-failed');
    dispatch(getReviewsInProgress(false));
    dispatch(getReviewsError(true));
  })
};
