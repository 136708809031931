import React, { useState, useEffect } from 'react';
import { Modal, FaqEditor } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from '../../containers/FAQPage/FAQPage.css';

const FaqComponentStudent = props => {
  const {
    onManageDisableScrolling,
    userType,
    createData,
    faqList,
    editData,
    isCreating,
    createSuccess,
    onCreateReset,
    onEditReset,
    editingSuccess,
    createError,
    editingError,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (createSuccess || editingSuccess) {
      setModalOpen(false);
    }
  }, [createSuccess, editingSuccess]);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const studentObj =
    faqList.length > 0 &&
    faqList.find(item => {
      return item.user_type === 'student';
    });

  const isEditFaq = faqList.length > 0 && studentObj;

  const addNewBtnStudents = userType === 'superadmin' && (
    <button className={css.addNewBtn} onClick={handleOpen}>
      {isEditFaq ? (
        <FormattedMessage id="FaqPage.editBtnText" />
      ) : (
        <FormattedMessage id="FaqPage.addBtnText" />
      )}
    </button>
  );

  const handleClose = () => {
    setModalOpen(false);
    isEditFaq ? onEditReset() : onCreateReset();
  };

  const handleCreate = data => {
    isEditFaq ? onEditReset() : onCreateReset();
    isEditFaq ? editData({ type: 'student', ...data }) : createData({ type: 'student', ...data });
  };

  return (
    <div className={css.contentMain}>
      {addNewBtnStudents}
      {isEditFaq ? (
        <div
          className={css.bottomRow}
          dangerouslySetInnerHTML={{ __html: studentObj.description }}
        ></div>
      ) : null}

      <Modal
        id="FaqPage.addNewPostStudent"
        isOpen={modalOpen}
        onClose={handleClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <FaqEditor
          editorTitle="FaqComponentStudentEditor.addTitleText"
          makeRequestFunction={data => handleCreate(data)}
          faqList={isEditFaq ? studentObj.description : null}
          createError={createError}
          editingError={editingError}
        />
      </Modal>
    </div>
  );
};

export default FaqComponentStudent;
