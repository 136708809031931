import React, { Component } from 'react';
import { bool, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { FilterForm } from '../../forms';
import css from './FilterPlainCustom.css';


class FilterPlainCustom extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: true };
    this.filter = null;
    this.filterContent = null;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleSubmit(values) {
    const { onSubmit, urlParam } = this.props;
    this.setState({ isOpen: true });
    onSubmit(urlParam, values);
  }

  handleClear() {
    const { onSubmit, onClear, urlParam } = this.props;
    this.setState({ isOpen: true });

    if (onClear) {
      onClear();
    }

    onSubmit(urlParam, null);
  }

  handleCancel() {
    const { onSubmit, onCancel, initialValues, urlParam } = this.props;
    this.setState({ isOpen: true });

    if (onCancel) {
      onCancel();
    }

    onSubmit(urlParam, initialValues);
  }

  render() {
    const {
      rootClassName,
      className,
      popupClassName,
      id,
      label,
      labelMaxWidth,
      isSelected,
      children,
      initialValues,
      keepDirtyOnReinitialize,
      contentPlacementOffset,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const popupClasses = classNames(css.popup, { [css.isOpen]: this.state.isOpen });
    const popupSizeClasses = popupClassName || css.popupSize;
    const labelStyles = isSelected ? css.labelSelected : css.label;
    const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
    const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;


    return (
        <div
          className={classes}
        >
          <button
            className={classNames(labelStyles, labelMaxWidthStyles)}
            style={labelMaxWidthMaybe}
            onClick={() => this.toggleOpen()}
          >
            {label}
          </button>
          <div
            id={id}
            className={popupClasses}
          >
            {this.state.isOpen ? (
              <FilterForm
                id={`${id}.form`}
                paddingClasses={popupSizeClasses}
                contentPlacementOffset={contentPlacementOffset}
                initialValues={initialValues}
                // keepDirtyOnReinitialize={keepDirtyOnReinitialize}
                onSubmit={this.handleSubmit}
                onChange={this.handleSubmit}
                onCancel={this.handleCancel}
                onClear={this.handleClear}
              >
                {children}
              </FilterForm>
            ) : null}
          </div>
        </div>
    );
  }
}

FilterPlainCustom.defaultProps = {
  rootClassName: null,
  className: null,
  popupClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  contentPlacementOffset: 0,
  liveEdit: false,
  label: null,
  labelMaxWidth: null,
};

FilterPlainCustom.propTypes = {
  rootClassName: string,
  className: string,
  popupClassName: string,
  id: string.isRequired,
  urlParam: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,
  contentPlacementOffset: number,
  label: string.isRequired,
  labelMaxWidth: number,
  isSelected: bool.isRequired,
  children: node.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(FilterPlainCustom);
