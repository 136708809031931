import React, { Component } from 'react';
import { func, object, string, array } from 'prop-types';
import classNames from 'classnames';
import { intlShape } from '../../util/reactIntl';
import moment from 'moment';
import {
  isInRange,
  isSameDate,
  isDayMomentInsideRange,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  dateIsAfter,
  findNextBoundary,
  timestampToDate,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
  getStartTime,
  getEndTime,
  isHourTimeStepFn,
  getTimeZoneOffset,
  isSameDay
} from '../../util/dates';
import { propTypes } from '../../util/types';
import { bookingDateRequired } from '../../util/validators';
import { FieldDateInput, FieldSelect } from '../../components';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import css from './FieldDateAndTimeInput.css';
import { sessionType } from '../../marketplace-custom-config';
import isObject from 'lodash/isObject';

const MAX_TIME_SLOTS_RANGE = 180;
const TODAY = new Date();

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const getTimeStep = (step, type) => {
  if (!step) return;
  const currentTimeStep = isObject(step) ? step[type] : step;
  return !isNaN(+currentTimeStep) ? +currentTimeStep : +moment(currentTimeStep);
}

const getAvailableStartTimes = (intl, timeZone, bookingStart, timeSlotsOnSelectedDate, timeStep) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }
  const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);

  return timeSlotsOnSelectedDate.reduce((availableTime, t) => {
    const startTime = t.attributes.start;
    const endTime = t.attributes.end;
    const nextTime = resetToStartOfDay(bookingStartDate, timeZone, 1);

    // If the start time is after timeslot start, use the start time.
    // Otherwise use the timeslot start time.
    const startLimit = dateIsAfter(bookingStartDate, startTime) ? bookingStartDate : startTime;

    // If time next to selected start time is inside timeslot use the next time.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endTime, nextTime) ? nextTime : endTime;

    const time = getStartTime(intl, timeZone, startLimit, endLimit, timeStep);
    return availableTime.concat(time);
  }, []);
};

const getAvailableEndTimes = (
  intl,
  timeZone,
  bookingStartTime,
  bookingEndDate,
  selectedTimeSlot,
  timeStep
) => {
  if (!selectedTimeSlot || !selectedTimeSlot.attributes || !bookingEndDate || !bookingStartTime) {
    return [];
  }

  const endDate = selectedTimeSlot.attributes.end;
  const bookingStartTimeAsDate = timestampToDate(bookingStartTime);

  const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, timeZone, 1);
  const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, timeZone, 1);
  const startOfEndDay = resetToStartOfDay(bookingEndDate, timeZone);

  let startLimit;
  let endLimit;

  if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
    startLimit = bookingStartTimeAsDate;
    endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
  } else {
    // If the end date is on the same day as the selected booking start time
    // use the start time as limit. Otherwise use the start of the selected end date.
    startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
      ? bookingStartTimeAsDate
      : startOfEndDay;

    // If the selected end date is on the same day as timeslot end, use the timeslot end.
    // Else use the start of the next day after selected date.
    endLimit = isSameDate(resetToStartOfDay(endDate, timeZone), startOfEndDay)
      ? endDate
      : dayAfterBookingEnd;
  }
  return getEndTime(intl, timeZone, startLimit, endLimit, timeStep);
};

const getTimeSlots = (timeSlots, date, timeZone) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter(t => isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone))
    : [];
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeSlots,
  startDate,
  selectedStartTime,
  selectedEndDate,
  timeStep
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        intl,
        timeZone,
        startDate,
        getTimeSlots(timeSlots, startDate, timeZone),
        timeStep
      );

  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
    ? startTimes[0].timestamp
    : null;

  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  const endDate = selectedEndDate
    ? timestampToDate(selectedEndDate.getTime() - 1)
    : startTimeAsDate
    ? new Date(findNextBoundary(timeZone, startTimeAsDate, timeStep).getTime() - 1)
    : null;

  const selectedTimeSlot = timeSlots.find(t =>
    isInRange(startTimeAsDate, t.attributes.start, t.attributes.end)
  );

  let endTime;

  if (!selectedEndDate) {
    const endTimes = getAvailableEndTimes(intl, timeZone, startTime, endDate, selectedTimeSlot, timeStep);
    endTime = endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp ? endTimes[0].timestamp : null;
  } else {
    endTime = selectedEndDate.getTime();
  }

  return { startTime, endDate, endTime, selectedTimeSlot };
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
    ? monthlyTimeSlots[monthId].timeSlots
    : [];
};

const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

/////////////////////////////////////
// FieldDateAndTimeInput component //
/////////////////////////////////////
class FieldDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.timeZone)
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
    this.onBookingStartTimeChange = this.onBookingStartTimeChange.bind(this);
    this.onBookingEndDateChange = this.onBookingEndDateChange.bind(this);
    this.onBookingEndTimeChange = this.onBookingEndTimeChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
  }

  componentDidMount(){
    const {values, timeZone } = this.props;

    if (values && values.bookingStartDate){
      this.setState({currentMonth: getMonthStartInTimeZone(values.bookingStartDate.date, timeZone)})
      this.onBookingStartDateChange(values.bookingStartDate);
    }
  }

  componentDidUpdate(prevProps){
    const { lessonType, values, diffBetweenStartAndEnd } = this.props;

    if (!!values.bookingStartDate && lessonType !== prevProps.lessonType){
      this.onBookingStartDateChange(values.bookingStartDate);
    }

    if (!!values.bookingStartDate && diffBetweenStartAndEnd && diffBetweenStartAndEnd !== prevProps.diffBetweenStartAndEnd){
      this.onBookingStartTimeChange(values.bookingStartTime);
    }
  }

  fetchMonthData(date) {
    const { listingId, timeZone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timeZone } = this.props;

    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);
        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(this.state.currentMonth, timeZone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onBookingStartDateChange = value => {
    const { monthlyTimeSlots, timeZone, intl, form, onChangeDate, timeStep, isUpdateBooking, minutesFromDiffRange, diffBetweenStartAndEnd, localTimeZone } = this.props;

    if (!value || !value.date) {
      form.batch(() => {
        form.change('bookingStartTime', null);
        form.change('bookingEndDate', { date: null });
        form.change('bookingEndTime', null);
      });
      // Reset the currentMonth too if bookingStartDate is cleared
      this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, timeZone) });

      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    let timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);
    if (this.props.lessonType === 'private' && timeSlotsOnSelectedDate.length > 0) {
      timeSlotsOnSelectedDate = timeSlotsOnSelectedDate.filter(i => i.attributes.seats === this.props.listing.attributes.publicData.seats)
    }

    const { startTime, endDate, endTime, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      null,
      null,
      getTimeStep(timeStep, 'start')
    );

    if (timeSlotsOnSelectedDate && timeSlotsOnSelectedDate[0]) {
      onChangeDate(startTime, timeSlotsOnSelectedDate[0].attributes.end.getTime());
    }
    // custom end time logic for update booking time feature
    let slots = null;
    let updateBookingTime = null;
    if (isUpdateBooking) {
      const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
        monthlyTimeSlots,
        this.state.currentMonth,
        timeZone
      );

      const tryToFindEndTimeSlots = slots => {
        let result = slots;

        if (!result && !!startTime){
          result = getTimeSlots(timeSlotsOnSelectedMonth, new Date(+startTime), localTimeZone)
            .find(item => !timeSlotsOnSelectedDate.some(slot => slot.id.uuid === item.id.uuid))
        }

        return result;
      };

      slots = tryToFindEndTimeSlots(selectedTimeSlot);

      let availableEndTimes = getAvailableEndTimes(
        intl,
        timeZone,
        startTime,
        endDate,
        slots,
        getTimeStep(minutesFromDiffRange * 60000, 'end')
      );


      const endTimeForUpdateBooking = [...availableEndTimes].filter((item) => {
        if (moment(item.timestamp).isSame(moment(Number(startTime)).clone().add(diffBetweenStartAndEnd, 'hours'))) {
          updateBookingTime = Number(moment(Number(startTime)).clone().add(diffBetweenStartAndEnd, 'hours').format('x'))
        }
      })
    }

    form.batch(() => {
      form.change('bookingStartTime', startTime);
      form.change('bookingEndDate', { date:  updateBookingTime && isUpdateBooking ? moment(updateBookingTime).clone().toDate() : endDate });
      form.change('bookingEndTime',  updateBookingTime && isUpdateBooking ? updateBookingTime : endTime);
      form.change('updateBookingEndDate', { date:  updateBookingTime ? moment(updateBookingTime).clone().toDate() : null });
      if(isUpdateBooking){
        const seats = slots ? slots.attributes.seats : 0;
        form.change('seats', seats);
      }
    });
  };

  onBookingStartTimeChange = value => {
    const { monthlyTimeSlots, timeZone, intl, form, values, onChangeDate, timeStep, localTimeZone, isUpdateBooking, diffBetweenStartAndEnd, minutesFromDiffRange } = this.props;

    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const startDate = values.bookingStartDate.date;
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, localTimeZone);

    const { startTime, endDate, endTime, selectedTimeSlot } = getAllTimeValues(
      intl,
      localTimeZone,
      timeSlotsOnSelectedDate,
      startDate,
      value,
      null,
      getTimeStep(timeStep, 'start')
    );

    onChangeDate(startTime, !timeStep ? selectedTimeSlot.attributes.end.getTime() : endTime);

    // custom end time logic for update booking time feature
    let updateBookingTime = null;
    let slots = null;
    if (isUpdateBooking) {

      const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
        monthlyTimeSlots,
        this.state.currentMonth,
        timeZone
      );

      const tryToFindEndTimeSlots = slots => {
        let result = slots;

        if (!result && !!startTime){
          result = getTimeSlots(timeSlotsOnSelectedMonth, new Date(+startTime), localTimeZone)
            .find(item => !timeSlotsOnSelectedDate.some(slot => slot.id.uuid === item.id.uuid))
        }

        return result;
      };

      slots = tryToFindEndTimeSlots(selectedTimeSlot);

      console.log(slots)

      let availableEndTimes = getAvailableEndTimes(
        intl,
        timeZone,
        startTime,
        endDate,
        slots,
        getTimeStep(minutesFromDiffRange * 60000, 'end')
      );

      const endTimeForUpdateBooking = [...availableEndTimes].filter((item) => {
        if (moment(item.timestamp).isSame(moment(Number(startTime)).clone().add(diffBetweenStartAndEnd, 'hours'))) {
          updateBookingTime = Number(moment(Number(startTime)).clone().add(diffBetweenStartAndEnd, 'hours').format('x'))
        }
      })
    }

    form.batch(() => {
      form.change('bookingStartDate', { date: startDate });
      form.change('bookingEndDate', { date:  updateBookingTime && isUpdateBooking ? moment(updateBookingTime).clone().toDate() : endDate });
      form.change('bookingEndTime', updateBookingTime && isUpdateBooking ? updateBookingTime : endTime);
      form.change('updateBookingEndDate', { date:  updateBookingTime ? moment(updateBookingTime).clone().toDate() : null });
      if(isUpdateBooking){
        const seats = slots ? slots.attributes.seats : 0;
        form.change('seats', seats);
      }
    });
  };

  onBookingEndTimeChange = value => {
    const { monthlyTimeSlots, timeZone, intl, form, values, onChangeDate, timeStep } = this.props;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const startDate = values.bookingStartDate.date;
    const startTime = values.bookingStartTime;
    const selectedEndDate = timestampToDate(value);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { endDate, endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      startTime,
      selectedEndDate,
      getTimeStep(timeStep, 'end')
    );

    onChangeDate(startTime, value);

    form.batch(() => {
      form.change('bookingEndDate', { date: endDate });
      form.change('bookingEndTime', endTime);
    });
  };

  onBookingEndDateChange = value => {
    const { monthlyTimeSlots, timeZone, intl, form, values, onChangeDate } = this.props;
    if (!value || !value.date) {
      form.change('bookingEndTime', null);
      return;
    }

    // This callback function (onBookingEndDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const endDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);

    const { bookingStartDate, bookingStartTime } = values;
    const startDate = bookingStartDate.date;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      bookingStartTime,
      endDate
    );

    form.change('bookingEndTime', endTime);
  };

  isOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone) {
    if (!selectedTimeSlot) {
      return true;
    }

    // 'day' is pointing to browser's local time-zone (react-dates gives these).
    // However, bookingStartDate and selectedTimeSlot refer to times in listing's timeZone.
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    // Given day (endDate) should be after the start of the day of selected booking start date.
    const startDate = resetToStartOfDay(bookingStartDate, timeZone);
    // 00:00 would return wrong day as the end date.
    // Removing 1 millisecond, solves the exclusivity issue.
    const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);
    // Given day (endDate) should be before the "next" day of selected timeSlots end.
    const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);
    return !(dateIsAfter(localizedDay, startDate) && dateIsAfter(endDate, localizedDay));
  }

  checkDisabledFields(name){
    const {disabledFields} = this.props;
    return !!disabledFields && !!disabledFields.length && disabledFields.indexOf(name) > -1
  }

  render() {
    const {
      rootClassName,
      className,
      formId,
      startDateInputProps,
      endDateInputProps,
      values,
      monthlyTimeSlots,
      timeZone,
      currentDuration,
      intl,
      lessonType,
      timeStep,
      localTimeZone,
      isUpdateBooking,
      diffBetweenStartAndEnd,
      minutesFromDiffRange,
    } = this.props;

    const tzOffset = getTimeZoneOffset(timeZone, localTimeZone);

    const classes = classNames(rootClassName || css.root, className);

    const bookingStartDate =
      values.bookingStartDate && values.bookingStartDate.date ? values.bookingStartDate.date : null;
    const bookingStartTime = values.bookingStartTime ? values.bookingStartTime : null;
    const bookingEndDate =
      values.bookingEndDate && values.bookingEndDate.date ? values.bookingEndDate.date : null;

    const startTimeDisabled = !bookingStartDate;
    const endDateDisabled = !bookingStartDate || !bookingStartTime;
    const endTimeDisabled = !bookingStartDate || !bookingStartTime || !bookingEndDate || lessonType === 'group';

    const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      this.state.currentMonth,
      timeZone
    );

    const timeSlotsOnSelectedDate = getTimeSlots(
      timeSlotsOnSelectedMonth,
      bookingStartDate,
      timeZone
    );

    const availableStartTimes = getAvailableStartTimes(
      intl,
      timeZone,
      bookingStartDate,
      timeSlotsOnSelectedDate,
      getTimeStep(timeStep, 'start')
    );

    const firstAvailableStartTime =
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
        ? availableStartTimes[0].timestamp
        : null;

    const { startTime, endDate, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingStartDate,
      bookingStartTime || firstAvailableStartTime,
      bookingEndDate || bookingStartDate,
      getTimeStep(timeStep, 'start')
    );

    const tryToFindEndTimeSlots = slots => {
      let result = slots;

      if (!result && !!startTime){
        result = getTimeSlots(timeSlotsOnSelectedMonth, new Date(+startTime), localTimeZone)
                      .find(item => !timeSlotsOnSelectedDate.some(slot => slot.id.uuid === item.id.uuid))
      }

      return result;
    };

    let availableEndTimes = getAvailableEndTimes(
      intl,
      timeZone,
      bookingStartTime || startTime,
      bookingEndDate || endDate,
      tryToFindEndTimeSlots(selectedTimeSlot),
      getTimeStep(isUpdateBooking ? minutesFromDiffRange * 60000 : timeStep, 'end')
    );

    const reformatEndTime = [];

    if (lessonType === 'group' && availableEndTimes.length > 0) {
      for (let i = 0; i < availableEndTimes.length * 6 + 1; i++) {
        if (reformatEndTime.length > 0) {
          const tempTime = moment(reformatEndTime[reformatEndTime.length -1].timestamp)
            .clone()
            .tz(timeZone)
            .add(10, 'm')
            .startOf('minutes');
          reformatEndTime.push({timestamp: tempTime.valueOf(), timeOfDay: tempTime.format('HH:mm')})
        } else {
          reformatEndTime.push({timestamp: availableEndTimes[0].timestamp, timeOfDay: availableEndTimes[0].timeOfDay})
        }
      }
    }

    availableEndTimes = reformatEndTime.length > 0 ? reformatEndTime : availableEndTimes;

    const isDayBlocked = timeSlotsOnSelectedMonth
      ? day =>
          !timeSlotsOnSelectedMonth.find(timeSlot =>
            isDayMomentInsideRange(
              day,
              timeSlot.attributes.start,
              timeSlot.attributes.end,
              localTimeZone
            )
          )
      : () => false;
    let customEndTime = 0;

    if (lessonType === 'group') {
      customEndTime = parseInt(values.bookingStartTime) + (currentDuration*60*60*1000);
    } else if (currentDuration > 0) {
      customEndTime = parseInt(values.bookingEndTime);
    } else {
      const ts = getTimeStep(timeStep, 'end');
      const timeOffset = isHourTimeStepFn(ts) ? 60*60*1000 : ts;
      customEndTime = parseInt(values.bookingStartTime) + (timeOffset);
    }

    const placeholderTime = localizeAndFormatTime(
      intl,
      timeZone,
      findNextBoundary(timeZone, TODAY)
    );

    // funcs for format UI output to local time

    const formatLableToLocalTime = (item, ltz) => {
      const time = item.timestamp;
      const result = localizeAndFormatTime(intl, ltz, time);

      return {
        group: moment(time).tz(ltz).format('DD MMMM'),
        label: result,
        time,
        direction: item.direction
      }
    }

    const getCombinedLocalTimeSlots = (times, currentDate, isStartTime)  => {
      if (!times && !currentDate){
        return {current: []};
      }

      if (!isStartTime || tzOffset == 0){
        return {current: times};
      }

      const startOfLocalDate = resetToStartOfDay(currentDate, localTimeZone);
      const slots = getTimeSlots(timeSlotsOnSelectedMonth, startOfLocalDate, localTimeZone)
                      // .filter(item => !timeSlotsOnSelectedDate.some(slot => slot.id.uuid === item.id.uuid));

      const additionalSlotsTime = getAvailableStartTimes(
        intl,
        localTimeZone,
        startOfLocalDate,
        slots,
        getTimeStep(timeStep, 'start')
      )
      .filter(item => !times.some(t => +t.timestamp === +item.timestamp));
      const isPrevAdditionalLocalTime = !!times.length && !!additionalSlotsTime.length && dateIsAfter(times[0].timestamp, additionalSlotsTime[0].timestamp);
      const direction = isPrevAdditionalLocalTime? 'prev': 'next';
      return {current: times, [direction]: additionalSlotsTime.map(item => ({...item, direction}))};
    }

    const renderTimeList = (times, isStartTime = false) => {

      const option = ({label, time, direction}) => (
        <option
          key={`${label}${direction}`}
          value={time}
          className={!!direction ? css.additionalSlotTime : null}
        >
          {label}
        </option>
      );

      const groups = {};
      const currentDate = bookingStartDate.getTime();
      let {prev = [], next = [], current} = getCombinedLocalTimeSlots(times, currentDate, isStartTime);

      //remove all values from current times array what relate to prev day
      current = current.filter((item, i, arr) =>
        isSameDay(currentDate, item.timestamp, localTimeZone) ||
        (dateIsAfter(item.timestamp, currentDate) && !arr.every(a => dateIsAfter(a.timestamp, currentDate) && isStartTime))
      );

      const combineTime = !!prev.length ? prev.concat(current) : current.concat(next);

      combineTime.forEach(item => {
        const {group, label, time, direction} = formatLableToLocalTime(item, localTimeZone);
        if (!(group in groups)){
          groups[group] = [];
        }

        groups[group].push({label, time, direction});
      })

      // if time list includes two different days - render options group with optgroup label = day + month
      // and usual options if only one day
      if (Object.keys(groups).length > 1) {
        return Object.keys(groups).map(key => (
          <optgroup label={key} key={key}>
            {groups[key].map(item => option(item))}
          </optgroup>
        ))
      } else {
        return !!Object.keys(groups).length && groups[Object.keys(groups)[0]].map(item => option(item)) || [];
      }
    };

    const startTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.startTime' });
    const endTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.endTime' });
    /**
     * NOTE: In this template the field for the end date is hidden by default.
     * If you want to enable longer booking periods, showing the end date in the form requires some code changes:
     * 1. Move the bookingStartTime field to the same formRow with the bookingStartDate field
     * 2. Remove the div containing the line between dates
     * 3. Remove the css related to hiding the booking end date from the bottom of the FieldDateAndTimeInput.css field
     */

    let endTimeForUpdateBooking = null;
    let tempEndTimes = [...availableEndTimes];

    if (isUpdateBooking) {
      endTimeForUpdateBooking = tempEndTimes.filter((item) => {
        if (moment(item.timestamp).isSame(moment(Number(bookingStartTime)).clone().add(diffBetweenStartAndEnd, 'hours'))) {
          return {
            timestamp: Number(moment(Number(bookingStartTime)).clone().add(diffBetweenStartAndEnd, 'hours').format('x')),
            timeOfDay: moment(Number(bookingStartTime)).clone().add(diffBetweenStartAndEnd, 'hours').format('HH:mm')
          }
        }
      })
    }

    return (
      <div className={classes}>
        <div className={css.formRow}>
          <div className={classNames(css.field, css.startDate)}>
            <FieldDateInput
              className={css.fieldDateInput}
              name="bookingStartDate"
              id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
              label={startDateInputProps.label}
              placeholderText={startDateInputProps.placeholderText}
              format={v =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={v =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              isDayBlocked={isDayBlocked}
              onChange={this.onBookingStartDateChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
              disabled={this.checkDisabledFields('bookingStartDate')}
            />
          </div>
        </div>
        <div className={css.formRow}>
          <div className={classNames(css.field, css.endDateHidden)}>
            <FieldDateInput
              {...endDateInputProps}
              name="bookingEndDate"
              id={formId ? `${formId}.bookingEndDate` : 'bookingEndDate'}
              className={css.fieldDateInput}
              label={endDateInputProps.label}
              placeholderText={endDateInputProps.placeholderText}
              format={v =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={v =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              isDayBlocked={isDayBlocked}
              onChange={this.onBookingEndDateChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              isOutsideRange={day =>
                this.isOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone)
              }
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
              disabled={this.checkDisabledFields('bookingEndDate') || endDateDisabled}
              showLabelAsDisabled={endDateDisabled}
            />
          </div>

          <div className={css.field}>
            <FieldSelect
              name="bookingStartTime"
              id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
              className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
              selectClassName={bookingStartDate ? css.select : css.selectDisabled}
              label={startTimeLabel}
              disabled={this.checkDisabledFields('bookingStartTime') || startTimeDisabled}
              onChange={this.onBookingStartTimeChange}
            >
              {bookingStartDate ? renderTimeList(  availableStartTimes, true)
              : (
                <option>{placeholderTime}</option>
              )}
            </FieldSelect>
          </div>

          <div className={bookingStartDate ? css.lineBetween : css.lineBetweenDisabled}>-</div>

          <div className={css.field}>
            <FieldSelect
              name="bookingEndTime"
              id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
              className={bookingStartDate ? css.fieldSelect : css.fieldSelectDisabled}
              selectClassName={bookingStartDate ? css.select : css.selectDisabled}
              label={endTimeLabel}
              disabled={this.checkDisabledFields('bookingEndTime') || endTimeDisabled}
              // value={customEndTime || null}
              defaultValue={customEndTime || null}
              onChange={this.onBookingEndTimeChange}
            >
              {bookingStartDate && (bookingStartTime || startTime) && !isUpdateBooking ? renderTimeList(availableEndTimes)
                : isUpdateBooking && endTimeForUpdateBooking && endTimeForUpdateBooking.length > 0 ?
                    <option key={endTimeForUpdateBooking[0].timeOfDay === '00:00' ? '24:00' : endTimeForUpdateBooking[0].timeOfDay} value={endTimeForUpdateBooking[0].timestamp}>
                      {endTimeForUpdateBooking[0].timestamp === '00:00' ? '24:00' : moment(endTimeForUpdateBooking[0].timestamp).clone().format('HH:mm')}
                    </option>
                : isUpdateBooking && endTimeForUpdateBooking.length === 0 ? ''
                // availableEndTimes.map(p => (
                //   <option key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay} value={p.timestamp}>
                //     {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                //   </option>
                // ))
              : (
                <option>{placeholderTime}</option>
              )}
            </FieldSelect>

            {isUpdateBooking && <input type="hidden" name="seats" value="0" id="seats"/>}
          </div>
        </div>
      </div>
    );
  }
}

FieldDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
  disabledFields: null
};

FieldDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  bookingStartLabel: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,
  disabledFields: array,
  timeStep: propTypes.timeStep,
  localTimeZone: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldDateAndTimeInput;
