import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEdit.css';

const IconEdit = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        fill="#614983"
        stroke="#614983"
        strokeWidth="0.6"
        d="M14.844 10.366a.374.374 0 00-.373.374v3.317c0 .618-.502 1.12-1.12 1.12H2.867c-.619 0-1.12-.502-1.12-1.12V4.322c0-.619.501-1.12 1.12-1.121h3.317a.374.374 0 100-.747H2.868A1.87 1.87 0 001 4.322v9.735a1.87 1.87 0 001.868 1.868H13.35a1.87 1.87 0 001.868-1.868V10.74a.373.373 0 00-.374-.374z"
      ></path>
      <path
        fill="#614983"
        stroke="#614983"
        strokeWidth="0.6"
        d="M15.07 1.492a1.681 1.681 0 00-2.377 0L6.028 8.157a.373.373 0 00-.096.165l-.876 3.164a.373.373 0 00.46.46l3.163-.877a.373.373 0 00.165-.096l6.665-6.665a1.683 1.683 0 000-2.377l-.439-.439zM6.842 8.4l5.455-5.455 1.759 1.76L8.6 10.158 6.841 8.4zm-.351.705l1.405 1.406-1.944.538.539-1.944zm8.49-5.325l-.397.396-1.76-1.76.397-.395a.934.934 0 011.32 0l.44.438a.935.935 0 010 1.321z"
      ></path>
    </svg>
  );
};

IconEdit.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconEdit.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconEdit;
