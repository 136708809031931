/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, getFormatedDisplayName } from '../../util/data';
import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  OwnListingLink,
} from '../../components';

import css from './TopbarMobileMenu.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const userTypes = currentUser !== null ?  currentUser.attributes.profile.privateData.userType : null;
  const isSuperAdmin = userTypes && userTypes.toLowerCase() === 'superadmin';

  const showCreateListingButtonMobile = userTypes === 'Tutor' ? (
    <div className={css.footer}>
      <NamedLink className={css.createNewListingLink} name="NewListingPage">
        {currentUserListing ? (
          <FormattedMessage id="TopbarDesktop.editYourListingLink" />
        ) : (
          <FormattedMessage id="TopbarDesktop.addYourListingLink" />
        )}
      </NamedLink>
    </div>
  ) : null;

  const showCreateListingLinkMobile = userTypes === 'Tutor' ? (
    <OwnListingLink
      listing={currentUserListing}
      listingFetched={currentUserListingFetched}
      className={css.navigationLink}
    />
  ) : null;

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <ul className={css.list}>
            <li className={css.listItem}>
              <NamedLink name="LandingPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toHomePage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="AboutPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toAboutPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="FAQpage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toFAQPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="BlogPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toBlogPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="AnnouncementsPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toAnnouncementsPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="ReferralSystemPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toInviteNewMembers" />
              </NamedLink>
            </li>
          </ul>
        </div>
        {showCreateListingButtonMobile}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = getFormatedDisplayName(user.attributes.profile.firstName);
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {showCreateListingLinkMobile}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        {isSuperAdmin && <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AdminPanelPage'))}
          name="AdminPanelPage"
        >
          <FormattedMessage id="TopbarDesktop.adminPanelLink" />
        </NamedLink>}
          <ul className={css.list}>
            <li className={css.listItem}>
              <NamedLink name="AboutPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toAboutPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="LandingPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toHomePage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="FAQpage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toFAQPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="BlogPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toBlogPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="AnnouncementsPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toAnnouncementsPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="NewListingPage" className={css.link}>
                <FormattedMessage id="TopbarDesktop.toInviteNewMembers" />
              </NamedLink>
            </li>
          </ul>
      </div>
      {showCreateListingButtonMobile}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
