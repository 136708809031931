import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import config from '../../config';
import axios from 'axios';
import moment from 'moment';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import {
  txIsEnquired,
  getReview1Transition,
  getReview2Transition,
  txIsInFirstReviewBy,
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_CANCEL_CUSTOMER,
  TRANSITION_CANCEL_PROVIDER,
  TRANSITION_CANCEL_PROVIDER_WITHOUT_REFUND,
  TRANSITION_CUSTOMER_UPDATE_AFTER_PREAUTHORIZED,
  TRANSITION_PROVIDER_UPDATE_AFTER_ACCEPT,
  TRANSITION_UPDATE_PROTECTED_DATA_AFTER_ACCEPT,
  TRANSITION_CUSTOMER_UPDATE_AFTER_ACCEPT_SUCCESS,
  TRANSITION_CUSTOMER_UPDATE_AFTER_ACCEPT_DECLINE,
  TRANSITION_CUSTOMER_UPDATE_AFTER_ACCEPT_EXPIRE_REQUEST
} from '../../util/transaction';
import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
  getFormatedDisplayName
} from '../../util/data';
import { findNextBoundary, nextMonthFn, monthIdStringInTimeZone } from '../../util/dates';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { updateTransactionExistOnProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  createBBBRoom,
  fetchCurrentUserNotifications,
  refundBonus,
  updateReview,
  updateUserDebt,
  changeBookingDate,
  fetchCurrentUser,
} from '../../ducks/user.duck';
import {
  referralsError
} from '../ReferralSystemPage/ReferralSystemPage.duck';
import {
  requestUpdateBooking,
  acceptUpdateBooking,
  declineUpdateBooking
} from '../../util/api';
import { getAllSubjects } from '../AdminPanelPage/AdminPanelPage.duck';

const { UUID } = sdkTypes;
const API_URL = process.env.REACT_APP_API_URL;
const RAILS_API_URL = process.env.REACT_APP_RAILS_API_URL;
const MESSAGES_PAGE_SIZE = 100;
const MINIMUM_DEPT = 50;
const STRIPE_PERCENT = 0.029;
const STRIPE_CENTS = 30;
const CUSTOMER = 'customer';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const SET_BONUS_EXIST = 'app/ListingPage/SET_BONUS_EXIST';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_CHAINED_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_CHAINED_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const UPDATE_SALE_REQUEST = 'app/TransactionPage/UPDATE_SALE_REQUEST';
export const UPDATE_SALE_SUCCESS = 'app/TransactionPage/UPDATE_SALE_SUCCESS';
export const UPDATE_SALE_ERROR = 'app/TransactionPage/UPDATE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const CANCEL_TRANSACTION_REQUEST = 'app/TransactionPage/CANCEL_TRANSACTION_REQUEST';
export const CANCEL_TRANSACTION_SUCCESS = 'app/TransactionPage/CANCEL_TRANSACTION_SUCCESS';
export const CANCEL_TRANSACTION_ERROR   = 'app/TransactionPage/CANCEL_TRANSACTION_ERROR';

export const DEBT_TRANSACTION_REQUEST = 'app/TransactionPage/DEBTL_TRANSACTION_REQUEST';
export const DEBT_TRANSACTION_SUCCESS = 'app/TransactionPage/DEBTL_TRANSACTION_SUCCESS';
export const DEBT_TRANSACTION_ERROR   = 'app/TransactionPage/DEBTL_TRANSACTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  transactionChainedRef: null,
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  bonusBalance: 0,
  updateInProgress: false,
  updateSaleError: null,
  sendTransactionInProgress: false,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SET_BONUS_EXIST:
      return { ...state, bonusBalance: payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_CHAINED_SUCCESS: {
      const transactionChainedRef = payload ? { id: payload.data.data.id, type: 'transaction' } : null;
      return { ...state, fetchTransactionInProgress: false, transactionChainedRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case UPDATE_SALE_REQUEST:
      return { ...state, updateInProgress: true, updateSaleError: null };
    case UPDATE_SALE_SUCCESS:
      return { ...state, updateInProgress: false };
    case UPDATE_SALE_ERROR:
      return { ...state, updateInProgress: false, updateSaleError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case CANCEL_TRANSACTION_REQUEST:
      return { ...state, sendTransactionInProgress: true, sendTransactionError: null };
    case CANCEL_TRANSACTION_SUCCESS:
      return { ...state, sendTransactionInProgress: false };
    case CANCEL_TRANSACTION_ERROR:
      return { ...state, sendTransactionInProgress: false, sendTransactionError: payload };

    case DEBT_TRANSACTION_REQUEST:
      return { ...state, sendDebtTransactionInProgress: true, sendDebtTransactionError: null };
    case DEBT_TRANSACTION_SUCCESS:
      return { ...state, sendDebtTransactionInProgress: false };
    case DEBT_TRANSACTION_ERROR:
      return { ...state, sendDebtTransactionInProgress: false, sendDebtTransactionError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress || state.TransactionPage.updateInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const setBonusBalance = bonusBalance => ({
  type: SET_BONUS_EXIST,
  payload: bonusBalance,
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = (response, chained) => ({
  type: chained ? FETCH_TRANSACTION_CHAINED_SUCCESS : FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const updateSaleRequest = () => ({ type: UPDATE_SALE_REQUEST });
const updateSaleSuccess = () => ({ type: UPDATE_SALE_SUCCESS });
const updateSaleError = e => ({ type: UPDATE_SALE_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

const cancelTransactionRequest = () => ({ type: CANCEL_TRANSACTION_REQUEST });
const cancelTransactionSuccess = () => ({ type: CANCEL_TRANSACTION_SUCCESS });
const cancelTransactionError   = e => ({ type: CANCEL_TRANSACTION_ERROR, error: true, payload: e });

const debtTransactionRequest = () => ({ type: DEBT_TRANSACTION_REQUEST });
const debtTransactionSuccess = () => ({ type: DEBT_TRANSACTION_SUCCESS });
const debtTransactionError   = e => ({ type: DEBT_TRANSACTION_ERROR, error: true, payload: e });

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

// ================ Thunks ================ //

const updateParentTransaction = (id, data) => (dispatch, getState, sdk) => {
  sdk
    .transactions
    .transition({
      id,
      transition: TRANSITION_UPDATE_PROTECTED_DATA_AFTER_ACCEPT,
      params: {protectedData: data}
    }, {
      expand: true
    }).then(res => {
      console.log('Parent transaction protected data updated');
    })
    .catch(e => {
      console.error('Unable to update parent transaction protected data:');
      throw new Error(e);
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

// Helper function for fetchTransaction call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id, txRole, chained = false) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      const hasChained = !!getState().TransactionPage.transactionChainedRef;
      const additionalId = transaction.attributes.protectedData && transaction.attributes.protectedData.additionalTimeTransactionId;
      const parentId = transaction.attributes.protectedData && transaction.attributes.protectedData.parentTransactionId;
      const chainedId = additionalId || parentId;

      if (!chained && !!chainedId) {
          dispatch(fetchTransaction(new UUID(chainedId), txRole, true));
      }

      if (hasChained && !chainedId) {
        // clear chained transaction data while update when chained transaction was canceled
        dispatch(fetchTransactionSuccess(null, true));
      }

      // Fetch time slots for transactions that are in enquired state
      // const canFetchTimeslots =
      //   txRole === 'customer' &&
      //   config.enableAvailability &&
      //   transaction &&
      //   txIsEnquired(transaction);

      // if (canFetchTimeslots) {
      //   fetchMonthlyTimeSlots(dispatch, listing);
      // }

      if (!chained && !parentId) {
        fetchMonthlyTimeSlots(dispatch, listing);
      }

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      } else {
        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(txResponse, chained));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const acceptSale = (id, provider, customer, tx, user) => async (dispatch, getState, sdk) => {
  // const debt = user.attributes.profile.protectedData.debt;
  const currentUser = await dispatch(fetchCurrentUser())
  const debt = parseInt(currentUser.attributes.profile.protectedData.debt);
  const providerEmail = user.attributes.email;
  const params = {
    amount: tx.attributes.payoutTotal.amount,
    tx_id: tx.id.uuid,
    stripe_account_id: user.stripeAccount.attributes.stripeAccountId,
    email: providerEmail,
    debt: debt
  };
  const hasDebt = debt !== undefined && parseInt(debt) > MINIMUM_DEPT
  if (acceptOrDeclineInProgress(getState())) {
   return Promise.reject(new Error('Accept or decline already in progress'));
  }

  dispatch(acceptSaleRequest());
  return dispatch(createBBBRoom(tx, providerEmail))
   .then(() => {
     return sdk.transactions
       .transition({ id, transition: TRANSITION_ACCEPT, params: {} }, { expand: true })
   })
   .then(response => {

    // update parent transcation when accepted additional time transaction
    const t = response.data.data;
    const parentTransactionId = t.attributes && t.attributes.protectedData && t.attributes.protectedData.parentTransactionId;
    if (parentTransactionId){
      setTimeout(() => {
        dispatch(updateParentTransaction(new UUID(parentTransactionId), {additionalTimeTransactionId: t.id.uuid}));
      }, 500);
    }

    return Promise.resolve(response);
   })
   .then(response => {
     dispatch(addMarketplaceEntities(response));
     dispatch(acceptSaleSuccess());
     if(hasDebt){
       dispatch(debtTransactionRequest());
       return axios.post(`${RAILS_API_URL}/api/v1/transaction/create`, params)
       .then( response => {
         dispatch(debtTransactionSuccess(response.data))
         const paidAmount =  parseInt(debt) - parseInt(response.data.amount)
         dispatch(updateUserDebt(paidAmount))
         return response;
       })
       .catch( error => {
         dispatch(debtTransactionError(storableError(error)));
       })
    }


    dispatch(fetchCurrentUserNotifications());
    dispatch(updateTransactionExistOnProfile(provider.id.uuid, customer.id.uuid));
    return response;
   })
   .catch(e => {
     dispatch(acceptSaleError(storableError(e)));
     log.error(e, 'accept-sale-failed', {
       txId: id,
       transition: TRANSITION_ACCEPT,
     });
     throw e;
   });
};

export const declineSale = (id, customer, sum, provider, providerBonus) => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(declineSaleSuccess());
      dispatch(fetchCurrentUserNotifications());
      if (sum > 0) dispatch(refundBonus(sum, customer.id.uuid));
      if (providerBonus) dispatch(refundBonus(1, provider.id.uuid));
      return response;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

export const requestToUpdateSale = (
  id,
  provider,
  customer,
  listing,
  data,
  isBeforeStart,
  expireDate,
  listingId,
  exceptionStart,
  exceptionEnd,
  seats,
  chainedData,
  cancelData
) => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept, decline or update already in progress'));
  }

  dispatch(updateSaleRequest());

  const transition = TRANSITION_PROVIDER_UPDATE_AFTER_ACCEPT;
  const transitionExpire = TRANSITION_CUSTOMER_UPDATE_AFTER_ACCEPT_EXPIRE_REQUEST;
  const providerName = getFormatedDisplayName(provider);
  const customerName = getFormatedDisplayName(customer);
  const listingTitle = listing.attributes.title;
  const customerId = customer.id.uuid;
  const currentUser = getState().user.currentUser;
  const providerEmail = currentUser && currentUser.attributes && currentUser.attributes.email || null;

  return requestUpdateBooking({
    id: id.uuid,
    transition,
    params: {protectedData: {
      ...data,
      ...(chainedData ? {chainedData} : {}),
      ...(cancelData ? {cancelData} : {}),
    }},
    isBeforeStart,
    listingId,
    exceptionStart,
    exceptionEnd,
    seats,
    expireDate: new Date(expireDate).toISOString(),
    transitionExpire,
    providerName,
    listingTitle,
    providerEmail,
    customerName,
    customerId,
  })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(updateSaleSuccess());
      dispatch(fetchCurrentUserNotifications());
      dispatch(updateTransactionExistOnProfile(provider.id.uuid, customer.id.uuid));
      return response;
    })
    .catch(e => {
      dispatch(updateSaleError(storableError(e)));
      log.error(e, 'request-to-update-sale-failed', {
        txId: id,
        transition,
      });
      throw e;
    });
}

export const updateSale = (id, provider, customer, tx, updateBookingDates) => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept, decline or update already in progress'));
  }
  const { exceptionId, ...rest } = updateBookingDates;
  const start = updateBookingDates.bookingStart;
  dispatch(updateSaleRequest());

  const transition = TRANSITION_CUSTOMER_UPDATE_AFTER_PREAUTHORIZED;

  return sdk.transactions
    .transition({ id, transition, params: rest }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCurrentUserNotifications());
      dispatch(updateTransactionExistOnProfile(provider.id.uuid, customer.id.uuid));
      dispatch(fetchTransaction(response.data.data.id, 'customer'));
      return response;
    })
    .then(response => {
      dispatch(updateSaleSuccess());
      dispatch(changeBookingDate(tx, start));
      return response;
    })
    .catch(e => {
      dispatch(updateSaleError(storableError(e)));
      log.error(e, 'update-sale-failed', {
        txId: id,
        transition,
      });
      throw e;
    });
}

export const acceptUpdateSale = t => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept, decline or update already in progress'));
  }

  dispatch(updateSaleRequest());

  const transition = TRANSITION_CUSTOMER_UPDATE_AFTER_ACCEPT_SUCCESS;
  const {id, provider, customer} = t;
  const protectedData = t.attributes.protectedData;
  const { awaitData, chainedData, cancelData } = protectedData;
  const { exceptionId, ...rest } = awaitData;

  const data = {...rest};
  const start = data.bookingStart;
  data.bookingStart = new Date(data.bookingStart);
  data.bookingEnd = new Date(data.bookingEnd);
  data.displayBookingStart = new Date(data.displayBookingStart);
  data.displayBookingEnd = new Date(data.displayBookingEnd);
  data.protectedData.awaitData = null;

  if (chainedData){
    chainedData.params.bookingStart = new Date(chainedData.params.bookingStart);
    chainedData.params.bookingEnd = new Date(chainedData.params.bookingEnd);
    chainedData.params.displayBookingStart = new Date(chainedData.params.displayBookingStart);
    chainedData.params.displayBookingEnd = new Date(chainedData.params.displayBookingEnd);
    data.protectedData.chainedData = null;
  }

  if (cancelData) {
    data.protectedData.cancelData = null;
    data.protectedData.additionalTimeTransactionId = null;
  }

  return acceptUpdateBooking({
    id: id.uuid,
    transition,
    params: data,
    exceptionId,
    chainedData,
    cancelData
  })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCurrentUserNotifications());
      dispatch(updateTransactionExistOnProfile(provider.id.uuid, customer.id.uuid));
      dispatch(fetchTransaction(response.data.data.id, 'customer'));
      return response;
    })
    .then(response => {
      dispatch(updateSaleSuccess());
      dispatch(changeBookingDate(t, start));
      return response;
    })
    .catch(e => {
      dispatch(updateSaleError(storableError(e)));
      log.error(e, 'accept-update-sale-failed', {
        txId: id,
        transition,
      });
      throw e;
    });
}

export const declineUpdateSale = t => (dispatch, getState, sdk) => {
  const transition = TRANSITION_CUSTOMER_UPDATE_AFTER_ACCEPT_DECLINE;
  const {id, provider, customer} = t;
  const protectedData = t.attributes.protectedData;
  const { awaitData: { exceptionId }, chainedData, cancelData } = protectedData;
  const data = {awaitData: null};

  if (chainedData) {
    data.chainedData = null;
  }

  if (cancelData) {
    data.cancelData = null;
  }

  return declineUpdateBooking({
    id: id.uuid,
    transition,
    params: {protectedData: {...data}},
    exceptionId
  })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(updateSaleSuccess());
      dispatch(fetchCurrentUserNotifications());
      dispatch(updateTransactionExistOnProfile(provider.id.uuid, customer.id.uuid));
      return response;
    })
    .catch(e => {
      dispatch(updateSaleError(storableError(e)));
      log.error(e, 'decline-update-sale-failed', {
        txId: id,
        transition,
      });
      throw e;
    });
}

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

export const updateListing = (id, data) => dispatch => {
  const url = `${API_URL}/api/listing-update`;

  return axios
    .post(url, { id, data })
    .then(res => {
      return res
    })
    .catch(e => {
      console.log('error', e);
    });
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = async (
  id,
  params,
  role,
  dispatch,
  sdk,
  userId,
  listingId,
  studentsReviews
) => {
  const transition = getReview2Transition(role === CUSTOMER);

  const include = REVIEW_TX_INCLUDES;
  const rating =
    studentsReviews && studentsReviews.totalRating
      ? parseFloat(studentsReviews.totalRating) * 10
      : 0;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      if (role === 'customer' && params.reviewRating === 5) dispatch(updateReview(params, userId));
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .then(response => {
      if (role === 'customer') {
        dispatch(
          updateListing(listingId, {
            publicData: { studentsReviews: { ...studentsReviews }, rating },
          })
        );
      }
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = async (
  id,
  params,
  role,
  dispatch,
  sdk,
  userId,
  listingId,
  studentsReviews
) => {
  const transition = getReview1Transition(role === CUSTOMER);
  const include = REVIEW_TX_INCLUDES;
  const rating =
    studentsReviews && studentsReviews.totalRating
      ? parseFloat(studentsReviews.totalRating) * 10
      : 0;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      if (role === 'customer' && params.reviewRating === 5) dispatch(updateReview(params, userId));
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .then(response => {
      if (role === 'customer') {
         dispatch(
           updateListing(listingId, {
             publicData: {
               studentsReviews: { ...studentsReviews },
               rating
             },
           })
         );
      }
      return response
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(
          id,
          params,
          role,
          dispatch,
          sdk,
          userId,
          listingId,
          studentsReviews
        );
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role, tx, reviewRating, reviewContent, listingId, studentsReviews) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== CUSTOMER);

  dispatch(sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk, tx.customer.id.uuid, listingId, studentsReviews)
    : sendReviewAsFirst(tx.id, params, role, dispatch, sdk, tx.customer.id.uuid, listingId, studentsReviews);
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const cancellationTransaction = (params, user, listing, reason) => (dispatch, getState, sdk) =>{
  const id = params.id.uuid
  params = prepareTransactionParams(params, user, listing, reason)
  dispatch(cancelTransactionRequest());
  return axios.put(`${RAILS_API_URL}/api/v1/transaction/update`, params)
  .then( response => {

    dispatch(cancelTransactionSuccess(response.data));
    const refound = response.data.refound

    sdk.transactions
    .transition({ id, transition: TRANSITION_CANCEL_CUSTOMER, params: { protectedData: { refound: refound }} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      if (params.attributes.protectedData.customerBonus > 0) dispatch(refundBonus(params.attributes.protectedData.customerBonus, params.customer.id.uuid));
      if (params.attributes.protectedData.providerBonus) dispatch(refundBonus(1, params.provider.id.uuid));
      return response;
    })
    .then(response => {
      if (new Date() > params.booking.attributes.start && reason === 'Tutor did not show up') {
        const stripeFee = Math.round(params.attributes.payinTotal.amount * STRIPE_PERCENT) + STRIPE_CENTS;
        const params = { userId: params.provider.id.uuid, debt: stripeFee}
        axios.post(`${API_URL}/api/update_dept`, params)
      }
    })
    .catch(e => {
      log.error(e, 'accept-cancel-failed', {
        txId: id,
        transition: TRANSITION_CANCEL_CUSTOMER,
      });
      throw e;
    });
  })
  .catch( error => {
    dispatch(cancelTransactionError(error));
  })
};

export const cancellationTransactionProvider = (params, commission, user, listing, reason) => (dispatch, getState, sdk) =>{
  const id = params.id.uuid
  const stripeFee = Math.round(params.attributes.payinTotal.amount * STRIPE_PERCENT) + STRIPE_CENTS
  // const marketCommission = commission && commission.lineTotal && commission.lineTotal.amount ?  commission.lineTotal.amount : 0;
  const value = user.attributes.profile.protectedData.debt !== undefined ? parseInt(user.attributes.profile.protectedData.debt) + stripeFee : stripeFee;
  params = prepareTransactionParams(params, user, listing, reason)

  dispatch(cancelTransactionRequest());

  const withoutRefund = new Date() > params.booking.attributes.start && reason === 'Student did not show up';
  const transition = withoutRefund ? TRANSITION_CANCEL_PROVIDER_WITHOUT_REFUND : TRANSITION_CANCEL_PROVIDER;

  return sdk.transactions
    .transition({ id, transition: transition, params: { protectedData: { refound: withoutRefund ? 0 : 'fullRefound' }}  }, { expand: true })
    .then(response => {
      dispatch(cancelTransactionSuccess(response.data));
      dispatch(addMarketplaceEntities(response));
      if(!withoutRefund) dispatch(updateUserDebt(value));
      dispatch(cancelTransactionRequest());
      axios.post(`${RAILS_API_URL}/api/v1/transaction/info`, params)
        .then( response => {
          dispatch(cancelTransactionSuccess(response.data));
          if (!withoutRefund) {
            if (params.attributes.protectedData.customerBonus > 0) dispatch(refundBonus(params.attributes.protectedData.customerBonus, params.customer.id.uuid));
            if (params.attributes.protectedData.providerBonus) dispatch(refundBonus(1, params.provider.id.uuid));
          }
        })
        .catch( error => {
          dispatch(cancelTransactionError(error));
        })
      return response;
    })
    .catch(e => {
      dispatch(cancelTransactionError(e));
      log.error(e, 'accept-cancel-failed', {
        txId: id,
        transition: TRANSITION_CANCEL_PROVIDER,
      });
      throw e;
    });
};


export const checkBonus = (id, userType) => dispatch => {
  const url = `${API_URL}/api/checking-bonuses?id=${id}`;

  return axios.get(url)
    .then(response => {
      if (userType !== 'customer') {
        return dispatch(setBonusBalance(response.data));
      } else {
        return response.data;
      }
    })
    .catch(e => dispatch(referralsError(storableError(e))));
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole)),
    dispatch(fetchMessages(txId, 1)),
    dispatch(fetchNextTransitions(txId)),
  ])
  .then(() => {
    dispatch(getAllSubjects())
  });
};

export const prepareTransactionParams = (params, user, listing, reason) => {
  params['user_uuid'] = user.id.uuid
  params['display_name'] = user.attributes.profile.displayName
  params['role'] = user.attributes.profile.protectedData.userType
  params['email'] = user.attributes.email
  params['listing_id']= listing.id.uuid
  params['listing_title'] = listing.attributes.title
  params['reason'] = reason
  params['cancelations'] = listing.attributes.publicData.cancelations
  return params
}
