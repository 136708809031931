import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated March 22, 2021</p>
      <h2>INTRODUCTION</h2>
      <p>
        Wisepal Inc. (“Wisepal” “we” or “us” or “our”) respects the privacy of our users (“Sites’
        visitors” “user” or “you”). This Privacy Policy explains how we collect, use, disclose, and
        safeguard your information when you visit our website{' '}
        <a href="https://www.wisepal.co" target="_blank">
          www.wisepal.co
        </a>
        . Please read this privacy policy carefully. If you do not agree with the terms of this
        privacy policy, please do not continue to access or use the services or Wisepal Website.
        <br />
        <br />
        We reserve the right to change, modify, add or remove any portions of this Privacy Policy
        contract at our sole discretion at any time and for any reason. Users will be notified of
        changes or modifications to the privacy policy through email. Your continued use of the site
        or Services after the posting of changes constitutes your binding acceptance of such
        changes.
      </p>
      <h2>COLLECTION OF YOUR INFORMATION</h2>
      <p>
        <b>Personal Data</b>
      </p>
      <p>
        We collect Personal Information about you that you voluntarily give to us when you register
        with the Wisepal Site to provide you with services we offer or information you request.
        <br />
        <br />
        We respect the privacy of our visitors. As a visitor, you can browse our website to learn
        more about our services. If a user has not created an account we do not collect any personal
        information unless there is communication between us and the user through email, phone, or
        contact us. Only cookies are collected.
        <br />
        <br />
        You are not required to create an account or provide us with any personal information.
        However, your refusal to do so may prevent you from accessing or using certain features or
        services of the Site. When you register, create or update a Wisepal Account, you voluntarily
        provide and supply us with certain personal information that is required to gain access to
        our website. We also collect the content you create, upload, or receive from others when
        using our services.
        <br />
        As a registered user, the personal information we collect (and may retain):
        <br />  • Registration information: email address, First name, Last name, unique password
        for security purposes and user type referring your role as a student or as a tutor
        <br />  • Photos, videos or any other content you provide or upload on our website
        <br />  • Comments, content of communications and messages made between you and Wisepal
        team, or the student and the tutor
        <br />
        Information we receive from Tutors:
        <br />  • Full Name is displayed in profile, listings, inbox answers to questions for tutor
        applications and displayed for admins
        <br />  • Social Security Number (SSN) or Federal Employer Identification Number (FEIN) for
        tax purposes or to complete a background check
        <br />  • Date of birth
        <br />  • Physical Address
        <br />  • Email Address is used for identifying the user and for the email notification
        <br />  • Phone Number
        <br />  • Education
        <br />  • Emergency Contact Number
        <br />  • Education and employment history such as Resume/CV, diploma/transcript, teaching
        certificate
        <br />  • Answers to questions for the tutor applications and other Personal Information
        needed for the administration of certain services
        <br />
        Information we receive from Students and parents:
        <br />  • Full Name
        <br />
        <br />
        Other information collected from the users of our platform will be:
        <br />
        <br />  • The time of visit, pages visited, and time spent on each page of the webpages.
        <br />  • Referring site entails (such as URI a user came through to arrive at this site)
        <br />  • Type of web browser
        <br />  • Type of operating system (OS)
        <br />  • Flash version, JavaScript support, screen resolution, and screen color processing
        ability
        <br />  • Network location and IP address 
      </p>
      <br />

      <p>
        <b>Financial Data</b>
      </p>
      <br />

      <p>
        Wisepal collects billing and payment information from users when using our services through
        our third-party payment processors, Stripe. All payment information is securely stored by
        our payment processor and we do not have access to your sensitive information, such as
        credit card number, account number, routing number, card brand, expiration date or security
        code.
      </p>
      <br />

      <p>
        <b>Third-Party Data</b>
      </p>
      <br />
      <p>
        The Site may contain links to third-party websites and applications of interest, including
        advertisements and external services, that are not affiliated with us. We are not
        responsible for the content or privacy and security practices and policies of any third
        parties, including other sites, services or applications that may be linked to or from the
        Site.
      </p>

      <h2>TRACKING TECHNOLOGIES</h2>
      <p>
        The website uses “Cookies” to identify the areas of our Website that you have visited. We
        use Cookies to personalize the Content that you see on our Website and to improve your
        experience with the Site. You may choose to disable the web browsers you use, however doing
        so, you will not be able to access functionality on our Website correctly or at all. 
      </p>

      <h2>USE OF YOUR INFORMATION</h2>
      <p>
        We may use information collected about you through the Wisepal website to:
        <br />  • Creation of new accounts which allows users to initially access the services and
        to manage their accounts.
        <br />  • To communicate with you regarding our services, your account or payments or
        transactions.
        <br />  • Allow user-to-user communications
        <br />  • To ask you to provide feedback about our services such as by completing a survey
        and through email or our website.
        <br />  • To market our services, notify and update you about our new services and regarding
        our promotions through our newsletter and our Site.
        <br />  • Send information or content to you which we think may be of interest to you by
        post, email or other means
        <br />  • To provide the services that you request
        <br />  • to respond to your requests, comments and questions
        <br />  • To research, develop, manage, protect and improve our level of service 
        <br />  • Meet legal requirements
      </p>

      <h2>DISCLOSURE OF YOUR INFORMATION</h2>
      <p>
        We may share information we have collected about you in certain situations. Your information
        may be disclosed as follows:
      </p>

      <p>
        <b>By Law or to Protect Rights</b>
      </p>
      <p>
        If we believe the release of information about you is necessary to respond to legal process,
        to investigate or remedy potential violations of our policies, or to protect the rights,
        property, and safety of others, we may share your information as permitted or required by
        any applicable law, rule, or regulation. 
      </p>

      <p>
        <b>Third-Party Service Providers</b>
      </p>
      <p>
        We may share your information with third parties that perform services for us or on our
        behalf, including payment processing, data analysis, email delivery, hosting services,
        customer service, and marketing assistance.
      </p>

      <p>
        <b>Interactions with Other Users</b>
      </p>
      <p>
        If you interact with other users of the Site [and our mobile application], those users may
        see your name, profile photo, and descriptions of your activity.
      </p>

      <p>
        <b>Online Postings</b>
      </p>
      <p>
        When you post comments, contributions or other content to the Site, your posts may be viewed
        by all users and may be publicly distributed outside the Site in perpetuity.
      </p>

      <h2>POLICY FOR CHILDREN/ MINORS</h2>
      <p>
        If you are under the age of 16, you must obtain your parent’s or legal guardian’s permission
        and their observation at all times to access the site and to use any of our services. The
        Site and Services are not intended for or directed to Users under the age of 16. We do not
        knowingly collect or gather personal information from children under the age of 16. If you
        believe or become aware of any personal data we have collected from children under the age
        of 16, please contact us at{' '}
        <a href="mailto:admin@wisepal.co" target="_blank">
          admin@wisepal.co
        </a>{' '}
        and we will take reasonable measures to promptly delete such data from our records. 
      </p>

      <p>
        <b>Marketing Communications</b>
      </p>

      <p>CONTACT US</p>

      <p>
        If you have questions or comments about this Privacy Policy, please contact us by email at{' '}
        <a href="mailto:admin@wisepal.co" target="_blank">
          admin@wisepal.co
        </a>{' '}
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
