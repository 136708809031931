import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Effective from March, 2021</p>

      <p>
        This is a legally binding contract agreement between you and Wisepal Inc. (“Wisepal”, “we”,
        “us”, “our”) located at{' '}
        <a href="https://www.wisepal.co" target="_blank">
          www.wisepal.co
        </a>{' '}
        (“Site”). Wisepal is an online marketplace for individuals (“Students” , “Learners”) seeking
        services from a tutor (“Tutors”, “Teachers” , “Instructors”). Please read this Terms of Use
        Agreement carefully before using it and agreeing to use the Site. By using and accessing
        this Site you are agreeing to be bound by the terms of this agreement. If you do not wish to
        abide by the Wisepal Terms of Use, you cannot continue to use the Wisepal Site.
      </p>

      <h2>1. General Terms</h2>
      <p>
        By accessing, or submitting any content or material on our Site, you agree to comply and be
        bound by the following terms and conditions, including those additional terms and conditions
        and policies referenced herein and/or available by hyperlink. Tutors have the right to
        control and direct the means, details, manner, and method by which the services will be
        performed. These terms apply to all users of the Site, including without limitation users
        who are browsers, or users who create an account.Wisepal provides this Site to Users seeking
        tutoring services, and to Users seeking to provide tutoring services. The term “You” or
        “User” shall refer to Students, Tutors or any person or entity who views, uses, accesses, or
        browses any content on, and/or creates, uploads, posts, sends, receives or stores content to
        the Site. You cannot use this Site:
        <br />
        •    If you are not of legal age in your state and/or residence.
        <br />
        •    If the contents are for illegal and/or unauthorized purposes.
        <br />
        •    To upload and/or transmit viruses or other malicious code that may harm Wisepal site.
        <br />
        •    Through automated and/or non-human means.
        <br />
        •    To reproduce, duplicate, copy, sell, and exploit the site without permission.
        <br />
        •    To breach or violate any of the terms of use.
        <br />
        If you do not acknowledge agreement with these terms, it will result in termination or
        deactivation of your account and/or limit your use of our services.
      </p>

      <h2>2. Privacy Policy</h2>
      <p>
        Your use of our Site is subject to Wisepal’s Privacy Policy. Please review our Privacy
        Policy, which also governs the Site and informs users of our data collection practices. 
      </p>

      <h2>3. Modification to the Agreement</h2>
      <p>
        Wisepal reserves the right to change, modify, add or remove any part of this agreement at
        any time without prior notice. If there are any material changes to this agreement they will
        become effective after being posted on the Wisepal site and we will notify you by email. If
        you do not agree with the new modifications, you cannot continue using or accessing the
        Wisepal site. By continuing to use the Wisepal Site, you agree to and are bound by the
        Wisepal modified terms and conditions.
      </p>

      <h2>4. Academic Dishonesty</h2>
      <p>
        Wisepal holds the right to close and/or suspend your account at any time if the tutor and
        student engage in any form of academic dishonesty. This includes the tutor completing
        examinations or quizzes on the students behalf, writing term papers that violate academic
        policies of schools. Tutor can utilize the platform to facilitate in the learning process.
        Tutor may assist students with assignments and any other lesson related questions by
        providing step-by-step solutions to the student.
      </p>

      <h2>5. Your obligations and conduct</h2>
      <p>
        To use the Site, you shall:
        <br />
        •    Agree to enter into a legally binding agreement with Wisepal.
        <br />
        •    Be responsible for any content you post or upload to the Wisepal site.
        <br />
        •    Be at least 18 years of age or older (legal age in your state of residence in the US).
        If you are under the age of 18, you cannot manage your own account, thus you cannot create
        an account without consent and supervision from a parent or legal guardian on your behalf.
        Student understands that there must be at least one other adult over the age of 18 present
        for the duration of any in-person or online tutoring session between Tutor and student if
        the student is a minor.
        <br />
        •    Reside within the United States.
        <br />
        •    Comply with all applicable laws and regulations.
        <br />•   Not have been the subject of a complaint restraining order or any other legal
        action involving violence, abuse, neglect, fraud, larceny or any other offense that involves
        endangering the safety of others.
        <br />
        •    Not have been convicted of a crime, such as felony or misdemeanor, including without
        limitation any sexual, child abuse or domestic violence offenses.
        <br />
        •    Not have been a registered sex offender in any jurisdiction or with any government
        entity.
        <br />
        •    Not be out on bail or on pending trial relating to any felony misdemeanor charges of
        any kind.
        <br />
        •    Create a username and password and it is your responsibility to keep your username and
        password secure and confidential, and not share your account details with any other person
        or entity.
        <br />
        •    Accept all risks of unauthorized access to information and registration data. You need
        to notify us immediately by email  if the confidentiality of your username, account or
        password is compromised. We will investigate and take appropriate action.
        <br />
        •    Agree to use current, complete and accurate information asked during Site registration
        and update at all times if there is any change.
        <br />
        •    Use the site in a lawful manner as you are responsible for all the content that you
        upload, receive, send and post. Any inappropriate or offensive use of the site may result in
        termination of the users’ account. <br />
        The following constitute as violation of the site use and users are not allowed:
        <br />
        o    To use information, material or content that contains harmful, hateful, obscene,
        discriminatory, pornographic, defamatory, racist, violent, offensive, harassing, infringing,
        profanity for the purpose to post, receive, store, or create material or content on the
        site;
        <br />
        o    Harm, defame, stalk, abuse, harass, threaten and violate the legal rights of others.
        <br />
        o    Upload files that contain malware, viruses, Trojan horses, worms, time bombs, corrupted
        files, cancel bots, that may damage, interfere, disrupt, disable and/or impair computer
        system network or any device
        <br />
        o    Upload files that violate the rights of any third-party including privacy or publicity
        rights and intellectual property rights;
        <br />
        o    To use content that includes unauthorized disclosure of personal information
        <br />
        o    attempting to hack the computer system or network site and/or for the purpose to gain
        access to user accounts’, downloading or accessing information, materials, content through
        hacking devices, bots, URL rewriting, web spoofing, spiders etc;
        <br />
        o    Contain software viruses, files or programs designed to interrupt or limit the
        functionality of any computer software.
        <br />
        o    duplicating, copying, selling, reproducing, any materials or content
        <br />
        o    creating an account for misuse or for your own financial or commercial gains;
        <br />
        o    spamming on the Wisepal site
        <br />
        o    impersonate another person by using their credentials or another entity to utilize our
        site.
        <br />
        o    inhibiting other users from utilizing the Wisepal site.
        <br />
        o    selling, advertising, offering to sell any goods that do not have to do with providing
        tutoring services.
      </p>

      <h2>6. Intellectual property Rights</h2>
      <p>
        The Site and its content, software and services are the property of Wisepal, Inc and is
        protected by copyright, trademark and intellectual property rights. Wisepal gives you
        permission to use the Site for your own personal and non-commercial use. You agree not to
        sell, resell, reproduce, duplicate/imitate, copy or use in whole or any part of its content
        without written permission of Wisepal and its owners.
      </p>

      <h2>7. Copyright infringement</h2>
      <p>
        Wisepal holds the right, as we see fit, to remove any copyrighted material or content that a
        user may use without permission. If we believe that a user infringed the copyright rights of
        others we reserve the right to close the user’s Wisepal account. 
      </p>

      <h2>8. Termination and suspension</h2>
      <p>
        Wisepal has the right to terminate or suspend your account for any reason or no reason.  If
        we notice potentially suspicious or high risk activity in connection with your use of the
        service(s) or other potential violations of the Wisepal Terms and Policies, we will first
        attempt to email you, then we will suspend or terminate your account. If we believe that you
        have not complied with these terms and conditions, we will immediately limit/restrict your
        access temporarily or close your Wisepal account permanently without any prior notice. If
        you no longer wish to have a registered account with Wisepal, you may terminate your account
        by sending an email to the administrator of the Wisepal site.
        <a href="mailto:admin@wisepal.co" target="_blank">
          admin@wisepal.co
        </a>{' '}
        You will not be able to close your account, if you owe us prior cancellation fees. 
      </p>

      <h2>9. Disclaimer of warranties</h2>
      <p>
        Students understand that Wisepal does not guarantee any specific outcomes or performance as
        a result of any tutoring provided by the tutor. 
      </p>

      <h2>10. Limitation of liability</h2>
      <p>
        Students understand that Tutors are independent contractors and they are solely responsible
        for their own actions, performance and quality of the services provided on or off the Site.
        You acknowledge and agree that Wisepal is not responsible or liable for conduct or behavior
        of any user. Each user expressly releases Wisepal from any and all liability whatsoever for
        any controversies, disputes, suits, claims, loss, injury, or damage of any kind that might
        arise from and/or in any way related to the Site, and during and after user interaction
        between themselves on or off the Site. Wisepal will not be involved in any user
        interactions. In no event, Wisepal will not be responsible under any legal theory, whether
        in tort (including negligence) or in contract for the following liabilities: direct or
        indirect loss, loss of business, revenue, loss of profit or goodwill, loss of use,
        corruption of any Data, database or software, acts or omissions of any hosting services
        provider, payment services provider or other third party services provider, any special,
        consequential loss or damage, electronically transmitted orders, or other economic
        advantage. You agree that you will not bring any claim personally against individual
        directors or employees in respect of any losses you suffer in connection with the website or
        these terms and conditions. 
      </p>

      <h2>11. Assumption of risk</h2>
      <p>
        You hereby fully accept and assume all the risks that may arise out of tutoring services
        with Wisepal.  
      </p>

      <h2>12. Indemnification</h2>
      <p>
        You agree to keep Wisepal harmless against (third) 3rd party claims or legal proceedings
        (including actions by government authorities), costs, breach, losses, damages, demands,
        penalties, judgments, losses, expenses, fines, litigation costs, attorney’s and litigation
        fees occurring from your unlawful use of Wisepal services or violations of these terms or
        submissions you provide. 
      </p>

      <h2>13. Arbitration</h2>
      <p>
        Disputes will be settled by binding arbitration. In the event a party fails to proceed with
        arbitration, unsuccessfully challenges the arbitrator’s award, or fails to comply with the
        arbitrator’s award, the other party is entitled of costs of suit including a reasonable
        attorney’s fee for having to compel or defend or enforce the award.  
      </p>

      <h2>14. Governing law</h2>
      <p>Terms of Use are governed by Federal law and laws of the state of New York.</p>

      <h2>15. Payment terms</h2>
      <p>
        You agree to use current, valid, complete and accurate billing and updated contact
        information. You must update at all times if there are any changes such as an expiration
        date, card number and billing information. You authorize us to charge your payment cards
        such as credit, debit card in advance before the tutoring service starts and once the
        session is booked. All fees billed are in U.S Dollars.
        <br />
        Tutors set their own hourly rates for private and group sessions and the charged price is
        not a fixed price as it can be modified at any time in the tutor profile. Students and
        Tutors are bound to honor the prices of the sessions established at the time of the booking.
        <br />
        There will be a service fee collected from the student’s payment at the time of the booking.
        The service fee includes Stripe processor fee.This amount will be deducted and the rest
        amount is deposited into the tutor’s account. Wisepal may at its sole discretion, adjust the
        service fee upon thirty (30) days written notice to the Tutor.
        <br />
        Lessons cannot be booked if the payment method is not valid or if the bank account shows
        zero balance or a negative status. Wisepal reserves the right to suspend, de-activate or
        deny your access to the services for failure to pay applicable fees as described in this
        agreement.
        <br />
        Saving Payment Method: You may be able to save a payment method and your information is
        stored at your Wisepal account such as billing information and payment methods. Payment
        Method may be removed if you delete the payment method and replace it with a current
        payment. All payments are sent and received through Stripe and Wisepal shares with Stripe
        payment information such as card and billing information details to complete the
        transactions. 
      </p>

      <h2>16. Cancellation, Refund, Rescheduling and Additional Time services</h2>
      <p>
        <b>Cancellation and Refunds.</b>
        <br />
        A Student may cancel a session according to a tutor’s cancellation policy. Prior to sending
        a booking request, a student should review carefully the tutor’s individual cancellation
        rules. Bookings that were accepted by the tutor may be cancelled by the student and refund
        can be initiated at any time for reasons including: cannot make it because of a problem or
        the tutor did not show up. A student that initiates a refund, it may take up to fourteen
        (14) business days to process.You authorize us to refund or credit your Payment method.
        <br />
        <br />
        A tutor has the option to set their own cancellation policy. When a tutor cancels a tutoring
        session, then the student can get a full refund of the session. When a tutor cancels a
        lesson he/she agrees to pay the exact stripe fee on the next tutoring session. On the next
        tutoring session this fee will show up as debt (“prior cancellation fee”).
        <br />
        <br />
        <b>Rescheduling.</b>
        <br />
        When a student books a session, the student can edit date and time if the student wants to
        reschedule the session. When the tutor accepts the booking request, only the tutor can
        reschedule a session.
        <br />
        <br />
        <b>Additional time.</b>
        <br />A student understands that one (1) private session has a minimum of one (1) hour. If
        the student prefers to extend the length of the private session, the student can do so by
        communicating with the tutor. A Student will be billed for the additional time for the
        session that was extended. A Student understands that a group session does not have a
        minimum allotted time. The time session is predetermined by the tutor before the session
        begins. If students prefer to extend the length of the group session, then students can do
        so by communicating with the tutor. Students will be billed for the additional time for the
        session that was extended.
      </p>

      <h2>17. Referral program</h2>
      <p>
        In order to be eligible to participate in the Referral program, you must have a tutor or
        student account with Wisepal and the referred client must book and complete one tutoring
        session. Both the referring  and the referred  party must be 18 years of age or older to
        participate. There is no expiration of the rewards/bonuses earned from the referral program,
        unless Wisepal administration decides to close the referral program. Wisepal has the right
        to cancel, terminate or modify the referral program at any time without any prior notice in
        its sole discretion.  The rewards or the bonuses from the referral program cannot be
        converted or redeemed to cash. When a student refers a student, the referred student needs
        to complete a session so that the referring student receives a fixed amount for the
        invitation ($10) after a successful transaction. When a student refers a tutor, the referred
        tutor needs to complete a session so that the referring student receives a fixed amount for
        the invitation ($10) after a successful transaction. When a tutor refers a student, the
        referred student has to complete a lesson and the referring tutor gets the platform fee
        reduction. When a tutor refers a tutor, the referred tutor needs to complete a session so
        that the referring tutor gets the platform fee reduction.  
      </p>

      <h2>18. Late Arrivals</h2>
      <p>
        It is the student’s and tutor’s obligation to arrive in time for the sessions.  A student
        and/or tutor has to communicate with each other in case of late arrivals. If a student
        arrives late for a particular session, then the student will be subject to the fees and
        refund according to the individual tutor cancellation policy. If a tutor arrives late for a
        particular session, and the student decides to cancel then the student will be reimbursed
        for the full amount for the missed session.  
      </p>

      <h2>19. Background Check consent</h2>
      <p>
        Wisepal is not required and does not have any obligation to conduct a criminal background
        check on tutors. We understand the importance of ensuring the safety and security of our
        users, we strongly encourage our tutors to complete a background check.
        <br />
        If the student or the parent would like to do a background check (including criminal history
        searches, sex offender background checks, verifications of education, work history, Social
        Security Verifications or reference checks) on tutors before the service begins, the student
        or the parent can do so by requesting a background check through a third party service.
        Wisepal does not represent or warrant the security, accuracy, or usage of any information
        submitted or any result or reports for a verification check. If a tutor fails a verification
        background check or to submit the necessary information requested for the background check,
        Wisepal may suspend and/or terminate your use of the Services. If the tutor believes that
        there has been any inaccurate reports through a third party service, the tutor may dispute
        the results. If there is a solution, we will reactivate the Tutor’s account.
        <br />
        If a tutor wishes to complete an identity verification and criminal background check on
        him/herself, we will review the background report and we will check mark a tutor’s card as
        successfully verified on our platform. The verified tutor’s card is only valid for a certain
        time. If the tutor wishes to be verified again after the expiration of the background check,
        he/she will need to renew and pass a background check. The party requesting the background
        check will be responsible for the payment of all the applicable fees listed to Wisepal.
        Wisepal expressly disclaims, and you expressly release Wisepal from, any and all liability
        whatsoever for any controversies, claims, suits, injuries and/or damages arising from and/or
        in any way related to: (a) any inaccuracy, untimeliness or incompleteness regarding a User’s
        Eligibility Conditions and/or (b) misstatements or misrepresentations made by any User. 
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
