import { storableError } from '../util/errors';
import * as log from '../util/log';

// ================ Action types ================ //

export const OPEN_CONTACT_MODAL = 'app/contactUs/OPEN_CONTACT_MODAL';
export const CLOSE_CONTACT_MODAL = 'app/contactUs/CLOSE_CONTACT_MODAL';
export const GET_FORM_DATA = 'app/contactUs/GET_FORM_DATA';

export const SAVE_TOKEN_CAPTCHA = 'app/contactUs/SAVE_TOKEN_CAPTCHA';
export const SEND_CONTACT_DATA_ERROR = 'app/contactUs/SEND_CONTACT_DATA_ERROR';
export const SEND_CONTACT_DATA_IN_PROGRESS = 'app/contactUs/SEND_CONTACT_DATA_IN_PROGRESS';

// ================ Environment ================ //

const API_URL = process.env.REACT_APP_API_URL;

// ================ Reducer ================ //

const initialState = {
  modalIsOpen: false,
  formData: {},

  tokenCaptcha: '',
  sendDataError: false,
  sendDataInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_CONTACT_MODAL:
      return { ...state, modalIsOpen: true };

    case CLOSE_CONTACT_MODAL:
      return { ...state, modalIsOpen: false };

    case GET_FORM_DATA:
      return { ...state, formData: payload };

    case SAVE_TOKEN_CAPTCHA:
      return { ...state, tokenCaptcha: payload };

    case SEND_CONTACT_DATA_ERROR:
      return { ...state, sendDataError: payload };

    case SEND_CONTACT_DATA_IN_PROGRESS:
      return { ...state, sendDataInProgress: payload};

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getFormData = (contactUsFormData) => ({
  type: GET_FORM_DATA,
  payload: contactUsFormData
});

export const saveTokenCaptcha = (data) => ({ type: SAVE_TOKEN_CAPTCHA, payload: data });
export const sendContactDataError = (data) => ({ type: SEND_CONTACT_DATA_ERROR, payload: data });
export const sendContactDataInProgress = (data) => ({ type: SEND_CONTACT_DATA_IN_PROGRESS, payload: data});

export const openContactModal = () => ({ type: OPEN_CONTACT_MODAL });
export const closeContactModal = () => (dispatch) => {
  dispatch({ type: CLOSE_CONTACT_MODAL });
  dispatch(sendContactDataError(false))
};

// ================ Thunks ================ //

export const postContactUsFormData = (formData) => async (dispatch, getState, sdk)=> {
  
  const inputData = {};
  dispatch(sendContactDataInProgress(true));
  dispatch(sendContactDataError(false));

  for (let [name, value] of formData) {
    inputData[`${name}`] = value;
    dispatch(getFormData(inputData));
  }

  try {
    const response = await fetch(`${API_URL}/api/contact-us`, {
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      const result = await response.json();
      dispatch(sendContactDataError(false));
      dispatch(sendContactDataInProgress(false));
      return true;
    } else {
      throw new Error('response is not ok');
    }
  } catch (e) {
    log.error(storableError(e), 'error-to-send-contact-form-data:');
    dispatch(sendContactDataError(true));
    dispatch(sendContactDataInProgress(false));
    return false
  }
}

export const checkCaptcha = params => (dispatch) => {
  if(params !== undefined){
    dispatch(saveTokenCaptcha(params))
  }
};

