import axios from 'axios';

async function makeRequest(method, url, data) {
  const options = {
    method: method,
    url: url,
    data,
  };

  let res = await axios(options);
  try {
    return res;
  } catch (e) {
    return new Error(e);
  }
}

const RAILS_API_URL = process.env.REACT_APP_RAILS_API_URL;

// ================ Action types ================ //

export const INITIATE_BLOG_POSTS_REQUEST = 'app/BlogPage/INITIATE_BLOG_POSTS_REQUEST';
export const INITIATE_BLOG_POSTS_SUCCESS = 'app/BlogPage/INITIATE_BLOG_POSTS_SUCCESS';
export const INITIATE_BLOG_POSTS_ERROR = 'app/BlogPage/INITIATE_BLOG_POSTS_ERROR';

export const INITIATE_BLOG_SEPARATE_POSTS_REQUEST =
  'app/BlogPage/INITIATE_BLOG_SEPARATE_POSTS_REQUEST';
export const INITIATE_BLOG_SEPARATE_POSTS_SUCCESS =
  'app/BlogPage/INITIATE_BLOG_SEPARATE_POSTS_SUCCESS';
export const INITIATE_BLOG_SEPARATE_POSTS_ERROR = 'app/BlogPage/INITIATE_BLOG_SEPARATE_POSTS_ERROR';

export const CREATE_BLOG_POST_REQUEST = 'app/BlogPage/CREATE_BLOG_POST_REQUEST';
export const CREATE_BLOG_POST_SUCCESS = 'app/BlogPage/CREATE_BLOG_POST_SUCCESS';
export const CREATE_BLOG_POST_ERROR = 'app/BlogPage/CREATE_BLOG_POST_ERROR';
export const CREATE_BLOG_POST_RESET = 'app/BlogPage/CREATE_BLOG_POST_RESET';

export const UPDATE_BLOG_POST_REQUEST = 'app/BlogPage/UPDATE_BLOG_POST_REQUEST';
export const UPDATE_BLOG_POST_SUCCESS = 'app/BlogPage/UPDATE_BLOG_POST_SUCCESS';
export const UPDATE_BLOG_POST_ERROR = 'app/BlogPage/UPDATE_BLOG_POST_ERROR';
export const UPDATE_BLOG_POST_RESET = 'app/BlogPage/UPDATE_BLOG_POST_RESET';

export const DELETE_BLOG_POST_REQUEST = 'app/BlogPage/DELETE_BLOG_POST_REQUEST';
export const DELETE_BLOG_POST_SUCCESS = 'app/BlogPage/DELETE_BLOG_POST_SUCCESS';
export const DELETE_BLOG_POST_ERROR = 'app/BlogPage/DELETE_BLOG_POST_ERROR';
export const DELETE_BLOG_POST_RESET = 'app/BlogPage/DELETE_BLOG_POST_RESET';

// ================ Reducer ================ //

const initialState = {
  blogPosts: [],
  blogSeparatePost: {},
  isLoading: false,
  error: null,
  isSeparatePostLoading: false,
  separatePostError: null,
  isPostCreating: false,
  postCreationSuccess: false,
  postCreationError: null,
  isPostDeleting: false,
  isPostDeletingSuccess: false,
  postDeletingError: null,
  isPostUpdating: false,
  isPostUpdatingSuccess: false,
  postUpdatingError: null,
};

export default function blogPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_BLOG_POSTS_REQUEST:
      return { ...state, isLoading: true, error: null };
    case INITIATE_BLOG_POSTS_SUCCESS:
      return { ...state, blogPosts: payload, isLoading: false, error: null };
    case INITIATE_BLOG_POSTS_ERROR:
      return { ...state, isLoading: false, error: payload };

    case INITIATE_BLOG_SEPARATE_POSTS_REQUEST:
      return { ...state, isSeparatePostLoading: true, separatePostError: null };
    case INITIATE_BLOG_SEPARATE_POSTS_SUCCESS:
      return { ...state, blogSeparatePost: payload, isSeparatePostLoading: false };
    case INITIATE_BLOG_SEPARATE_POSTS_ERROR:
      return { ...state, isSeparatePostLoading: false, separatePostError: payload };

    case CREATE_BLOG_POST_REQUEST:
      return { ...state, isPostCreating: true, postCreationSuccess: false, postCreationError: null };
    case CREATE_BLOG_POST_SUCCESS:
      return {
        ...state,
        blogPosts: [...state.blogPosts, payload],
        isPostCreating: false,
        postCreationSuccess: true,
        postCreationError: null,
      };
    case CREATE_BLOG_POST_ERROR:
      return { ...state, isPostCreating: false, postCreationSuccess: false, postCreationError: payload };
    case CREATE_BLOG_POST_RESET:
      return {
        ...state,
        isPostCreating: false,
        postCreationSuccess: false,
        postCreationError: null,
      };

    case DELETE_BLOG_POST_REQUEST:
      return { ...state, isPostDeleting: true, isPostDeletingSuccess: false, postDeletingError: null };
    case DELETE_BLOG_POST_SUCCESS:
      return { ...state, isPostDeleting: false, isPostDeletingSuccess: true, postDeletingError: null };
    case DELETE_BLOG_POST_ERROR:
      return { ...state, isPostDeleting: false, isPostDeletingSuccess: false, postDeletingError: payload };
    case DELETE_BLOG_POST_RESET:
      return { ...state, isPostDeleting: false, isPostDeletingSuccess: false, postDeletingError: null };

    case UPDATE_BLOG_POST_REQUEST:
      return { ...state, isPostUpdating: true, isPostUpdatingSuccess: false, postUpdatingError: null };
    case UPDATE_BLOG_POST_SUCCESS:
      return { ...state, isPostUpdating: false, isPostUpdatingSuccess: true, postUpdatingError: null };
    case UPDATE_BLOG_POST_ERROR:
      return { ...state, isPostUpdating: false, isPostUpdatingSuccess: false, postUpdatingError: payload };
    case UPDATE_BLOG_POST_RESET:
      return { ...state, isPostUpdating: false, isPostUpdatingSuccess: false, postUpdatingError: null };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const initiateBlogPostsRequest = () => ({ type: INITIATE_BLOG_POSTS_REQUEST });
const blogPostsSuccess = response => ({
  type: INITIATE_BLOG_POSTS_SUCCESS,
  payload: response.data,
});
const blogPostsRequestError = error => ({ type: INITIATE_BLOG_POSTS_ERROR, payload: error });

const initiateSeparatePostRequest = () => ({ type: INITIATE_BLOG_SEPARATE_POSTS_REQUEST });
const separatePostSuccess = post => ({
  type: INITIATE_BLOG_SEPARATE_POSTS_SUCCESS,
  payload: post.data,
});
const separatePostError = error => ({ type: INITIATE_BLOG_SEPARATE_POSTS_SUCCESS, payload: error });

const initiatePostCreating = () => ({ type: CREATE_BLOG_POST_REQUEST });
const createPostSuccess = post => ({ type: CREATE_BLOG_POST_SUCCESS, payload: post });
const createPostError = error => ({ type: CREATE_BLOG_POST_ERROR, payload: error });
const createPostReset = () => ({ type: CREATE_BLOG_POST_RESET });

const initiateBlogDeleting = () => ({ type: DELETE_BLOG_POST_REQUEST });
const blogPostDeletedSuccessfully = () => ({ type: DELETE_BLOG_POST_SUCCESS });
const blogPostDeletingError = error => ({ type: DELETE_BLOG_POST_ERROR, payload: error });
const blogPostDeletingReset = () => ({ type: DELETE_BLOG_POST_RESET });

const initiateBlogUpdating = () => ({ type: UPDATE_BLOG_POST_REQUEST });
const blogPostUpdatedSuccessfully = post => ({ type: UPDATE_BLOG_POST_SUCCESS, payload: post });
const blogPostUpdatingError = error => ({ type: UPDATE_BLOG_POST_ERROR, payload: error });
const blogPostUpdatingReset = () => ({ type: UPDATE_BLOG_POST_RESET });

/* ================ Thunks ================ */

export const getBlogPosts = params => (dispatch, getState) => {
  dispatch(initiateBlogPostsRequest());

  makeRequest('get', `${RAILS_API_URL}/api/v1/posts`)
    .then(response => {
      dispatch(blogPostsSuccess(response));
    })
    .catch(e => {
      const error = new Error('something went wrong, test error');
      dispatch(blogPostsRequestError(error));
    });
};

export const getSeparatePost = id => (dispatch, getState) => {
  dispatch(initiateSeparatePostRequest());

  makeRequest('get', `${RAILS_API_URL}/api/v1/posts/${id}`)
    .then(response => {
      dispatch(separatePostSuccess(response));
    })
    .catch(e => {
      const error = new Error('something went wrong, test error');
      dispatch(separatePostError(error));
    });
};

export const createNewPost = data => dispatch => {
  dispatch(initiatePostCreating());

  makeRequest('post', `${RAILS_API_URL}/api/v1/posts`, data)
    .then(response => {
      dispatch(createPostSuccess(response.data));
      // dispatch(createPostReset());
    })
    .catch(e => {
      dispatch(createPostError(e));
    });
};

export const deletePost = data => dispatch => {
  dispatch(initiateBlogDeleting());

  makeRequest('delete', `${RAILS_API_URL}/api/v1/posts/${data}`)
    .then(response => {
      dispatch(blogPostDeletedSuccessfully());
      // dispatch(blogPostDeletingReset());
    })
    .catch(e => {
      dispatch(blogPostDeletingError(e));
    });
};

export const updatePost = (id, data) => dispatch => {
  dispatch(initiateBlogUpdating());
  makeRequest('put', `${RAILS_API_URL}/api/v1/posts/${id}`, data)
    .then(response => {
      dispatch(blogPostUpdatedSuccessfully(response));
      dispatch(separatePostSuccess(response));
      // dispatch(blogPostUpdatingReset());
    })
    .catch(e => {
      dispatch(blogPostUpdatingError(e));
    });
};

export const resetUpdateData = () => dispatch => {
  dispatch(blogPostUpdatingReset());
}

export const resetDeletingData = () => dispatch => {
  dispatch(blogPostDeletingReset());
};

export const resetCreateData = () => dispatch => {
  dispatch(createPostReset());
}