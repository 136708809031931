import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { ensureCurrentUser } from '../../util/data';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
} from '../../components';
import { TopbarContainer } from '..';
import {
  getAgreements,
  getBackgroundCheckConsent,
  getTutorsApp,
  createTutorsApp,
  updateTutorsApp,
  addAttachment,
  deleteAttachment
} from './TutorsAppPage.duck';
import AgreementsSection from './AgreementsSection';
import FormSection from './FormSection';
import ConsentSection from './ConsentSection';
import { tutorsStatus } from '../../util/user';

import css from './TutorsAppPage.css';

export const TutorsAppPage = props => {
  const {
    currentUser,
    intl,
    scrollingDisabled,
    agreements,
    onGetAgreements,
    tutorsApp,
    tutorsProgress,
    tutorsError,
    onGetBackgroundCheckConsent,
    onGetTutorsApp,
    onCreateTutorsApp,
    onUpdateTutorsApp,
    initialAttachments,
    attachments,
    attachmentsProgress,
    attachmentsError,
    onAddAttachment,
    onDeleteAttachment,
    edit,
    history,
    bgConsent,
    bgConsentProgress,
    bgConsentError
  } = props;

  const [progress, setProgress] = useState('agreements');
  const [tempData, setTempData] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const appStatus = tutorsStatus(currentUser);
  
  if (redirect){
    return <NamedRedirect name="LandingPage" />
  }

  if (appStatus){
    if (appStatus === 'student') {
      return <NamedRedirect name="LandingPage"/>;
    } else if (appStatus === 'nostatus' && edit){
      return <NamedRedirect name="TutorsAppPage"/>;
    } else if (!edit && (appStatus === 'pending' || appStatus === 'approved')){
      return <NamedRedirect name="TutorsAppPageEdit"/>
    }
  }

  const user = ensureCurrentUser(currentUser);
  const id = user.id;
  const isTutor = user.attributes &&
                  user.attributes.profile &&
                  user.attributes.profile.protectedData &&
                  user.attributes.profile.protectedData.userType &&
                  user.attributes.profile.protectedData.userType.toLowerCase() === 'tutor';

  const handleRedirect = () => {
    setRedirect(true);
  }

   if (id && !isTutor){
    handleRedirect();
  }

  const handleDecline = () => {
    handleRedirect();
  }

  const storeData = data => {
    setTempData(data);
    setProgress('BG');
  }

  const handleStoredFormData = () => {
    const {form, id} = tempData;

    const handler = id ? onUpdateTutorsApp : onCreateTutorsApp;

    handler(form, id)
      .then(() => {
        history.push(createResourceLocatorString('ProfileTutorsAppPage', routeConfiguration(), {}))
      })
  } 

  const showForm = progress === 'form' && currentUser;
  const showBGConsent = progress === 'BG';

  return (
    <Page
      title={intl.formatMessage({ id: 'TutorsAppPage.pageTitle' })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.root}>
            <div className={css.content}>
              {progress === 'agreements' && (
                <AgreementsSection
                  agreements={agreements}
                  onComplete={() => setProgress('form')}
                  onDecline={handleDecline}
                  onLoad={onGetAgreements}
                />
              )}
              {showForm && (
                <FormSection
                  currentUser={currentUser}
                  onComplete={data => storeData(data)}
                  edit={edit}
                  initialValues={tutorsApp}
                  onGetTutorsApp={onGetTutorsApp}
                  attachments={attachments}
                  initialAttachments={initialAttachments}
                  attachmentsProgress={attachmentsProgress}
                  attachmentsError={attachmentsError}
                  onAddAttachment={onAddAttachment}
                  onDeleteAttachment={onDeleteAttachment}
                />
              )}

              {showBGConsent && (
                <ConsentSection
                  onLoad={onGetBackgroundCheckConsent}
                  bgConsent={bgConsent}
                  bgConsentProgress={bgConsentProgress}
                  bgConsentError={bgConsentError}
                  onComplete={handleStoredFormData}
                  onDecline={handleDecline}
                />
              )}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

TutorsAppPage.defaultProps = {
  currentUser: null
};

const { bool, func, shape, string, array } = PropTypes;

TutorsAppPage.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  onGetAgreements: func,
  onGetBackgroundCheckConsent: func,
  onAddAttachment: func,
  onDeleteAttachment: func,
  onGetTutorsApp: func,
  onCreateTutorsApp: func,
  onUpdateTutorsApp: func, 
  edit: bool,
  initialAttachments: array,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    agreements,
    agreementsProgress,
    agreementsError,
    tutorsApp,
    tutorsProgress,
    tutorsError,
    initialAttachments,
    attachments,
    attachmentsProgress,
    attachmentsError,
    bgConsent,
    bgConsentProgress,
    bgConsentError
  } = state.TutorsAppPage;
  const { currentUser } = state.user;
 
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    agreements,
    agreementsProgress,
    agreementsError,
    tutorsApp,
    tutorsProgress,
    tutorsError,
    initialAttachments,
    attachments,
    attachmentsProgress,
    attachmentsError,
    bgConsent,
    bgConsentProgress,
    bgConsentError
  };
};

const mapDispatchToProps = dispatch => ({
  onGetAgreements: () => dispatch(getAgreements()),
  onGetTutorsApp: id => dispatch(getTutorsApp(id)),
  onCreateTutorsApp: data => dispatch(createTutorsApp(data)),
  onUpdateTutorsApp: (data, id) => dispatch(updateTutorsApp(data, id)),
  onGetBackgroundCheckConsent: () => dispatch(getBackgroundCheckConsent()),
  onAddAttachment: data => dispatch(addAttachment(data)),
  onDeleteAttachment: id => dispatch(deleteAttachment(id)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TutorsApp = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(TutorsAppPage);

// TutorsApp.loadData = (params, search) => {
// };

export default TutorsApp;
