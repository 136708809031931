import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
  getAgreements,
  createAgreements,
  updateAgreements,
  deleteAgreements
} from '../../../containers/AdminPanelPage/AdminPanelPage.duck';
import { func, array, bool, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AgreementsEditor, Modal } from '../..';
import AgreementsList from './AgreementsList';

import css from './AdminAgreementsComponent.css';

export class AdminAgreementsComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      showEditor: false,
      editorsData: null
    };
  }

  componentDidMount(){
    this.props.onGetAgreements();
  }

  handleClose(){
    this.setState({
      showEditor: false,
      editorsData: null
    });
  }

  handleCreateNew(){
    this.setState({showEditor: true, editorsData: null});
  }

  handleUpdate(editorsData){
    this.setState({showEditor: true,editorsData});
  }

  handleData({text, title}){
    const { onCreateAgreements, onUpdateAgreements } = this.props;
    const id = this.state.editorsData && this.state.editorsData.id;
    const formdata = new FormData();
    
    formdata.append('text', text);
    formdata.append('title', title);

    if (id) {
      onUpdateAgreements(formdata, id);
    } else {
      onCreateAgreements(formdata);
    }

    this.handleClose();
  }

  handleDelete(id){
    const {onDeleteAgreements} = this.props;

    onDeleteAgreements(id);

    this.handleClose();
  }

  render() {
    const { showEditor, editorsData } = this.state;
    const {
      onManageDisableScrolling,
      agreements
    } = this.props;

    const title =  <FormattedMessage id={"AdminTutorsAppComponent.title"}/>;
  
    return (
      <div>
        <h3 className={css.title}>{title}</h3>

        <div className={css.createRow}>
          <button
            onClick={() => this.handleCreateNew()}
          >
            <FormattedMessage id={"AdminTutorsAppComponent.buttonNewTitle"}/>
          </button>
        </div>
        
        <AgreementsList
          data={agreements}
          handleUpdate={data => this.handleUpdate(data)}
          handleDelete={id => this.handleDelete(id)}
        />

        <Modal
          id="AdminTutorsAppComponent.agreementsEditor"
          isOpen={showEditor}
          onClose={() => this.handleClose()}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <AgreementsEditor
            data={editorsData}
            onSubmit={data => this.handleData(data)}
          />
        </Modal>
      </div>
    );
  }
};

AdminAgreementsComponent.propTypes = {
  agreements: array,
  agreementsProgress: bool,
  agreementsError: bool,
  onGetAgreements: func,
  onCreateAgreements: func,
  onUpdateAgreements: func,
  onDeleteAgreements: func,
};

const mapStateToProps = state => {
  const { agreements, agreementsProgress, agreementsError } = state.AdminPanelPage;
  return {
    agreements,
    agreementsProgress,
    agreementsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetAgreements: () => {dispatch(getAgreements())},
  onCreateAgreements: data => {dispatch(createAgreements(data))},
  onUpdateAgreements: (data, id) => {dispatch(updateAgreements(data, id))},
  onDeleteAgreements: id => {dispatch(deleteAgreements(id))}
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminAgreementsComponent);
