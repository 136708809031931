import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getBlogPosts, createNewPost, resetCreateData } from './BlogPage.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BlogItem,
  BlogPostEditor,
  Modal,
} from '../../components';
import { TopbarContainer } from '../index';
import css from './BlogPage.css';

const BlogPageComponent = props => {
  const {
    blogPosts,
    onGetBlogPosts,
    isLoading,
    error,
    isAuthenticated,
    currentUser,
    onManageDisableScrolling,
    scrollingDisabled,
    onCreateNewPost,
    postCreationSuccess,
    postCreationError,
    onResetCreateData,
  } = props;

  useEffect(() => {
    onGetBlogPosts();
  }, []);

  useEffect(() => {
    if (postCreationSuccess) {
      setModalOpen(false);
    }
  }, [postCreationSuccess]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    onResetCreateData();
  };

  const handleSavePost = data => {
    onResetCreateData();
    onCreateNewPost(data);
  };

  const userType =
    isAuthenticated &&
    currentUser &&
    currentUser.attributes.profile.protectedData.userType.toLowerCase();

  const loadingMsg = isLoading && (
    <div>
      <FormattedMessage id="BlogPage.loadingPostsMessage" />
    </div>
  );

  const errorMsg = error && !isLoading && (
    <div>
      <FormattedMessage id="BlogPage.loadingPostsErrorMessage" />
    </div>
  );

  const addNewBtn = userType === 'superadmin' && (
    <button className={css.addNewBtn} onClick={handleOpen}>
      <FormattedMessage id="BlogPage.addNewBtnText" />
    </button>
  );

  const sortBlogPost = blogPosts.sort((a, b) => {
    return +new Date(a.id) < +new Date(b.id) ? 1 : +new Date(b.id) < +new Date(a.id) ? -1 : 0;
  });

  return (
    <Page
      title="Blog"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: 'Blog page',
      }}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <h1>
            <FormattedMessage id="BlogPage.pageTitleText" />
          </h1>
          {addNewBtn}
          <div className={css.blogItemsWrapper}>
            {!isLoading &&
              blogPosts &&
              blogPosts.length > 0 &&
              sortBlogPost.map(item => {
                return <BlogItem item={item} key={item.id} />;
              })}
            {loadingMsg}
            {errorMsg}
          </div>
          <Modal
            id="BlogPage.addNewPostModal"
            isOpen={isModalOpen}
            onClose={handleClose}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <BlogPostEditor
              editorTitle="BlogPostEditor.addTitle"
              edit={false}
              makeRequestFunction={data => handleSavePost(data)}
              postCreationError={postCreationError}
            />
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { blogPosts, isLoading, error, postCreationSuccess, postCreationError } = state.BlogPage;
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    blogPosts,
    isLoading,
    error,
    isAuthenticated,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    postCreationSuccess,
    postCreationError,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetBlogPosts: () => {
    dispatch(getBlogPosts());
  },
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCreateNewPost: data => {
    dispatch(createNewPost(data));
  },
  onResetCreateData: () => {
    dispatch(resetCreateData());
  },
});

const BlogPage = compose(connect(mapStateToProps, mapDispatchToProps))(BlogPageComponent);

export default BlogPage;
