import React, { Component } from 'react';
import { string, bool, object, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldBirthdayInput,
  FieldCheckbox,
  FieldBoolean,
  FieldRadioButton,
} from '../../components';
import * as validators from '../../util/validators';
import moment from 'moment';

import css from './TutorsAppForm.css';

const formatTodaysDate = date => moment(date).format('DD MMMM YYYY');
class FilesUploadComponent extends Component {
  constructor(props) {
    super(props);
  }

  upload(files) {
    this.props.onAddAttachment(files);
  }

  delete(e, id) {
    e.preventDefault();
    const { disabled } = this.props;

    if (disabled) {
      return;
    }

    this.props.onDeleteAttachment(id);
  }

  componentDidUpdate(prevProps) {
    const { form, attachments } = this.props;

    if (prevProps.attachments !== attachments) {
      form.change('files', this.attachmentsIds());
    }
  }

  renderList() {
    const {
      initialAttachments,
      attachments,
      attachmentsProgress,
      attachmentsError,
      disabled,
    } = this.props;

    const allAttachments = [...initialAttachments.filter(att => !!att.used), ...attachments];

    if (!allAttachments || !allAttachments.length || attachmentsProgress) {
      return null;
    }

    return (
      <>
        <div>
          <FormattedMessage id="TutorsAppForm.filesTitle" />
        </div>
        <ul className={css.filesList}>
          {allAttachments.map(({ file_file_name, id, url }, i) => (
            <li key={String(id)} className={css.filesItem}>
              <a href={url} target="_blank">
                {i + 1}) {file_file_name}
              </a>

              {!disabled && (
                <a href="#" onClick={e => this.delete(e, id)}>
                  X
                </a>
              )}
            </li>
          ))}
        </ul>
      </>
    );
  }

  attachmentsIds() {
    const { attachments } = this.props;

    return attachments.map(att => att.id) || [];
  }

  render() {
    const { disabled, accept, intl } = this.props;

    const requiredMessage = intl.formatMessage({
      id: 'TutorsAppForm.fieldRequired',
    });
    const fileRequired = validators.required(requiredMessage);

    return (
      <div className={css.field}>
        <Field
          component="input"
          type="hidden"
          name="files"
          // defaultValue={this.attachmentsIds()}
        />

        {!disabled && (
          <div className={classNames(css.field, css.fieldRequired)}>
            <label>
              <FormattedMessage id="TutorsAppForm.filesLabel" />
            </label>

            <p className={css.note}>
              {intl.formatMessage(
                { id: 'TutorsAppForm.filesNote' },
              )}
            </p>
            <p className={css.noteWithMargin}>
              {intl.formatMessage(
                { id: 'TutorsAppForm.acceptTitle' },
                { formats: 'jpeg, png, gif, doc, docx, odt, rtf, pdf' }
              )}
            </p>

            <Field
              component="input"
              type="file"
              name="filesUpload"
              onChange={e => this.upload(e.target.files)}
              multiple
              {...(accept ? { accept } : {})}
            />
          </div>
        )}

        <div className={css.filesListWrapper}>{this.renderList()}</div>
      </div>
    );
  }
}

class TutorsAppFormComponent extends Component {
  handleSubmit(values) {
    const { isPreview, onSubmit } = this.props;

    if (isPreview) {
      return;
    }

    onSubmit(values);
  }

  render() {
    const {
      initialValues,
      edit,
      initialAttachments,
      attachments,
      attachmentsProgress,
      attachmentsError,
      onAddAttachment,
      onDeleteAttachment,
      ...rest
    } = this.props;

    return (
      <FinalForm
        {...rest}
        onSubmit={values => this.handleSubmit(values)}
        initialValues={initialValues}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            isPreview,
            values,
            form,
          } = fieldRenderProps;

          const allAttachments = [...initialAttachments.filter(att => !!att.used), ...attachments]

          const fieldsDisabled = !!isPreview;

          const requiredFieldClasses = fieldsDisabled
            ? css.field
            : classNames(css.field, css.fieldRequired);

          const requiredMessage = intl.formatMessage({
            id: 'TutorsAppForm.fieldRequired',
          });

          const fullnameLabel = intl.formatMessage({
            id: 'TutorsAppForm.fullnameLabel',
          });
          const fullnameRequired = validators.required(requiredMessage);

          const addressLabel = intl.formatMessage({
            id: 'TutorsAppForm.addressLabel',
          });
          const addressRequired = validators.required(requiredMessage);

          const emailLabel = intl.formatMessage({
            id: 'TutorsAppForm.emailLabel',
          });
          const emailRequired = validators.required(requiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'TutorsAppForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const phoneLabel = intl.formatMessage({
            id: 'TutorsAppForm.phoneLabel',
          });
          const phoneRequired = validators.required(requiredMessage);

          const birthdayLabel = intl.formatMessage({
            id: 'TutorsAppForm.birthdayLabel',
          });
          const birthdayDateLabel = intl.formatMessage({
            id: 'TutorsAppForm.birthdayDateLabel',
          });
          const birthdayLabelMonth = intl.formatMessage({
            id: 'TutorsAppForm.birthdayLabelMonth',
          });
          const birthdayLabelYear = intl.formatMessage({
            id: 'TutorsAppForm.birthdayLabelYear',
          });

          const birthdayRequired = validators.required(requiredMessage);

          const ssnLabel = intl.formatMessage({
            id: 'TutorsAppForm.ssnLabel',
          });
          const ssnRequired = validators.required(requiredMessage);

          const emergencyNumberLabel = intl.formatMessage({
            id: 'TutorsAppForm.emergencyNumberLabel',
          });

          const legallyLabel = intl.formatMessage({
            id: 'TutorsAppForm.legallyLabel',
          });
          const legallyRequired = validators.required(requiredMessage);

          const educationLabel = intl.formatMessage({
            id: 'TutorsAppForm.educationLabel',
          });

          const educationLabelCollege = intl.formatMessage({
            id: 'TutorsAppForm.educationLabelCollege',
          });

          const educationLabelBachelors = intl.formatMessage({
            id: 'TutorsAppForm.educationLabelBachelors',
          });

          const educationLabelMasters = intl.formatMessage({
            id: 'TutorsAppForm.educationLabelMasters',
          });

          const educationLabelDoctorate = intl.formatMessage({
            id: 'TutorsAppForm.educationLabelDoctorate',
          });

          const gpaLabel = intl.formatMessage({
            id: 'TutorsAppForm.gpaLabel',
          });

          const tutoringExperienceLabel = intl.formatMessage({
            id: 'TutorsAppForm.tutoringExperienceLabel',
          });
          const tutoringExperienceRequired = validators.required(requiredMessage);

          const experienceYearsLabel = intl.formatMessage({
            id: 'TutorsAppForm.experienceYearsLabel',
          });
          const tutoringExperienceYearsRequired = validators.requiredIf(
            requiredMessage,
            'tutoring_experience'
          );

          const teachingCertificateLabel = intl.formatMessage({
            id: 'TutorsAppForm.teachingCertificateLabel',
          });
           const teachingCertificateNote = intl.formatMessage({
             id: 'TutorsAppForm.teachingCertificateNote',
           });
          const teachingCertificateRequired = validators.required(requiredMessage);

          const q1Label = intl.formatMessage({
            id: 'TutorsAppForm.q1Label',
          });
          const q1Required = validators.required(requiredMessage);

          const q2Label = intl.formatMessage({
            id: 'TutorsAppForm.q2Label',
          });
          const q2Note = intl.formatMessage({
            id: 'TutorsAppForm.q2Note',
          });
          const q2Required = validators.required(requiredMessage);

          const q3Label = intl.formatMessage({
            id: 'TutorsAppForm.q3Label',
          });
          const q3Required = validators.required(requiredMessage);

          const q4Label = intl.formatMessage({
            id: 'TutorsAppForm.q4Label',
          });
          const q4Required = validators.required(requiredMessage);

          const q5Label = intl.formatMessage({
            id: 'TutorsAppForm.q5Label',
          });
          const q5Required = validators.required(requiredMessage);

          const q6Label = intl.formatMessage({
            id: 'TutorsAppForm.q6Label',
          });

          const q7Label = intl.formatMessage({
            id: 'TutorsAppForm.q7Label',
          });

          const q8Label = intl.formatMessage({
            id: 'TutorsAppForm.q8Label',
          });
          const q8Required = validators.required(requiredMessage);

          const q9Label = intl.formatMessage({
            id: 'TutorsAppForm.q9Label',
          });
          const q9Note = intl.formatMessage({
            id: 'TutorsAppForm.q9Note',
          });

          const q10Label = intl.formatMessage({
            id: 'TutorsAppForm.q10Label',
          });

          const duplicateFullnameLabel = intl.formatMessage({
            id: 'TutorsAppForm.duplicateFullnameLabel',
          });
          const duplicateFullnameRequired = validators.required(requiredMessage);

          const signatureLabel = intl.formatMessage({
            id: 'TutorsAppForm.signatureLabel',
          });
          const signatureNote = intl.formatMessage({
            id: 'TutorsAppForm.signatureNote',
          });
          const signatureRequired = validators.required(requiredMessage);

          const todayDateLabel = intl.formatMessage({
            id: 'TutorsAppForm.todayDateLabel',
          });

          const buttonTitle = edit
            ? intl.formatMessage({ id: 'TutorsAppForm.buttonUpdate' })
            : intl.formatMessage({ id: 'TutorsAppForm.buttonSubmit' });

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const hasAttachments = allAttachments.length > 0;
          const submitDisabled = submitInProgress || !hasAttachments;

          const onChange = ({ values: newValues, dirtyFields }) => {
            if (
              newValues.tutoring_experience !== values.tutoring_experience &&
              dirtyFields.tutoring_experience
            ) {
              form.change('experience_years', '');
            }
          };

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
                <FormSpy onChange={onChange} />

                <Field component="input" type="hidden" name="flex_id" />

                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.full_name` : 'full_name'}
                  name="full_name"
                  label={fullnameLabel}
                  validate={validators.composeValidators(fullnameRequired)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.address` : 'address'}
                  name="address"
                  label={addressLabel}
                  validate={validators.composeValidators(addressRequired)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={requiredFieldClasses}
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.phone_number` : 'phone_number'}
                  name="phone_number"
                  label={phoneLabel}
                  validate={validators.composeValidators(phoneRequired)}
                  disabled={fieldsDisabled}
                />

                <FieldBirthdayInput
                  id={formId ? `${formId}.date_of_birth` : 'date_of_birth'}
                  name={'date_of_birth'}
                  className={requiredFieldClasses}
                  label={birthdayLabel}
                  dateLabel={birthdayDateLabel}
                  labelForMonth={birthdayLabelMonth}
                  labelForYear={birthdayLabelYear}
                  valueFromForm={new Date(values.date_of_birth)}
                  validate={validators.composeValidators(birthdayRequired)}
                  parse={v => (v ? new Date(v.year, v.month - 1, v.day).toISOString() : v)}
                  format={v => (v ? new Date(v) : v)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.ssn` : 'ssn'}
                  name="ssn"
                  label={ssnLabel}
                  validate={validators.composeValidators(ssnRequired)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={formId ? `${formId}.emergency_contact_number` : 'emergency_contact_number'}
                  name="emergency_contact_number"
                  label={emergencyNumberLabel}
                  disabled={fieldsDisabled}
                />

                <FilesUploadComponent
                  intl={intl}
                  form={form}
                  initialAttachments={initialAttachments}
                  attachments={attachments}
                  attachmentsProgress={attachmentsProgress}
                  attachmentsError={attachmentsError}
                  onAddAttachment={onAddAttachment}
                  onDeleteAttachment={onDeleteAttachment}
                  disabled={fieldsDisabled}
                  accept={'image/jpeg,image/png,image/gif,.doc,.docx,.odt,.rtf,application/pdf'}
                />

                <FieldBoolean
                  className={requiredFieldClasses}
                  id={formId ? `${formId}.legally_work` : 'legally_work'}
                  name={'legally_work'}
                  label={legallyLabel}
                  showAsRequired={true}
                  validate={validators.composeValidators(legallyRequired)}
                  disabled={fieldsDisabled}
                />

                <div className={css.field}>
                  <div className={requiredFieldClasses}>
                    <label>{educationLabel}</label>
                  </div>

                  <FieldRadioButton
                    id={formId ? `${formId}.highest_education_1` : 'highest_education_1'}
                    name="highest_education"
                    label={educationLabelCollege}
                    value={'college'}
                    showAsRequired={true}
                    disabled={fieldsDisabled}
                  />

                  <FieldRadioButton
                    id={formId ? `${formId}.highest_education_2` : 'highest_education_2'}
                    name="highest_education"
                    label={educationLabelBachelors}
                    value={'bachelors'}
                    showAsRequired={true}
                    disabled={fieldsDisabled}
                  />

                  <FieldRadioButton
                    id={formId ? `${formId}.highest_education_3` : 'highest_education_3'}
                    name="highest_education"
                    label={educationLabelMasters}
                    value={'masters'}
                    showAsRequired={true}
                    disabled={fieldsDisabled}
                  />

                  <FieldRadioButton
                    id={formId ? `${formId}.highest_education_4` : 'highest_education_4'}
                    name="highest_education"
                    label={educationLabelDoctorate}
                    value={'doctorate'}
                    showAsRequired={true}
                    disabled={fieldsDisabled}
                  />
                </div>

                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={formId ? `${formId}.gpa` : 'gpa'}
                  name="gpa"
                  label={gpaLabel}
                  disabled={fieldsDisabled}
                />

                <FieldBoolean
                  id={formId ? `${formId}.tutoring_experience` : 'tutoring_experience'}
                  className={requiredFieldClasses}
                  name={'tutoring_experience'}
                  label={tutoringExperienceLabel}
                  showAsRequired={true}
                  validate={validators.composeValidators(tutoringExperienceRequired)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={
                    fieldsDisabled || !values.tutoring_experience ? css.field : requiredFieldClasses
                  }
                  type="text"
                  id={formId ? `${formId}.experience_years` : 'experience_years'}
                  name="experience_years"
                  label={experienceYearsLabel}
                  validate={tutoringExperienceYearsRequired}
                  disabled={fieldsDisabled || !values.tutoring_experience}
                />

                <label className={classNames(css.fieldRequiredLabel)}>
                  {teachingCertificateLabel}
                </label>
                <p className={css.note}>{teachingCertificateNote}</p>
                <FieldBoolean
                  id={formId ? `${formId}.teaching_certificate` : 'teaching_certificate'}
                  className={requiredFieldClasses}
                  name={'teaching_certificate'}
                  // label={teachingCertificateLabel}
                  showAsRequired={true}
                  validate={validators.composeValidators(teachingCertificateRequired)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.q1` : 'q1'}
                  name="q1"
                  label={q1Label}
                  validate={validators.composeValidators(q1Required)}
                  disabled={fieldsDisabled}
                />

                <label className={classNames(css.fieldRequiredLabel)}>{q2Label}</label>
                <p className={css.note}>{q2Note}</p>
                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.q2` : 'q2'}
                  name="q2"
                  // label={q2Label}
                  validate={validators.composeValidators(q2Required)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.q3` : 'q3'}
                  name="q3"
                  label={q3Label}
                  validate={validators.composeValidators(q3Required)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.q4` : 'q4'}
                  name="q4"
                  label={q4Label}
                  validate={validators.composeValidators(q4Required)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.q5` : 'q5'}
                  name="q5"
                  label={q5Label}
                  validate={validators.composeValidators(q5Required)}
                  disabled={fieldsDisabled}
                />

                {/* <FieldTextInput
                  className={css.field}
                  type="text"
                  id={formId ? `${formId}.q6` : 'q6'}
                  name="q6"
                  label={q6Label}
                  disabled={fieldsDisabled}
                /> */}

                <FieldBoolean
                  className={css.fieldSelect}
                  id={formId ? `${formId}.q7` : 'q7'}
                  name={'q7'}
                  label={q7Label}
                  disabled={fieldsDisabled}
                />

                <FieldBoolean
                  className={requiredFieldClasses}
                  id={formId ? `${formId}.q8` : 'q8'}
                  name={'q8'}
                  label={q8Label}
                  showAsRequired={true}
                  validate={validators.composeValidators(q8Required)}
                  disabled={fieldsDisabled}
                />

                <label>{q9Label}</label>
                <p className={css.note}>{q9Note}</p>
                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={formId ? `${formId}.q9` : 'q9'}
                  name="q9"
                  // label={q9Label}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={formId ? `${formId}.q10` : 'q10'}
                  name="q10"
                  label={q10Label}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={formId ? `${formId}.duplicate_full_name` : 'duplicate_full_name'}
                  name="duplicate_full_name"
                  label={duplicateFullnameLabel}
                  // validate={validators.composeValidators(duplicateFullnameRequired)}
                  disabled={fieldsDisabled}
                />

                <label className={classNames(css.fieldRequiredLabel)}>{signatureLabel}</label>
                <p className={css.note}>{signatureNote}</p>
                <FieldTextInput
                  className={requiredFieldClasses}
                  type="text"
                  id={formId ? `${formId}.signature` : 'signature'}
                  name="signature"
                  // label={signatureLabel}
                  validate={validators.composeValidators(signatureRequired)}
                  disabled={fieldsDisabled}
                />

                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={formId ? `${formId}.today_date` : 'today_date'}
                  name="today_date"
                  label={todayDateLabel}
                  disabled={true}
                  format={formatTodaysDate}
                />
              </div>

              {!isPreview && (
                <div className={css.bottomWrapper}>
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                  >
                    {buttonTitle}
                  </PrimaryButton>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

TutorsAppFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
  initialValues: {},
  isPreview: false,
  edit: false,
};

TutorsAppFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  initialValues: object,
  isPreview: bool,
  edit: bool,
};

const TutorsAppForm = compose(injectIntl)(TutorsAppFormComponent);
TutorsAppForm.displayName = 'TutorsAppForm';

export default TutorsAppForm;
