import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';

import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
  NotificationBadge,
} from '../../components';

import { languagesHelper as language } from '../../helpers/languages';
import { TopbarSearchForm } from '../../forms';
import TopbarDesktopNotificationIcon from './TopbarDesktopNotificationIcon';

import css from './TopbarDesktop.css';
import config from '../../config';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const changeLanguage = locale => {
    language.set(locale);
  };

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const userTypes = currentUser ?  currentUser?.attributes?.profile?.privateData?.userType?.toLowerCase() : null;
  const showCreateListingLink = isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing);


  const classes = classNames(rootClassName || css.root, className);
  const labelText = config.locale === null ? 'en' : config.locale;

  let currentLocale = config.locale;
  const languages = {
    'en': css.en,
    'de': css.de
  }

  const multiLanguageContent = (
    <Menu className={isAuthenticatedOrJustHydrated ? classNames(css.languages, css.languagesLeft) : css.languages}>
      <MenuLabel className={css.langBtn + ' ' + languages[currentLocale]}>
        {labelText}
      </MenuLabel>
      <MenuContent>
        <MenuItem key="en">
          <div className={css.langBtnOption} onClick={() => changeLanguage('en')} >
            EN
          </div>
        </MenuItem>
        <MenuItem key="de">
          <div className={css.langBtnOption} onClick={() => changeLanguage('de')} >
            DE
          </div>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <TopbarDesktopNotificationIcon/>
        {notificationCountBadge}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const createListingLinkModal = userTypes === 'tutor' ? (
    <OwnListingLink
      listing={currentUserListing}
      listingFetched={currentUserListingFetched}
      className={css.yourListingsLink}
    >
      <div>
        <span className={css.menuItemBorder} />
        {currentUserListing ? (
          <FormattedMessage id="TopbarDesktop.editYourListingLink" />
        ) : (
          <FormattedMessage id="TopbarDesktop.addYourListingLink" />
        )}
      </div>
    </OwnListingLink>
    ) : null;

  const adminPanelLink = userTypes === 'superadmin' ? (
    <NamedLink
      className={classNames(css.yourListingsLink, currentPageClass('AdminPanelPage'))}
      name="AdminPanelPage"
    >
      <span className={css.menuItemBorder}/>
      <FormattedMessage id="TopbarDesktop.adminPanelLink"/>
    </NamedLink>
  ) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="EditListingPage">
          <>{createListingLinkModal}</>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AdminPanelPage">
            {adminPanelLink}
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <Menu className={css.signupLink}>
      <MenuLabel className={css.signupLinkInner}><FormattedMessage id="SignupForm.signUp" /></MenuLabel>
      <MenuContent className={css.signupLinkMenu}>
        <MenuItem key="tutor">
          <NamedLink to={{userType: 'Tutor'}} name="SignupPage" className={css.signupLinkMenuItem}>
            <span>
              <FormattedMessage id="TopbarDesktop.signupTutor" />
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="student">
          <NamedLink to={{userType: 'Student'}} name="SignupPage" className={css.signupLinkMenuItem}>
            <span>
              <FormattedMessage id="TopbarDesktop.signupStudent" />
            </span>
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const createListingLink =
    showCreateListingLink && userTypes === 'Tutor' ? null
      : userTypes === 'Tutor' ? (
          <NamedLink className={css.createListingLink} name="NewListingPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>)
      : null;

  const listingLink =
    authenticatedOnClientSide && currentUserListingFetched && currentUserListing ? (
      <ListingLink
        className={css.createListingLink}
        listing={currentUserListing}
        children={
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.viewListing" />
          </span>
        }
      />
    ) : null;

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      <div className={css.infoLinks}>
        <ul className={css.list}>
          <li className={css.listItem}>
            <NamedLink name="LandingPage" className={css.link} activeClassName={css.activeLink}>
              <FormattedMessage id="TopbarDesktop.toHomePage" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="AboutPage" className={css.link} activeClassName={css.activeLink}>
              <FormattedMessage id="TopbarDesktop.toAboutPage" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="FAQpage" className={css.link} activeClassName={css.activeLink}>
              <FormattedMessage id="TopbarDesktop.toFAQPage" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="BlogPage" className={css.link} activeClassName={css.activeLink}>
              <FormattedMessage id="TopbarDesktop.toBlogPage" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="AnnouncementsPage" className={css.link} activeClassName={css.activeLink}>
              <FormattedMessage id="TopbarDesktop.toAnnouncementsPage" />
            </NamedLink>
          </li>
          {isAuthenticatedOrJustHydrated ? <li className={css.listItem}>
            <NamedLink name="ReferralSystemPage" className={css.link} activeClassName={css.activeLink}>
              <FormattedMessage id="TopbarDesktop.toInviteNewMembers" />
            </NamedLink>
          </li> : null}
        </ul>
        <div className={css.userLinksBlock}>
          {listingLink}
          {createListingLink}
          {inboxLink}
          {signupLink}
          {loginLink}
          {profileMenu}
          {multiLanguageContent}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
