import React from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { TopbarSearchForm } from '../../forms';

import css from './SectionHero.css';

const SectionHero = props => {
  const {
    rootClassName,
    className,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <div className={css.heroSearchForm}>
          {search}
        </div>
      </div>
    </div>
  );
};



SectionHero.defaultProps = { rootClassName: null, className: null, initialSearchFormValues: {}, };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
};

export default SectionHero;
