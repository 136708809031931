import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  Modal,
  AboutEditor,
  AboutSectionItem,
  ExperienceSectionItem,
  ExperienceEditor,
  ChangeImageEditor,
} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  getAboutData,
  getExperienceData,
  getImagesData,
  createAboutSection,
  createAboutReset,
  createExperience,
  createExperienceSectionReset,
  saveImages,
  saveImagesAboutReset,
  editImages,
  editImagesAboutReset,
} from './AboutPage.duck';

import css from './AboutPage.css';
import aboutUsImgHeader from './about-us-img-1.jpg';
import aboutUsImgFooter from './about-us-img-2.jpg';
import aboutUsListImg1 from './about-us-list-img-1.jpg';
import aboutUsListImg2 from './about-us-list-img-2.jpg';
import aboutUsListImg3 from './about-us-list-img-3.jpg';
import aboutUsListImg4 from './about-us-list-img-4.jpg';

const AboutPageComponent = props => {
  const {
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    onGetAboutData,
    onGetExperienceData,
    onGetImagesData,
    onCreateAboutSection,
    onCreateAboutReset,
    onCreateExperience,
    onCreateExperienceSectionReset,
    aboutSection,
    experience,
    createAboutSuccess,
    createAboutError,
    createExperienceSuccess,
    createExperienceError,
    editingAboutError,
    editingAboutSuccess,
    editingExperienceError,
    editingExperienceSuccess,
    deletingAboutError,
    deletingAboutSuccess,
    deletingExperienceError,
    deletingExperienceSuccess,
    error,
    experienceError,
    isLoading,
    isExperienceLoading,
    onSaveImages,
    onSaveImagesAboutReset,
    onEditImages,
    onEditImagesAboutReset,
    isImagesLoading,
    images,
    imagesError,
    saveImagesInProgress,
    saveImagesSuccess,
    saveImagesError,
    isEditImagesInProgress,
    editImagesSuccess,
    editImagesError,
  } = props;

  const [modalChangeImageOpen, setModalChangeImageOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalExperienceOpen, setModalExperienceOpen] = useState(false);
  const [typeSection, setTypeSection] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [firstImage, setFirstImage] = useState('');
  const [secondImage, setSecondImage] = useState('');

  useEffect(() => {
    onGetAboutData();
    onGetExperienceData();
    onGetImagesData();
  }, []);

  useEffect(() => {
    if (createAboutSuccess) {
      setModalOpen(false);
    }
  }, [createAboutSuccess]);

  useEffect(() => {
    if (createExperienceSuccess) {
      setModalExperienceOpen(false);
    }
  }, [createExperienceSuccess]);

  useEffect(() => {
    if (saveImagesSuccess) {
      setModalChangeImageOpen(false);
    }
  }, [saveImagesSuccess]);

  useEffect(() => {
    if (editImagesSuccess) {
      setModalChangeImageOpen(false);
    }
  }, [editImagesSuccess]);

  useEffect(() => {
    if (images.length > 0) {
      images.map(item => {
        if (item.section && item.section === 'about') {
          setFirstImage(item.image_file_name);
        } else if (item.section && item.section === 'experience') {
          setSecondImage(item.image_file_name);
        }
      });
    }
  }, [images]);

  const userType =
    isAuthenticated &&
    currentUser &&
    currentUser?.attributes?.profile?.protectedData?.userType?.toLowerCase();

  const handleOpen = () => {
    setModalOpen(true);
  };

  const imageChangeModal = type => {
    setTypeSection(type);
    const image =
      images && images.length > 0
        ? images.filter(img => img.section && img.section === type.split('_')[1])
        : null;
    setSelectedImage(image && image.length > 0 ? image[0] : null);
    setModalChangeImageOpen(true);
  };

  const handleChangeImageModalClose = () => {
    setModalChangeImageOpen(false);
    setTypeSection('');
    setSelectedImage(null);
    onSaveImagesAboutReset();
    onEditImagesAboutReset();
  };

  const handleOpenExperience = () => {
    setModalExperienceOpen(true);
  };

  const addNewBtn = userType === 'superadmin' && (
    <button className={css.addNewBtn} onClick={handleOpen}>
      <FormattedMessage id="FaqPage.addBtnText" />
    </button>
  );
  const addNewBtnExperience = userType === 'superadmin' && (
    <button className={css.addNewBtn} onClick={handleOpenExperience}>
      <FormattedMessage id="FaqPage.addBtnText" />
    </button>
  );

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleCloseExperience = () => {
    setModalExperienceOpen(false);
  };

  const handleCreate = data => {
    onCreateAboutReset();
    onCreateAboutSection(data);
  };

  const handleCreateExperience = data => {
    onCreateExperienceSectionReset();
    onCreateExperience(data);
  };

  const handleChangeImage = data => {
    const isNewImage = !!!selectedImage;
    isNewImage ? onSaveImages(data) : onEditImages(selectedImage.id, data);
  };

  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Wisepal',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn className={css.root}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <Modal
            id="AboutPage.changeimage"
            isOpen={modalChangeImageOpen}
            onClose={handleChangeImageModalClose}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <ChangeImageEditor
              editorTitle="AboutPage.changeTitleText"
              makeRequestFunction={data => handleChangeImage(data)}
              createError={saveImagesError}
              editingError={editImagesError}
              typeSection={typeSection}
              item={selectedImage}
              edit={false}
              inProgress={saveImagesInProgress || isEditImagesInProgress}
              modalChangeImageOpen={modalChangeImageOpen}
            />
          </Modal>
          {firstImage ? (
            <div className={css.imgContainer}>
              <img className={css.coverImage} src={firstImage} alt="About Wisepal image" />
              {userType === 'superadmin' && (
                <button className={css.changeBtn} onClick={() => imageChangeModal('about_about')}>
                  <FormattedMessage id="AboutPage.changeImage" />
                </button>
              )}
            </div>
          ) : userType === 'superadmin' ? (
            <div className={css.noImg}>
              <button className={css.changeBtn} onClick={() => imageChangeModal('about_about')}>
                <FormattedMessage id="AboutPage.changeImage" />
              </button>
            </div>
          ) : null}
          {isLoading || isExperienceLoading ? (
            <div className={css.loadingMsq}>
              <FormattedMessage id="AboutPage.loading" />
            </div>
          ) : (
            <>
              {addNewBtn}
              {error ? (
                <div className={css.errorMsq}>
                  <FormattedMessage id="AboutPage.error" />
                </div>
              ) : null}
              <Modal
                id="AboutPage.addNewPostAbout"
                isOpen={modalOpen}
                onClose={handleClose}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <AboutEditor
                  editorTitle="AboutPage.addTitleText"
                  makeRequestFunction={data => handleCreate(data)}
                  createError={createAboutError}
                  type={'about'}
                />
              </Modal>

              <ul className={css.aboutUsList}>
                {aboutSection &&
                  aboutSection.length > 0 &&
                  aboutSection.map(item => {
                    return (
                      <AboutSectionItem
                        item={item}
                        key={item.id}
                        editingAboutError={editingAboutError}
                        editingAboutSuccess={editingAboutSuccess}
                        deletingAboutError={deletingAboutError}
                        deletingAboutSuccess={deletingAboutSuccess}
                      />
                    );
                  })}
              </ul>

              {secondImage ? (
                <div className={css.imgContainer}>
                  <img className={css.coverImage} src={secondImage} alt="About Wisepal image" />
                  {userType === 'superadmin' && (
                    <button
                      className={css.changeBtn}
                      onClick={() => imageChangeModal('about_experience')}
                    >
                      <FormattedMessage id="AboutPage.changeImage" />
                    </button>
                  )}
                </div>
              ) : userType === 'superadmin' ? (
                <div className={css.noImg}>
                  <button
                    className={css.changeBtn}
                    onClick={() => imageChangeModal('about_experience')}
                  >
                    <FormattedMessage id="AboutPage.changeImage" />
                  </button>
                </div>
              ) : null}

              <h2 className={css.sectionTitle}>
                <FormattedMessage id="AboutPage.sectionTitle" />
              </h2>

              {addNewBtnExperience}
              {experienceError ? (
                <div className={css.errorMsq}>
                  <FormattedMessage id="AboutPage.error" />
                </div>
              ) : null}
              <Modal
                id="AboutPage.addNewPostExperience"
                isOpen={modalExperienceOpen}
                onClose={handleCloseExperience}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <ExperienceEditor
                  editorTitle="AboutPage.addTitleText"
                  makeRequestFunction={data => handleCreateExperience(data)}
                  createError={createExperienceError}
                  type={'experience'}
                  edit={false}
                />
              </Modal>
              <ul className={css.aboutUsList}>
                {experience &&
                  experience.length > 0 &&
                  experience.map(item => {
                    return (
                      <ExperienceSectionItem
                        item={item}
                        key={item.id}
                        editingExperienceError={editingExperienceError}
                        editingExperienceSuccess={editingExperienceSuccess}
                        deletingExperienceError={deletingExperienceError}
                        deletingExperienceSuccess={deletingExperienceSuccess}
                      />
                    );
                  })}
              </ul>

              {!currentUser ? (
                <NamedLink name="SignupPage" className={css.createAccountBtn}>
                  <FormattedMessage id="AboutPage.buttonText" />
                </NamedLink>
              ) : null}
            </>
          )}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

AboutPageComponent.defaultProps = {
  currentUser: null,
};

AboutPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const {
    aboutSection,
    experience,
    createAboutSuccess,
    createAboutError,
    createExperienceError,
    editingAboutError,
    editingAboutSuccess,
    createExperienceSuccess,
    editingExperienceError,
    editingExperienceSuccess,
    deletingAboutError,
    deletingAboutSuccess,
    deletingExperienceError,
    deletingExperienceSuccess,
    error,
    experienceError,
    isLoading,
    isExperienceLoading,
    isImagesLoading,
    images,
    imagesError,
    saveImagesInProgress,
    saveImagesSuccess,
    saveImagesError,
    isEditImagesInProgress,
    editImagesSuccess,
    editImagesError,
  } = state.AboutPage;
  return {
    currentUser,
    isAuthenticated,
    aboutSection,
    experience,
    createAboutSuccess,
    createAboutError,
    createExperienceError,
    editingAboutError,
    editingAboutSuccess,
    createExperienceSuccess,
    editingExperienceError,
    editingExperienceSuccess,
    deletingAboutError,
    deletingAboutSuccess,
    deletingExperienceError,
    deletingExperienceSuccess,
    error,
    experienceError,
    isLoading,
    isExperienceLoading,
    isImagesLoading,
    images,
    imagesError,
    saveImagesInProgress,
    saveImagesSuccess,
    saveImagesError,
    isEditImagesInProgress,
    editImagesSuccess,
    editImagesError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onGetAboutData: () => dispatch(getAboutData()),
  onGetExperienceData: () => dispatch(getExperienceData()),
  onGetImagesData: () => dispatch(getImagesData()),
  onCreateAboutSection: data => dispatch(createAboutSection(data)),
  onCreateExperience: data => dispatch(createExperience(data)),
  onCreateAboutReset: () => dispatch(createAboutReset()),
  onCreateExperienceSectionReset: () => dispatch(createExperienceSectionReset()),
  onSaveImages: data => dispatch(saveImages(data)),
  onSaveImagesAboutReset: () => dispatch(saveImagesAboutReset()),
  onEditImages: (id, data) => dispatch(editImages(id, data)),
  onEditImagesAboutReset: () => dispatch(editImagesAboutReset()),
});

const AboutPage = compose(connect(mapStateToProps, mapDispatchToProps))(AboutPageComponent);

export default AboutPage;
