import axios from 'axios';

async function makeRequest(method, url, data) {
  const options = {
    method: method,
    url: url,
    data,
  };

  let res = await axios(options);
  try {
    return res;
  } catch (e) {
    return new Error(e);
  }
}

const RAILS_API_URL = process.env.REACT_APP_RAILS_API_URL;

// ================ Action types ================ //

export const INITIATE_ABOUT_REQUEST = 'app/AboutPage/INITIATE_ABOUT_REQUEST';
export const INITIATE_ABOUT_SUCCESS = 'app/AboutPage/INITIATE_ABOUT_SUCCESS';
export const INITIATE_ABOUT_ERROR = 'app/AboutPage/INITIATE_ABOUT_ERROR';

export const INITIATE_EXPERIENCE_REQUEST = 'app/AboutPage/INITIATE_EXPERIENCE_REQUEST';
export const INITIATE_EXPERIENCE_SUCCESS = 'app/AboutPage/INITIATE_EXPERIENCE_SUCCESS';
export const INITIATE_EXPERIENCE_ERROR = 'app/AboutPage/INITIATE_EXPERIENCE_ERROR';

export const INITIATE_IMAGES_REQUEST = 'app/AboutPage/INITIATE_IMAGES_REQUEST';
export const INITIATE_IMAGES_SUCCESS = 'app/AboutPage/INITIATE_IMAGES_SUCCESS';
export const INITIATE_IMAGES_ERROR = 'app/AboutPage/INITIATE_IMAGES_ERROR';

export const CREATE_ABOUT_SECTION_REQUEST = 'app/AboutPage/CREATE_ABOUT_SECTION_REQUEST';
export const CREATE_ABOUT_SECTION_SUCCESS = 'app/AboutPage/CREATE_ABOUT_SECTION_SUCCESS';
export const CREATE_ABOUT_SECTION_ERROR = 'app/AboutPage/CREATE_ABOUT_SECTION_ERROR';
export const CREATE_ABOUT_SECTION_RESET = 'app/AboutPage/CREATE_ABOUT_SECTION_RESET';

export const CREATE_EXPERIENCE_REQUEST = 'app/AboutPage/CREATE_EXPERIENCE_REQUEST';
export const CREATE_EXPERIENCE_SUCCESS = 'app/AboutPage/CREATE_EXPERIENCE_SUCCESS';
export const CREATE_EXPERIENCE_ERROR = 'app/AboutPage/CREATE_EXPERIENCE_ERROR';
export const CREATE_EXPERIENCE_RESET = 'app/AboutPage/CREATE_EXPERIENCE_RESET';

export const SAVE_IMAGES_REQUEST = 'app/AboutPage/SAVE_IMAGES_REQUEST';
export const SAVE_IMAGES_SUCCESS = 'app/AboutPage/SAVE_IMAGES_SUCCESS';
export const SAVE_IMAGES_ERROR = 'app/AboutPage/SAVE_IMAGES_ERROR';
export const SAVE_IMAGES_RESET = 'app/AboutPage/SAVE_IMAGES_RESET';

export const EDIT_ABOUT_SECTION_REQUEST = 'app/AboutPage/EDIT_ABOUT_SECTION_REQUEST';
export const EDIT_ABOUT_SECTION_SUCCESS = 'app/AboutPage/EDIT_ABOUT_SECTION_SUCCESS';
export const EDIT_ABOUT_SECTION_ERROR = 'app/AboutPage/EDIT_ABOUT_SECTION_ERROR';
export const EDIT_ABOUT_SECTION_RESET = 'app/AboutPage/EDIT_ABOUT_SECTION_RESET';

export const EDIT_EXPERIENCE_REQUEST = 'app/AboutPage/EDIT_EXPERIENCE_REQUEST';
export const EDIT_EXPERIENCE_SUCCESS = 'app/AboutPage/EDIT_EXPERIENCE_SUCCESS';
export const EDIT_EXPERIENCE_ERROR = 'app/AboutPage/EDIT_EXPERIENCE_ERROR';
export const EDIT_EXPERIENCE_RESET = 'app/AboutPage/EDIT_EXPERIENCE_RESET';

export const EDIT_IMAGES_REQUEST = 'app/AboutPage/EDIT_IMAGES_REQUEST';
export const EDIT_IMAGES_SUCCESS = 'app/AboutPage/EDIT_IMAGES_SUCCESS';
export const EDIT_IMAGES_ERROR = 'app/AboutPage/EDIT_IMAGES_ERROR';
export const EDIT_IMAGES_RESET = 'app/AboutPage/EDIT_IMAGES_RESET';

export const DELETE_ABOUT_SECTION_REQUEST = 'app/AboutPage/DELETE_ABOUT_SECTION_REQUEST';
export const DELETE_ABOUT_SECTION_SUCCESS = 'app/AboutPage/DELETE_ABOUT_SECTION_SUCCESS';
export const DELETE_ABOUT_SECTION_ERROR = 'app/AboutPage/DELETE_ABOUT_SECTION_ERROR';
export const DELETE_ABOUT_SECTION_RESET = 'app/AboutPage/DELETE_ABOUT_SECTION_RESET';

export const DELETE_EXPERIENCE_REQUEST = 'app/AboutPage/DELETE_EXPERIENCE_REQUEST';
export const DELETE_EXPERIENCE_SUCCESS = 'app/AboutPage/DELETE_EXPERIENCE_SUCCESS';
export const DELETE_EXPERIENCE_ERROR = 'app/AboutPage/DELETE_EXPERIENCE_ERROR';
export const DELETE_EXPERIENCE_RESET = 'app/AboutPage/DELETE_EXPERIENCE_RESET';

// ================ Reducer ================ //

const initialState = {
  aboutSection: [],
  experience: [],
  isLoading: false,
  isExperienceLoading: false,
  error: null,
  experienceError: null,
  isImagesLoading: false,
  images: [],
  imagesError: null,
  //////////////////////////
  isCreatingAbout: false,
  createAboutSuccess: false,
  createAboutError: null,
  isCreatingExperience: false,
  createExperienceSuccess: false,
  createExperienceError: null,
  saveImagesInProgress: false,
  saveImagesSuccess: false,
  saveImagesError: null,
  ///////////////////////////
  isEditingAbout: false,
  editingAboutSuccess: false,
  editingAboutError: null,
  isEditingExperience: false,
  editingExperienceSuccess: false,
  editingExperienceError: null,
  isEditImagesInProgress: false,
  editImagesSuccess: false,
  editImagesError: null,
  ////////////////////////////
  isDeletingAbout: false,
  deletingAboutSuccess: false,
  deletingAboutError: null,
  isDeletingExperience: false,
  deletingExperienceSuccess: false,
  deletingExperienceError: null,
};

export default function aboutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_ABOUT_REQUEST:
      return { ...state, isLoading: true };
    case INITIATE_ABOUT_SUCCESS:
      return { ...state, isLoading: false, error: null, aboutSection: payload };
    case INITIATE_ABOUT_ERROR:
      return { ...state, isLoading: false, error: payload };

    case INITIATE_EXPERIENCE_REQUEST:
      return { ...state, isExperienceLoading: true };
    case INITIATE_EXPERIENCE_SUCCESS:
      return { ...state, isExperienceLoading: false, experienceError: null, experience: payload };
    case INITIATE_EXPERIENCE_ERROR:
      return { ...state, isExperienceLoading: false, experienceError: payload };

    case INITIATE_IMAGES_REQUEST:
      return { ...state, isImagesLoading: true, imagesError: null, images: [] };
    case INITIATE_IMAGES_SUCCESS:
      return { ...state, isImagesLoading: false, imagesError: null, images: payload };
    case INITIATE_IMAGES_ERROR:
      return { ...state, isImagesLoading: false, imagesError: payload, images: [] };

    /*************************************************************** */

    case CREATE_ABOUT_SECTION_REQUEST:
      return { ...state, isCreatingAbout: true, createAboutSuccess: false, createAboutError: null };
    case CREATE_ABOUT_SECTION_SUCCESS:
      return {
        ...state,
        isCreatingAbout: false,
        createAboutSuccess: true,
        createAboutError: null,
        aboutSection: [...payload],
      };
    case CREATE_ABOUT_SECTION_ERROR:
      return {
        ...state,
        isCreatingAbout: false,
        createAboutSuccess: false,
        createAboutError: payload,
      };
    case CREATE_ABOUT_SECTION_RESET:
      return {
        ...state,
        isCreatingAbout: false,
        createAboutSuccess: false,
        createAboutError: null,
      };

    case CREATE_EXPERIENCE_REQUEST:
      return {
        ...state,
        isCreatingExperience: true,
        createExperienceSuccess: false,
        createExperienceError: null,
      };
    case CREATE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        isCreatingExperience: false,
        createExperienceSuccess: true,
        createExperienceError: null,
        experience: [...payload],
      };
    case CREATE_EXPERIENCE_ERROR:
      return {
        ...state,
        isCreatingExperience: false,
        createExperienceSuccess: false,
        createExperienceError: payload,
      };
    case CREATE_EXPERIENCE_RESET:
      return {
        ...state,
        isCreatingExperience: false,
        createExperienceSuccess: false,
        createExperienceError: null,
      };

    case SAVE_IMAGES_REQUEST:
      return {
        ...state,
        saveImagesInProgress: true,
        saveImagesSuccess: false,
        saveImagesError: null,
      };
    case SAVE_IMAGES_SUCCESS:
      return {
        ...state,
        saveImagesInProgress: false,
        saveImagesSuccess: true,
        saveImagesError: null,
        images: [...payload],
      };
    case SAVE_IMAGES_ERROR:
      return {
        ...state,
        saveImagesInProgress: false,
        saveImagesSuccess: false,
        saveImagesError: payload,
      };
    case SAVE_IMAGES_RESET:
      return {
        ...state,
        saveImagesInProgress: false,
        saveImagesSuccess: false,
        saveImagesError: null,
      };

    /*************************************************************** */

    case EDIT_ABOUT_SECTION_REQUEST:
      return { ...state, isEditingAbout: true, editingAboutSuccess: false };
    case EDIT_ABOUT_SECTION_SUCCESS:
      return {
        ...state,
        isEditingAbout: false,
        editingAboutSuccess: true,
        editingAboutError: null,
      };
    case EDIT_ABOUT_SECTION_ERROR:
      return {
        ...state,
        isEditingAbout: false,
        editingAboutSuccess: false,
        editingAboutError: payload,
      };
    case EDIT_ABOUT_SECTION_RESET:
      return {
        ...state,
        isEditingAbout: false,
        editingAboutSuccess: false,
        editingAboutError: null,
      };

    case EDIT_EXPERIENCE_REQUEST:
      return { ...state, isEditingExperience: true, editingExperienceSuccess: false };
    case EDIT_EXPERIENCE_SUCCESS:
      return {
        ...state,
        isEditingExperience: false,
        editingExperienceSuccess: true,
        editingExperienceError: null,
      };
    case EDIT_EXPERIENCE_ERROR:
      return {
        ...state,
        isEditingExperience: false,
        editingExperienceSuccess: false,
        editingExperienceError: payload,
      };
    case EDIT_EXPERIENCE_RESET:
      return {
        ...state,
        isEditingExperience: false,
        editingExperienceSuccess: false,
        editingExperienceError: null,
      };

    case EDIT_IMAGES_REQUEST:
      return { ...state, isEditImagesInProgress: true, editImagesSuccess: false, editImagesError: null };
    case EDIT_IMAGES_SUCCESS:
      return {
        ...state,
        isEditImagesInProgress: false,
        editImagesSuccess: true,
        editImagesError: null,
        images: [...payload],
      };
    case EDIT_IMAGES_ERROR:
      return {
        ...state,
        isEditImagesInProgress: false,
        editImagesSuccess: false,
        editImagesError: payload,
      };
    case EDIT_IMAGES_RESET:
      return {
        ...state,
        isEditImagesInProgress: false,
        editImagesSuccess: false,
        editImagesError: null,
      };

    /*************************************************************** */

    case DELETE_ABOUT_SECTION_REQUEST:
      return {
        ...state,
        isDeletingAbout: true,
        deletingAboutSuccess: false,
        deletingAboutError: null,
      };
    case DELETE_ABOUT_SECTION_SUCCESS:
      return {
        ...state,
        isDeletingAbout: false,
        deletingAboutSuccess: true,
        deletingAboutError: null,
      };
    case DELETE_ABOUT_SECTION_ERROR:
      return {
        ...state,
        isDeletingAbout: false,
        deletingAboutSuccess: false,
        deletingAboutError: payload,
      };
    case DELETE_ABOUT_SECTION_RESET:
      return {
        ...state,
        isDeletingAbout: false,
        deletingAboutSuccess: false,
        deletingAboutError: null,
      };

    case DELETE_EXPERIENCE_REQUEST:
      return {
        ...state,
        isDeletingExperience: true,
        deletingExperienceSuccess: false,
        deletingExperienceError: null,
      };
    case DELETE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        isDeletingExperience: false,
        deletingExperienceSuccess: true,
        deletingExperienceError: null,
      };
    case DELETE_EXPERIENCE_ERROR:
      return {
        ...state,
        isDeletingExperience: false,
        deletingExperienceSuccess: false,
        deletingExperienceError: payload,
      };
    case DELETE_EXPERIENCE_RESET:
      return {
        ...state,
        isDeletingExperience: false,
        deletingExperienceSuccess: false,
        deletingExperienceError: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const initiateAboutRequest = () => ({ type: INITIATE_ABOUT_REQUEST });
const initiateAboutSuccess = response => ({
  type: INITIATE_ABOUT_SUCCESS,
  payload: response.data,
});
const initiateAboutError = error => ({
  type: INITIATE_ABOUT_ERROR,
  payload: error,
});

const initiateExperienceRequest = () => ({ type: INITIATE_EXPERIENCE_REQUEST });
const initiateExperienceSuccess = response => ({
  type: INITIATE_EXPERIENCE_SUCCESS,
  payload: response.data,
});
const initiateExperienceError = error => ({
  type: INITIATE_EXPERIENCE_ERROR,
  payload: error,
});

const initiateImagesRequest = () => ({ type: INITIATE_IMAGES_REQUEST });
const initiateImagesSuccess = response => ({
  type: INITIATE_IMAGES_SUCCESS,
  payload: response.data,
});
const initiateImagesError = error => ({
  type: INITIATE_IMAGES_ERROR,
  payload: error,
});

///////////////////////////////////////////

const createAboutSectionRequest = () => ({ type: CREATE_ABOUT_SECTION_REQUEST });
const createAboutSectionSuccess = response => ({
  type: CREATE_ABOUT_SECTION_SUCCESS,
  payload: response,
});
const createAboutSectionError = error => ({ type: CREATE_ABOUT_SECTION_ERROR, payload: error });
const createAboutSectionReset = () => ({ type: CREATE_ABOUT_SECTION_RESET });

const createExperienceSectionRequest = () => ({ type: CREATE_EXPERIENCE_REQUEST });
const createExperienceSectionSuccess = response => ({
  type: CREATE_EXPERIENCE_SUCCESS,
  payload: response,
});
const createExperienceSectionError = error => ({ type: CREATE_EXPERIENCE_ERROR, payload: error });
const createExperienceReset = () => ({ type: CREATE_EXPERIENCE_RESET });

const saveImagesRequest = () => ({ type: SAVE_IMAGES_REQUEST });
const saveImagesSuccess = response => ({
  type: SAVE_IMAGES_SUCCESS,
  payload: response,
});
const saveImagesError = error => ({ type: SAVE_IMAGES_ERROR, payload: error });
const saveImagesReset = () => ({ type: SAVE_IMAGES_RESET });

///////////////////////////////////////////

const editAboutSectionRequest = () => ({ type: EDIT_ABOUT_SECTION_REQUEST });
const editAboutSectionSuccess = () => ({ type: EDIT_ABOUT_SECTION_SUCCESS });
const editAboutSectionError = error => ({ type: EDIT_ABOUT_SECTION_ERROR, payload: error });
const editAboutSectionReset = () => ({ type: EDIT_ABOUT_SECTION_RESET });

const editExperienceRequest = () => ({ type: EDIT_EXPERIENCE_REQUEST });
const editExperienceSuccess = () => ({ type: EDIT_EXPERIENCE_SUCCESS });
const editExperienceError = error => ({ type: EDIT_EXPERIENCE_ERROR, payload: error });
const editExperienceReset = () => ({ type: EDIT_EXPERIENCE_RESET });

const editImagesRequest = () => ({ type: EDIT_IMAGES_REQUEST });
const editImagesSuccess = response => ({ type: EDIT_IMAGES_SUCCESS, payload: response });
const editImagesError = error => ({ type: EDIT_IMAGES_ERROR, payload: error });
const editImagesReset = () => ({ type: EDIT_IMAGES_RESET });

///////////////////////////////////////////

const deleteAboutSectionRequest = () => ({ type: DELETE_ABOUT_SECTION_REQUEST });
const deleteAboutSectionSuccess = () => ({ type: DELETE_ABOUT_SECTION_SUCCESS });
const deleteAboutSectionError = error => ({ type: DELETE_ABOUT_SECTION_ERROR, payload: error });
const deleteAboutSectionReset = () => ({ type: DELETE_ABOUT_SECTION_RESET });

const deleteExperienceRequest = () => ({ type: DELETE_EXPERIENCE_REQUEST });
const deleteExperienceSuccess = () => ({ type: DELETE_EXPERIENCE_SUCCESS });
const deleteExperienceError = error => ({ type: DELETE_EXPERIENCE_ERROR, payload: error });
const deleteExperienceReset = () => ({ type: DELETE_EXPERIENCE_RESET });

/* ================ Thunks ================ */

export const getAboutData = () => dispatch => {
  dispatch(initiateAboutRequest());

  makeRequest('get', `${RAILS_API_URL}/api/v1/about/about_sections`)
    .then(response => {
      dispatch(initiateAboutSuccess(response));
    })
    .catch(e => {
      const error = new Error('something went wrong, test error');
      dispatch(initiateAboutError(error));
    });
};

export const getExperienceData = () => dispatch => {
  dispatch(initiateExperienceRequest());

  makeRequest('get', `${RAILS_API_URL}/api/v1/about/experience_sections`)
    .then(response => {
      dispatch(initiateExperienceSuccess(response));
    })
    .catch(e => {
      const error = new Error('something went wrong, test error');
      dispatch(initiateExperienceError(error));
    });
};

export const getImagesData = () => dispatch => {
  dispatch(initiateImagesRequest());

  makeRequest('get', `${RAILS_API_URL}/api/v1/images/about`)
    .then(response => {
      dispatch(initiateImagesSuccess(response));
    })
    .catch(e => {
      const error = new Error('something went wrong, test error');
      dispatch(initiateImagesError(error));
    });
};

export const createAboutSection = data => dispatch => {
  dispatch(createAboutSectionRequest());

  makeRequest('post', `${RAILS_API_URL}/api/v1/about/about_sections`, data)
    .then(response => {
      dispatch(createAboutSectionSuccess(response.data));
    })
    .catch(e => {
      dispatch(createAboutSectionError(e));
    });
};

export const createExperience = data => dispatch => {
  dispatch(createExperienceSectionRequest());

  makeRequest('post', `${RAILS_API_URL}/api/v1/about/experience_sections`, data)
    .then(response => {
      dispatch(createExperienceSectionSuccess(response.data));
    })
    .catch(e => {
      dispatch(createExperienceSectionError(e));
    });
};

export const editAboutSection = (id, data) => dispatch => {
  dispatch(editAboutSectionRequest());

  makeRequest('put', `${RAILS_API_URL}/api/v1/about/about_sections/${id}`, data)
    .then(response => {
      dispatch(editAboutSectionSuccess());
      dispatch(getAboutData());
    })
    .catch(e => {
      dispatch(editAboutSectionError(e));
    });
};

export const editExperience = (id, data) => dispatch => {
  dispatch(editExperienceRequest());

  makeRequest('put', `${RAILS_API_URL}/api/v1/about/experience_sections/${id}`, data)
    .then(response => {
      dispatch(editExperienceSuccess());
      dispatch(getExperienceData());
    })
    .catch(e => {
      dispatch(editExperienceError(e));
    });
};

export const deleteAboutSection = data => dispatch => {
  dispatch(deleteAboutSectionRequest());

  makeRequest('delete', `${RAILS_API_URL}/api/v1/about/about_sections/${data}`)
    .then(response => {
      dispatch(deleteAboutSectionSuccess());
      dispatch(getAboutData());
    })
    .catch(e => {
      dispatch(deleteAboutSectionError(e));
    });
};

export const deleteExperience = data => dispatch => {
  dispatch(deleteExperienceRequest());

  makeRequest('delete', `${RAILS_API_URL}/api/v1/about/experience_sections/${data}`)
    .then(response => {
      dispatch(deleteExperienceSuccess());
      dispatch(getExperienceData());
    })
    .catch(e => {
      dispatch(deleteExperienceError(e));
    });
};

export const createAboutReset = () => dispatch => {
  dispatch(createAboutSectionReset());
};
export const createExperienceSectionReset = () => dispatch => {
  dispatch(createExperienceReset());
};
export const saveImagesAboutReset = () => dispatch => {
  dispatch(saveImagesReset());
}

export const editAboutReset = () => dispatch => {
  dispatch(editAboutSectionReset());
};
export const editExperienceSectionReset = () => dispatch => {
  dispatch(editExperienceReset());
};
export const editImagesAboutReset = () => dispatch => {
  dispatch(editImagesReset());
};

export const deleteAboutReset = () => dispatch => {
  dispatch(deleteAboutSectionReset());
};
export const deleteExperienceSectionReset = () => dispatch => {
  dispatch(deleteExperienceReset());
};

export const saveImages = data => dispatch => {
  dispatch(saveImagesRequest());

  makeRequest('post', `${RAILS_API_URL}/api/v1/images`, data)
    .then(response => {
      dispatch(saveImagesSuccess(response.data));
    })
    .catch(e => {
      dispatch(saveImagesError(e));
    });
};

export const editImages = (id, data) => dispatch => {
  dispatch(editImagesRequest());

  makeRequest('put', `${RAILS_API_URL}/api/v1/images/${id}`, data)
    .then(response => {
      dispatch(editImagesSuccess(response.data));
    })
    .catch(e => {
      dispatch(editImagesError(e));
    });
};
