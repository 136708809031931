import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NamedLink } from '../../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getCancellationTransactions } from '../../../containers/AdminPanelPage/AdminPanelPage.duck';
import { createSlug } from '../../../util/urlHelpers';
import css from './AdminCancellationComponent.css';

const createListingLink = (listingId, label) => {
  const params = { id: listingId, slug: createSlug(label) };
  return (
    <NamedLink className="" name="ListingPage" params={params}>
      {label}
    </NamedLink>
  );
};

export class AdminCancellationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getCancellationTransactions();
  }

  render() {
    const cancellations = <FormattedMessage id={'TransactionPanel.cancellations'} />;
    const email = <FormattedMessage id={'TransactionPanel.email'} />;
    const displayName = <FormattedMessage id={'TransactionPanel.dispayName'} />;
    const role = <FormattedMessage id={'TransactionPanel.role'} />;
    const listingTitle = <FormattedMessage id={'TransactionPanel.listingTitle'} />;
    const reason = <FormattedMessage id={'TransactionPanel.reason'} />;
    const createdAt = <FormattedMessage id={'TransactionPanel.createdAt'} />;

    const transactions = this.props.transactions;
    return (
      <div>
        <h3 className={css.transactionTableTitle}>{cancellations}</h3>
        <table className={css.transactionTable}>
          <thead>
            <tr>
              <th>{email}</th>
              <th>{displayName}</th>
              <th>{role}</th>
              <th>{listingTitle}</th>
              <th>{reason}</th>
              <th>{createdAt}</th>
            </tr>
          </thead>

          <tbody>
            {transactions
              .sort(
                (t1, t2) => new Date(t2.created_at).getTime() - new Date(t1.created_at).getTime()
              )
              .map(tx => (
                <tr className={css.tdMargin} key={tx.id}>
                  <td>
                    <Link to={`u/${tx.user_uuid}`}>{tx.email}</Link>
                  </td>
                  <td> {tx.display_name}</td>
                  <td> {tx.role}</td>
                  <td>{createListingLink(tx.listing_id, tx.listing_title)}</td>
                  <td> {tx.reason}</td>
                  <td> {tx.created_at}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {transactions.map(tx => (
          <p>{tx.destination}</p>
        ))}
      </div>
    );
  }
}
AdminCancellationComponent.defaultProps = {};

AdminCancellationComponent.propTypes = {};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const transactions = state.AdminPanelPage.getTransactions;
  return {
    currentUser,
    transactions,
  };
};

const mapDispatchToProps = dispatch => ({
  getCancellationTransactions: () => dispatch(getCancellationTransactions()),
});

const AdminCancellation = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AdminCancellationComponent);

export default AdminCancellation;
