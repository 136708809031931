import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { TutorsAppForm } from '../../forms';

import css from './TutorsAppPage.css';

export default class FormSection extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       initialValues: {}
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    const { onGetTutorsApp, edit, currentUser } = this.props;

    if (edit) {
      onGetTutorsApp(currentUser.id.uuid);
    }

    this.setInitialValues();
  }

  componentDidUpdate(prevProps){
    const { initialValues, edit } = this.props;

    if (edit && !!initialValues && initialValues !== prevProps.initialValues){
      this.setInitialValues();
    }
  }

  setInitialValues(){
    const { initialValues, currentUser } = this.props;

    this.setState({
      initialValues: {
        ...initialValues,
        today_date: new Date().toISOString(),
        flex_id: currentUser.id.uuid
      }
    });
  }

  handleUpload(files){
    const { onAddAttachment, currentUser } = this.props;
    const flex_id = currentUser.id.uuid;

    const formData = new FormData();

    [].slice.call(files).map((file, i) => formData.append(`files[${i}]`, file));
    formData.append('flex_id', flex_id);

    onAddAttachment(formData);
  }
  
  handleSubmit(values){
    const {edit, onComplete} = this.props;

    const {flex_id, id, user_id, ...data} = values;
    if (edit) {
      onComplete({form: data, id: flex_id});
      return;
    } 
    
    onComplete({form: {...data, id: flex_id}});
  }

  render() {
    const { initialValues } = this.state;
    const {
      edit,
      initialAttachments,
      attachments,
      attachmentsProgress,
      attachmentsError,
      onDeleteAttachment
    } = this.props;
    return (
      <div>
        <h2>
          <FormattedMessage id="TutorsAppPage.formTitle"/>
        </h2>

        <TutorsAppForm
          formId="tutorsAppForm"
          inProgress={false}
          initialValues={initialValues}
          isPreview={false}
          onSubmit={this.handleSubmit}
          edit={edit}
          initialAttachments={initialAttachments}
          attachments={attachments}
          attachmentsProgress={attachmentsProgress}
          attachmentsError={attachmentsError}
          onAddAttachment={files => this.handleUpload(files)}
          onDeleteAttachment={onDeleteAttachment}
        />
      </div>
    )
  }
}
