import React, { Component } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { compose } from 'redux';
import { connect } from 'react-redux';
import css from './FaqEditor.css';
import { FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';

class FaqEditorComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      count: 0,
    };
  }

  handleSavePost = () => {
    const { editorState } = this.state;
    const { makeRequestFunction } = this.props;

    const postData = {
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };
    makeRequestFunction(postData);
  };

  onEditorStateChange = content => {
    this.setState(() => {
      return { editorState: content };
    });
  };

  render() {
    if (typeof window === 'undefined' && typeof document === 'undefined') {
      return null;
    }

    const { editorState, count } = this.state;
    const { createError, editingError, faqList, userType } = this.props;

    const { Editor } = require('react-draft-wysiwyg'); //eslint-disable-line global-require
    const htmlToDraft = require('html-to-draftjs').default;

    if (faqList && count === 0) {
      const contentDescription = htmlToDraft(faqList);

      if (contentDescription) {
        const contentStateDescription = ContentState.createFromBlockArray(
          contentDescription.contentBlocks
        );
        const editorStateDescription = EditorState.createWithContent(contentStateDescription);

        this.setState({
          editorState: editorStateDescription,
          count: 1,
        });
      }
    }

    const editorStyle = {
      border: '1px solid black',
      padding: '5px',
      borderRadius: '2px',
      height: '200px',
      width: '100%',
    };

    return (
      <div className={css.modalPayoutDetailsWrapper}>
        <h1 className={css.modalTitle}>
          <FormattedMessage id={this.props.editorTitle} />
        </h1>
        <label className={css.label} htmlFor="description">
          Faq content *
        </label>
        <Editor
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          wrapperStyle={{}}
          editorStyle={editorStyle}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'image',
              'remove',
              'history',
            ],
            fontFamily: {
              options: [
                `sofiapro, Helvetica, Arial, sans-serif`,
                'Arial',
                'Georgia',
                'Impact',
                'Tahoma',
                'Times New Roman',
                'Verdana',
              ],
            },
          }}
        />
        <button onClick={this.handleSavePost} className={css.savePostBtn}>
          Save
        </button>
        {createError ? (
          <p className={css.editError}>
            <FormattedMessage id="FaqEditor.errorCreate" />
          </p>
        ) : null}
        {editingError ? (
          <p className={css.editError}>
            <FormattedMessage id="FaqEditor.errorEdite" />
          </p>
        ) : null}
      </div>
    );
  }
}


const FaqEditor = compose()(FaqEditorComponent);

export default FaqEditor;
