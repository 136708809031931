import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../../util/reactIntl';

import {
  getAllSubjects,
  saveSubject,
  saveSubjectReset,
  deleteSubject,
  deleteSubjectReset,
  editSubject,
  editSubjectReset,
} from '../../../containers/AdminPanelPage/AdminPanelPage.duck';

import { Modal } from '../../../components';
import AdminProprofsForm from './AdminProprofsForm';
import { manageDisableScrolling } from '../../../ducks/UI.duck';
import SubjectItem from './SubjectItem';

import css from './AdminProprofsComponent.css';

const formatLabel = subject => {
  return subject
    .toLowerCase()
    .match(/[A-Z0-9]+/gi)
    .map((word, i) => {
      if (!i) return word;
      return word[0].toUpperCase() + word.slice(1);
    })
    .join('');
};

const AdminProprofsComponent = props => {
  const {
    onManageDisableScrolling,
    getSubjectProgress,
    getSubjectError,
    getSubjectSuccess,
    allSubjects,
    onGetAllSubjects,
    onSaveSubject,
    saveSubjectProgress,
    saveSubjectError,
    saveSubjectSuccess,
    onSaveReset,
    ...rest
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    onGetAllSubjects();
  }, []);

  useEffect(() => {
    if (saveSubjectSuccess) {
      setModalOpen(false);
    }
  }, [saveSubjectSuccess]);

  const hasInitialQuiz = allSubjects && allSubjects.length > 0 ? true : false;

  const handleSubmit = async value => {
    const initialQuizLabel = 'Initial Quiz';

    const submitValue = {
      key: hasInitialQuiz ? formatLabel(value.subject) : formatLabel(initialQuizLabel),
      label: hasInitialQuiz ? value.subject : initialQuizLabel,
      url: value.link,
    };

    await onSaveSubject(submitValue);
  };

  const isSaveLinkError = saveSubjectError ? (
    <p className={css.errorMessage}>Something went wrong. Try again later</p>
  ) : null;
  const isGetLinkError = getSubjectError ? (
    <p className={css.errorMessage}>
      Failed to get the list of links from the server. Try to reload the page
    </p>
  ) : null;

  const handleClose = () => {
    setModalOpen(false);
    onSaveReset();
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const addNewBtn = (
    <button className={css.addNewBtn} onClick={handleOpen}>
      {hasInitialQuiz ? (
        <FormattedMessage id="AdminPanelPage.addBtnText" />
      ) : (
        <FormattedMessage id="AdminPanelPage.addInitialBtnText" />
      )}
    </button>
  );

  return (
    <div className={css.container}>
      <div className={css.conponentTitle}>Configuring the ProProfs service</div>

      {hasInitialQuiz ? (
        <div className={css.subjectsContainer}>
          {allSubjects && allSubjects.map(subject => {
            return (
              <SubjectItem
                key={subject.id}
                subjectId={subject.id}
                subjectName={subject.label}
                subjectLink={subject.url}
                onManageDisableScrolling={onManageDisableScrolling}
                {...rest}
              />
            );
          })}
        </div>
      ) : (
        <div className={css.noSubjectsContainer}>
          <h3 className={css.noSubjectsTitle}>
            You don't have an subject yet. First of all, add an initial quiz
          </h3>
        </div>
      )}
      <Modal
        id="AdminPanel.addSubject"
        isOpen={isModalOpen}
        onClose={handleClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <AdminProprofsForm
          onSubmit={handleSubmit}
          className={css.formContainer}
          inProgress={saveSubjectProgress}
          hasInitialQuiz={hasInitialQuiz}
        />
      </Modal>
      <div className={css.buttonContainer}>{addNewBtn}</div>
      {isSaveLinkError}
      {isGetLinkError}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    getSubjectProgress,
    getSubjectError,
    getSubjectSuccess,
    allSubjects,
    saveSubjectProgress,
    saveSubjectError,
    saveSubjectSuccess,
    deleteSubjectProgress,
    deleteSubjectError,
    deleteSubjectSuccess,
    editSubjectProgress,
    editSubjectError,
    editSubjectSuccess,
  } = state.AdminPanelPage;
  return {
    getSubjectProgress,
    getSubjectError,
    getSubjectSuccess,
    allSubjects,
    saveSubjectProgress,
    saveSubjectError,
    saveSubjectSuccess,
    deleteSubjectProgress,
    deleteSubjectError,
    deleteSubjectSuccess,
    editSubjectProgress,
    editSubjectError,
    editSubjectSuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onGetAllSubjects: () => {
    dispatch(getAllSubjects());
  },
  onSaveSubject: data => {
    dispatch(saveSubject(data));
  },
  onSaveReset: () => {
    dispatch(saveSubjectReset());
  },
  onDeleteSubject: id => {
    dispatch(deleteSubject(id));
  },
  onDeleteSubjectReset: () => {
    dispatch(deleteSubjectReset());
  },
  onEditSubject: (data, id) => {
    dispatch(editSubject(data, id));
  },
  onEditSubjectReset: () => {
    dispatch(editSubjectReset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminProprofsComponent);
