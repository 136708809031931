import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { updateListing } from '../TransactionPage/TransactionPage.duck';
import config from '../../config';
import axios from 'axios';
import { denormalisedResponseEntities } from '../../util/data';

const API_URL = process.env.REACT_APP_API_URL;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const SAVE_FAVOURITES_REQUEST = 'app/SearchPage/SAVE_FAVOURITES_REQUEST';
export const SAVE_FAVOURITES_SUCCESS = 'app/SearchPage/SAVE_FAVOURITES_SUCCESS';
export const SAVE_FAVOURITES_ERROR = 'app/SearchPage/SAVE_FAVOURITES_ERROR';

export const FETCH_FAVORITE_LISTINGS_REQUEST =
  'app/ManageListingsPage/FETCH_FAVORITE_LISTINGS_REQUEST';
export const FETCH_FAVORITE_LISTINGS_SUCCESS =
  'app/ManageListingsPage/FETCH_FAVORITE_LISTINGS_SUCCESS';
export const FETCH_FAVORITE_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_FAVORITE_LISTINGS_ERROR';

export const RESET_FAVORITE_LISTINGS = 'app/ManageListingsPage/RESET_FAVORITE_LISTINGS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  saveFavouriteRequest: false,
  saveFavouriteSuccess: false,
  saveFavouriteError: null,
  favouriteListings: null,
  favoriteListingsRequest: false,
  favoriteListingsSuccess: false,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case SAVE_FAVOURITES_REQUEST:
      return {
        ...state,
        saveFavouriteRequest: true,
        saveFavouriteSuccess: false,
        saveFavouriteError: null,
      };
    case SAVE_FAVOURITES_SUCCESS:
      return {
        ...state,
        saveFavouriteRequest: false,
        saveFavouriteSuccess: true,
        saveFavouriteError: null,
      };
    case SAVE_FAVOURITES_ERROR:
      return {
        ...state,
        saveFavouriteRequest: false,
        saveFavouriteSuccess: false,
        saveFavouriteError: payload,
      };

    case FETCH_FAVORITE_LISTINGS_REQUEST:
      return {
        ...state,
        favouriteListings: null,
        favoriteListingsRequest: true,
        favoriteListingsSuccess: false,
      };
    case FETCH_FAVORITE_LISTINGS_SUCCESS:
      return {
        ...state,
        favouriteListings: payload.data,
        favoriteListingsRequest: false,
        favoriteListingsSuccess: true,
      };
    case FETCH_FAVORITE_LISTINGS_ERROR:
      return {
        ...state,
        favouriteListings: null,
        favoriteListingsRequest: false,
        favoriteListingsSuccess: false,
      };

    case RESET_FAVORITE_LISTINGS:
      return { ...state, favouriteListings: null };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const saveFavouritesRequest = () => ({
  type: SAVE_FAVOURITES_REQUEST,
});

export const saveFavouritesSuccess = () => ({
  type: SAVE_FAVOURITES_SUCCESS,
});

export const saveFavouritesError = e => ({
  type: SAVE_FAVOURITES_ERROR,
  error: true,
  payload: e,
});

export const queryFavoriteListingsRequest = queryParams => ({
  type: FETCH_FAVORITE_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryFavoriteListingsSuccess = response => ({
  type: FETCH_FAVORITE_LISTINGS_SUCCESS,
  payload: { data: response },
});

export const queryFavoriteListingsError = e => ({
  type: FETCH_FAVORITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const resetFavouriteListings = () => ({
  type: RESET_FAVORITE_LISTINGS,
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const { perPage, price, dates, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const isKeywords = !!searchParams.keywords;

  const params = isKeywords
    ? {
        ...rest,
        ...priceMaybe,
        per_page: perPage,
      }
    : {
        ...rest,
        ...priceMaybe,
        per_page: perPage,
        sort: 'pub_rating, createdAt',
      };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const saveFavourites = (listingId, userId, userFavourite, listingFavourite) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(saveFavouritesRequest());

  return await dispatch(
    updateListing(listingId, { publicData: { listingFavourite: listingFavourite } })
  )
    .then(dispatch(updateProfile({ publicData: { userFavourite: userFavourite } })))
    .then(res => {
      dispatch(saveFavouritesSuccess());
    })
    .catch(e => {
      dispatch(saveFavouritesError(storableError(e)));
      throw e;
    });
};

export const queryFavoriteListings = (queryParams, delay) => (dispatch, getState, sdk) => {
  dispatch(queryFavoriteListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, per_page: perPage };

  const url = `${API_URL}/api/favorite-listings`;

  setTimeout(async () => {
    return await axios
      .post(url, params)
      .then(async response => {
        const data = await denormalisedResponseEntities(response);
        dispatch(queryFavoriteListingsSuccess(data));
        return data;
      })
      .catch(e => {
        dispatch(queryFavoriteListingsError(storableError(e)));
        throw e;
      });
  }, delay ? delay : 1500);
};
