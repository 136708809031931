import React, { useState } from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  txIsCanceled,
  txIsDelivered,
  txIsDeclined,
  TRANSITION_CANCEL_CUSTOMER,
  isAvailableToCancel
} from '../../util/transaction';
import { propTypes, LINE_ITEM_CUSTOMER_COMMISSION } from '../../util/types';

import { compose } from 'redux';
import { cancellationTransaction, cancellationTransactionProvider } from '../../containers/TransactionPage/TransactionPage.duck';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, IconSpinner } from '../../components';
import { CancellationForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { prepareText } from '../../util/data';
import moment from 'moment';

import css from './BookingBreakdown.css';
const { Money } = sdkTypes;

const LineItemUnitPriceComponent = props => {
  const { transaction, isProvider, intl, currentUser, sendTransactionInProgress, awaitToCancel } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  let startDate = null
  if(transaction && transaction.booking && transaction.booking.attributes && transaction.booking.attributes.displayStart){
    startDate = new Date(Math.max(transaction.booking.attributes.start.getTime(), transaction.booking.attributes.displayStart.getTime()))
  }
  const _startDate = moment(startDate).clone();
  const showCancellationButton = !awaitToCancel && new Date() < moment(_startDate).add(10, 'minutes').toDate();
  const reasons = [intl.formatMessage({id: 'BookingBreakdown.reasonsOverall'})]

  if (_startDate.toDate() < new Date()) reasons.push(intl.formatMessage({id: 'BookingBreakdown.reasonsNotShowUp'}, {role: isProvider ? 'Student' : 'Tutor'}))

  const listing = transaction.listing
  const styleCancellationButton = sendTransactionInProgress ? css.cancellationButtonRequest : css.cancellationButton
  const cancellationButton = showCancellationButton && isAvailableToCancel(transaction.attributes.lastTransition) ? (
    <button disabled={sendTransactionInProgress} className={styleCancellationButton} onClick={() => setModalOpen(true) }>
      <FormattedMessage id="BookingBreakdown.cancelButton" />
    </button>
  )
  : '';
  const showSpiner = sendTransactionInProgress ? (
    <IconSpinner rootClassName={css.spinner} />
  ) : null;

  const handleSubmit = value => {
    const reason = value && value.cancellationReason !== undefined ? value.cancellationReason : intl.formatMessage({id: 'BookingBreakdown.reasonsOverall'})
    if (isProvider) {
      props.onCancellationTransactionProvider(transaction, customerCommissionLineItem, currentUser, listing, reason)
    } else {
      props.onCancellationTransaction(transaction, currentUser, listing, reason)
    }
    setModalOpen(false);
  }

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction) || txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );
  let totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  const customRefund = transaction && transaction.attributes.protectedData && transaction.attributes.protectedData.refound ? transaction.attributes.protectedData.refound.refound : null;
  const customerCommissionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal
  );

  if(customRefund && customRefund !== 0 && transaction.attributes.lastTransition === TRANSITION_CANCEL_CUSTOMER){
    if(totalPrice.amount - customRefund >= 0){
      totalPrice = totalPrice.amount - customRefund
      if(isProvider){
        const refound = transaction.attributes.protectedData.refound.refound;
        const stripe_fee = transaction.attributes.protectedData.refound.stripe_fee;
        totalPrice = transaction.attributes.payinTotal.amount - refound - stripe_fee;
      }
    }else{
      totalPrice = 0
    }
    totalPrice = new Money(totalPrice, config.currency);
  }
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
        {showSpiner}
        {cancellationButton}
        <Modal
          id="EditAvailabilityPlan"
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onManageDisableScrolling={() => null}
          containerClassName={css.modalContainer}
        >
          <div className={css.cancellationModal}>
            <p>{prepareText(startDate, isProvider, listing)}</p>
            <CancellationForm
              onSubmit={handleSubmit}
              selectValues={reasons}
              setModalOpen={setModalOpen}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

LineItemUnitPriceComponent.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const sendTransactionInProgress = state.TransactionPage.sendTransactionInProgress;
  return {
    currentUser,
    sendTransactionInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onCancellationTransaction: (transaction, currentUser, listing, selectValue) => dispatch(cancellationTransaction(transaction, currentUser, listing, selectValue)),
  onCancellationTransactionProvider: (transaction, customerCommissionLineItem, currentUser, listing, selectValue) => dispatch(cancellationTransactionProvider(transaction, customerCommissionLineItem, currentUser, listing, selectValue)),

});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LineItemUnitPrice = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LineItemUnitPriceComponent);

export default LineItemUnitPrice;
