import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Modal } from '../../index';
import AdminProprofsForm from './AdminProprofsForm';
import css from './AdminProprofsComponent.css';
import { FormattedMessage } from '../../../util/reactIntl';

const formatLabel = subject => {
  return subject
    .toLowerCase()
    .match(/[A-Z0-9]+/gi)
    .map((word, i) => {
      if (!i) return word;
      return word[0].toUpperCase() + word.slice(1);
    })
    .join('');
};

const SubjectItemComponent = props => {
  const {
    onManageDisableScrolling,
    deleteSubjectError,
    subjectName,
    subjectLink,
    onDeleteSubject,
    subjectId,
    onDeleteSubjectReset,
    deleteSubjectSuccess,
    onEditSubject,
    editSubjectProgress,
    editSubjectSuccess,
    onEditSubjectReset,
  } = props;

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [initValues, setInitValues] = useState(null);

  useEffect(() => {
    if (deleteSubjectSuccess) {
      setConfirmModalOpen(false);
    }
  }, [deleteSubjectSuccess]);

  useEffect(() => {
    if (editSubjectSuccess) {
      setEditModalOpen(false);
    }
  }, [editSubjectSuccess]);

  const handleOpen = () => {
    setInitValues({
      subject: subjectName,
      link: subjectLink,
      id: subjectId,
    });
    setEditModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    onDeleteSubjectReset();
    setConfirmModalOpen(false);
  };

  const handleEditModalClose = () => {
    onEditSubjectReset();
    setEditModalOpen(false);
  };

  const handleDeleteBtn = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmButton = e => {
    e.persist();
    onDeleteSubjectReset();
    const confirm = e.currentTarget.value;

    if (confirm === 'true') {
      onDeleteSubject(subjectId);
    } else {
      setConfirmModalOpen(false);
    }
  };

  const handleEditSubmit = data => {
    const submitValue = {
      key: formatLabel(data.subject),
      label: data.subject,
      url: data.link,
    };

    onEditSubject(submitValue, subjectId);
  };

  const isInitialQuiz = subjectName === 'Initial Quiz';

  const editBtn = (
    <button onClick={handleOpen} className={css.editButton}>
      <FormattedMessage id="AdminPanelPage.editBtnText" />
    </button>
  );

  const deleteBtn = !isInitialQuiz ? (
    <button onClick={handleDeleteBtn} className={css.deleteButton}>
      <FormattedMessage id="AdminPanelPage.deleteBtnText" />
    </button>
  ) : null;

  return (
    <div className={css.subjectWrap}>
      <div className={css.subjectInfoContainer}>
        <div className={css.topRow}>
          <span className={css.title}>{subjectName}</span>
        </div>
        <a href={subjectLink} className={css.bottomRow} target={'blank'}>
          {subjectLink}
        </a>
      </div>
      <div className={css.editBtnsWrap}>
        {editBtn}
        {deleteBtn}
      </div>
      <Modal
        id="AdminPanel.confirmDelete"
        isOpen={isConfirmModalOpen}
        onClose={handleConfirmModalClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.confirmDeleteWrap}>
          <span className={css.confirmTitle}>
            <FormattedMessage id="AdminPanelPage.confirmDeleteSubject" />
          </span>
          <div className={css.confirmDeleteBtnsWrap}>
            <button value={true} className={css.deleteButton} onClick={handleConfirmButton}>
              <FormattedMessage id="ConfirmModal.yesBtn" />
            </button>

            <button value={false} className={css.editButton} onClick={handleConfirmButton}>
              <FormattedMessage id="ConfirmModal.noBtn" />
            </button>
          </div>
          {deleteSubjectError ? (
            <p className={css.deleteError}>
              <FormattedMessage id="ConfirmModal.errorDeleting" />
            </p>
          ) : null}
        </div>
      </Modal>
      <Modal
        id="AdminPane.editSubject"
        isOpen={isEditModalOpen}
        onClose={handleEditModalClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <AdminProprofsForm
          onSubmit={handleEditSubmit}
          className={css.formContainer}
          initialValues={initValues}
          inProgress={editSubjectProgress}
          isInitialQuiz={isInitialQuiz}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {};
const mapDispatchToProps = dispatch => ({});

const SubjectItem = compose(connect(mapStateToProps, mapDispatchToProps))(SubjectItemComponent);

export default SubjectItem;
