import React, { useState, useEffect } from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getUsersReviews } from '../../ducks/Reviews.duck';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionSearchSubject,
  SectionReviews,
  SectionOnlineTeaching,
  SectionBecomingTutor,
  SectionSubscribe,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';

import facebookImage from '../../assets/wisepalFacebook-1200x630.jpg';
import twitterImage from '../../assets/wisepalTwitter-600x314.jpg';
import css from './CTutoringPage.css';


import college from '../CTutoringPage/images/college-tutoring.jpg';
import tutoring from '../CTutoringPage/images/college-tutoring-online.jpg';
import online from '../CTutoringPage/images/online-tutoring.jpg';
import collegeStudent from '../../assets/seoteric-pages/collegepages/college-tutoring.jpg';
import student from '../../assets/seoteric-pages/collegepages/college-students-03.jpg';
import subjects from '../../assets/seoteric-pages/collegepages/subjects.jpg';
import teachingSubjects from '../../assets/seoteric-pages/collegepages/teaching-subjects.jpg';

export const CTutoringPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    reviews,
    currentUserListingFetched,
    onGetUsersReviews,
    currentUser,
    isAuthenticated,
  } = props;

  const [recievedReviews, setRecieveReviews] = useState(reviews);

  useEffect(() => {
    onGetUsersReviews()
  }, []);

  useEffect(() => {
    setRecieveReviews(reviews);
  }, [reviews]);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'CTutoringPage.pageTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'CTutoringPage.pageTitle' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
        <div id="CollegeTutoring" class="container">
        <div class="row my-5">
            <div class="col-md-12 text-center">
                <h1>The Best in College Tutoring</h1>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-md-12 justify-content-center text-center">
                <p class="intro-content">Get the best college tutoring services without breaking the bank.</p>
                <br />
                <p class="intro-content">Wisepal connects you with qualified college tutors that can help you elevate
                    your studies, improve your understanding of specific topics, and score higher on exams.</p><br />
                <a href="https://wisepal.co/signup">
                    <button type="button" class="btn btn-prpl btn-lg rounded-pill my-3 hover-orng">Sign Up
                        Today!</button>
                </a>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-md-6 justify-content-center text-center">
                <p>Our flexible online and in-person tutoring for college-level subjects enables you to schedule your
                    college tutoring sessions at the most convenient times. College life is an exciting time for growth.
                    You meet friends, attend classes, and maybe even get
                    your first paying job. At Wisepal, we know that your study time deserves to be just as productive
                    and exciting as the other
                    areas in your life.</p>
            </div>
            <div class="col-md-6 d-flex justify-content-center"><img class="my-3"
                    src={collegeStudent} alt="college tutoring " /></div>
        </div>

        <div class="row d-flex my-5">
            <div class="col-md-12 justify-content-center text-center">
                <h2 class="my-2 text-center">What We Offer</h2>
                <p class="my-3">Wisepal offers top-notch tutorial services with the most experienced tutors.</p>
            </div>
            <div class="col-md-6 my-5 justify-content-center text-center">
                <img class="my-3" src={subjects} alt="Multiple Subjects" />
                <h3>Tutors With Demonstrated Expertise on a Range of College Subjects</h3>
                <p>We make sure that our tutors represent our company's commitment to quality college tutoring by making
                    sure that they are knowledgeable, bright, and nurturing to clients, with a passion to help you
                    elevate your college grades.</p>
                <p>With this, we make sure that continued sessions with our tutors will help you increase your test
                    scores for MCAT, LSAT, GRE, GMAT, DAT, PCAT, and OAT.</p>
            </div>
            <div class="col-md-6 my-5 justify-content-center text-center">
                <img class="my-3" src={student} alt="Student Technology" />
                <h3>State-Of-The-Art Tutoring Technology</h3>
                <p>As a response to the rising trend of online classes and an acknowledgment that technology is a vital
                    component for your growth, our platform utilizes tutoring technology like screen sharing, multi-user
                    whiteboards, and other collaborative tools that foster a more intuitive learning environment,
                    helping you achieve your academic goals by allowing you to personalize your learning process.</p>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-md-12 justify-content-center text-center">
                <h2 class="my-2 text-center">College Subjects We Tutor</h2>
                <p>Wisepal's tutors have obtained higher academic degrees from universities all around the world,
                    allowing for nearly limitless options for you to choose your tutors in the tri-state area.</p>
                <p>Our college tutoring services encompass numerous college courses which include Calculus, Chemistry,
                    Biology, and Economics, among others. Feel free to contact the Wisepal team to learn more about the
                    different subjects we offer for college tutoring.</p>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-md-6 d-flex justify-content-center"><img class="my-3"
                    src={college} alt="Standardized Tests Tutoring" /></div>
            <div class="col-md-6">
                <h2 class="my-2 text-center">Standardized Tests</h2>
                <p>Wisepal tutors assure the quality of their college tutoring services and how they may conduct topical
                    quizzes that mirror standardized tests to assess your current ability level. Our college tutoring platform allows you to:</p>
                <ol>
                    <li>Create a personalized strategy and roadmap with your tutor to help you improve your test scores.
                    </li>
                    <li>Peek through an insightful overview of the exam and prepare yourself in their most crucial
                        sections.</li>
                    <li>Gain mastery of the cornerstone concepts of subjects that you are tutoring for.</li>
                </ol>
                <p>Our college tutoring platform allows you to:</p>
                <p>With Wisepal tutors, you are assured to achieve better performance on your college exams.</p>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-md-6">
                <h2 class="my-2 text-center">Wisepal Features & Benefits for College Students</h2>
                <p>Wisepal offers the most robust college tutoring solution in New York City. Its features include:</p>
                <ol>
                    <li>Attend your college tutoring sessions based on your own schedule. Our tutors will meet you
                        anytime that is convenient for you.</li>
                    <li>Provide the option for online and in-person college tutoring.</li>
                    <li>Dedicated and approachable tutors will help you grasp subject concepts that you are having
                        difficulty with.</li>
                    <li>Our tutors use a platform feature called polling to assess the learning mileage of students.
                        This is crucial in your preparation for important exams.</li>
                    <li>Wisepal offers one of the most affordable college tutoring rates in NYC.</li>
                    <li>Every tutorial session is recorded and immediately sent to you right after they are finished.
                        Review them at your own convenience.</li>
                    <li>Attend a group session or schedule one-on-one college tutoring with your tutors.</li>
                    <li>Collaborate with your peers with our platform's breakout rooms in group sessions.</li>
                    <li>Wisepal tutors will help you prepare for your most important college exams such as midterms,
                        finals, and even other ongoing subjects that you need help with.</li>
                </ol>
            </div>
            <div class="col-md-6 d-flex justify-content-center"><img class="my-3"
                    src={tutoring} alt="Wisepal Features" /></div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row py-5 btn-prpl">
            <div class="col-md-12 my-5 text-center">
                <h2>Book a Lesson with an Expert Tutor!</h2>
                <a href="https://wisepal.co/signup">
                    <button type="button" class="btn btn-orng btn-lg rounded-pill my-3 hover-orng">Sign Up
                        Today!</button>
                </a>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row my-5">
            <div class="col-md-6 d-flex justify-content-center"><img class="my-3"
                    src={online} alt="Tutor Selection" /></div>
            <div class="col-md-6">
                <h2 class="my-2 text-center">How We Select Our College Tutors</h2>
                <p>We screen our tutors to ensure that they fit Wisepal’s standards for college tutoring in New York
                    City.</p>
                <p>Our prospective tutors should present their academic credentials such as certifications, diplomas,
                    grades, and test scores. All these credentials are rigorously reviewed by Wisepal’s selection team.
                </p>
                <p>After being reviewed, prospects then undergo subject assessment tests in which they need to obtain
                    qualifying scores.</p>
                <p>After obtaining qualifying scores, Wisepal’s selection team will then conduct a background check if
                    requested.</p>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-md-6">
                <h2 class="my-2 text-center">How Our College Tutoring Works</h2>
                <ol>
                    <li>Create an account</li>
                    <li>Browse your preferred tutors by university subject</li>
                    <li>Liaise with our tutors, and select the one that fits you</li>
                </ol>
                <p>Get only the most qualified tutors in the tri-state area. We only hire competent tutors that can
                    guarantee you improved academic results.</p>
            </div>
            <div class="col-md-6 d-flex justify-content-center"><img class="my-3"
                    src={teachingSubjects} alt="How Wisepal Works" /></div>
        </div>

        <div class="row my-5">
            <div class="col-md-12">
                <h2 class="text-center my-3">FAQ</h2>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed btn-prpl" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                How much does a college-level tutor cost?</button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <span>Our tutors set their own prices for each college tutoring session. These prices
                                    are clearly displayed as you browse the tutors' catalog. Prices will depend on
                                    experience and educational attainment.</span>.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed btn-prpl" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                What will you need in a tutoring session?
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <span>You just need questions about your lessons, a computer with the ability to screen
                                    share, and a passion to learn and improve.</span>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed btn-prpl" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Why is tutoring important for college students?
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <span>College students view their studies as a vital part of ensuring a decent future.
                                    To do this, they are expected to maintain their GPA and have a solid understanding
                                    of their subjects. The college tutoring services of Wisepal aids you in these goals
                                    and improves your comprehension and retention of college subjects.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

CTutoringPageComponent.defaultProps = {
  currentUserListing: null,
  currentUser: null,
  currentUserListingFetched: false,
  reviews: [],
};

CTutoringPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  reviews: arrayOf(propTypes.review),
  isAuthenticated: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const {
    reviews,
  } = state.Reviews;

  return {
    reviews,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetUsersReviews: () => dispatch(getUsersReviews()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
// 
// See: https://github.com/ReactTraining/react-router/issues/4671
const CTutoringPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CTutoringPageComponent);

export default CTutoringPage;