import React from 'react';
import PropTypes, { shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { getFormatedDisplayName } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav
} from '../../components';
import { ReferralSystemForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { inviteMembers, referrals } from './ReferralSystemPage.duck';
import { encryptBonus, decryptBonus } from '../../ducks/user.duck';
import css from './ReferralSystemPage.css';

export class ReferralSystemPageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bonusReferralsCount: 0,
      email: null,
      inviteSuccessEmail: null,
      getReferral: false
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
  }

  componentDidUpdate(prevProps) {
    const cr = this.props.currentUser; // current user

    if (cr !== undefined && !this.state.getReferral) {
      this.setState({getReferral: true});
      this.props.getReferrals(cr.id.uuid);
      this.props.onDecryptBonus(cr.attributes.profile.privateData.bonus);
    }

    if (this.props.referrals !== prevProps.referrals) {
      const bonusReferrals = this.props.referrals.filter(referral => referral.attributes.profile.privateData.txe === true);
      const userType = cr.attributes.profile.privateData.userType;
      this.setState({ bonusReferralsCount: bonusReferrals.length });

      if (bonusReferrals.length > 0) {
        if (cr.attributes.profile.privateData.rbl) {
          const bonusReferralsFilter = bonusReferrals.filter(referral => !cr.attributes.profile.privateData.rbl.includes(referral.id.uuid));
          if (bonusReferralsFilter.length > 0) {
            this.setBonus(userType, bonusReferralsFilter, cr);
          }
        } else {
          this.setBonus(userType, bonusReferrals, cr);
        }
      }
    }
  }

  setBonus(userType, bonusReferrals, cr) {
    const rbl = cr.attributes.profile.privateData.rbl || [];
    bonusReferrals.map(referral => rbl.push(referral.id.uuid));
    const b = userType === 'Student' ? bonusReferrals.length * 10 : bonusReferrals.length;
    this.props.onEncryptBonus(b + parseInt(this.props.bonus), rbl);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({inviteSuccessEmail: e.target[0].value});
    this.setState({email: null});
    const data = {
      email: e.target[0].value,
      id: this.props.currentUser.id.uuid
    };
    this.props.onInviteMembers(data);
  };

  onChangeEmail(e) {
    this.setState({email: e.target.value})
  }

  render() {
    const {
      currentUser,
      onChange,
      scrollingDisabled,
      referrals,
      emailInviteSuccess,
      emailInviteError,
      emailInviteProgress,
      referralsSuccess,
      referralsError,
      referralsProgress,
      bonus,
      intl,
    } = this.props;

    const title = intl.formatMessage({ id: 'ReferralSystemPage.title' });
    const referralName = intl.formatMessage({ id: 'ReferralSystemPage.referralsListName' });
    const referralType = intl.formatMessage({ id: 'ReferralSystemPage.referralsListReferralType' });
    const referralTransactionExist = intl.formatMessage({ id: 'ReferralSystemPage.referralsListTransactionExist' });
    const renderReferralsList = (referrals) => {
      return referrals.map((item, i) => {
        const displayName = getFormatedDisplayName(item);
        return (
          <li key={i}>
            <b>{referralName}</b>{displayName}
            <b>{referralType}</b>{item.attributes.profile.protectedData.userType}
            <b>{referralTransactionExist}</b>{item.attributes.profile.privateData.txe ? 'Yes' : 'No'}
          </li>
        )
      });
    };

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="ReferralSystemPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav selectedPageName="ReferralSystemPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSettingsSideNav currentTab="ReferralSystemPage" />
          <LayoutWrapperMain>
            <div className={css.content}>
              <h1 className={css.title}>
                <FormattedMessage id="ReferralSystemPage.heading" />
              </h1>
              <p>
                <FormattedMessage
                  id="ReferralSystemPage.myReferrals"
                  values={{ countReferrals: referrals.length || 0 }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="ReferralSystemPage.bonus"
                  values={{ count: currentUser && bonus.toFixed(2) || 0 }}
                />
              </p>
              <ReferralSystemForm
                className={css.form}
                initialValues={{ email: this.state.email }}
                currentUser={currentUser}
                emailInviteSuccess={emailInviteSuccess}
                emailInviteError={emailInviteError}
                emailInviteProgress={emailInviteProgress}
                onSubmit={this.handleSubmit}
                onChange={onChange}
                onChangeEmail={this.onChangeEmail}
                email={this.state.email}
                inviteSuccessEmail={this.state.inviteSuccessEmail}
              />
              <div>
                <h2>My referrals list:</h2>
                {renderReferralsList(referrals)}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

ReferralSystemPageComponent.defaultProps = {
  currentUser: null,
};

const { bool, func } = PropTypes;

ReferralSystemPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,

  location: shape({
    search: string.isRequired,
  }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, bonus } = state.user;
  const {
    referrals,
    emailInviteSuccess,
    emailInviteError,
    emailInviteProgress,
    referralsSuccess,
    referralsError,
    referralsProgress
  } = state.ReferralSystemPage;

  return {
    currentUser,
    bonus,
    referrals,
    emailInviteSuccess,
    emailInviteError,
    emailInviteProgress,
    referralsSuccess,
    referralsError,
    referralsProgress,
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onInviteMembers: (data) => dispatch(inviteMembers(data)),
  getReferrals: (data) => dispatch(referrals(data)),
  onEncryptBonus: (data, rbl) => dispatch(encryptBonus(data, rbl)),
  onDecryptBonus: (data) => dispatch(decryptBonus(data)),
});

const ReferralSystemPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ReferralSystemPageComponent);

export default ReferralSystemPage;
