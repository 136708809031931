import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getBackgroundCheckConsent,
  updateBackgroundCheckConsent,
} from '../../../containers/AdminPanelPage/AdminPanelPage.duck';
import { func, bool, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AgreementsEditor } from '../..';

import css from './AdminBGConsentComponent.css';

export class AdminBGConsentComponent extends Component {
  componentDidMount() {
    this.props.onGetBackgroundCheckConsent();
  }

  handleData(formdata) {
    console.log(formdata);
    this.props.onUpdateBackgroundCheckConsent(formdata);
  }

  render() {
    const { bgConsent } = this.props;

    return (
      <div>
        <h3 className={css.title}>
          <FormattedMessage id={'AdminBGConsentComponent.title'} />
        </h3>

        {bgConsent && (
          <AgreementsEditor
            contentOnly={true}
            data={bgConsent}
            onSubmit={data => this.handleData(data)}
          />
        )}
      </div>
    );
  }
}

AdminBGConsentComponent.propTypes = {
  bgConsent: object,
  bgConsentProgress: bool,
  bgConsentError: bool,
  onGetBackgroundCheckConsent: func,
  onUpdateBackgroundCheckConsent: func,
};

const mapStateToProps = state => {
  const { bgConsent, bgConsentProgress, bgConsentError } = state.AdminPanelPage;
  return {
    bgConsent,
    bgConsentProgress,
    bgConsentError,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetBackgroundCheckConsent: () => {
    dispatch(getBackgroundCheckConsent());
  },
  onUpdateBackgroundCheckConsent: data => {
    dispatch(updateBackgroundCheckConsent(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminBGConsentComponent);
