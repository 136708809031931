import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { Button, FieldTextInput, Form } from '../../../components';

import css from './AdminProprofsComponent.css';

const AdminProprofsForm = props => {
  const id = props.initialValues && props.initialValues.id;

  return (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        hasInitialQuiz,
        isInitialQuiz,
        form,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);

      return (
        <Form
          className={classes}
          onSubmit={event => {
            const promise = handleSubmit(event);
            promise &&
              promise.then(() => {
                form.reset();
              });
            return promise;
          }}
        >
          {hasInitialQuiz || !isInitialQuiz ? (
            <FieldTextInput
              id={id ? `${id}-subject` : 'subject'}
              name={'subject'}
              className={css.subjectInput}
              type="text"
              label={'Subject Name'}
              required={true}
              placeholder="enter subject name"
            />
          ) : (
            <FieldTextInput
              id={'initialQuiz'}
              name={'subject'}
              className={css.subjectInput}
              type="text"
              label={'Initial quiz name'}
              required={true}
              disabled={true}
              defaultValue={'Initial Quiz'}
            />
          )}
          <FieldTextInput
            id={id ? `${id}-link` : 'link'}
            name={'link'}
            className={css.subjectInput}
            type="text"
            label={'Link'}
            required={true}
            placeholder="put the link"
          />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={inProgress}
          >
            Save subject
          </Button>
        </Form>
      );
    }}
  />
)};

export default withRouter(AdminProprofsForm);
