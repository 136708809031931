import { storableError } from '../util/errors';
import * as log from '../util/log';
import { sendMessage } from '../containers/TransactionPage/TransactionPage.duck';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const RAILS_API_URL = process.env.REACT_APP_RAILS_API_URL;

// ================ Action types ================ //

export const GET_TRANSACTIONS = 'app/Transactions/GET_TRANSACTIONS';
export const GET_TRANSACTIONS_IN_PROGRESS = 'app/Transactions/GET_TRANSACTIONS_IN_PROGRESS';
export const GET_TRANSACTIONS_ERROR = 'app/Transactions/GET_TRANSACTIONS_ERROR';

export const UPLOAD_ATTACHMENT_SUCCESS = 'app/Transactions/UPLOAD_ATTACHMENT_SUCCESS';
export const UPLOAD_ATTACHMENT_REQUEST = 'app/Transactions/UPLOAD_ATTACHMENT_REQUEST';
export const UPLOAD_ATTACHMENT_ERROR = 'app/Transactions/UPLOAD_ATTACHMENT_ERROR';

export const GET_ATTACHMENT_SUCCESS = 'app/Transactions/GET_ATTACHMENT_SUCCESS';
export const GET_ATTACHMENT_REQUEST = 'app/Transactions/GET_ATTACHMENT_REQUEST';
export const GET_ATTACHMENT_ERROR = 'app/Transactions/GET_ATTACHMENT_ERROR';

// ================ Reducer ================ //

const initialState = {
  transactions: [],
  attachments: [],
  getTransactionsInProress: false,
  getTransactionsError: false,
  uploadAttachmentRequest: false,
  uploadAttachmentError: false,
  getAttachmentRequest: false,
  getAttachmentError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload,
      };
    case GET_TRANSACTIONS_IN_PROGRESS:
      return {
        ...state,
        getTransactionsInProress: payload,
      };
    case GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        getTransactionsError: payload,
      };
    case UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachments: payload,
        uploadAttachmentRequest: false
      };
    case UPLOAD_ATTACHMENT_REQUEST:
      return {
        ...state,
        uploadAttachmentRequest: true
      };
    case UPLOAD_ATTACHMENT_ERROR:
      return {
        ...state,
        uploadAttachmentError: true,
        uploadAttachmentRequest: false
      };
    case GET_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachments: payload,
        getAttachmentRequest: false
      };
    case GET_ATTACHMENT_REQUEST:
      return {
        ...state,
        getAttachmentRequest: true
      };
    case GET_ATTACHMENT_ERROR:
      return {
        ...state,
        getAttachmentError: true,
        getAttachmentRequest: false
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getTransactions = data => ({ type: GET_TRANSACTIONS, payload: data.data.data});
export const getTransactionsInProgress = data => ({ type: GET_TRANSACTIONS_IN_PROGRESS, payload: data });
export const getTransactionsError = data => ({ type: GET_TRANSACTIONS_ERROR, payload: data });

export const uploadAttachmentSuccess = data => ({ type: UPLOAD_ATTACHMENT_SUCCESS, payload: data.data });
export const uploadAttachmentRequest = () => ({ type: GET_TRANSACTIONS_IN_PROGRESS });
export const uploadAttachmentError = () => ({ type: GET_TRANSACTIONS_ERROR });

export const getAttachmentSuccess = data => ({ type: GET_ATTACHMENT_SUCCESS, payload: data.data.data });
export const getAttachmentRequest = () => ({ type: GET_ATTACHMENT_REQUEST });
export const getAttachmentError = () => ({ type: GET_ATTACHMENT_ERROR });

// ================ Thunks ================ //

export const getUsersTransactions = (userId) => (dispatch) => {
  const url = `${API_URL}/api/transactions?userId=${userId}`;

  dispatch(getTransactionsInProgress(true));
  dispatch(getTransactionsError(false));

  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      dispatch(getTransactionsInProgress(false));
      dispatch(getTransactions(data));
    })
    .catch ((e) => {
      log.error(storableError(e), 'get-users-reviews-failed');
      dispatch(getTransactionsInProgress(false));
      dispatch(getTransactionsError(true));
    })
};

export const uploadAttachments = data => async (dispatch) => {
  const url = `${RAILS_API_URL}/api/v1/transaction/upload_attachments`;
  dispatch(uploadAttachmentRequest())

  return axios.post(url, data)
    .then(response => {
      dispatch(uploadAttachmentSuccess(response))
      return response.data
    })
    .then(response => {
      dispatch(sendMessage(response.transaction_id, response.path))
    })
    .catch(e => dispatch(uploadAttachmentError()))
};

export const getAttachments = transactionId => (dispatch) => {
  const url = `${RAILS_API_URL}/api/v1/transaction/attachments/${transactionId}`;
  dispatch(getAttachmentRequest())

  return axios.get(url)
    .then(response => {
      dispatch(getAttachmentSuccess(response))
    })
    .catch(e => dispatch(getAttachmentError()))
};
