import React from 'react';
import PropTypes from 'prop-types';
import css from './TopbarDesktop.css';

const TopbarDesktopNotificationIcon = props => {
  const { className } = props;

  return (
    <svg
      className={css.notificationIcon}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="26"
      fill="none"
      viewBox="0 0 23 26"
    >
      <path
        fill="#4A4A4A"
        stroke="#4A4A4A"
        strokeWidth="0.5"
        d="M22.26 21.257c-2.061-2.07-3.106-4.522-3.106-7.286V9.948c.019-3.067-2.022-5.101-3.775-5.911a8.83 8.83 0 00-1.546-.544v-.257C13.833 2.003 12.841 1 11.62 1 10.4 1 9.409 2.003 9.409 3.236v.296a8.7 8.7 0 00-1.392.509c-1.938.903-3.9 3.078-3.918 5.904v4.026c0 2.857-.999 5.309-2.967 7.286a.452.452 0 00-.11.452c.049.16.183.278.346.307l4.2.733c.782.136 1.503.248 2.188.34.773 1.53 2.29 2.511 3.946 2.511 1.657 0 3.174-.982 3.946-2.513a55.49 55.49 0 002.177-.339l4.2-.733a.45.45 0 00.237-.758zM10.294 3.237c0-.74.595-1.342 1.327-1.342s1.328.602 1.328 1.341v.082a8.705 8.705 0 00-2.655.02v-.102zM11.7 24.704c-1.12 0-2.167-.562-2.84-1.482a27.11 27.11 0 002.835.155c.926 0 1.853-.052 2.845-.157-.672.921-1.72 1.485-2.84 1.485zm5.973-2.838c-.85.148-1.628.268-2.363.363h-.003c-.324.041-.639.078-.947.109h-.01c-.282.03-.557.052-.83.072l-.155.011c-.236.016-.47.028-.702.037l-.143.006a24.21 24.21 0 01-2.65-.054c-.265-.019-.533-.042-.808-.069-.01 0-.02-.002-.028-.003a35.32 35.32 0 01-.941-.108H8.09a53.08 53.08 0 01-2.372-.364l-3.39-.592c1.762-2.037 2.655-4.49 2.655-7.304V9.948c.015-2.43 1.72-4.31 3.403-5.095.5-.233 1.02-.407 1.547-.529l.014-.001a7.961 7.961 0 013.354-.03c.006 0 .012-.002.017-.001a7.944 7.944 0 011.693.559c1.632.754 3.273 2.621 3.257 5.095v4.025c0 2.699.963 5.208 2.794 7.305l-3.388.59z"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

TopbarDesktopNotificationIcon.defaultProps = {
  className: null,
};

TopbarDesktopNotificationIcon.propTypes = {
  className: string,
};

export default TopbarDesktopNotificationIcon;
