import React from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.css';

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg className={className} width="16" height="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <rect className={boxClassName || css.box} width="15" height="15" x="0.5" y="0.5" fill="#fff" stroke="#D3D3D3" rx="1.5"></rect>

      <rect className={checkedClassName || css.checked} width="15" height="15" x="0.5" y="0.5" fill="#614983" rx="1.5"></rect>
      <path className={checkedClassName || css.tick}  stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 7.5L6.75 11l4.75-6"></path>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    disabled,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    disabled,
    ...rest,
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
  disabled: false,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
  disabled: bool,
};

export default FieldCheckboxComponent;
