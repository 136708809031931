import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { func, string, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal, Button } from '..';
import { notificationBackgroundCheck } from '../../ducks/user.duck';

import css from './BackgroundCheckModal.css';

const API_URL = process.env.REACT_APP_API_URL;

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.props.portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.props.portalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

function BackgroundCheckModal(props){
    const {
      onManageDisableScrolling,
      showModal,
      onCloseModal,
      remindMeLater,
      modalName,
      cancelButtonTitle,
      onNotificationBackgroundCheck,
      currentUser,
      currentUserListing
    } = props;
    const [portalRoot, setPortalRoot] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const handleSubmit = () => {
        const data = {
          email: currentUser.attributes.email,
          listing: `${API_URL}/l/${currentUserListing.id.uuid}`
        }
        setLoading(true);
        onNotificationBackgroundCheck(data)
          .then(() => {
            setSent(true);
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          })
    }

    const setPortalRootAfterInitialRender = () => {
      setPortalRoot(document && document.getElementById('portal-root') || null);
    };

    const classes = '';

    return (
      <div className={classes} ref={setPortalRootAfterInitialRender}>
        {portalRoot && onManageDisableScrolling && (
          <Portal portalRoot={portalRoot}>
            <Modal
              id={modalName}
              isOpen={showModal}
              onClose={() => onCloseModal()}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              {!sent && (
                <>
                  <h3>
                    <FormattedMessage id="BackgroundCheckModal.modalTitle"/>
                  </h3>

                  <p>
                    <FormattedMessage id="BackgroundCheckModal.modalContent"/>
                  </p>

                  <div className={css.modalButtons}>
                    <div className={css.modalButton}>
                      <Button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={loading}
                        inProgress={loading}
                      >
                        <FormattedMessage id="BackgroundCheckModal.submitButton"/>
                      </Button>
                    </div>
                  
                    {<div className={css.modalButton}>
                      <Button
                        className={css.cancelButton}
                        onClick={onCloseModal}
                        disabled={loading}
                      >
                        <FormattedMessage id={cancelButtonTitle || 'BackgroundCheckModal.cancelButton'}/>
                      </Button>
                    </div>}
                  </div>
                </>
              )}

              {sent && (
                <>
                  <h3>
                    <FormattedMessage id="BackgroundCheckModal.modalTitleSent"/>
                  </h3>
                </>
              )}
            </Modal>
          </Portal>
        )}
      </div>
    );
}

BackgroundCheckModal.defaultProps = {
  remindMeLater: false
}

BackgroundCheckModal.propTypes = {
  onManageDisableScrolling: func.isRequired,
  modalName: string.isRequired,
  remindMeLater: bool,
  showModal: bool,
  onCloseModal: func.isRequired,
  cancelButtonTitle: string,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserListing } = state.user;

  return {
    currentUser,
    currentUserListing
  };
};

const mapDispatchToProps = dispatch => ({
  onNotificationBackgroundCheck: data => dispatch(notificationBackgroundCheck(data))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BackgroundCheckModal);
