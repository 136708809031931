import React, { Component } from 'react';
import { compose } from 'redux';
import css from './ChangeImageEditor.css';
import { FormattedMessage } from '../../util/reactIntl';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import NoImage from '../../assets/noimage.png';
import { Button } from '../../components';

const hasImage = image => {
  if (image) {
    if (image.includes('/missing.png')) {
      return NoImage;
    } else {
      return image;
    }
  } else {
    return NoImage;
  }
};

class ChangeImageEditorComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasFile: false,
      image: null,
      imageURL: null,
      count: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.modalChangeImageOpen !== this.props.modalChangeImageOpen &&
      this.props.modalChangeImageOpen === false
    ) {
      this.setState({
        hasFile: false,
        image: null,
        imageURL: null,
        count: 0,
      });
    }

  }

  handleSavePost = () => {
    const { image } = this.state;
    const { makeRequestFunction, typeSection } = this.props;
    const formData = new FormData();

    formData.append('image_section', typeSection);
    if (image) formData.append('image', image);

    makeRequestFunction(formData);
  };

  handleImageChange = e => {
    this.setState({
      imageURL: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
      hasFile: true,
    });
  };

  clickChange = () => {
    this.setState({
      hasFile: !this.state.hasFile,
    });
  };

  render() {
    if (typeof window === 'undefined' && typeof document === 'undefined') {
      return null;
    }

    const { image, imageURL, count } = this.state;
    const { edit, editingError, createError, item, inProgress, modalChangeImageOpen } = this.props;

    if (item && count === 0 && modalChangeImageOpen !== false) {
      const { image_file_name } = item;

      this.setState({
        hasFile: image_file_name.includes('/missing.png') ? false : true,
        imageURL: hasImage(image_file_name),
        count: 1,
      });
    }

    return (
      <div className={css.modalPayoutDetailsWrapper}>
        <h1 className={css.modalTitle}>
          <FormattedMessage id={this.props.editorTitle} />
        </h1>
        <label className={css.label} htmlFor="image">
          Image *
        </label>

        {this.state.hasFile ? (
          <div className={css.prevImageContainer}>
            {imageURL && <img className={css.image} src={imageURL} alt="img" />}
            <button onClick={this.clickChange} className={css.changeImgButton}>
              Change image
            </button>
          </div>
        ) : (
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={this.handleImageChange}
            required
          />
        )}
        <Button
          onClick={this.handleSavePost}
          className={css.savePostBtn}
          disabled={!image && this.state.hasFile}
          inProgress={inProgress}
        >
          {item ? 'Save change' : 'Save'}
        </Button>
        {editingError ? (
          <p className={css.editError}>
            <FormattedMessage id="BlogPostEditor.errorEditPost" />
          </p>
        ) : null}
        {createError ? (
          <p className={css.editError}>
            <FormattedMessage id="BlogPostEditor.errorCreatePost" />
          </p>
        ) : null}
      </div>
    );
  }
}

const ChangeImageEditor = compose()(ChangeImageEditorComponent);

export default ChangeImageEditor;
