import React from 'react';
import PropTypes from 'prop-types';
import css from './SectionHowItWorks.css';

const SectionHowItWorksIconBrowse = props => {
  const { className } = props;

  return (
    <svg
      className={css.icons}
      xmlns="http://www.w3.org/2000/svg"
      width="78"
      height="64"
      fill="none"
      viewBox="0 0 78 64"
    >
      <path
        stroke="#614983"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M39.5 44C51.374 44 61 34.374 61 22.5S51.374 1 39.5 1 18 10.626 18 22.5 27.626 44 39.5 44z"
      ></path>
      <path
        stroke="#E77B20"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M39.5 37C47.508 37 54 30.508 54 22.5S47.508 8 39.5 8 25 14.492 25 22.5 31.492 37 39.5 37z"
      ></path>
      <path
        stroke="#E77B20"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M28.5 32s4.208-5 11.007-5C46.307 27 50 32 50 32M39.5 27a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
      ></path>
      <path
        stroke="#614983"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M55.73 36l18.433 18.552s3.533 3.385.8 6.51c-2.91 3.335-5.676 1.708-7.594 0C65.45 59.351 49 41.89 49 41.89M68 27a5 5 0 100-10 5 5 0 000 10z"
      ></path>
      <path
        stroke="#614983"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M58 35s2.979-8 9.774-8C74.569 27 77 35 77 35H58z"
      ></path>
      <path
        stroke="#614983"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M11 27a5 5 0 100-10 5 5 0 000 10z"
      ></path>
      <path
        stroke="#614983"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M1 35s2.979-8 9.774-8C17.569 27 20 35 20 35H1z"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

SectionHowItWorksIconBrowse.defaultProps = {
  className: null,
};

SectionHowItWorksIconBrowse.propTypes = {
  className: string,
};

export default SectionHowItWorksIconBrowse;
