import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import SectionHowItWorksIconBrowse from './SectionHowItWorksIconBrowse';
import SectionHowItWorksIconLesson from './SectionHowItWorksIconLesson';
import SectionHowItWorksIconReview from './SectionHowItWorksIconReview';

import css from './SectionHowItWorks.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
      </div>

      <div className={css.steps}>

        <div className={css.step}>
          <SectionHowItWorksIconBrowse  className={css.test}/>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <SectionHowItWorksIconLesson />
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <SectionHowItWorksIconReview />
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part3Text" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
