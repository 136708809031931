import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './SectionBecomingTutor.css';

import image1 from './images/becoming-tutor-img1.jpg';
import image2 from './images/becoming-tutor-img2.jpg';
import image3 from './images/becoming-tutor-img3.jpg';
import image4 from './images/becoming-tutor-img4.jpg';

const SectionBecomingTutor = ({ isAuthenticated }) => {
  return (
    <>
      <div className={css.title}>
        <FormattedMessage id="SectionBecomingTutor.becomingTutorTitle" />
      </div>

      <div className={css.becomingTutorWrapper}>
        <div>
          <div className={css.becomingTutorItem}>
            <img className={css.becomingTutorImg} src={image1} alt="Interested in becoming a tutor?" />
            <div className={css.becomingTutorText}>
              <h3><FormattedMessage id="SectionBecomingTutor.findStudents" /></h3>
              <p><FormattedMessage id="SectionBecomingTutor.findStudentsText" /></p>
            </div>
          </div>
          <div className={css.becomingTutorItem}>
            <img className={css.becomingTutorImg} src={image2} alt="Interested in becoming a tutor?" />
            <div className={css.becomingTutorText}>
              <h3><FormattedMessage id="SectionBecomingTutor.earnOn" /></h3>
              <p><FormattedMessage id="SectionBecomingTutor.earnOnText" /></p>
            </div>
          </div>
          <div className={css.becomingTutorItem}>
            <img className={css.becomingTutorImg} src={image3} alt="Interested in becoming a tutor?" />
            <div className={css.becomingTutorText}>
              <h3><FormattedMessage id="SectionBecomingTutor.workRemotely" /></h3>
              <p><FormattedMessage id="SectionBecomingTutor.workRemotelyText" /></p>
            </div>
          </div>
          <div className={css.becomingTutorItem}>
            <img className={css.becomingTutorImg} src={image4} alt="Interested in becoming a tutor?" />
            <div className={css.becomingTutorText}>
              <h3><FormattedMessage id="SectionBecomingTutor.availability" /></h3>
              <p><FormattedMessage id="SectionBecomingTutor.availabilityText" /></p>
            </div>
          </div>
        </div>
        {!isAuthenticated &&
          <NamedLink name='SignupPage' className={css.createAccountBtn}>
            <FormattedMessage id="SectionBecomingTutor.becomingTutorButton"/>
          </NamedLink>
        }
      </div>
    </>
  );
};
SectionBecomingTutor.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionBecomingTutor.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionBecomingTutor;
