import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import SectionReviewsStars from './SectionReviewsStars';
import { setInitialsFromUserName } from '../../util/setInitials';
import { getFormatedDisplayName } from '../../util/data';

import css from './SectionReviews.css';

const SectionReviews = props => {
  const { rootClassName, className, reviews } = props;

  return (
    <div className={css.reviewBlock}>
      <div className={css.title}>
        <FormattedMessage id="SectionReview.title" />
      </div>

      <div className={css.reviewRow}>
        {reviews.map((review) => {
          return (
            <div className={css.reviewCol} key={review.id}>
              <div className={css.reviewItem}>
                <div className={css.reviewHeader}>
                  <div className={css.reviewAuthor}>
                    {review.authorImage ?
                    <div className={css.reviewImgContainer}>
                      <img src={review.authorImage} alt="avatar" className={css.reviewAvatar}/>
                    </div>
                    : <span className={css.reviewInitials}>{setInitialsFromUserName(review.displayName)}</span>
                    }
                    <span className={css.reviewName}>{getFormatedDisplayName(review.displayName)}</span>
                  </div>
                  <SectionReviewsStars />
                </div>
                <div className={css.reviewText}>
                <span>{review.reviewContent}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

    </div>
  );
};

SectionReviews.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionReviews.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionReviews;
