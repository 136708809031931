import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';

import css from './ListingPage.css';

const getBackgroundCheckInfo = (backgroundCheckConfig, key) => {
  return backgroundCheckConfig.find(c => c.key === key);
};

const SectionHeading = props => {
  const {
    richTitle,
    backgroundCheck,
    backgroundCheckConfig,
    showContactUser,
    onContactUser,
  } = props;

  const className = backgroundCheck ? css.iconChecked : css.iconFailed;

  // const backgroundCheckValue = getBackgroundCheckInfo(backgroundCheckConfig, backgroundCheck);
  // const showBackgroundCheck = backgroundCheckValue && !backgroundCheckValue.hideFromListingInfo;
  // console.log(backgroundCheck);
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}

          <span className={css.backgroundCheckIconsWrapper}>
            <span className={className}></span>
            <FormattedMessage id="ListingPage.backgroundCheck" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;

