import React, {useState, useEffect, useRef} from 'react';
import { PrimaryButton, SecondaryButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './TutorsAppPage.css';

const AgreementsSection = ({agreements, onComplete, onDecline, onLoad}) => {
  const blockRef = useRef(null);
  const [step, setStep] = useState(0);
  const lastStep = (agreements.length - 1) == step;

  useEffect(() => {
    onLoad();
  }, [])

  const handleComplete = () => {
    if (typeof window !== undefined){
      window.scrollTo(0, blockRef.current.offsetTop - 80);
    }

    if (!lastStep){
      setStep(step + 1);
      return;
    }

    onComplete();
  }

  const currentAgreement = agreements[step];

  if (!currentAgreement) {
    return null;
  }

  return (
    <div
      className={css.agreementWrapper}
      ref={blockRef}
    >
      <div className={css.titleWrapper}>
        <h2 className={css.pageTitle}>
          {currentAgreement.title}
        </h2>
      </div>

      <div className={css.textWrapper}>
        <div
          className={css.text}
          dangerouslySetInnerHTML={{ __html: currentAgreement.text }}
        />
      </div>

      <div className={css.buttonsWrapper}>
        <PrimaryButton
          onClick={() => handleComplete()}
        >
          <FormattedMessage id="TutorsAppPage.agreementButtonTitleAgree"/>
        </PrimaryButton>

        <SecondaryButton
          onClick={() => onDecline()}
        >
          <FormattedMessage id="TutorsAppPage.agreementButtonTitleDecline"/>
        </SecondaryButton>
      </div>
    </div>
  )
};

export default AgreementsSection;
