import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldSelect, Button } from '../../components';

import css from '../../components/BookingBreakdown/BookingBreakdown.css';

const CancellationFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        intl,
        setModalOpen,
        handleSubmit,
        selectValues,
      } = fieldRenderProps;

      return (
        <Form onSubmit={handleSubmit}>
          <FieldSelect name='cancellationReason' id='cancellationReason'>
            <option disabled value="" className={css.none}>{intl.formatMessage({id: 'BookingBreakdown.reasonsPlaceholder'})}</option>
            {selectValues.map(c => (<option key={c} value={c}>{c}</option>))}
          </FieldSelect>
          <div className={css.cancellationModalButtonHolder}>
            <Button className={css.cancellationModalButton} type="submit">
              <FormattedMessage id="BookingBreakdown.yes" />
            </Button>
            <Button
              className={classNames(css.cancellationModalButton,css.cancellationModalButtonNo)}
              type="button"
              onClick={() => setModalOpen(false)}
            >
              <FormattedMessage id="BookingBreakdown.no" />
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

CancellationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

CancellationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const CancellationForm = compose(injectIntl)(CancellationFormComponent);
CancellationForm.displayName = 'CancellationForm';

export default CancellationForm;
