import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATETIME } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureListing } from '../../util/data';
import { BookingBreakdown } from '../../components';

import css from './TransactionPanel.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const { className, rootClassName, breakdownClassName, transaction, transactionRole, localTimeZone, short, awaitToCancel, updateData } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(
    breakdownClassName || css.breakdown,
    {[css.isAwaitToUpdate] : awaitToCancel}
  );

  let awaitData = null;

  if (updateData){
    const { bookingStart, bookingEnd } = updateData || {};

    awaitData = bookingStart && bookingEnd ? { awaitStart: bookingStart, awaitEnd: bookingEnd } : null;
  }

  return loaded ? (
    <div className={classes}>
      {awaitToCancel && (
        <h4 className={css.awaitToTransactionTitle}>
          <FormattedMessage id="BookingBreakdown.awaitingCanceletion"/>
        </h4>)}

      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={config.bookingUnitType}
        transaction={transaction}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATETIME}
        timeZone={localTimeZone}
        short={short}
        awaitData={awaitData}
        awaitToCancel={awaitToCancel}
      />
    </div>
  ) : null;
};

export default BreakdownMaybe;
