import React from 'react';
import PropTypes from 'prop-types';


const SectionReviewsStars = props => {
  const { className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="12"
      fill="none"
      viewBox="0 0 68 12"
    >
      <path
        fill="#C0392B"
        fillRule="evenodd"
        d="M11.968 4.178a.548.548 0 00-.514-.36H7.503L6.525.396a.547.547 0 00-1.05 0l-.978 3.422H.546a.547.547 0 00-.35.965L3.17 7.262l-1.5 4a.545.545 0 00.828.636L6 9.398l3.501 2.5a.545.545 0 00.828-.635l-1.5-4.001 2.975-2.479a.548.548 0 00.164-.605zM25.968 4.178a.548.548 0 00-.514-.36h-3.951L20.526.396a.547.547 0 00-1.051 0l-.979 3.422h-3.95a.547.547 0 00-.35.965l2.974 2.479-1.5 4a.545.545 0 00.828.636L20 9.398l3.501 2.5a.545.545 0 00.828-.635l-1.5-4.001 2.975-2.479a.548.548 0 00.164-.605zM39.968 4.178a.548.548 0 00-.514-.36h-3.951L34.526.396a.547.547 0 00-1.051 0l-.979 3.422h-3.95a.547.547 0 00-.35.965l2.974 2.479-1.5 4a.545.545 0 00.828.636L34 9.398l3.501 2.5a.545.545 0 00.828-.635l-1.5-4.001 2.975-2.479a.548.548 0 00.164-.605zM53.968 4.178a.548.548 0 00-.514-.36h-3.951L48.526.396a.547.547 0 00-1.051 0l-.979 3.422h-3.95a.547.547 0 00-.35.965l2.974 2.479-1.5 4a.545.545 0 00.828.636L48 9.398l3.501 2.5a.545.545 0 00.828-.635l-1.5-4.001 2.975-2.479a.548.548 0 00.164-.605zM67.968 4.178a.548.548 0 00-.514-.36h-3.951L62.526.396a.547.547 0 00-1.051 0l-.979 3.422h-3.95a.547.547 0 00-.35.965l2.974 2.479-1.5 4a.545.545 0 00.828.636L62 9.398l3.501 2.5a.545.545 0 00.828-.635l-1.5-4.001 2.975-2.479a.548.548 0 00.164-.605z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

SectionReviewsStars.defaultProps = {
  className: null,
};

SectionReviewsStars.propTypes = {
  className: string,
};

export default SectionReviewsStars;
