import React from 'react';
import PropTypes, { shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  Modal,
  IconSpinner,
  PrimaryButton,
  SecondaryButton
} from '../../components';
import { TopbarContainer } from '..';
import { getTutorsApp } from './ProfileTutorsAppPage.duck';
import { TutorsAppForm } from '../../forms';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';

import css from './ProfileTutorsAppPage.css';

export class ProfileTutorsAppPageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTutorsApp: false,
      requested: false
    };
  }

  componentDidMount(){
    this.getApp();
  }

  componentDidUpdate(){
    const { currentUser, tutorsApp, tutorsProgress, tutorsError } = this.props;
    if (!tutorsApp && !!currentUser && !tutorsProgress && !tutorsError) {
      this.getApp();
    }
  }

  getApp(){
    const { currentUser, onGetTutorsApp} = this.props;
    const id = currentUser && currentUser.id && currentUser.id.uuid;
    const isTutor = currentUser &&
      currentUser.attributes.profile.privateData.userType && 
      (currentUser.attributes.profile.privateData.userType).toLowerCase() === 'tutor';

    if (isTutor && id){
      onGetTutorsApp(id)
        .then(() => {
          this.setState({requested: true});
        });
    }
  }

  handleClose(){
    this.setState({showTutorsApp: false})
  }

  goToCreate(){
    this.props.history.push(createResourceLocatorString('TutorsAppPage', routeConfiguration(), {}));
  }

  goToEdit(){
    this.props.history.push(createResourceLocatorString('TutorsAppPageEdit', routeConfiguration(), {}));
  }

  renderStatus(){
    const {tutorsApp} = this.props;

    if (!tutorsApp || !tutorsApp.tutor){
      return null;
    }

    const statusType = tutorsApp.tutor.status;
    const status = <FormattedMessage id={`ProfileTutorsAppPage.${statusType}`}/>

    return (
      <div className={css.statusWrapper}>
        <span className={css.statusTitle}>
          <FormattedMessage id={'ProfileTutorsAppPage.statusTitle'}/>:
        </span>

        <span className={classNames(css.status, css[statusType])}>
          {status}
        </span>
      </div>
    )
  }

  render() {
    const {
      currentUser,
      scrollingDisabled,
      tutorsProgress,
      tutorsApp,
      intl,
      onManageDisableScrolling,
      tutorsError
    } = this.props;

    const { requested, showTutorsApp } = this.state;

    const title = intl.formatMessage({ id: 'ProfileTutorsAppPage.title' });

    const isTutor = currentUser &&
      currentUser.attributes.profile.privateData.userType && 
      (currentUser.attributes.profile.privateData.userType).toLowerCase() === 'tutor';

    const readyToStart = !tutorsProgress && (!tutorsApp || tutorsApp && !tutorsApp.tutor) && requested;
    const hasApp = !tutorsProgress && tutorsApp && tutorsApp.tutor && requested;
    const serverError = tutorsError && tutorsError != 404;

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="ProfileTutorsAppPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav selectedPageName="ProfileTutorsAppPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSettingsSideNav currentTab="ProfileTutorsAppPage" />
          <LayoutWrapperMain>
            <div className={css.content}>
              <h1 className={css.title}>
                <FormattedMessage id="ProfileTutorsAppPage.heading" />
              </h1>

              {tutorsProgress && (
                <div className={css.spinner}>
                  <IconSpinner/>
                </div>
              )}

              {!serverError && !tutorsProgress && this.renderStatus()}

              {!serverError && readyToStart && (
                <div className={css.readyToStart}>
                  <PrimaryButton
                    onClick={() => this.goToCreate()}
                  >
                    <FormattedMessage id="ProfileTutorsAppPage.buttonCreate"/>
                  </PrimaryButton>
                </div>
              )}

              {!serverError && hasApp && (
                <div className={css.hasApp}>
                  <SecondaryButton
                    onClick={() => this.setState({showTutorsApp: true})}
                  >
                    <FormattedMessage id="ProfileTutorsAppPage.buttonShow"/>
                  </SecondaryButton>

                  <PrimaryButton
                  onClick={() => this.goToEdit()}
                  >
                  <FormattedMessage id="ProfileTutorsAppPage.buttonEdit"/>
                  </PrimaryButton>
                </div>
              )}

              {serverError && (
                <h3 className={css.error}>
                  <FormattedMessage id="ProfileTutorsAppPage.error"/>
                </h3>
              )}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>

        {!tutorsProgress && tutorsApp && (
          <Modal
            id="ProfileTutorsAppPage.addNewAnnouncement"
            isOpen={showTutorsApp}
            onClose={() => this.handleClose()}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <TutorsAppForm
              formId="tutorsAppFormPreview"
              initialValues={tutorsApp.tutor}
              isPreview={true}
              attachments={[]}
              initialAttachments={tutorsApp.tutor_application_attachment || []}
            />
          </Modal>
        )}
      </Page>
    );
  }
}

ProfileTutorsAppPageComponent.defaultProps = {
  currentUser: null,
};

const { bool, func } = PropTypes;

ProfileTutorsAppPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  onGetTutorsApp: func,

  location: shape({
    search: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, bonus } = state.user;
  const {
    tutorsApp,
    tutorsProgress,
    tutorsError
  } = state.ProfileTutorsAppPage;

  return {
    tutorsApp,
    tutorsProgress,
    tutorsError,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onGetTutorsApp: id => dispatch(getTutorsApp(id))
});

const ProfileTutorsAppPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ProfileTutorsAppPageComponent);

export default ProfileTutorsAppPage;
