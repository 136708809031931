import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  BlogPostEditor,
} from '../../components';
import { StaticPage, TopbarContainer } from '../index';
import css from './BlogPostPage.css';
import {
  getSeparatePost,
  updatePost,
  deletePost,
  resetUpdateData,
  resetDeletingData,
} from '../BlogPage/BlogPage.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';
import NoImage from '../../assets/noimage.png';

const BlogPostPageComponent = props => {
  const {
    blogSeparatePost,
    isSeparatePostLoading,
    separatePostError,
    onGetSeparatePost,
    match,
    isAuthenticated,
    currentUser,
    onManageDisableScrolling,
    onUpdateBlogPost,
    onDeleteBlogPost,
    history,
    isPostUpdatingSuccess,
    postUpdatingError,
    onResetUpdateData,
    isPostDeletingSuccess,
    postDeletingError,
    onResetDeletingData,
  } = props;

  const { image_file_name: image, description, id, title } = blogSeparatePost;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditPostModalOpen, setEditPostModalOpen] = useState(false);

  useEffect(() => {
    onGetSeparatePost(match.params.postId);
  }, []);

  useEffect(() => {
    if (isPostUpdatingSuccess) {
      setEditPostModalOpen(false)
    }
  }, [isPostUpdatingSuccess])

  useEffect(() => {
    if (isPostDeletingSuccess) {
      setModalOpen(false);
      history.push(`/blog`);
    }
  }, [isPostDeletingSuccess]);

  const hasImage = image => {
    if (image) {
      if (image.includes('/missing.png')) {
        return NoImage;
      } else {
        return image;
      }
    } else {
      return NoImage;
    }
  };

  const handleDeletePostBtn = () => {
    setModalOpen(true);
  };

  const handleEditPostBtn = () => {
    setEditPostModalOpen(true);
  };

  const handleConfirmButton = e => {
    e.persist();
    onResetDeletingData()
    const confirm = e.currentTarget.value;
    if (confirm === 'true') {
      onDeleteBlogPost(id);
    } else {
      setModalOpen(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    onResetDeletingData();
  };
  const handleEditPostModalClose = () => {
    setEditPostModalOpen(false);
    onResetUpdateData()
  };

  const clickSavePost = data => {
    onResetUpdateData()
    onUpdateBlogPost(id, data);
  };

  const loadingMsg = isSeparatePostLoading && (
    <div>
      <FormattedMessage id="BlogPostPage.loadingSeparatePostMessage" />
    </div>
  );

  const errorMsg = separatePostError && !isSeparatePostLoading && (
    <div>
      <FormattedMessage id="BlogPostPage.loadingSeparatePostsErrorMessage" />
    </div>
  );

  const userType =
    isAuthenticated &&
    currentUser &&
    currentUser.attributes.profile.protectedData.userType.toLowerCase();

  const editBtns = userType === 'superadmin' && (
    <div className={css.editBtnsWrap}>
      <button onClick={handleEditPostBtn} className={css.editButton}>
        <FormattedMessage id="AnnouncementsPage.editBtnText" />
      </button>
      <button onClick={handleDeletePostBtn} className={css.deleteButton}>
        <FormattedMessage id="AnnouncementsPage.deleteBtnText" />
      </button>
    </div>
  );

  return (
    <StaticPage
      title="Blog"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BlogPostPage',
        description: 'Blog post',
        name: 'Blog post',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>
            {!isSeparatePostLoading && !separatePostError && (
              <>
                {editBtns}
                <Modal
                  id="BlogPostPage.confirmDelete"
                  isOpen={isModalOpen}
                  onClose={handleModalClose}
                  onManageDisableScrolling={onManageDisableScrolling}
                >
                  <div className={css.confirmDeleteWrap}>
                    <span className={css.confirmTitle}>
                      <FormattedMessage id="BlogPostPage.confirmDeletePostMessage" />
                    </span>
                    <div className={css.confirmDeleteBtnsWrap}>
                      <button
                        value={true}
                        className={css.deleteButton}
                        onClick={handleConfirmButton}
                      >
                        <FormattedMessage id="ConfirmModal.yesBtn" />
                      </button>
                      <button
                        value={false}
                        className={css.editButton}
                        onClick={handleConfirmButton}
                      >
                        <FormattedMessage id="ConfirmModal.noBtn" />
                      </button>
                    </div>
                    {postDeletingError ? (
                      <p className={css.errorMesssage}>
                        <FormattedMessage id="ConfirmModal.errorDeleting" />
                      </p>
                    ) : null}
                  </div>
                </Modal>
                <Modal
                  id="BlogPostPage.editPost"
                  isOpen={isEditPostModalOpen}
                  onClose={handleEditPostModalClose}
                  onManageDisableScrolling={onManageDisableScrolling}
                >
                  <BlogPostEditor
                    editorTitle="BlogPostEditor.editTitle"
                    edit={true}
                    makeRequestFunction={clickSavePost}
                    blogSeparatePost={blogSeparatePost}
                    postUpdatingError={postUpdatingError}
                  />
                </Modal>
                <img alt="blog image" src={hasImage(image)} className={css.coverImage} />
                <h1 className={css.pageTitle}>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
              </>
            )}
            {loadingMsg}
            {errorMsg}
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const {
    blogSeparatePost,
    isSeparatePostLoading,
    separatePostError,
    isPostUpdatingSuccess,
    postUpdatingError,
    isPostDeletingSuccess,
    postDeletingError,
  } = state.BlogPage;
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    blogSeparatePost,
    isSeparatePostLoading,
    separatePostError,
    isAuthenticated,
    currentUser,
    isPostUpdatingSuccess,
    postUpdatingError,
    isPostDeletingSuccess,
    postDeletingError,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetSeparatePost: postId => {
    dispatch(getSeparatePost(postId));
  },
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateBlogPost: (id, data) => {
    dispatch(updatePost(id, data));
  },
  onDeleteBlogPost: (id, data) => {
    dispatch(deletePost(id, data));
  },
  onResetUpdateData: () => {
    dispatch(resetUpdateData());
  },
  onResetDeletingData: () => {
    dispatch(resetDeletingData());
  }
});

const BlogPostPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BlogPostPageComponent);

export default BlogPostPage;
