import React, {useState, useEffect, useRef} from 'react';
import { PrimaryButton, SecondaryButton, IconSpinner } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './TutorsAppPage.css';

const ConsentSection = ({bgConsent, bgConsentProgress, bgConsentError, onComplete, onDecline, onLoad}) => {
  useEffect(() => {
    if (typeof window !== undefined){
      window.scrollBy(0, 0);
    }
    onLoad();
  }, [])

  const handleComplete = () => {
    onComplete();
  }

  return (
    <div
      className={css.agreementWrapper}
    >
      <div className={css.titleWrapper}>
        <h2 className={css.pageTitle}>
          <FormattedMessage id="TutorsAppPage.consentTitle"/>
        </h2>
      </div>

      {bgConsentProgress && (
        <div className={css.spinner}>
          <IconSpinner/>
        </div>
      )}

      {!bgConsentProgress && bgConsent && (
        <>
          <div className={css.textWrapper}>
            <div
              className={css.text}
              dangerouslySetInnerHTML={{ __html: bgConsent.text }}
            />
          </div>

          <div className={css.buttonsWrapper}>
            <PrimaryButton
              onClick={() => handleComplete()}
            >
              <FormattedMessage id="TutorsAppPage.agreementButtonTitleAgree"/>
            </PrimaryButton>

            <SecondaryButton
              onClick={() => onDecline()}
            >
              <FormattedMessage id="TutorsAppPage.agreementButtonTitleDecline"/>
            </SecondaryButton>
          </div>
        </>
      )}
    </div>
  )
};

export default ConsentSection;
