import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { compose } from 'redux';
import css from './ExperienceEditor.css';
import { FormattedMessage } from '../../util/reactIntl';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import NoImage from '../../assets/noimage.png';

const hasImage = image => {
  if (image) {
    if (image.includes('/missing.png')) {
      return NoImage;
    } else {
      return image;
    }
  } else {
    return NoImage;
  }
};

class ExperienceEditorComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      hasFile: false,
      image: null,
      imageURL: null,
      descriptionEditorState: EditorState.createEmpty(),
      count: 0,
    };
  }

  handleSavePost = () => {
    const { descriptionEditorState, title, image } = this.state;
    const { makeRequestFunction } = this.props;
    const formData = new FormData();

    if (image) formData.append('image', image);

    formData.append('title', title);
    formData.append(
      'description',
      draftToHtml(convertToRaw(descriptionEditorState.getCurrentContent()))
    );

    makeRequestFunction(formData);
    // this.setState(() => {
    //   return {
    //     title: '',
    //     image: null,
    //     imageURL: null,
    //     descriptionEditorState: EditorState.createEmpty(),
    //   };
    // });
  };

  onDescrEditorStateChange = shortState => {
    this.setState(() => {
      return { descriptionEditorState: shortState };
    });
  };

  handleTitle = e => {
    e.persist();

    this.setState(() => {
      return { title: e.target.value };
    });
  };

  handleImageChange = e => {
    this.setState({
      imageURL: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
      hasFile: true,
    });
  };

  clickChange = () => {
    this.setState({
      hasFile: !this.state.hasFile,
    });
  };

  render() {
    if (typeof window === 'undefined' && typeof document === 'undefined') {
      return null;
    }

    const { descriptionEditorState, title, image, imageURL, count } = this.state;
    const { edit, editingError, createError, item } = this.props;

    const { Editor } = require('react-draft-wysiwyg'); //eslint-disable-line global-require
    const htmlToDraft = require('html-to-draftjs').default; //eslint-disable-line global-require

    if (item && count === 0) {
      const { image_file_name, description, title } = item;

      const contentDescription = description && htmlToDraft(description);

      if (contentDescription) {
        const contentStateDescription = ContentState.createFromBlockArray(
          contentDescription.contentBlocks
        );
        const editorStateDescription = EditorState.createWithContent(contentStateDescription);

        this.setState({
          title: title,
          hasFile: image_file_name.includes('/missing.png') ? false : true,
          imageURL: hasImage(image_file_name),
          descriptionEditorState: editorStateDescription,
          count: 1,
        });
      }
    }

    const editorStyle = {
      border: '1px solid black',
      padding: '5px',
      borderRadius: '2px',
      height: '200px',
      width: '100%',
    };

    return (
      <div className={css.modalPayoutDetailsWrapper}>
        <h1 className={css.modalTitle}>
          <FormattedMessage id={this.props.editorTitle} />
        </h1>
        <label className={css.label} htmlFor="postTitle">
          Title *
        </label>
        <input type="text" id="postTitle" value={title} onChange={this.handleTitle} required />
        <label className={css.label} htmlFor="description">
          Description *
        </label>
        <Editor
          editorState={descriptionEditorState}
          onEditorStateChange={this.onDescrEditorStateChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          wrapperStyle={{}}
          editorStyle={editorStyle}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'image',
              'remove',
              'history',
            ],
            fontFamily: {
              options: [
                `sofiapro, Helvetica, Arial, sans-serif`,
                'Arial',
                'Georgia',
                'Impact',
                'Tahoma',
                'Times New Roman',
                'Verdana',
              ],
            },
          }}
        />
        <label className={css.label} htmlFor="image">
          Image *
        </label>

        {this.state.hasFile ? (
          <div className={css.prevImageContainer}>
            {imageURL && <img className={css.image} src={imageURL} alt="img" />}
            <button onClick={this.clickChange} className={css.changeImgButton}>
              Change image
            </button>
          </div>
        ) : (
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={this.handleImageChange}
            required
          />
        )}
        <button
          onClick={this.handleSavePost}
          className={!image && !this.state.hasFile ? css.savePostBtnDisabled : css.savePostBtn}
          disabled={!image && !this.state.hasFile}
        >
          {edit ? 'Save change' : 'Save'}
        </button>
        {editingError ? (
          <p className={css.editError}>
            <FormattedMessage id="BlogPostEditor.errorEditPost" />
          </p>
        ) : null}
        {createError ? (
          <p className={css.editError}>
            <FormattedMessage id="BlogPostEditor.errorCreatePost" />
          </p>
        ) : null}
      </div>
    );
  }
}

const ExperienceEditor = compose()(ExperienceEditorComponent);

export default ExperienceEditor;
