import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import css from './AgreementsEditor.css';
import { FormattedMessage } from '../../util/reactIntl';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class AgreementsEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      text: EditorState.createEmpty(),
    };
  }

  componentDidMount(){
    this.handleData();
  }

  componentDidUpdate(prevProps){
    const {data} = this.props;

    if (data !== prevProps.data){
      this.handleData();
    }
  }

  clearData(){
    this.setState({
      title: '',
      text: EditorState.createEmpty()
    });
  }

  handleData(){
    const {data} = this.props;

    if (!data) {
      this.clearData();
      return;
    }
    
    const htmlToDraft = require('html-to-draftjs').default; //eslint-disable-line global-require

    const {title, text} = data;

    const editor = text && htmlToDraft(text);
    const editorContent = ContentState.createFromBlockArray(editor.contentBlocks);
    const editorState = EditorState.createWithContent(editorContent);

    this.setState({
      title: title,
      text: editorState
    });
  }

  handleSave(){
    if (!this.checkData()){
      return;
    }

    const { text, title } = this.state;
    const { onSubmit, contentOnly } = this.props;

    const data = {text: draftToHtml(convertToRaw(text.getCurrentContent()))};

    if (!contentOnly){
      data.title = title;
    }

    onSubmit(data);

    this.clearData();
  };

  checkData(){
    const { contentOnly } = this.props;
    const { text, title } = this.state;
    return (!!title || contentOnly) && text.getCurrentContent().hasText()
  }

  onContentChange(text){
    this.setState(() => {
      return { text };
    });
  };

  handleTitle(e){
    e.persist();

    this.setState(() => {
      return { title: e.target.value };
    });
  };

  render() {
    if (typeof window === 'undefined' && typeof document === 'undefined') {
      return null;
    }

    const { text, title } = this.state;
    const { data, contentOnly } = this.props;

    const edit = !!data;

    const { Editor } = require('react-draft-wysiwyg'); //eslint-disable-line global-require
    const sectionTitle = edit ?
                        <FormattedMessage id="AgreementsEditor.titleEdit" /> :
                        <FormattedMessage id="AgreementsEditor.titleNew"/>

    const buttonTitle = edit ?
                        <FormattedMessage id="AgreementsEditor.titleButtonEdit" /> :
                        <FormattedMessage id="AgreementsEditor.titleButtonNew"/>

    const editorStyle = {
      border: '1px solid black',
      padding: '5px',
      borderRadius: '2px',
      height: '200px',
      width: '100%',
    };

    return (
      <div className={css.modalPayoutDetailsWrapper}>
        {!contentOnly && (
          <h4 className={css.modalTitle}>
            {sectionTitle}
          </h4>
        )}
        {!contentOnly && (
          <>
            <label className={css.label} htmlFor="agreementsTitle">
              Title *
            </label>
            <input
              type="text"
              id="agreementsTitle"
              value={title}
              onChange={e => this.handleTitle(e)}
              required
            />
            <label className={css.label}>
              Content *
            </label>
          </>
        )}
        <Editor
          editorState={text}
          onEditorStateChange={text => this.onContentChange(text)}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          wrapperStyle={{}}
          editorStyle={editorStyle}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'link',
              'remove',
              'history',
            ],
            fontFamily: {
              options: [
                `sofiapro, Helvetica, Arial, sans-serif`,
                'Arial',
                'Georgia',
                'Impact',
                'Tahoma',
                'Times New Roman',
                'Verdana',
              ],
            },
          }}
        />
       
        <button
          onClick={() => this.handleSave()}
          // className={css.savePostBtn}
          // disabled={!image && !this.state.hasFile}
        >
          {buttonTitle}
        </button>
      </div>
    );
  }
}

export default AgreementsEditor;
