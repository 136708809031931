import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import NoImage from '../../assets/noimage.png';

import css from './BlogItem.css';
import { InlineTextButton } from '..';

const BlogItem = ({ item, history }) => {
  const { image_file_name: image, title, id, short_description: shortDescription } = item;

  const hasImage = image => {
    if (image) {
      if (image.includes('/missing.png')) {
        return NoImage;
      } else {
        return image;
      }
    } else {
      return NoImage;
    }
  };

  return (
    <div className={css.blogItemWrap}>
      <div className={css.imageContainer}>
        <img className={css.image} alt="blog image" src={hasImage(image)} width="100%" />
      </div>
      <div className={css.blogItemTitle}>{title}</div>
      <div dangerouslySetInnerHTML={{ __html: shortDescription }}></div>
      <InlineTextButton
        className={css.blogItemReadMoreBtn}
        onClick={() => history.push(`/blog/${id}`)}
      >
        <FormattedMessage id="BlogPage.readMoreBtnText" />
      </InlineTextButton>
    </div>
  );
};

export default withRouter(BlogItem);
