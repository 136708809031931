import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { manageDisableScrolling } from '../../ducks/UI.duck';
// import 'react-tabs/style/react-tabs.css';
import {
  Page,
  LayoutWrapperTopbar,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  AdminBonusesComponent,
  AdminProprofsComponent,
  AdminCancellationComponent,
  AdminAgreementsComponent,
  AdminBGConsentComponent,
  AdminTutorsApps
  // Tabs
} from '../../components';
import { TopbarContainer } from '../index';
import css from './AdminPanelPage.css';
import { NamedRedirect } from '../../components';
import { isScrollingDisabled } from '../../ducks/UI.duck';

const AdminPanelPage = ({
  currentUser,
  isAuthenticated,
  scrollingDisabled,
  onManageDisableScrolling,
}) => {
  const userType =
    isAuthenticated &&
    currentUser &&
    currentUser.attributes.profile.protectedData.userType.toLowerCase();

  if (!isAuthenticated) {
    return <NamedRedirect name="LandingPage" />;
  } else if (currentUser && userType !== 'superadmin') {
    return <NamedRedirect name="LandingPage" />;
  }

  return (
    <Page
      title="Admin"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: 'Admin page',
      }}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <h2 style={{ textAlign: 'center' }}>Admin</h2>
          <Tabs>
            <TabList>
              <Tab>
                <p className={css.tabTitle}>Tutor Application</p>
              </Tab>
              <Tab>
                <p className={css.tabTitle}>Consent for BC</p>
              </Tab>
              <Tab>
                <p className={css.tabTitle}>Agreements</p>
              </Tab>
              <Tab>
                <p className={css.tabTitle}>Bonuses</p>
              </Tab>
              <Tab>
                <p className={css.tabTitle}>ProProfs and Subjects</p>
              </Tab>
              <Tab>
                <p className={css.tabTitle}>Transactions</p>
              </Tab>
            </TabList>

            <TabPanel>
              <AdminTutorsApps onManageDisableScrolling={onManageDisableScrolling} />
            </TabPanel>

            <TabPanel>
              <AdminBGConsentComponent onManageDisableScrolling={onManageDisableScrolling} />
            </TabPanel>

            <TabPanel>
              <AdminAgreementsComponent onManageDisableScrolling={onManageDisableScrolling} />
            </TabPanel>

            <TabPanel>
              <AdminBonusesComponent />
            </TabPanel>
            <TabPanel>
              <AdminProprofsComponent />
            </TabPanel>
            <TabPanel>
              <AdminCancellationComponent />
            </TabPanel>
          </Tabs>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    currentUser,
    isAuthenticated,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(AdminPanelPage);
