import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { checkCaptcha } from '../../ducks/contactUs.duck';
import { injectIntl } from '../../util/reactIntl';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

class CaptchaComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    //loadReCaptcha(RECAPTCHA_SITE_KEY;
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
    document.body.appendChild(script)
  }

  verifyCallback = (recaptchaToken) => {
    this.props.checkReCaptcha(recaptchaToken);
  }

  render() {
    return (
      <ReCaptcha
        sitekey={RECAPTCHA_SITE_KEY}
        action='contactForm'
        verifyCallback={this.verifyCallback}
      />
    );
  };
};

 const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({
  checkReCaptcha: (token) => dispatch(checkCaptcha(token))
});

const Captcha = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl
)(CaptchaComponent);

export default Captcha;
