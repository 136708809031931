import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { func, string, bool } from 'prop-types';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { BackgroundCheckModal } from '..';
import { LISTING_STATE_PUBLISHED } from '../../util/types';
import debounce from 'lodash/debounce';

function BackgroundCheckReminder(props){
    const {
      onManageDisableScrolling,
      currentUser,
      currentUserListing,
      stripeAccount
    } = props;

    const reminderStorageName = 'backgroundCheckIsRemided';
    const modalName = 'Page.remindBackgroundCheck';
    const showModalDelay = 5000;

    const [isVisible, setIsVisible] = useState(false);

    const debouncedShow = debounce(() => {setIsVisible(true)}, showModalDelay);

    useEffect(() => {
      // get stored reminding value
      // debounce modal first showing
    
      if (checkForRemind()){
        debouncedShow();
      }

      function checkForRemind(){
        const isTutor = currentUser &&
                        currentUser.attributes.profile.privateData.userType && 
                        (currentUser.attributes.profile.privateData.userType).toLowerCase() === 'tutor';

        const isNotSended = currentUser &&
                            !currentUser.attributes.profile.privateData.sendBackgroundCheck;

        const hasStripe = stripeAccount &&
                          stripeAccount.attributes &&
                          stripeAccount.attributes.stripeAccountData &&
                          stripeAccount.attributes.stripeAccountData.payouts_enabled;

        const isPublished = currentUserListing &&
                            currentUserListing.id &&
                            currentUserListing.attributes.state === LISTING_STATE_PUBLISHED;

        const isNotChecked = currentUserListing &&
                              currentUserListing.id &&
                              !currentUserListing.attributes.publicData.backgroundCheck

        const isReminded = !!window ? window.sessionStorage.getItem(reminderStorageName) : true;

        return !isVisible && !!isTutor && !!hasStripe && !!isNotSended && !!isPublished && !!isNotChecked && !isReminded;
      }
    }, [currentUser, currentUserListing, stripeAccount]);

    const manageRemindMeLater = () => {
      if (!window) {
        return;
      }
      
      window.sessionStorage.setItem(reminderStorageName, true);
    }

    const onClose = () => {
      manageRemindMeLater();
      onManageDisableScrolling(modalName, false);
      setIsVisible(false);
    }

    return (
      <BackgroundCheckModal
        onManageDisableScrolling={onManageDisableScrolling}
        modalName={modalName}
        remindMeLater
        showModal={isVisible}
        onCloseModal={onClose}
        cancelButtonTitle={'BackgroundCheckModal.remindMeLaterButton'}
      />
    );
}

BackgroundCheckReminder.propTypes = {
  onManageDisableScrolling: func.isRequired
};

const mapStateToProps = state => {
  const { currentUser, currentUserListing } = state.user;

  const {
    stripeAccount,
  } = state.stripeConnectAccount;


  return {
    stripeAccount,
    currentUser,
    currentUserListing
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundCheckReminder);
