import React, { useState, useEffect } from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getUsersReviews } from '../../ducks/Reviews.duck';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionSearchSubject,
  SectionReviews,
  SectionOnlineTeaching,
  SectionBecomingTutor,
  SectionSubscribe,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';

import facebookImage from '../../assets/wisepalFacebook-1200x630.jpg';
import twitterImage from '../../assets/wisepalTwitter-600x314.jpg';
import css from './MSTutoringPage.css';

import middle from '../MSTutoringPage/images/middle-school.jpg';
import middleTwo from '../MSTutoringPage/images/middle-school-2.jpg';
import mOnline from '../MSTutoringPage/images/middle-school-online.jpg';
import mTutoring from '../MSTutoringPage/images/middle-school-tutoring.jpg';
import msTutoring from '../MSTutoringPage/images/ms-tutoring.jpg';
import onlineTutor from '../MSTutoringPage/images/online-tutoring.jpg';
import onlineStudent from '../MSTutoringPage/images/online-tutoring-student.jpg';
import wisepalComputer from '../../assets/seoteric-pages/high-school-edits/wisepal-computer.png';


export const MSTutoringPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    reviews,
    currentUserListingFetched,
    onGetUsersReviews,
    currentUser,
    isAuthenticated,
  } = props;

  const [recievedReviews, setRecieveReviews] = useState(reviews);

  useEffect(() => {
    onGetUsersReviews()
  }, []);

  useEffect(() => {
    setRecieveReviews(reviews);
  }, [reviews]);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'MSTutoring.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'MSTutoring.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
        <div class="container">
            <div class="row my-5">
                <div class="col-md-12 text-center">
                    <h1>Middle School Tutoring</h1>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-12 justify-content-center text-center">
                    <h2>Online Tutoring for Middle School Students</h2>
                    <p class="my-3 intro-content">Wisepal offers industry-best middle school tutoring online without requiring students or learners to commit long-term. Decide what you want to pay for and learn at your own pace.</p><br />
                    <a href="https://wisepal.co/signup">
                    <button type="button" class="btn btn-prpl btn-lg rounded-pill my-3 hover-orng">Sign Up Today!</button>
                </a>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-6 jusify-content-center">
                    <p>Our tutoring sessions are some of the most affordable across all platforms in the industry without ever compromising quality education. Students receive the best from our top-of-the-line teachers and mentors!</p>
                    <p>We offer tutorials for all middle school subjects and our tutors are available whenever you are.</p>
                    <p>That means that you no longer need to adjust your schedule just to study with us. Take your pick, day, night, weekends — it’s your choice.</p>
                    <p>We also offer live online tutoring that allows screen-sharing sessions meant for in-depth discussions. This allows your child to focus and engage more with our tutors and mentors that foster a better understanding of the subject matters discussed. Students make real progress in their lessons and learning at Wisepal.</p>    
                </div>
                <div class="col-md-6 justify-content-center">
                  <img src={middleTwo} alt="middle school tutors online" />
                </div>
            </div>
            <div class="row my-5">
              <div class="col-md-6 justify-content-center">
                  <img src={middle} alt="student being tutored" />
                </div>
                <div class="col-md-6 justify-content-center">
                    <h2>Available Middle School Subjects</h2>
                    <p class="my-3">Wisepal offers middle school tutoring online for a number of subjects with lesson plans, including math and reading. While most online tutoring platforms today focus only on math, we offer a wide variety of subjects.</p>
                    <p>If there’s a subject that your child is having problems with, browse and book a session with our tutors and you’ll be on your way to progress. We’re excited to have you join us!</p>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-6 justify-content-center">
                    <h2>Help Middle Schoolers Progress Their Learning</h2>
                    <p class="my-3">Our main goal is to help middle schoolers progress their learning at a comfortable pace. Our tutors are flexible and accommodating to the varying needs of each student.</p>
                    <p>By far, the students that sign up for tutoring lessons in our platform:</p>
                    <ol class="my-3">
                        <li>Have a significantly improved understanding of the subject and its varying concepts</li>
                        <li>Garner better grades</li>
                        <li>Have greater confidence in their own abilities</li>
                        <li>Exhibit improved study habits, organizational skills, and time management skills</li>
                    </ol>
                </div>
                <div class="col-md-6 justify-content-center">
                  <img src={mTutoring} alt="middle school student" />
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-6 justify-content-center">
                  <img src={onlineStudent}  alt="wisepal features" />
                </div>
                <div class="col-md-6 justify-content-center">
                    <h2>Wisepal Features & Benefits</h2>
                    <ol class="my-3">
                        <li>Tutors are available based on your schedule</li>
                        <li>In-person and online tutoring are both available</li>
                        <li>We only select personal and caring tutors who will make a connection and significant positive impact on our students</li>
                        <li>We have affordable rates</li>
                        <li>Recorded sessions available </li>
                        <li>Our online learning tools include whiteboard, screen-sharing, online quizzes, and more</li>
                        <li>One-on-one tutoring or group tutoring sessions are available</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row py-5 btn-prpl">
                <div class="col-md-12 my-5 text-center">
                    <h2>Book a Lesson with an Expert Tutor!</h2>
                    <a href="https://wisepal.co/signup">
                    <button type="button" class="btn btn-orng btn-lg rounded-pill my-3">Sign Up Today!</button>
                </a>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row my-5">
                <div class="col-md-6 justify-content-center">
                    <h2>Personalized Lesson Plan</h2>
                    <p class="my-3">Our tutors personalize lesson plans based on where the student is currently at in their studies. Ensuring that middle school students get the assistance they need with difficult areas is one of the main goals of each tutoring session.</p>
                    <p>Our tutors will gain insight from parents as needed for particular lesson plans and gauge student understanding of these concepts through practical application in the form of exercises, practice questions, and homework studies.</p>
                    <p class="my-3">As students begin to absorb and understand these concepts, their confidence will improve in their skills as they are able to solve problems on their own. At Wisepal, our lesson plans are created with the student in mind. Our personalized lesson plans teach students the material they need to understand through guided practices, collaborative processes, and individual practice. Tutors will evaluate each student’s progress through quick wrap-up evaluations.</p>
                    <p>If more time is needed on particular concepts, our tutors will recommend a solution.</p>
                </div>
                <div class="col-md-6 justify-content-center"><img src={msTutoring} alt="lessn plan" /></div>
            </div>
            <div class="row my-5">
                <div class="col-md-6 justify-content-center"><img src={mOnline} alt="selecting tutors"/></div>
                <div class="col-md-6 justify-content-center">
                    <h2>How We Select Our Tutors</h2>
                    <p class="my-3">Selecting tutors is a rigorous process but one that we understand cannot be taken for granted. Tutors are our front-liners who will foster the learning atmosphere that we have envisioned. </p>
                    <p>We carefully review all academic credentials, including certifications, grades, and test scores. Then, all applicant tutors are then required to obtain qualifying scores in timed subject assessment tests.</p>
                    <p>Lastly, a background check is performed upon the parents request.</p>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-6 justify-content-center">
                    <h2>How It Works</h2>
                    <ol class="my-3">
                        <li>Create an account</li>
                        <li>Browse our tutors by subject and grade level</li>
                        <li>Reach out to tutors, ask questions, and hire the one that best fits your needs.</li>
                    </ol>
                </div>
                <div class="col-md-6 justify-content-center"><img src={wisepalComputer} alt="computer icon" /></div>
            </div>
            <div class="row my-5">
            <div class="col-md-6 justify-content-center">
                  <img src={onlineTutor}  alt="Middle school tutor" />
                </div>                
                <div class="col-md-6 justify-content-center">
                    <h2>How Can We Help Your Child Succeed? </h2>
                    <p class="my-3">At Wisepal, we are committed to providing exceptional customer service and quality educational products and services for students. Our experienced team of middle school educators will assist you in finding the best solution for your child’s educational needs.</p>
                    <p>Search the list of middle school subjects available, browse our lineup of tutors, and book the tutor that is right for you. </p>
                    <p class="my-3">Feel free to pick the date and time of the tutoring session based on your own schedule!</p>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-12">
                    <h2 class="text-center my-3">FAQ</h2>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button btn-prpl" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Who Should Use Online Tutoring Services?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <span>Everyone can take advantage of online tutoring services but most especially students who are struggling in classes or falling behind in their understanding of one or more subject matter and its varying concepts. In addition, tutors can also help if you would like to learn new skills including art, music, computer science, and more.</span>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button btn-prpl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Are Middle School Tutors Online Worth It?
                            </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <span>If it's for educational needs, no matter the tier, it will always be worth it. Besides improvement in their grades, it's also about your child learning good study habits that they may utilize throughout their academic journey.</span>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button btn-prpl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                How Much Does a Tutor for a Middle School Student Cost?
                            </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <span>Our tutors set their own prices for their tutoring sessions and this will be clearly displayed. Prices will vary based on their experience and education level.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

MSTutoringPageComponent.defaultProps = {
  currentUserListing: null,
  currentUser: null,
  currentUserListingFetched: false,
  reviews: [],
};

MSTutoringPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  reviews: arrayOf(propTypes.review),
  isAuthenticated: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const {
    reviews,
  } = state.Reviews;

  return {
    reviews,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetUsersReviews: () => dispatch(getUsersReviews()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
// 
// See: https://github.com/ReactTraining/react-router/issues/4671
const MSTutoringPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MSTutoringPageComponent);

export default MSTutoringPage;