import React, { useState, useEffect } from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getUsersReviews } from '../../ducks/Reviews.duck';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionSearchSubject,
  SectionReviews,
  SectionOnlineTeaching,
  SectionBecomingTutor,
  SectionSubscribe,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';

import facebookImage from '../../assets/wisepalFacebook-1200x630.jpg';
import twitterImage from '../../assets/wisepalTwitter-600x314.jpg';
import css from './NYTutoringPage.css';

import how from '../NYPage/images/how-it-works.jpg';
import elementary from '../NYPage/images/elementary-image.jpg';
import middle from '../NYPage/images/middle-image.jpg';
import high from '../NYPage/images/high-image.jpg';
import college from '../NYPage/images/college-image.jpg';
import tutoring from '../NYPage/images/tutoring-image.jpg';

export const NYTutoringPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    reviews,
    currentUserListingFetched,
    onGetUsersReviews,
    currentUser,
    isAuthenticated,
  } = props;

  const [recievedReviews, setRecieveReviews] = useState(reviews);

  useEffect(() => {
    onGetUsersReviews()
  }, []);

  useEffect(() => {
    setRecieveReviews(reviews);
  }, [reviews]);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'NYTutoring.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'NYTutoring.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
        <div class="container">
            <div class="row my-5">
                <div class="col-md-12 text-center">
                    <h1>The Best Tutoring in NYC</h1>    
                    <p class="my-5">At Wisepal, our mission is to make learning accessible, convenient and useful, as well as provide instructors in NYC with a platform to share their invaluable knowledge. Our online marketplace connects students with qualified tutors in NYC through remote or in-person sessions. Wisepal covers various academic subjects on the K-12, university and graduate school levels.</p>
                    <a href="https://wisepal.co/signup" class="button-styles"><span class="px-5">Learn More</span></a>
                </div>
            </div>
        </div>
        <div class="container-fluid alt-panel">
          <div class="row py-5">
            <div class="col-md-6 text-center my-5"><img src={how} alt="Tutoring in NYC" /></div>
            <div class="col-md-6 my-5">
              <h2 class="text-center">How it Works</h2>
              <p class="my-3">The process for selecting New York City tutors is rigorous. We screen our tutor applicants by their submitted academic credentials, certifications, grades, and test scores. They are then required to obtain qualifying scores in timed subject assessment tests. After all of these, we run background checks if requested. Not only do we work with only highly-qualified NYC tutors, but we provide the online tools and functionality to have a great learning experience.</p>
                <ol>
                    <li>Create an account: Visit our website and sign up for a free account.</li>
                    <li>Browse our New York City tutors by subject and session type. Our platform incorporates a filtering system to help you locate a tutor in NYC suited for you.</li>
                    <li>Reach out to tutors: Once you find a suitable tutor, simply reach out and communicate with them to see if they're a good fit for you.</li>
                    <li>Book a session: Booking a tutoring session is easy on our platform. Select a day and time, make a payment, and enjoy your session.</li>
                </ol>
            </div>
          </div>
        </div>
        <div class="container">
            <div class="row my-5 justify-content-center row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">                
                <div class="col d-flex justify-content-center mt-5">
                    <div class="card card-style">
                        <img src={elementary} class="card-img-top rounded-circle my-3" alt="Elementary Tutoring in NYC" />
                        <div class="card-body d-flex flex-column text-center">
                            <h2 class="card-title">Elementary School Tutoring in NYC</h2>
                            <p class="card-text py-5 mt-auto">Our elementary tutors in NYC implement effective strategies to support all kinds of learners, encouraging them to achieve academic excellence through guided exercises that zero in on the areas where the learner needs the most help. We value each young learner and aim to always provide a positive learning experience for them so they may succeed through middle school, high school, and beyond.</p>
                            <a href="https://wisepal.co/elementary-tutors" class="button-styles mt-auto">Explore Elementary School Tutoring</a>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center mt-5">
                    <div class="card card-style">
                        <img src={middle} class="card-img-top my-3 rounded-circle" alt="Middle School Tutoring in NYC" />
                        <div class="card-body d-flex flex-column text-center">
                            <h2 class="card-title text-center">Middle School Tutoring in NYC</h2>
                            <p class="card-text py-5 mt-auto">Wisepal offers middle school tutoring in NYC for a number of subjects with lesson plans, including math and reading. While most tutoring platforms today focus only on math, we offer a wide variety of subjects. If there's a subject that your child is having problems with, browse and book a session with one of our NYC tutors and you'll be on your way to progress. We're excited to have you join us!</p>
                            <a href="https://wisepal.co/middle-school-tutoring-online" class="button-styles mt-auto">Explore Middle School Tutoring</a>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center mt-5">
                    <div class="card card-style">
                        <img src={high} class="card-img-top my-3 rounded-circle" alt="High School Tutoring in NYC" />
                        <div class="card-body d-flex flex-column text-center">
                            <h2 class="card-title">High School Tutoring in NYC</h2>
                            <p class="card-text py-5 mt-auto">Our high school tutoring in NYC offers tutoring for all high school subjects. We aim to help students stay on track with their classes. At Wisepal, we understand the importance of education and provide tools that complement your study goals. That is why our expert NYC tutors have different processes to help you understand the material efficiently and be fully prepared on exam days. To find a high school tutor in NYC, perform a subject search, then simply filter by niche subject, price range, and lesson type, then try an instructor that fits your preference. You don't have to commit or pay for expensive tutoring packages.</p>
                            <a href="https://wisepal.co/high-school-tutoring-online" class="button-styles mt-auto">Explore High School Tutoring</a>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-center mt-5">
                    <div class="card card-style">
                        <img src={college} class="card-img-top my-3 rounded-circle" alt="College Tutoring in NYC" />
                        <div class="card-body d-flex flex-column text-center">
                            <h2 class="card-title">College Tutoring in New York City</h2>
                            <p class="card-text py-5 mt-auto">Wisepal's NYC tutors have obtained higher academic degrees from universities all around the world, allowing for nearly limitless options for you to choose your tutors in the tri-state area. Our college tutoring services in NYC encompass numerous college courses which include Calculus, Chemistry, Biology, and Economics, among others. Feel free to contact the Wisepal team to learn more about the different subjects we offer for college tutoring in NYC.</p>
                            <a href="https://wisepal.co/college-tutoring/" class="button-styles mt-auto">Explore College Tutoring</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid alt-panel">
            <div class="row py-5 align-content-center">
                <div class="col-md-12 text-center">
                    <h2>Browse the list of NYC Tutors</h2>    
                    <p class="my-5 mx-5">Whether you seek assistance in Elementary, Middle or High School or you need help with studying or test preparation at the college-level, choose from a list of New York City tutors ready to assist you!</p>
                    <a href="https://wisepal.co/signup" class="button-styles"><span class="px-5">Learn More</span></a>
                </div>
            </div>
        </div>
        <div class="container bottom-panel">
          <div class="row my-5 py-5">
            <div class="col-md-6 my-5 order-last">
              <h2 class="text-center">Interested in becoming a New York City Tutor?</h2>
              <p class="my-3">Becoming a tutor in New York City is easy! Simply follow the below steps to get started today.</p>
              <ul class="mb-5">
                <li>Find new students - create a visible profile now so that students can find you!</li>
                <li>Earn on your terms - you can set your own hourly rates</li>
                <li>Work remotely or at a convenient location in NYC - You can teach at your home or from any NYC location</li>
                <li>Availability & Flexibility - Teach in any capacity, full-time or part-time. Check your books in your calendar.</li>
              </ul>
              <a href="https://wisepal.co/signup" class="button-styles"><span class="px-5 my-5 text-nowrap">Apply to Be a NY Tutor Today</span></a>
            </div>
            <div class="col-md-6 text-center my-5"><img src={tutoring} alt="Tutoring Service in NYC"/></div>
          </div>
        </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

NYTutoringPageComponent.defaultProps = {
  currentUserListing: null,
  currentUser: null,
  currentUserListingFetched: false,
  reviews: [],
};

NYTutoringPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  reviews: arrayOf(propTypes.review),
  isAuthenticated: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const {
    reviews,
  } = state.Reviews;

  return {
    reviews,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetUsersReviews: () => dispatch(getUsersReviews()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
// 
// See: https://github.com/ReactTraining/react-router/issues/4671
const NYTutoringPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(NYTutoringPageComponent);

export default NYTutoringPage;