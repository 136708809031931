import React, { Component } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { compose } from 'redux';
import css from './AboutEditor.css';
import { FormattedMessage } from '../../util/reactIntl';

class AboutEditorComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      descriptionEditorState: EditorState.createEmpty(),
      count: 0,
    };
  }

  handleSavePost = () => {
    const { descriptionEditorState, title } = this.state;
    const { makeRequestFunction } = this.props;

    const postData = {
      title: title,
      description: draftToHtml(convertToRaw(descriptionEditorState.getCurrentContent())),
    };
    makeRequestFunction(postData);
  };

  onDescrEditorStateChange = shortState => {
    this.setState(() => {
      return { descriptionEditorState: shortState };
    });
  };

  handleTitle = e => {
    e.persist();

    this.setState(() => {
      return { title: e.target.value };
    });
  };

  render() {
    if (typeof window === 'undefined' && typeof document === 'undefined') {
      return null;
    }

    const { descriptionEditorState, title, count } = this.state;
    const { createError, editingError, item } = this.props;

    const { Editor } = require('react-draft-wysiwyg'); //eslint-disable-line global-require
    const htmlToDraft = require('html-to-draftjs').default;

    if (item && count === 0) {
      const { description, title } = item;
      const contentDescription = description && htmlToDraft(description);

      if (contentDescription) {
        const contentStateDescription = ContentState.createFromBlockArray(
          contentDescription.contentBlocks
        );
        const editorStateDescription = EditorState.createWithContent(contentStateDescription);

        this.setState({
          title: title,
          descriptionEditorState: editorStateDescription,
          count: 1,
        });
      }
    }

    const editorStyle = {
      border: '1px solid black',
      padding: '5px',
      borderRadius: '2px',
      height: '200px',
      width: '100%',
    };

    return (
      <div className={css.modalPayoutDetailsWrapper}>
        <h1 className={css.modalTitle}>
          <FormattedMessage id={this.props.editorTitle} />
        </h1>
        <label className={css.label} htmlFor="postTitle">
          Title *
        </label>
        <input type="text" id="postTitle" value={title} onChange={this.handleTitle} required />
        <label className={css.label} htmlFor="description">
          Description *
        </label>
        <Editor
          editorState={descriptionEditorState}
          onEditorStateChange={this.onDescrEditorStateChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          wrapperStyle={{}}
          editorStyle={editorStyle}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'image',
              'remove',
              'history',
            ],
            fontFamily: {
              options: [
                `sofiapro, Helvetica, Arial, sans-serif`,
                'Arial',
                'Georgia',
                'Impact',
                'Tahoma',
                'Times New Roman',
                'Verdana',
              ],
            },
          }}
        />
        <button onClick={this.handleSavePost} className={css.savePostBtn}>
          Save
        </button>
        {createError ? (
          <p className={css.editError}>
            <FormattedMessage id="FaqEditor.errorCreate" />
          </p>
        ) : null}
        {editingError ? (
          <p className={css.editError}>
            <FormattedMessage id="FaqEditor.errorEdite" />
          </p>
        ) : null}
      </div>
    );
  }
}


const AboutEditor = compose()(AboutEditorComponent);

export default AboutEditor;
