import React, { Component, useState, useEffect } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, Button, ReviewRating } from '../../components';
import { UserDisplayName } from '../../components';
import ListingCardAvatar from './ListingCardAvatar';
import ListingCardSubjects from './ListingCardSubjects';
import noImage from './images/noImageIcon.svg';

import css from './ListingCard.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
  loadAfterInitialRendering: 3000,
  customStyle: true,
});

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    allSubjects,
    onContactUser,
    onSaveFavourites,
    currentUser,
    saveFavouriteRequest,
    saveFavouriteSuccess,
    saveFavouriteError,
    getFavoriteListings,
    favoriteListingsRequest,
    requestInProgress,
    setInProgress,
  } = props;

  const [idForProgress, setIdForProgress] = useState(null);

  useEffect(() => {
    if (saveFavouriteSuccess) {
      setIdForProgress(null);
    }
  }, [saveFavouriteSuccess]);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { location, sessionType, subjectItems, studentsReviews, education } = publicData;
  const shortAddress = publicData.shortAddress ? publicData.shortAddress : null;
  // const address = location && location.address;
  const building = location && location.building;
  const authorDisplayName = listing.author.attributes.profile.displayName;
  const rating = studentsReviews && studentsReviews.totalRating;
  const image = listing.images[0].attributes.variants['landscape-crop'].url;
  const profileImage =
    listing.author.profileImage && listing.author.profileImage.attributes.variants['landscape-crop']
      .url;

  const subjectsPrivatePrice = {};
  const subjectsGroupPrice = {};

  const listingId = currentListing.id;
  const userId = currentUser && currentUser.id;

  const favouritesListUser =
    currentUser &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.userFavourite
      ? currentUser.attributes.profile.publicData.userFavourite
      : [];
  const favouritesListListing =
    currentListing &&
    currentListing.attributes.publicData &&
    currentListing.attributes.publicData.listingFavourite
      ? currentListing.attributes.publicData.listingFavourite
      : [];

  const userHasListing =
    listingId &&
    favouritesListUser.length > 0 &&
    favouritesListUser.filter(el => el === listingId.uuid).length > 0;

  sessionType &&
    sessionType.map(type => {
      if (type === 'private') {
        subjectItems.map(item => {
          for (let key in publicData) {
            if (key === `${item}_${type}`) {
              subjectsPrivatePrice[item] = publicData[key].amount;
            }
          }
        });
      } else if (type === 'group') {
        subjectItems.map(item => {
          for (let key in publicData) {
            if (key === `${item}_${type}`) {
              subjectsGroupPrice[item] = publicData[key].amount;
            }
            if (key === `${item}`) {
              subjectsGroupPrice[`${item}_duration`] = {
                hours: publicData[key].hours,
                minutes: publicData[key].minutes,
              };
            }
          }
        });
      }
    });

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const onSaveFavouriteListing = async () => {
    setInProgress();
    setIdForProgress(listingId.uuid);
    if (!userHasListing && listingId && userId) {
      favouritesListUser.push(listingId.uuid);
      favouritesListListing.push(userId.uuid);
      await onSaveFavourites(listingId, userId, favouritesListUser, favouritesListListing).then(
        getFavoriteListings()
      );
    } else {
      const userFavourite =
        favouritesListUser.length > 0
          ? favouritesListUser.filter(el => el !== listingId.uuid)
          : favouritesListUser;
      const listingFavourite =
        favouritesListListing.length > 0
          ? favouritesListListing.filter(el => el !== userId.uuid)
          : favouritesListListing;
      await onSaveFavourites(listingId, userId, userFavourite, listingFavourite).then(
        getFavoriteListings()
      );
    }
  };

  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.allInfoContainer}>
          <div className={css.aspectWrapper}>
            {/* <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            /> */}
            {profileImage ? (
              <div
                style={{
                  backgroundImage: `url(${profileImage})`,
                }}
                className={css.listingImage}
              />
            ) : (
              <img src={noImage} alt="no image" className={css.listingImage} />
            )}
            <Button
              className={css.buttonContact}
              onClick={() => onContactUser(title, authorDisplayName, id)}
            >
              <FormattedMessage id="ListingCard.contact" />
            </Button>
            {userId ? (
              <Button
                className={classNames(css.buttonSave, userHasListing ? css.buttonDelete : null)}
                onClick={onSaveFavouriteListing}
                inProgress={idForProgress === currentListing.id.uuid && requestInProgress}
                disabled={idForProgress === currentListing.id.uuid && requestInProgress}
              >
                {userHasListing ? (
                  <FormattedMessage id="ListingCard.delete" />
                ) : (
                  <FormattedMessage id="ListingCard.save" />
                )}
              </Button>
            ) : null}
          </div>
          <div className={css.info}>
            <div className={css.mainInfo}>
              {/* <ListingCardAvatar user={listing.author} intl={intl} /> */}
              <div className={css.tutorInfo}>
                <p className={css.tutorName}>{title}</p>
                <p className={css.row}>{education}</p>
                {sessionType && sessionType.includes('private') ? (
                  <div className={css.subjectContainer}>
                    <ListingCardSubjects
                      options={allSubjects}
                      education
                      publicData={publicData}
                      sessionType="Private session"
                      subjectsPrice={subjectsPrivatePrice}
                    />
                  </div>
                ) : null}
                {sessionType && sessionType.includes('group') ? (
                  <div className={css.subjectContainer}>
                    <ListingCardSubjects
                      options={allSubjects}
                      publicData={publicData}
                      sessionType="Group session"
                      subjectsPrice={subjectsGroupPrice}
                    />
                  </div>
                ) : null}
                {/* <UserDisplayName user={listing.author} intl={intl} className={css.tutorName} /> */}
                {shortAddress ? <p className={css.row}>{`${shortAddress}`}</p> : null}
                <div className={css.row}>
                  <ReviewRating
                    reviewStarClassName={css.reviewStar}
                    className={css.reviewStars}
                    rating={rating}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={css.buttonContainer}>
          <NamedLink className={css.buttonViewProfile} name="ListingPage" params={{ id, slug }}>
            <FormattedMessage id="ListingCard.viewProfile" />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
