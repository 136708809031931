import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { createAnnouncement, createReset } from './AnnouncementsPage.duck';
import { getAnnouncements } from './AnnouncementsPage.duck';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  AnnouncementsItem,
  Modal,
  AnnouncementEditor,
} from '../../components';
import { TopbarContainer } from '../index';
import css from './AnnouncementsPage.css';

const AnnouncementsPageComponent = props => {
  const {
    scrollingDisabled,
    onGetAnnouncements,
    announcements,
    isAuthenticated,
    currentUser,
    onManageDisableScrolling,
    onCreateAnnouncement,
    createSuccess,
    onCreateReset,
    createError,
    editingError,
    editingSuccess,
    deletingSuccess,
    deletingError,
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    onGetAnnouncements();
  }, []);

  useEffect(() => {
    if (createSuccess) {
      setModalOpen(false);
    }
  }, [createSuccess]);

  const handleClose = () => {
    setModalOpen(false);
    onCreateReset();
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const userType =
    isAuthenticated &&
    currentUser &&
    currentUser.attributes.profile.protectedData.userType.toLowerCase();

  const addNewBtn = userType === 'superadmin' && (
    <button className={css.addNewBtn} onClick={handleOpen}>
      <FormattedMessage id="AnnouncementsPage.addBtnText" />
    </button>
  );

  const handleCreate = data => {
    onCreateReset()
    onCreateAnnouncement(data);
  };

  const sortAnnouncements = announcements.sort((a, b) => {
    return +new Date(a.created_at) < +new Date(b.created_at)
      ? 1
      : +new Date(b.created_at) < +new Date(a.created_at)
      ? -1
      : 0;
  });

  return (
    <Page
      title="Announcements"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: 'Announcements page',
      }}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <h1>
            <FormattedMessage id="AnnouncementsPage.pageTitleText" />
          </h1>
          {addNewBtn}
          {announcements &&
            announcements.length > 0 &&
            sortAnnouncements.map(item => {
              return (
                <AnnouncementsItem
                  item={item}
                  key={item.id}
                  editingError={editingError}
                  editingSuccess={editingSuccess}
                  deletingError={deletingError}
                  deletingSuccess={deletingSuccess}
                />
              );
            })}
          <Modal
            id="AnnouncementsPage.addNewAnnouncement"
            isOpen={isModalOpen}
            onClose={handleClose}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <AnnouncementEditor
              editorTitle="AnnouncementsEditor.addTitleText"
              makeRequestFunction={data => handleCreate(data)}
              createError={createError}
            />
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const {
    announcements,
    createSuccess,
    createError,
    editingError,
    editingSuccess,
    deletingError,
    deletingSuccess,
  } = state.AnnouncementsPage;
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    announcements,
    isAuthenticated,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    createSuccess,
    createError,
    editingError,
    editingSuccess,
    deletingError,
    deletingSuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onGetAnnouncements: () => {
    dispatch(getAnnouncements());
  },
  onCreateAnnouncement: data => {
    dispatch(createAnnouncement(data));
  },
  onCreateReset: () => {
    dispatch(createReset());
  },
});

const AnnouncementsPage = compose(connect(mapStateToProps, mapDispatchToProps))(
  AnnouncementsPageComponent
);

export default AnnouncementsPage;
