import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { compose } from 'redux';
import { maxLength, required, requiredCheckBox, composeValidators } from '../../util/validators';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroupSubject, FieldTextInput, Form } from '../../components';

import css from './EditListingFeaturesForm.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        intl,
        saveActionMsg,
        updated,
        invalid,
        listing,
        updateInProgress,
        fetchErrors,
        onChangeTime,
        data,
        clickToPassSubject,
        setQuiz,
        refreshQuizResult,
        infoAllSubjects,
        getQuizResultProgress,
        proProfsProgress,
        allSubjects,
        values,
        passedSubjects
      } = formRenderProps;

      const quizResult = infoAllSubjects.filter(item => item.status === 'Passed');

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || quizResult.length === 0;

      const featuresRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.titleRequired',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={e => handleSubmit(e)}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroupSubject
            className={css.features}
            id={name}
            name={name}
            listing={listing}
            withPrice={true}
            options={allSubjects}
            onChangeTime={onChangeTime}
            values={values}
            validate={composeValidators(requiredCheckBox(featuresRequiredMessage))}
            clickToPassSubject={clickToPassSubject}
            setQuiz={setQuiz}
            infoAllSubjects={infoAllSubjects}
            passedSubjects={passedSubjects}
            proProfsProgress={proProfsProgress}
          />

          <p className={css.customText}>
            <FormattedMessage id="EditListingFeaturesForm.customText" />
          </p>

          <Button
            className={css.quizesResult}
            type="button"
            onClick={refreshQuizResult}
            inProgress={getQuizResultProgress}
          >
            <FormattedMessage id="EditListingFeaturesForm.updateQuizesResult" />
          </Button>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  intl: intlShape.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
