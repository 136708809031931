import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { func, string, bool } from 'prop-types';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { BackgroundCheckModal, Button } from '..';
import { LISTING_STATE_PUBLISHED } from '../../util/types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import css from './BackgroundCheckStatus.css';

function BackgroundCheckStatus(props){
    const {
      onManageDisableScrolling,
      stripeAccount,
      currentUser,
      currentUserListing,
    } = props;

    const innerStatuses = [
      'draft',
      'ready',
      'inprogress',
      'checked'
    ];

    const modalName = 'Page.remindBackgrounCheck';

    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const checkStatus = () => {
      const hasStripe = stripeAccount &&
                        stripeAccount.attributes &&
                        stripeAccount.attributes.stripeAccountData &&
                        !!stripeAccount.attributes.stripeAccountData.payouts_enabled;

      const isPublished = currentUserListing &&
                          currentUserListing.id &&
                          currentUserListing.attributes.state === LISTING_STATE_PUBLISHED;

      const isSended = currentUser &&
                      !!currentUser.attributes.profile.privateData.sendBackgroundCheck;

      const isChecked = currentUserListing &&
                        currentUserListing.id &&
                        !!currentUserListing.attributes.publicData.backgroundCheck

      if (!hasStripe || !isPublished) {
        return innerStatuses[0];
      } else if (!isSended && !isChecked) {
        return innerStatuses[1];
      } else if (isSended && !isChecked) {
        return innerStatuses[2];
      } else if (!!isChecked) {
        return innerStatuses[3];
      }
    }

    const onClose = () => {
      onManageDisableScrolling(modalName, false);
      setIsVisible(false);
      setLoading(false);
    }

    const handleClick = () => {
      setLoading(true);
      setIsVisible(true);
    }

    const status = checkStatus();

    const renderButton = (
      <>
        <Button
          className={css.button}
          onClick={handleClick}
          inProgress={loading}
        >
          <FormattedMessage id="BackgroundCheckStatus.buttonTitle"/>
        </Button>

        <BackgroundCheckModal
          onManageDisableScrolling={onManageDisableScrolling}
          modalName={modalName}
          showModal={isVisible}
          onCloseModal={onClose}
        />
      </>
    )

    const renderInProgress = (
      <div className={css.messageWrapper}>
        <p className={css.textInProgress}>
          <FormattedMessage id="BackgroundCheckStatus.inProgress"/>
        </p>
      </div>
    )

    const renderChecked = (
      <div className={css.messageWrapper}>
        <p className={css.textDone}>
          <FormattedMessage id="BackgroundCheckStatus.confirmed"/>
        </p>
      </div>
    )

    if (status === innerStatuses[0]) {
      return null;
    }

    return (
      <div className={css.wrapper}>
        {status === innerStatuses[1] && renderButton}
        {status === innerStatuses[2] && renderInProgress}
        {status === innerStatuses[3] && renderChecked}
      </div>
    );
}

BackgroundCheckStatus.propTypes = {
  onManageDisableScrolling: func.isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserListing } = state.user;

  const { stripeAccount } = state.stripeConnectAccount;

  return {
    stripeAccount,
    currentUser,
    currentUserListing
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BackgroundCheckStatus);
