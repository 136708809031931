import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './AdminAgreementsComponent.css';

const AgreementsItem = ({item, handleUpdate, handleDelete}) => {
  const {id, title} = item;

  const editBtn = (
    <button
      onClick={() => handleUpdate(item)}
      className={css.editButton}
    >
      <FormattedMessage id="AdminPanelPage.editBtnText" />
    </button>
  );

  const deleteBtn = (
    <button
      onClick={() => handleDelete(id)}
      className={css.deleteButton}
    >
      <FormattedMessage id="AdminPanelPage.deleteBtnText" />
    </button>
  );

  return (
    <div className={css.agreementWrap}>
      <div className={css.agreementInfoContainer}>
        <div className={css.topRow}>
          <span className={css.title}>{title}</span>
        </div>
      </div>
      <div className={css.editBtnsWrap}>
        {editBtn}
        {deleteBtn}
      </div>
    </div>
  )
};

const AgreementsList = props => {
  const {
    data,
    handleUpdate,
    handleDelete
  } = props;

  if (!data || !data.length) {
    return null;
  }

  return (
    <div className={css.agreementsList}>
      {data.map(item => (
        <AgreementsItem
          key={item.id}
          item={item}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      ))}
    </div>
  );
};

export default AgreementsList;
