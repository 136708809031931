import axios from 'axios';
import { tutorsAppDecline } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';

async function makeRequest(method, url, data) {
  const options = {
    baseURL: process.env.REACT_APP_RAILS_API_URL,
    method: method,
    url: url,
    data,
  };

  let res = await axios(options);
  try {
    return res;
  } catch (e) {
    return new Error(e);
  }
}

// ================ Action types ================ //
export const AGREEMENTS_REQUEST = 'app/TutorsAppPage/AGREEMENTS_REQUEST';
export const AGREEMENTS_SUCCESS = 'app/TutorsAppPage/AGREEMENTS_SUCCESS';
export const AGREEMENTS_ERROR   = 'app/TutorsAppPage/AGREEMENTS_ERROR';

export const APP_REQUEST = 'app/TutorsAppPage/APP_REQUEST';
export const APP_SUCCESS = 'app/TutorsAppPage/APP_SUCCESS';
export const APP_ERROR   = 'app/TutorsAppPage/APP_ERROR';

export const ATTACHMENTS_REQUEST = 'app/TutorsAppPage/ATTACHMENTS_REQUEST';
export const ATTACHMENTS_SUCCESS = 'app/TutorsAppPage/ATTACHMENTS_SUCCESS';
export const ATTACHMENTS_CLEAR = 'app/TutorsAppPage/ATTACHMENTS_CLEAR';
export const INIT_ATTACHMENTS_SUCCESS = 'app/TutorsAppPage/INIT_ATTACHMENTS_SUCCESS';

export const ATTACHMENTS_ERROR   = 'app/TutorsAppPage/ATTACHMENTS_ERROR';
export const ATTACHMENTS_DELETE   = 'app/TutorsAppPage/ATTACHMENTS_DELETE';

export const BG_CONSENT_REQUEST = 'app/TutorsAppPage/BG_CONSENT_REQUEST';
export const BG_CONSENT_SUCCESS = 'app/TutorsAppPage/BG_CONSENT_SUCCESS';
export const BG_CONSENT_ERROR   = 'app/TutorsAppPage/BG_CONSENT_ERROR';
// ================ Reducer ================ //

const initialState = {
  agreements: [],
  agreementsProgress: false,
  agreementsError: false,

  tutorsApp: null,
  tutorsProgress: false,
  tutorsError: false,

  attachments: [],
  initialAttachments: [],
  attachmentsProgress: false,
  attachmentsError: false,

  bgConsent: null,
  bgConsentProgress: false,
  bgConsentError: false,
};

export default function tutorsAppPagePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AGREEMENTS_REQUEST:
      return {
        ...state,
        agreementsProgress: true,
        agreementsError: false
      };
    case AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements: payload,
        agreementsProgress: false,
      };
    case AGREEMENTS_ERROR:
      return {
        ...state,
        agreementsProgress: false,
        agreementsError: true
      };

    case APP_REQUEST:
      return {
        ...state,
        tutorsProgress: true,
        tutorsError: false
      };
    case APP_SUCCESS:
      return {
        ...state,
        tutorsApp: payload,
        tutorsProgress: false,
      };
    case APP_ERROR:
      return {
        ...state,
        tutorsProgress: false,
        tutorsError: true
      };

    case ATTACHMENTS_REQUEST:
      return {
        ...state,
        attachmentsProgress: true,
        attachmentsError: false
      };
    case ATTACHMENTS_SUCCESS:
      return {
        ...state,
        attachments: [
          ...state.attachments,
          ...payload
        ],
        attachmentsProgress: false,
      };
    case ATTACHMENTS_CLEAR:
      return {
        ...state,
        attachments: []
      };
    case INIT_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        initialAttachments: payload
      };
    case ATTACHMENTS_ERROR:
      return {
        ...state,
        attachmentsProgress: false,
        attachmentsError: true
      };
    case ATTACHMENTS_DELETE:
      return {
        ...state,
        attachments: state.attachments.filter(att => att.id !== payload),
        initialAttachments: state.initialAttachments.filter(att => att.id !== payload),
        attachmentsProgress: false,
      };

    case BG_CONSENT_REQUEST:
      return {
        ...state,
        bgConsentProgress: true,
        bgConsentError: false
      };
    case BG_CONSENT_SUCCESS:
      return {
        ...state,
        bgConsent: payload,
        bgConsentProgress: false,
      };
    case BG_CONSENT_ERROR:
      return {
        ...state,
        bgConsentProgress: false,
        bgConsentError: true
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //
const agreementsRequest = () => ({ type: AGREEMENTS_REQUEST });
const agreementsSuccess = response => ({
  type: AGREEMENTS_SUCCESS,
  payload: response.data,
});
const agreementsError = error => ({ type: AGREEMENTS_ERROR, payload: error });

const tutorsAppRequest = () => ({ type: APP_REQUEST });
const tutorsAppSuccess = response => ({
  type: APP_SUCCESS,
  payload: response.data.tutor,
});
const tutorsAppError = error => ({ type: APP_ERROR, payload: error });

const attachmentsRequest = () => ({ type: ATTACHMENTS_REQUEST });
const attachmentsSuccess = response => ({
  type: ATTACHMENTS_SUCCESS,
  payload: response,
});
const clearAttachments = () => ({type: ATTACHMENTS_CLEAR});
const setInitialAttachments = attachments => ({
  type: INIT_ATTACHMENTS_SUCCESS,
  payload: attachments,
});
const attachmentsError = error => ({ type: ATTACHMENTS_ERROR, payload: error });
const attachmentsDelete = id => ({ type: ATTACHMENTS_DELETE, payload: id });

const bgRequest = () => ({ type: BG_CONSENT_REQUEST });
const bgSuccess = response => ({
  type: BG_CONSENT_SUCCESS,
  payload: response.data,
});
const bgError = error => ({ type: BG_CONSENT_ERROR, payload: error });

/* ================ Thunks ================ */
export const getAgreements = () => dispatch => {
  dispatch(agreementsRequest());

  return makeRequest('get', `api/v1/tutor_application_pages`)
    .then(response => {
      dispatch(agreementsSuccess(response));
      return response.data;
    })
    .catch(e => {
      dispatch(agreementsError(e));
    });
}

export const getTutorsApp = id => dispatch => {
  dispatch(tutorsAppRequest());

  return makeRequest('get', `api/v1/users/${id}`)
    .then(response => {
      dispatch(tutorsAppSuccess(response));
      dispatch(clearAttachments());
      dispatch(setInitialAttachments(response.data.tutor_application_attachment))
      return response.data;
    })
    .catch(e => {
      dispatch(tutorsAppError(e));
    });
}

export const createTutorsApp = data => (dispatch, getState, sdk) => {
  dispatch(tutorsAppRequest());
  
  return makeRequest('post', `api/v1/tutors`, data)
    // .then(response => {
    //   // dispatch(tutorsAppSuccess(response));
    //   return response.data;
    // })
    .then(() => {
      return sdk
        .currentUser
        .updateProfile({
          privateData: {
            tutorsAppStatus: 'pending'
          }
        }, {
          expand: true
        });
    })
    .catch(e => {
      dispatch(tutorsAppError(e));
    });
}

export const updateTutorsApp = (data, id) => dispatch => {
  dispatch(tutorsAppRequest());

  return makeRequest('put', `api/v1/tutors/${id}`, data)
    .then(response => {
      // dispatch(tutorsAppSuccess(response));
      return response.data;
    })
    .then(data => {
      return tutorsAppDecline({id});
    })
    .then(() => {
      return dispatch(fetchCurrentUser())
    })
    .catch(e => {
      dispatch(tutorsAppError(e));
    });
}

export const addAttachment = data => dispatch => {
  dispatch(attachmentsRequest());

  return makeRequest('post', `/api/v1/tutor_application_attachment`, data)
    .then(response => {
      dispatch(attachmentsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(attachmentsError(e));
    });
}   

export const deleteAttachment = id => dispatch => {
  dispatch(attachmentsRequest());

  return makeRequest('delete', `/api/v1/tutor_application_attachment/${id}`)
    .then(() => {
      return dispatch(attachmentsDelete(id));
    })
    .catch(e => {
      dispatch(attachmentsError(e));
    });
}

export const getBackgroundCheckConsent = () => dispatch => {
  dispatch(bgRequest());

  return makeRequest('get', `api/v1/static_infos/consent-for-background-check`)
    .then(response => {
      dispatch(bgSuccess(response));
      return response.data;
    })
    .catch(e => {
      dispatch(bgError(e));
    });
}