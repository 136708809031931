import React, { Component } from 'react';
import { array, bool, func, number, object, objectOf, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import merge from 'lodash/merge';
import { propTypes } from '../../util/types';
import {
  SearchResultsPanel,
  SearchFilters,
  SearchFiltersMobile,
  SearchFiltersPanel,
  SearchFavouriteResultsPanel,
  Button,
  IconSpinner,
} from '../../components';
import { validFilterParams } from './SearchPage.helpers';

import css from './SearchPage.css';

class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { isSearchFiltersPanelOpen: false, showLess: true };
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      primaryFilters,
      secondaryFilters,
      allSubjects,
      onContactUser,
      onSaveFavourites,
      currentUser,
      saveFavouriteRequest,
      saveFavouriteSuccess,
      saveFavouriteError,
      favouriteListings,
      getFavoriteListings,
      favoriteListingsRequest,
      viewport,
      requestInProgress,
      setInProgress,
      favoriteListingsSuccess,
    } = this.props;

    const isSearchFiltersPanelOpen = !!secondaryFilters && this.state.isSearchFiltersPanelOpen;

    const filters = merge(primaryFilters, secondaryFilters);
    const selectedFilters = validFilterParams(urlQueryParams, filters);
    const selectedFiltersCount = Object.keys(selectedFilters).length;

    const selectedSecondaryFilters = secondaryFilters
      ? validFilterParams(urlQueryParams, secondaryFilters)
      : {};
    const searchFiltersPanelSelectedCount = Object.keys(selectedSecondaryFilters).length;

    const searchFiltersPanelProps = !!secondaryFilters
      ? {
          isSearchFiltersPanelOpen: this.state.isSearchFiltersPanelOpen,
          toggleSearchFiltersPanel: isOpen => {
            this.setState({ isSearchFiltersPanelOpen: isOpen });
          },
          searchFiltersPanelSelectedCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    const filterParamNames = Object.values(filters).map(f => f.paramName);
    const secondaryFilterParamNames = secondaryFilters
      ? Object.values(secondaryFilters).map(f => f.paramName)
      : [];

    const { width } = viewport;
    const mobileView = width < 768;

    const filterFavourites =
      !mobileView && this.state.showLess && favouriteListings && favouriteListings.length > 3
        ? favouriteListings.slice(0, 3)
        : mobileView && this.state.showLess && favouriteListings && favouriteListings.length > 2
        ? favouriteListings.slice(0, 2)
        : favouriteListings;

    return (
      <div className={classes}>
        <SearchFilters
          className={css.searchFilters}
          urlQueryParams={urlQueryParams}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          onManageDisableScrolling={onManageDisableScrolling}
          {...searchFiltersPanelProps}
          {...primaryFilters}
        />
        <SearchFiltersMobile
          className={css.searchFiltersMobile}
          urlQueryParams={urlQueryParams}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onMapIconClick={onMapIconClick}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          filterParamNames={filterParamNames}
          selectedFiltersCount={selectedFiltersCount}
          {...primaryFilters}
          {...secondaryFilters}
        />
        {isSearchFiltersPanelOpen ? (
          <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersPanel
              urlQueryParams={urlQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              onClosePanel={() => this.setState({ isSearchFiltersPanelOpen: false })}
              filterParamNames={secondaryFilterParamNames}
              {...secondaryFilters}
            />
          </div>
        ) : (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {favoriteListingsSuccess || !currentUser ? null : (
              <div className={css.loadingFavorits}>
                <IconSpinner className={css.spinner} />
              </div>
            )}
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null}
            {favouriteListings && favouriteListings.length > 0 ? (
              <div className={css.favouritesContainer}>
                <h3 className={css.titleFavourites}>
                  <FormattedMessage id="SearchPage.favouritesTitle" />
                </h3>
                <SearchFavouriteResultsPanel
                  className={css.searchListingsPanel}
                  listings={filterFavourites}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={searchParamsForPagination}
                  setActiveListing={onActivateListing}
                  allSubjects={allSubjects}
                  onContactUser={onContactUser}
                  onSaveFavourites={onSaveFavourites}
                  currentUser={currentUser}
                  saveFavouriteRequest={saveFavouriteRequest}
                  saveFavouriteSuccess={saveFavouriteSuccess}
                  saveFavouriteError={saveFavouriteError}
                  getFavoriteListings={getFavoriteListings}
                  favoriteListingsRequest={favoriteListingsRequest}
                  requestInProgress={requestInProgress}
                  setInProgress={setInProgress}
                />
                {(favouriteListings && !mobileView && favouriteListings.length > 3) ||
                (favouriteListings && mobileView && favouriteListings.length > 2) ? (
                  <Button
                    className={css.btnSeeMore}
                    onClick={() => this.setState({ showLess: !this.state.showLess })}
                  >
                    {this.state.showLess ? 'See more' : 'See less'}
                  </Button>
                ) : null}
              </div>
            ) : null}
            <SearchResultsPanel
              className={css.searchListingsPanel}
              listings={listings}
              pagination={listingsAreLoaded ? pagination : null}
              search={searchParamsForPagination}
              setActiveListing={onActivateListing}
              allSubjects={allSubjects}
              onContactUser={onContactUser}
              onSaveFavourites={onSaveFavourites}
              currentUser={currentUser}
              saveFavouriteRequest={saveFavouriteRequest}
              saveFavouriteSuccess={saveFavouriteSuccess}
              saveFavouriteError={saveFavouriteError}
              getFavoriteListings={getFavoriteListings}
              favoriteListingsRequest={favoriteListingsRequest}
              requestInProgress={requestInProgress}
              setInProgress={setInProgress}
            />
          </div>
        )}
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  primaryFilters: null,
  secondaryFilters: null,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  primaryFilters: objectOf(propTypes.filterConfig),
  secondaryFilters: objectOf(propTypes.filterConfig),
};

export default MainPanel;
