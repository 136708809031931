import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';
import { Money } from 'sharetribe-flex-sdk/src/types';
import config from '../../config';

import { Modal } from '../../components';

import css from './EditListingFeaturesPanel.css';

const FEATURES_NAME = 'subjectItems';
const CENTS = 100;
const NOT_PRICE_FIELD = ['subjectItems'];

class EditListingFeaturesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isModalOpen: false,
      selectedSubject: '',
    };
    this.onChangeTime = this.onChangeTime.bind(this);
  }

  onChangeTime(value, key, type) {
    let data = this.state.data;
    if (data[key]) {
      data[key][type] = +value;
    } else {
      data[key] = { [type]: +value };
    }
    if (type === 'hours' && +value === 24) data[key]['minutes'] = 0;
    this.setState({ data });
  }

  componentDidMount() {
    const { currentUser } = this.props;
    const emailUser = currentUser.attributes.email;

    if (currentUser && emailUser) this.props.refreshQuizResult(emailUser);
  }

  componentDidUpdate(prevProps) {
    const { proProfsProgress } = prevProps;
    if (proProfsProgress !== null && this.props.proProfsProgress === null) {
      this.setState({ isModalOpen: true });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      clickToPassSubject,
      setQuiz,
      onManageDisableScrolling,
      currentUser,
      proProfsProgress,
      proProfsError,
      refreshQuizResult,
      infoAllSubjects,
      getQuizResultProgress,
      allSubjects,
    } = this.props;

    const closeModal = () => {
      this.setState({ isModalOpen: false });
    };

    const setTutorQuiz = async value => {
      const idUser = currentUser.id.uuid;
      const emailUser = currentUser.attributes.email;
      const listSubject = allSubjects;
      const getSubject = value;

      await setQuiz(getSubject, idUser, emailUser);

      const subjectName =
        listSubject && listSubject.filter(item => item.key === getSubject)[0].label;

      this.setState({ selectedSubject: subjectName });
      // this.setState({ isModalOpen: true });
    };

    const getQuizResult = () => {
      const emailUser = currentUser.attributes.email;
      refreshQuizResult(emailUser);
    };

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingFeaturesPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingFeaturesPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
    );

    const passedSubjectsFn = () => {
      return infoAllSubjects
        .filter(subject => subject && subject.status === 'Passed' && subject.key !== 'initialQuiz')
        .map(subject => subject.key);
    };

    const passedSubjects = passedSubjectsFn();

    const subjectItems = publicData && publicData.subjectItems;

    const initialValues = { subjectItems };
    passedSubjects &&
      passedSubjects.map(item => {
        initialValues[`${item}_private`] = listing.attributes.publicData[`${item}_private`]
          ? listing.attributes.publicData[`${item}_private`].amount / CENTS
          : null;
        initialValues[`${item}_group`] = listing.attributes.publicData[`${item}_group`]
          ? listing.attributes.publicData[`${item}_group`].amount / CENTS
          : null;
        initialValues[`${item}_duration_hours`] =
          listing.attributes.publicData[item] && listing.attributes.publicData[item].hours
            ? listing.attributes.publicData[item].hours
            : '1';
        initialValues[`${item}_duration_minutes`] =
          listing.attributes.publicData[item] && listing.attributes.publicData[item].minutes
            ? listing.attributes.publicData[item].minutes
            : '0';
      });

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingFeaturesForm
          className={css.form}
          name={FEATURES_NAME}
          initialValues={initialValues}
          onSubmit={values => {
            const { subjectItems = [], featureWords = subjectItems.join(), ...rest } = values;
            const price = new Money(0, config.currency);
            const updatedValues = {
              price,
              publicData: { subjectItems, featureWords },
            };
            let minPrice = null;
            let maxPrice = 0;

            for (let key in rest) {
              const subject = key
                .split('_')
                .filter(k => !['duration', 'hours', 'minutes', 'private', 'group'].includes(k))
                .join('_');
              if (subjectItems.includes(subject) || passedSubjects.includes(subject)) {
                if (!key.includes('_duration_hours') && !key.includes('_duration_minutes')) {
                  if (values[key] < minPrice || minPrice === null) {
                    minPrice = +values[key];
                  }
                  if (values[key] > maxPrice) {
                    maxPrice = +values[key];
                  }
                  updatedValues.publicData[key] = {
                    amount: values[key] * CENTS,
                    currency: config.currency,
                  };
                  updatedValues.publicData.minPrice = minPrice;
                  updatedValues.publicData.maxPrice = maxPrice;
                } else {
                  const time =
                    key.split('_').length === 3
                      ? key.split('_')
                      : [
                          key
                            .split('_')
                            .slice(0, -2)
                            .join('_'),
                          ...key.split('_').slice(-2),
                        ];
                  console.log(time);
                  if (!updatedValues.publicData[time[0]]) {
                    updatedValues.publicData[time[0]] = {};
                  }
                  const value =
                    time[2] === 'minutes' && rest[`${time[0]}_duration_hours`] == 24
                      ? 0
                      : +rest[key];
                  updatedValues.publicData[time[0]][time[2]] = value;
                }
              }
            }

            // for (let key in this.state.data) {
            //   updatedValues.publicData[key] = this.state.data[key];
            // }

            getQuizResult();

            onSubmit(updatedValues);
            minPrice = null;
            maxPrice = 0;
          }}
          onChange={onChange}
          onChangeTime={this.onChangeTime}
          data={this.state.data}
          listing={currentListing}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          clickToPassSubject={clickToPassSubject}
          setQuiz={e => setTutorQuiz(e)}
          refreshQuizResult={getQuizResult}
          infoAllSubjects={infoAllSubjects}
          getQuizResultProgress={getQuizResultProgress}
          proProfsProgress={proProfsProgress}
          allSubjects={allSubjects}
          passedSubjects={passedSubjects}
        />
        <Modal
          id="QuizModal"
          isOpen={this.state.isModalOpen}
          onClose={closeModal}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
        >
          <div className={css.textContainer}>
            {proProfsError ? (
              <p className={css.modalError}>{proProfsError}</p>
            ) : (
              <>
                <h3 className={css.modalTitle}>
                  <FormattedMessage
                    id="EditListingFeaturesPanel.modalTitle"
                    values={{
                      subject: this.state.selectedSubject,
                    }}
                  />
                </h3>
                <div className={css.credContainer}>
                  <p className={css.modalLabel}>
                    <FormattedMessage id="EditListingFeaturesPanel.modalText" />
                  </p>
                </div>
                <a
                  className={css.modalLink}
                  href="https://www.proprofs.com/classroom/2923084"
                  target="_blank"
                >
                  <FormattedMessage id="EditListingFeaturesPanel.modalLink" />
                </a>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
