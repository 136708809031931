import axios from 'axios';
import { createUserEntryCreated } from '../../ducks/user.duck';

async function makeRequest(method, url, data) {
  const options = {
    baseURL: process.env.REACT_APP_RAILS_API_URL,
    method: method,
    url: url,
    data,
  };

  let res = await axios(options);
  try {
    return res;
  } catch (e) {
    return new Error(e);
  }
}

// ================ Action types ================ //
export const APP_REQUEST = 'app/ProfileTutorsAppPage/APP_REQUEST';
export const APP_SUCCESS = 'app/ProfileTutorsAppPage/APP_SUCCESS';
export const APP_ERROR   = 'app/ProfileTutorsAppPage/APP_ERROR';

// ================ Reducer ================ //

const initialState = {
  tutorsApp: null,
  tutorsProgress: false,
  tutorsError: false,
};

export default function profileTutorsAppPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case APP_REQUEST:
      return {
        ...state,
        tutorsProgress: true,
        tutorsError: false
      };
    case APP_SUCCESS:
      return {
        ...state,
        tutorsApp: payload,
        tutorsProgress: false,
      };
    case APP_ERROR:
      return {
        ...state,
        tutorsProgress: false,
        tutorsError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //
const tutorsAppRequest = () => ({ type: APP_REQUEST });
const tutorsAppSuccess = response => ({
  type: APP_SUCCESS,
  payload: response.data,
});
const tutorsAppError = error => ({ type: APP_ERROR, payload: error });

/* ================ Thunks ================ */

const dropInvalidUser = () => (dispatch, getState, sdk) => {
  return sdk.currentUser
    .updateProfile({
      privateData: {
        hasEntryAccount: null,
        tutorsAppStatus: null
      }
    }, {
      expand: true
    }).then(() => {
      return dispatch(createUserEntryCreated())
    })
    .catch(e => {})
}

export const getTutorsApp = id => dispatch => {
  dispatch(tutorsAppRequest());

  return makeRequest('get', `api/v1/users/${id}`)
    .then(response => {
      dispatch(tutorsAppSuccess(response));
      return response.data;
    })
    .catch(e => {
      console.log(e.response)
      const {status} = e.response;
      if (status == 404) {
        dispatch(dropInvalidUser())
      }
      dispatch(tutorsAppError(status));
    });
}