import React, { useState, useEffect } from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getUsersReviews } from '../../ducks/Reviews.duck';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionSearchSubject,
  SectionReviews,
  SectionOnlineTeaching,
  SectionBecomingTutor,
  SectionSubscribe,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '..';

import facebookImage from '../../assets/wisepalFacebook-1200x630.jpg';
import twitterImage from '../../assets/wisepalTwitter-600x314.jpg';
import css from './ESTutoringPage.css';

import pageBanner from '../../assets/seoteric-pages/high-school-edits/elementary-school-banner.jpg';
import happyStudentTwo from '../ESTutoringPage/images/ele-tutor-child.jpg';
import happyStudent from '../../assets/seoteric-pages/elementary-school-edits/happy-student.jpg';
import lessonPlan from '../ESTutoringPage/images/ele-tutor.jpg';
import stayInformed from '../../assets/seoteric-pages/elementary-school-edits/stay-informed.jpg';
import tutoringStudent from '../ESTutoringPage/images/ele-tutor-two.jpg';
import studentTutoring from '../ESTutoringPage/images/ele-tutor-chair.jpg';

export const ESTutoringPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    reviews,
    currentUserListingFetched,
    onGetUsersReviews,
    currentUser,
    isAuthenticated,
  } = props;

  const [recievedReviews, setRecieveReviews] = useState(reviews);

  useEffect(() => {
    onGetUsersReviews()
  }, []);

  useEffect(() => {
    setRecieveReviews(reviews);
  }, [reviews]);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ESTutoring.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'ESTutoring.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
        <div class="container">
            <div class="row my-5">
                <div class="col-md-12 text-center">
                    <h1>Elementary School Tutoring</h1>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-12 justify-content-center text-center">
                    <p class="my-3 intro-content">Are you considering hiring a tutor for your child in elementary? At Wisepal, we know the value of great elementary school tutors. The right tutor can help your child love learning and excel in their studies.</p><br />
                    <a href="https://wisepal.co/signup">
                    <button type="button" class="btn btn-prpl btn-lg rounded-pill my-3 hover-orng">Sign Up Today!</button>
                </a>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-6 justify-content-center text-center">
                    <p class="my-3">Wisepal tutors have extensive experience working with younger students. They have the patience and the tenacity to handle young learners and are focused on student achievement. They create an environment of learning and joyous interactions, focusing on critical subjects that matter.</p>
                    <p>Our elementary tutors implement effective strategies to support all kinds of learners, encouraging them to achieve academic excellence through guided exercises that zero in on the areas where the learner needs the most help. We value each young learner and aim to always provide a positive learning experience for them so they may succeed through middle school, high school, and beyond.</p>
                </div>
                <div class="col-md-6 d-flex justify-content-center"><img class="my-3" src={stayInformed} alt="Elementary School Student" /></div>
            </div>
            <div class="row d-flex my-5">
                <div class="col-md-6 d-flex justify-content-center"><img class="my-3" src={lessonPlan} alt="elementary tutors" /></div>
                <div class="col-md-6 justify-content-center text-center">
                    <h2>Interactive Lessons to Engage Young Students</h2>
                    <p class="my-3">We make learning fun! Because our learners are young, tutors use creative teaching methods that include games and puzzles, to pique young learners’ curiosity and encourage them to learn while at play. Problem-solving, pattern matching, reading, and writing exercises have never been this exciting. Tutors are focused on developing core skills in math, reading, writing, and science to guide learners to academic excellence.</p>
                    <p>At Wisepal, you can find a passionate elementary school tutor to match your child’s personality and learning ability in each subject.</p>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-6 justify-content-center text-center">
                    <h2>Tutoring Strategies &amp; Skills</h2>
                    <p class="my-3">Our elementary tutors provide individualized lessons that support students as they learn. Their objective is to help each student achieve his or her full learning potential. Tutors equip them with the right knowledge and skills so they are confident in their abilities when they go to class. We hone champion learners who are not afraid to ask questions and are always open to new learning experiences that allow them to go higher and achieve more in school.</p>
                    <p>It’s a very simple equation — if they follow a regular routine of tutoring sessions that complement their studies, their learning pace is more efficient, and they will be positioned to achieve better grades.</p>
                    <p class="my-3">We source, select and hire only the best and most qualified elementary tutors. They have extensive experience in curriculum and lesson plan development. Their teaching methods and executions are continually upgraded because at Wisepal we value upskilling and perpetual learning for our staff, too.</p>
                </div>
                <div class="col-md-6 d-flex justify-content-center"><img class="my-3" src={tutoringStudent} alt="Student Tutoring" /></div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row py-5 btn-prpl">
                <div class="col-md-12 my-5 text-center">
                    <h2>Book a Lesson with an Expert Tutor!</h2>
                    <a href="https://wisepal.co/signup">
                    <button type="button" class="btn btn-orng btn-lg rounded-pill my-3">Sign Up Today!</button>
                </a>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row my-5">
                <div class="col-md-6 d-flex justify-content-center"><img class="my-3" src={happyStudent} alt="Lesson Plan" /></div>
                <div class="col-md-6 justify-content-center">
                    <h2 class="justify-content-center text-center">Personalized Lesson Plan</h2>
                    <ol class="my-3">
                        <li>Pre-testing</li>
                        <li>Determining Learning Styles</li>
                        <li>Feedback with Parents </li>
                        <li>Practice Tests</li>
                        <li>Goal Setting</li>
                    </ol>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-6 justify-content-center text-center">
                    <h2>We Keep You Informed and Involved</h2>
                    <p class="my-3">We value parents’ feedback and believe the education of a child is a community effort. We always keep parents in the loop by providing timely and detailed progress reports. Our communication lines are always open and parents may discuss their child’s tutoring progress as often as needed.</p>
                </div>
                <div class="col-md-6 d-flex justify-content-center"><img class="my-3" src={happyStudentTwo} alt="Stay Informed" /></div>
            </div>
            <div class="row my-5">
                <div class="col-md-6 d-flex justify-content-center"><img class="my-3" src={studentTutoring} alt="Happy Student" /></div>
                <div class="col-md-6 justify-content-center text-center">
                    <h2>How Can We Help Your Child Succeed?</h2>
                    <p class="my-3">At Wisepal, we believe that every child has potential for greatness. It is our goal, our duty, and our passion to bring out this potential in our learners.</p>
                    <p>If your child has been struggling with math, reading, writing, spelling, science, or any other subject, connect with us and browse our roster of elementary education tutors. We’ll help you find the best tutor for your child’s specific academic needs and schedule.</p>
                    <p class="my-3">We are committed to providing exceptional customer service, and, needless to say, quality educational products and services. We go above and beyond just tutoring your child by embracing their academic needs and providing them with a safe and fun learning environment.</p>
                    <p>Our goal is to give support and encourage learning for students who need it most. We thrive on providing solutions to your child’s academic needs.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h2>FAQs</h2>
                </div>
            </div>
            <div class="row my-5">
                <div class="col-md-12">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button btn-prpl" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                How Much Does an Elementary School Tutor Cost?
                            </button>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <span>Tutor rates vary based on their educational level and experience. Our tutors’ price range is displayed on our website.</span>.
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button btn-prpl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              How do your tutors break down concepts for young learners?
                            </button>
                          </h2>
                          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <span>Pre-tests and continued assessments help us understand which concepts your elementary-age child is struggling with so we can help them improve. Our tutors do a thorough assessment and consider the learner’s performance in class. A lesson plan is customized based on these concepts that need more practice and reinforcement.</span>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button btn-prpl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              What does your tutor do to help elementary students who are tired, frustrated, or stuck?
                            </button>
                          </h2>
                          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <span>Students have a long day at school and may even be frustrated by some of the tasks they are having trouble understanding. When the school day is over, they are ready for a break. A tutor will be patient and break down concepts despite competing with a waning attention span. They may do this in a variety of ways including shorter bursts, breaks, and making it fun through interactive lessons.</span>
                            </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button btn-prpl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                              What do your tutors do to encourage elementary students who struggle?
                            </button>
                          </h2>
                          <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <span>Elementary tutors employ strategies of positive reinforcement, focusing on the child’s interests. Different learning styles are rotated to encourage fun learning. Parents may also want to establish learning-centered rewards.</span>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button btn-prpl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                              Is a tutor worth it for an elementary school student?
                            </button>
                          </h2>
                          <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <span>Incorporating a regular tutoring routine alongside your child’s learning in school results in better learning habits and a more efficient learning pace. This improves school performance, yielding better grades and more self-confidence, which sets children up for continued success throughout middle school, high school, and college. These benefits make tutoring worthwhile for many students.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ESTutoringPageComponent.defaultProps = {
  currentUserListing: null,
  currentUser: null,
  currentUserListingFetched: false,
  reviews: [],
};

ESTutoringPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  reviews: arrayOf(propTypes.review),
  isAuthenticated: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const {
    reviews,
  } = state.Reviews;

  return {
    reviews,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetUsersReviews: () => dispatch(getUsersReviews()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
// 
// See: https://github.com/ReactTraining/react-router/issues/4671
const ESTutoringPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ESTutoringPageComponent);

export default ESTutoringPage;