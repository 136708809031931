import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import css from './SectionOnlineTeaching.css';

import image1 from './images/online-teaching-img1.jpg';
import image2 from './images/online-teaching-img2.jpg';
import image3 from './images/online-teaching-img3.jpg';
import image4 from './images/online-teaching-img4.jpg';
import image5 from './images/online-teaching-img5.jpg';

const SectionOnlineTeaching = () => {
  
  return (
    <>
      <div className={css.title}>
        <FormattedMessage id="SectionOnlineTeaching.title" />
      </div>
      <div className={css.subTitle}>
        <FormattedMessage id="SectionOnlineTeaching.subtitle" />
      </div>

      <div className={css.onlineTeachingWrapper}>
        <div className={css.onlineTeachingItem}>
          <ul className={css.onlineTeachingList}>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.audio" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.audioText" />
            </li>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.webcam" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.webcamText" />
            </li>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.recordings" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.recordingsText" />
            </li>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.screenSharing" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.screenSharingText" />
            </li>
          </ul>
          <img className={css.onlineTeachingImg} src={image1} alt="Interactive board image" />
        </div>
        <div className={css.onlineTeachingItem}>
          <img className={css.onlineTeachingImg} src={image2} alt="Interactive board image" />
          <ul className={css.onlineTeachingList}>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.chat" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.chatText" />
            </li>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.breakoutRooms" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.breakoutRoomsText" />
            </li>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.sharedNotes" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.sharedNotesText" />
            </li>
          </ul>
        </div>
        <div className={css.onlineTeachingItem}>
          <ul className={css.onlineTeachingList}>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.polling" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.pollingText" />
            </li>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.uploadPresentations" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.uploadPresentationsText" />
            </li>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.shareVideo" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.shareVideoText" />
            </li>
          </ul>
          <img className={css.onlineTeachingImg} src={image3} alt="Interactive board image" />
        </div>
        <div className={css.onlineTeachingItem}>
          <img className={css.onlineTeachingImg} src={image4} alt="Interactive board image" />
          <ul className={css.onlineTeachingList}>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.multiUser" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.multiUserText" />
            </li>
            <li className={css.onlineTeachingListItem}>
              <div className={css.onlineTeachingListItemTitle}>
                <FormattedMessage id="SectionOnlineTeaching.codingEditor" />
              </div>
              <FormattedMessage id="SectionOnlineTeaching.codingEditorText" />
            </li>
          </ul>
        </div>
        <div className={css.onlineTeachingItem}>
          <img className={classNames(css.onlineTeachingImg, css.onlineTeachingImgSmall)} src={image5} alt="Interactive board image" />
          <h3 className={css.findTtutorText}>
            <FormattedMessage id="SectionOnlineTeaching.findTutorText" />
          </h3>
          <NamedLink name="SearchPage" className={css.browseBtn}>
            <FormattedMessage id="SectionOnlineTeaching.browseBtn" />
          </NamedLink>
        </div>
      </div>

    </>
  );
};

SectionOnlineTeaching.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionOnlineTeaching.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionOnlineTeaching;
