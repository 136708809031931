import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionTopicsUpdatesMaybe = props => {
  const { publicData } = props;

  if (!publicData.topicsUpdates) return null;

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.topicsUpdatesTitle" />
      </h2>
      <p>{publicData.topicsUpdates}</p>
    </div>
  );
};

export default SectionTopicsUpdatesMaybe;
