import { storableError } from '../util/errors';
import { fetchCurrentUser } from './user.duck';
import { updateProfile } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { createUserEntry } from './user.duck';
import axios from 'axios';

const API_PROPROFS_URL = process.env.REACT_APP_PROPROFS_URL_V3;
const RAILS_API_URL = process.env.REACT_APP_RAILS_API_URL;

// ================ Action types ================ //

export const VERIFICATION_REQUEST = 'app/EmailVerification/VERIFICATION_REQUEST';
export const VERIFICATION_SUCCESS = 'app/EmailVerification/VERIFICATION_SUCCESS';
export const VERIFICATION_ERROR = 'app/EmailVerification/VERIFICATION_ERROR';

export const REGISTRATION_FLEXIQUIZ_REQUEST =
  'app/EmailVerification/REGISTRATION_FLEXIQUIZ_REQUEST';
export const REGISTRATION_FLEXIQUIZ_SUCCESS =
  'app/EmailVerification/REGISTRATION_FLEXIQUIZ_SUCCESS';
export const REGISTRATION_FLEXIQUIZ_ERROR = 'app/EmailVerification/REGISTRATION_FLEXIQUIZ_ERROR';

// ================ Reducer ================ //

const initialState = {
  isVerified: false,

  // verification
  verificationError: null,
  verificationInProgress: false,
  registrationFlexiuzInProgress: false,
  registrationFlexiuzInError: null,
  isRegisteredOnFlexiquiz: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case VERIFICATION_REQUEST:
      return {
        ...state,
        verificationInProgress: true,
        verificationError: null,
      };
    case VERIFICATION_SUCCESS:
      return { ...state, verificationInProgress: false, isVerified: true };
    case VERIFICATION_ERROR:
      return { ...state, verificationInProgress: false, verificationError: payload };
    case REGISTRATION_FLEXIQUIZ_REQUEST:
      return {
        ...state,
        registrationFlexiuzInProgress: true,
        registrationFlexiuzInError: null,
      };
    case REGISTRATION_FLEXIQUIZ_SUCCESS:
      return {
        ...state,
        registrationFlexiuzInProgress: false,
        registrationFlexiuzInError: null,
        isRegisteredOnFlexiquiz: true,
      };
    case REGISTRATION_FLEXIQUIZ_ERROR:
      return {
        ...state,
        registrationFlexiuzInProgress: false,
        registrationFlexiuzInError: payload,
        isRegisteredOnFlexiquiz: false,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const verificationInProgress = state => {
  return state.EmailVerification.verificationInProgress;
};

// ================ Action creators ================ //

export const verificationRequest = () => ({ type: VERIFICATION_REQUEST });
export const verificationSuccess = () => ({ type: VERIFICATION_SUCCESS });
export const verificationError = error => ({
  type: VERIFICATION_ERROR,
  payload: error,
  error: true,
});

export const registrationFlexiquizRequest = () => ({ type: REGISTRATION_FLEXIQUIZ_REQUEST });
export const registrationFlexiquizSuccess = () => ({ type: REGISTRATION_FLEXIQUIZ_SUCCESS });
export const registrationFlexiquizError = error => ({
  type: REGISTRATION_FLEXIQUIZ_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const regOnFlexQuiz = user => async dispatch => {
  dispatch(registrationFlexiquizRequest());

  const createNewUserParams = {
    email: user.attributes.email,
    id: user.id.uuid,
    fname: user.attributes.profile.firstName,
    lname: user.attributes.profile.lastName
  };

  try {
    const quizUser = await axios.post(
      `${RAILS_API_URL}/api/v1/users/create_user_proprofs`, createNewUserParams
    );

    dispatch(registrationFlexiquizSuccess());
    dispatch(updateProfile({ protectedData: { proProfsUserId: quizUser.data.uid } }));
  } catch (e) {
    dispatch(registrationFlexiquizError(e));
  }
};

export const verify = verificationToken => (dispatch, getState, sdk) => {
  if (verificationInProgress(getState())) {
    return Promise.reject(new Error('Email verification already in progress'));
  }
  dispatch(verificationRequest());

  // Note that the thunk does not reject when the verification fails, it
  // just dispatches the login error action.
  return sdk.currentUser
    .verifyEmail({ verificationToken })
    .then(() => dispatch(verificationSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .then(user => {
      dispatch(createUserEntry());
      return user;
    })
    .then(user => {
      if (user.attributes.profile.privateData.userType.toLowerCase() !== 'tutor') {
        return;
      }
      dispatch(regOnFlexQuiz(user));
    })
    .catch(e => dispatch(verificationError(storableError(e))));
};
