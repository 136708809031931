import React from 'react';
import {  bool, func } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import css from './SubscribeForm.css';
import * as validators from '../../util/validators';
import { compose } from 'redux';

export class SubscribeFormComponent extends React.Component {
  handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.currentTarget[0].value;
    await this.props.onSubmit(email);
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const { inProgress, ready, className, invalid, submitInProgress, form, intl } = formRenderProps;
          const classes = className ? className : css.form;
          const emailValidMessage = "Please enter a valid email";
          const emailRequired = validators.required(emailValidMessage);
          const emailValid = validators.emailFormatValid(emailValidMessage);
          const submitDisabled = invalid || submitInProgress;

          const emailPlaceholder = intl.formatMessage({
            id: 'SectionSubscribe.subscribeFormPlaceholder',
          });

          return (
            <Form
              className={classes}
              onSubmit={event => {
                const promise = this.handleSubmit(event);
                promise &&
                promise.then(() => {
                  form.restart();
                });
                return promise;
              }}>
              <FieldTextInput
                className={css.field}
                inputClass={css.input}
                type="text"
                id="SubscribeForm.email"
                name="email"
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />

              <PrimaryButton
                type="submit"
                inProgress={inProgress}
                ready={ready}
                disabled={submitDisabled}
                className={css.submitButton}
              >
                <FormattedMessage id="SectionSubscribe.subscribeFormBtn" />
              </PrimaryButton>
            </Form>
          );
        }}
      />
    );
  }
}

SubscribeFormComponent.defaultProps = {
  onSubmit: null,
  inProgress: false,
  ready: false,
  submitDisabled: false,
};

SubscribeFormComponent.propTypes = {
  onSubmit: func,
  inProgress: bool,
  ready: bool,
  submitDisabled: bool,
  intl: intlShape.isRequired,
};

const SubscribeForm = compose(injectIntl)(SubscribeFormComponent);

export default SubscribeForm;
