import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { openContactModal, closeContactModal, postContactUsFormData } from '../../ducks/contactUs.duck';
import { Modal } from '../../components';
import { subscribe } from '../../ducks/SubscribeForm.duck';
import { SubscribeForm } from '../../forms';
import css from './SectionSubscribe.css';

const SectionSubscribeComponent = props => {
  const {
    onManageDisableScrolling,
    modalIsOpen,
    onOpenModalContactUsForm,
    onCloseModalContactUs,
    onPostContactUsFormData,
    tokenCaptcha,
    sendDataError,
    onSubscribe,
    data,
    subscribeSuccess,
    subscribeError
  } = props;

  const [isOpenModal, setIsOpenModal] = useState(modalIsOpen);

  useEffect(() => {
    setIsOpenModal(modalIsOpen);
  }, [modalIsOpen]);

  const subscribeErrorMessage = subscribeError ? data : null;

  const subscribeSuccessMessage = subscribeSuccess ? (
    <FormattedMessage id="SectionSubscribe.subscribeSuccessMessage" />
  ) : null;

  return (
    <>
      <div className={css.subscribeBlock}>
        <div className={css.title}>
          <FormattedMessage id="SectionSubscribe.haveQuestions" />
        </div>

        <div className={css.contactUsBtn} onClick={() => onOpenModalContactUsForm()}>
          <FormattedMessage id="SectionSubscribe.button" />
        </div>
      </div>

      <div className={css.subscribeBlock}>
        <p className={css.subscribeFormText}>
          <FormattedMessage id="SectionSubscribe.subscribeFormText" />
        </p>

        <SubscribeForm onSubmit={onSubscribe}/>

        <div className={css.subscribeMessageHolder}>
          {subscribeError && <p className={classNames(css.subscribeMessageItem, css.subscribeMessageError)}>
            {subscribeErrorMessage}
          </p>}
          {subscribeSuccess && <p className={classNames(css.subscribeMessageItem, css.subscribeMessageSuccess)}>
            {subscribeSuccessMessage}
          </p>}
        </div>
      </div>

      <div className={css.modalContainer}>
        <Modal
          id="MissingInformationReminder"
          onManageDisableScrolling={onManageDisableScrolling}
          isOpenModal={isOpenModal}
          isCloseModal={onCloseModalContactUs}
          onPostContactUsFormData={onPostContactUsFormData}
          tokenCaptcha={tokenCaptcha}
          sendDataError={sendDataError}
        />
      </div>
    </>
  );
};

SectionSubscribeComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionSubscribeComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const mapStateToProps = state => {
  const { modalIsOpen, tokenCaptcha, sendDataError } = state.contactUs;
  const { data, subscribeSuccess, subscribeError } = state.SubscribeForm;

  return {
    modalIsOpen,
    tokenCaptcha,
    sendDataError,
    data,
    subscribeSuccess,
    subscribeError
  };
};

const mapDispatchToProps = dispatch => ({
  onSubscribe: (email) => dispatch(subscribe(email)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onOpenModalContactUsForm: () => dispatch(openContactModal()),
  onCloseModalContactUs: () => dispatch(closeContactModal()),
  onPostContactUsFormData: (formData) => dispatch(postContactUsFormData(formData)),
});

const SectionSubscribe = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SectionSubscribeComponent);


export default SectionSubscribe;
