import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Button, Form } from '../../components';
import css from './EditListingCancelationForm.css';
import { customUpdateListing } from '../../containers/EditListingPage/EditListingPage.duck';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const MIN_VALUE = 0;
const MAX_VALUE = 72;
const DEF_PERCENT = 100;
const MIN_PERCENT = 0;
const DEFAULT_CANCELLATION = [
  {from: MAX_VALUE, to: MIN_VALUE, percent: DEF_PERCENT, errorfrom: null, errorto: null, errorpercent: null}
];

export const EditListingCancelationFormComponent = props => {
  const cancellations = props.currentListing.attributes.publicData.cancelations;
  const { intl } = props;
  const [selectValue, setSelectValue] = useState(cancellations || DEFAULT_CANCELLATION);

  const updateCancellation = () => {
    const data = selectValue ? selectValue : [];
    const id = props.currentListing.id
    props.onCustomUpdateListing(data, id);
  }

  const from = <FormattedMessage id="EditListingPricingForm.from" />
  const fromPlaceholder = intl.formatMessage({ id: "EditListingPricingForm.from"});
  const toPlaceholder = intl.formatMessage({ id: "EditListingPricingForm.to"});
  const percentPlaceholder = intl.formatMessage({ id: "EditListingPricingForm.percent"});
  const to = <FormattedMessage id="EditListingPricingForm.to" />
  const percent = <FormattedMessage id="EditListingPricingForm.percent" />

  const handleRules = (value, index) => {
    setSelectValue(selectValue => {
      const _selectValue = [...selectValue];

      _selectValue[index].to = value;

      if (!!_selectValue[index + 1]){
        _selectValue[index + 1].from = value;
      }

      return _selectValue;
    });
  }

  const addRow = from => {
    setSelectValue(selectValue => {
      return [
        ...selectValue,
        {
          from,
          to: MIN_VALUE,
          percent: DEF_PERCENT,
          errorfrom: null,
          errorto: null,
          errorpercent: null
        }];
    });
  }

  const removeRows = index => {
    setSelectValue(selectValue => selectValue.slice(0, index));
  }

  const changePercentValue = (value, index) => {
    setSelectValue(selectValue => {
      const _selectValue = [...selectValue];
      let _value = isNaN(+value) || value < MIN_PERCENT ? MIN_PERCENT : value > 100 ? 100 : +value;
      _selectValue[index].percent = _value;
      return _selectValue;
    });
  }

  const changeTo = (value, index) => {
    const lastIndex = selectValue.length === index + 1;
    const from = selectValue[index].from;
    const _value = isNaN(+value) || value < MIN_VALUE ? MIN_VALUE : value > from - 1 ? from - 1 : +value;
    
    if (lastIndex && _value != MIN_VALUE){
      addRow(_value - 1);
    }

    if (!lastIndex && _value == MIN_VALUE) {
      removeRows(index + 1)
    }

    handleRules(_value, index);
  }

  return (
    <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        ready,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors
      } = formRenderProps;
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={(values)=>{updateCancellation();handleSubmit(values); }} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <table className={css.transactionTable}>
            <thead>
              { selectValue.length != 0 ? (
                <tr>
                  <th>{from}</th>
                  <th>{to}</th>
                  <th>{percent}</th>
                </tr>
              ) : null}
            </thead>

            <tbody>
            {selectValue.map((item, index) =>
              <tr className={css.tdMargin} key={String(index)}>
                <td className={css.inputTd}>
                  {item.errorfrom ? (
                    <span className={css.errorTd}>{item.errorfrom}</span>
                  ) : null}
                  <input
                    name='from'
                    type="text"
                    placeholder={fromPlaceholder}
                    pattern="[0-9]*"
                    value={item.from}
                    disabled
                  />
                </td>
                <td className={css.inputTd}>
                {item.errorto ? (
                  <span className={css.errorTd}>{item.errorto}</span>
                  ) : null}
                  <input
                    name='to'
                    type="text"
                    placeholder={toPlaceholder}
                    pattern="[0-9]*"
                    value={item.to}
                    onChange={e => changeTo(e.target.value, index)}
                  />
                </td>
                <td className={css.inputTd}>
                {item.errorpercent ? (
                <span className={css.errorTd}>{item.errorpercent}</span>
                  ) : null}
                  <input
                    name='percent'
                    type="text"
                    placeholder={percentPlaceholder}
                    pattern="[0-9]*"
                    value={item.percent}
                    onChange={e=>changePercentValue(e.target.value, index)}
                  />
                </td>
              </tr>
            )}
            </tbody>
          </table>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />)

};

EditListingCancelationFormComponent.defaultProps = { fetchErrors: null };

EditListingCancelationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

const mapStateToProps = state => {return {}};

const mapDispatchToProps = dispatch => ({
  onCustomUpdateListing: (data, id) => dispatch(customUpdateListing(data, id))
});

const EditListingCancelationForm = compose(
withRouter,
connect(
    mapStateToProps,
    mapDispatchToProps
),
injectIntl
)(EditListingCancelationFormComponent);

export default EditListingCancelationForm;
