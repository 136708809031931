import React from 'react';
import {  bool, func } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import css from './ReferralSystemForm.css';
import * as validators from '../../util/validators';
import { compose } from 'redux';

export class ReferralSystemFormComponent extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const email = e.currentTarget[0].value;
    this.props.onSubmit(email);
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            inProgress,
            ready,
            className,
            invalid,
            emailInviteSuccess,
            emailInviteError,
            emailInviteProgress,
            submitInProgress,
            intl,
            onChangeEmail,
            email,
            inviteSuccessEmail,
          } = fieldRenderProps;
          const classes = className ? className : css.form;
          const emailValidMessage = "Please enter a valid email";
          const emailRequired = validators.required(emailValidMessage);
          const emailValid = validators.emailFormatValid(emailValidMessage);
          const submitDisabled = invalid || submitInProgress || !email;
          const emailPlaceholder = intl.formatMessage({
            id: 'ReferralSystem.referralSystemFormPlaceholder',
          });
          const inviteError = emailInviteError ? intl.formatMessage({
            id: 'ReferralSystem.inviteError'
          }) : null;
          const invitePending = emailInviteProgress ? intl.formatMessage({
            id: 'ReferralSystem.invitePending',
          }) : null;
          const inviteSuccess = emailInviteSuccess ? intl.formatMessage({
            id: 'ReferralSystem.inviteSuccess'
          }, {email: inviteSuccessEmail}) : null;

          return (
            <>
              <Form
                className={classes}
                onSubmit={e => {
                  e.preventDefault();
                  this.props.onSubmit(e);
                }}
              >
                <FieldTextInput
                  className={css.field}
                  type="text"
                  id="ReferralSystemForm.email"
                  name="email"
                  placeholder={emailPlaceholder}
                  validate={email && validators.composeValidators(emailRequired, emailValid)}
                  onChange={e => onChangeEmail(e)}
                />
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={ready}
                  disabled={submitDisabled}
                  className={css.submitButton}
                >
                  <FormattedMessage id="ReferralSystem.referralSystemFormBtn" />
                </PrimaryButton>
              </Form>
              {inviteSuccess}
              {invitePending}
              {inviteError}
            </>
          );
        }}
      />
    );
  }
}

ReferralSystemFormComponent.defaultProps = {
  onSubmit: null,
  inProgress: false,
  ready: false,
  submitDisabled: false,
};

ReferralSystemFormComponent.propTypes = {
  onSubmit: func,
  inProgress: bool,
  ready: bool,
  submitDisabled: bool,
  intl: intlShape.isRequired,
};

const ReferralSystemForm = compose(injectIntl)(ReferralSystemFormComponent);

export default ReferralSystemForm;
