import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingCard.css';

const checkSelected = (options, selectedOptions) => {
  return options.map(option => ({
    key: option.key,
    label: option.label,
    isSelected: includes(selectedOptions, option.key),
  }));
};

const Item = props => {
  const { label, isSelected, price, durationH, durationM } = props;
  const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;
  return (
    <li className={css.tutorSubjectsItem}>
      {`${label} - $ ${price}/ ${durationH ? `${durationH}h` : ''} ${
        durationM ? `${durationM}m` : ''
      }`}
    </li>
  );
};

const PropertyGroup = props => {
  const {
    rootClassName,
    className,
    id,
    options,
    selectedOptions,
    twoColumns,
    sessionType,
    subjectsPrice,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const checked = options && checkSelected(options, selectedOptions);

  return (
    <div>
      <p className={css.sessionType}>{sessionType}</p>
      <ul className={css.tutorSubjectsList}>
        {checked &&
          checked.map(option => {
            const price = parseInt(subjectsPrice[option.key]) / 100;
            const durationH =
              subjectsPrice[`${option.key}_duration`] &&
              subjectsPrice[`${option.key}_duration`].hours ?
              subjectsPrice[`${option.key}_duration`].hours : 1
            const durationM =
              subjectsPrice[`${option.key}_duration`] &&
              subjectsPrice[`${option.key}_duration`].minutes &&
              subjectsPrice[`${option.key}_duration`].minutes;
            return (
              <Item
                key={`${id}.${option.key}`}
                label={option.label}
                isSelected={option.isSelected}
                price={price}
                durationH={durationH}
                durationM={durationM}
              />
            );
          })}
      </ul>
    </div>
  );
};

PropertyGroup.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

PropertyGroup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ),
  selectedOptions: arrayOf(string),
  twoColumns: bool,
};

const ListingCardSubjects = props => {
  const { options, publicData, sessionType, subjectsPrice } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.subjectItems ? publicData.subjectItems : [];
  const selectedConfigOptions =
    options && options.filter(o => selectedOptions.find(s => s === o.key));

  return (
    <PropertyGroup
      id="ListingPage.subjectItems"
      options={selectedConfigOptions}
      sessionType={sessionType}
      subjectsPrice={subjectsPrice}
    />
  );
};

export default ListingCardSubjects;
