import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  editExperience,
  deleteExperience,
  editExperienceSectionReset,
  deleteExperienceSectionReset,
} from '../../containers/AboutPage/AboutPage.duck';
import { ExperienceEditor, Modal } from '../index';
import css from './ExperienceSectionItem.css';
import { FormattedMessage } from '../../util/reactIntl';

const ExperienceSectionItemComponent = props => {
  const {
    item,
    isAuthenticated,
    currentUser,
    onManageDisableScrolling,
    onEditExperience,
    onDeleteExperience,
    editingExperienceError,
    editingExperienceSuccess,
    onEditExperienceSectionReset,
    deletingExperienceError,
    deletingExperienceSuccess,
    onDeleteExperienceSectionReset,
    createExperienceError,
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (editingExperienceSuccess) {
      setModalOpen(false);
    }
  }, [editingExperienceSuccess]);

  useEffect(() => {
    if (deletingExperienceSuccess) {
      setConfirmModalOpen(false);
    }
  }, [deletingExperienceSuccess]);

  const handleClose = () => {
    setModalOpen(false);
    onEditExperienceSectionReset();
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    onDeleteExperienceSectionReset();
    setConfirmModalOpen(false);
  };

  const handleDeleteBtn = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmButton = e => {
    e.persist();
    onDeleteExperienceSectionReset();
    const confirm = e.currentTarget.value;
    if (confirm === 'true') {
      onDeleteExperience(item.id);
    } else {
      setConfirmModalOpen(false);
    }
  };

  const userType =
    isAuthenticated &&
    currentUser &&
    currentUser.attributes.profile.protectedData.userType.toLowerCase();

  const editBtn = userType === 'superadmin' && (
    <button onClick={handleOpen} className={css.editButton}>
      <FormattedMessage id="AboutPage.editBtnText" />
    </button>
  );
  const deleteBtn = userType === 'superadmin' && (
    <button onClick={handleDeleteBtn} className={css.deleteButton}>
      <FormattedMessage id="AboutPage.deleteBtnText" />
    </button>
  );

  const handleSave = data => {
    onEditExperienceSectionReset();
    onEditExperience(item.id, data);
  };

  return (
    <>
      <div className={css.editBtnsWrap}>
        {editBtn}
        {deleteBtn}
      </div>
      <div className={css.aboutUsListItem}>
        <div className={css.aboutUsListItemImg}>
          <img className={css.listImage} src={item.image_file_name} alt="Personalized learning" />
        </div>
        <div className={css.aboutUsListItemContent}>
          <span className={css.aboutUsListItemTitle}>{item.title}</span>
          <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
        </div>

        <Modal
          id="AboutPage.addNewAnnouncement"
          isOpen={isModalOpen}
          onClose={handleClose}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <ExperienceEditor
            editorTitle="AboutPage.editTitleText"
            makeRequestFunction={data => handleSave(data)}
            item={item}
            editingError={editingExperienceError}
            edit={true}
          />
        </Modal>
        <Modal
          id="AboutPage.confirmDelete"
          isOpen={isConfirmModalOpen}
          onClose={handleConfirmModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.confirmDeleteWrap}>
            <span className={css.confirmTitle}>
              <FormattedMessage id="AboutPage.confirmDeletePostMessage" />
            </span>
            <div className={css.confirmDeleteBtnsWrap}>
              <button value={true} className={css.deleteButton} onClick={handleConfirmButton}>
                <FormattedMessage id="ConfirmModal.yesBtn" />
              </button>
              <button value={false} className={css.editButton} onClick={handleConfirmButton}>
                <FormattedMessage id="ConfirmModal.noBtn" />
              </button>
            </div>
            {deletingExperienceError ? (
              <p className={css.deleteError}>
                <FormattedMessage id="ConfirmModal.errorDeleting" />
              </p>
            ) : null}
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;

  return {
    isAuthenticated,
    currentUser,
  };

};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onEditExperience: (id, data) => {
    dispatch(editExperience(id, data));
  },
  onDeleteExperience: id => {
    dispatch(deleteExperience(id));
  },
  onEditExperienceSectionReset: () => {
    dispatch(editExperienceSectionReset());
  },
  onDeleteExperienceSectionReset: () => {
    dispatch(deleteExperienceSectionReset());
  },
});

const ExperienceSectionItem = compose(connect(mapStateToProps, mapDispatchToProps))(
  ExperienceSectionItemComponent
);

export default ExperienceSectionItem;
