import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingDescriptionForm } from '../../forms';
import { getUsersTransactions } from '../../ducks/Transactions.duck';

import {
  TRANSITION_EXPIRE,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_DECLINE,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_CUSTOMER,
  TRANSITION_CANCEL_PROVIDER,
  TRANSITION_CANCEL_PROVIDER_WITHOUT_REFUND,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_ENQUIRE
} from '../../util/transaction';

import css from './EditListingDescriptionPanel.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const FEATURES_NAME = 'lessonType';
const TRANSITIONS = [
  TRANSITION_EXPIRE,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_DECLINE,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_CUSTOMER,
  TRANSITION_CANCEL_PROVIDER,
  TRANSITION_CANCEL_PROVIDER_WITHOUT_REFUND,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_ENQUIRE
];

class EditListingDescriptionPanelComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.listing.author) {
      this.props.onGetUsersTransactions(this.props.listing.author.id.uuid);
    }
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      transactions
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { description, title, publicData } = currentListing.attributes;

    const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingDescriptionPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingDescriptionPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
    );

    let transactionCompleteCount = 0;
    let allowChangeSessionType = false;

    transactions.map(transaction => {
      if (TRANSITIONS.indexOf(transaction.attributes.lastTransition) !== -1) {
        transactionCompleteCount += 1;
      }
    });

    if (transactionCompleteCount === transactions.length) allowChangeSessionType = true;

    const education = publicData && publicData.education;
    const lessonType = publicData && publicData.lessonType;
    const sessionType = publicData && publicData.sessionType;
    const topicsUpdates = publicData && publicData.topicsUpdates;
    const rulesPolicies = publicData && publicData.rulesPolicies;
    const initiateBackgroundCheck = publicData &&  publicData.initiateBackgroundCheck;
    const rating = publicData && publicData.rating

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingDescriptionForm
          className={css.form}
          name={FEATURES_NAME}
          initialValues={{
            title,
            description,
            education,
            lessonType,
            sessionType,
            topicsUpdates,
            rulesPolicies,
            initiateBackgroundCheck: typeof initiateBackgroundCheck === 'boolean' && initiateBackgroundCheck.toString()
          }}
          saveActionMsg={submitButtonText}
          onSubmit={values => {
            const {
              title,
              description,
              certificate,
              education,
              lessonType,
              sessionType,
              topicsUpdates,
              rulesPolicies,
              initiateBackgroundCheck
            } = values;
            // this value needs for correct sorting listings on serachPage
            let updateValues = {
              title: title.trim(),
              description,
              publicData: {
                education,
                certificate,
                lessonType,
                sessionType,
                rulesPolicies,
                topicsUpdates,
              },
            };

            if (!rating && rating !== 0) updateValues.publicData.rating = 0;
            if (initiateBackgroundCheck) updateValues.publicData.initiateBackgroundCheck = initiateBackgroundCheck === 'true';

            onSubmit(updateValues);
          }}
          allowChangeSessionType={allowChangeSessionType}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      </div>
    );
  }
}

EditListingDescriptionPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

const mapStateToProps = state => {
  const { transactions } = state.Transactions;
  return {
    transactions
  };
};

const mapDispatchToProps = dispatch => ({
  onGetUsersTransactions: (userId) => dispatch(getUsersTransactions(userId))
});

const EditListingDescriptionPanel = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EditListingDescriptionPanelComponent);

export default EditListingDescriptionPanel;
