import React, { useState, useEffect }  from 'react';
import { func, string, bool } from 'prop-types';
import { compose } from 'redux';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { PrimaryButton, Captcha } from '../../components';

import css from './ContactUsForm.css';;

function ContuctUsFormComponent(props) {
  const { intl, onClose, onPostContactUsFormData, tokenCaptcha, sendDataError } = props;

  const [fileList, setFileList] = useState([]);
  const [previewFiles, setPreviewFiles] = useState(false);
  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);
  const [inputNameValue, setInputNameValue] = useState('');
  const [inputEmailValue, setInputEmailValue] = useState('');
  const [inputSubjectValue, setInputSubjectValue] = useState('');
  const [inputTextValue, setInputTextValue] = useState('');
  const [isDataSendError, setIsDataSendError] = useState(sendDataError);

  const messageLabelName = intl.formatMessage({
    id: 'ContactUsForm.labelName',
  });

  const messageLabelEmail = intl.formatMessage({
    id: 'ContactUsForm.labelEmail',
  });

  const messageLabelSubject = intl.formatMessage({
    id: 'ContactUsForm.labelSubject',
  });

  const messageLabelUserText = intl.formatMessage({
    id: 'ContactUsForm.labelUserText',
  });

  const messageButtonUpload = intl.formatMessage({
    id: 'ContactUsForm.buttonUpload',
  });

  function handleUploadFile(event) {
    const list = event.target.files;
    const data = fileList;

    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        data.push(list[i]);
      }
      setPreviewFiles(true);
    }
  }

  useEffect(() => {
    setFileList(fileList);
    setPreviewFiles(previewFiles);
  },[fileList, previewFiles]);

  useEffect(() => {
    if (inputNameValue !== '' && inputEmailValue !== '' && inputSubjectValue !== '' && inputTextValue !== '') {
      setButtonSubmitDisabled(false);
    } else {
      setButtonSubmitDisabled(true);
    }
  },[inputNameValue, inputEmailValue, inputSubjectValue, inputTextValue]);

  useEffect(() => {
    setIsDataSendError(sendDataError);
  },[sendDataError]);


  function habdleChange(e) {
    const type = e.target.name;

    switch (type) {
      case 'fullName':
        setInputNameValue(e.target.value);
        break;
      case 'email':
        setInputEmailValue(e.target.value);
        break;
      case 'subject':
        setInputSubjectValue(e.target.value);
        break;
      case 'text':
        setInputTextValue(e.target.value);
        break;

      default:
        break;
    }
  }

  async function onSubmit(e) {
    e.preventDefault();

    const formData = new FormData();

    fileList.map(file => {
      return (
        formData.append('attach', file)
      )
    });
    formData.append('name', inputNameValue);
    formData.append('email', inputEmailValue);
    formData.append('subject', inputSubjectValue);
    formData.append('body', inputTextValue);
    formData.append('token', tokenCaptcha);

    setFileList([]);
    setPreviewFiles(false);

    const sendSuccess = await onPostContactUsFormData(formData);

    if (sendSuccess) {
      setPreviewFiles(false);
      setFileList([]);
      setButtonSubmitDisabled(true);

      setInputNameValue('');
      setInputEmailValue('');
      setInputSubjectValue('');
      setInputTextValue('');

      onClose();
    }
  }

  function deleteFile(file, e) {
    e.preventDefault();

    const name = file.name;
    setFileList(fileList.filter(file => {
      return file.name !== name
    }))

    fileList.length === 1 && setPreviewFiles(false);
  }

  function captchaMessage() {
    return (
      <>
        <FormattedMessage id="ContactUsForm.captchaMessage" />
        {' '}
        <a href="https://policies.google.com/privacy">
          <FormattedMessage id="ContactUsForm.captchaPrivacyPolicy" />
        </a>
        {' '}
        <FormattedMessage id="ContactUsForm.captchaAnd" />
        {' '}
        <a href="https://policies.google.com/terms">
          <FormattedMessage id="ContactUsForm.captchaTermsOfService" />
        </a>
        {' '}
        <FormattedMessage id="ContactUsForm.captchaApply" />
      </>
    )
  }

  return (
    <div className="container">
        <form onSubmit={onSubmit}>
          <h2 className={css.heading}>
            <FormattedMessage id="ContactUsForm.heading" />
          </h2>

          <div className={css.formGroup}>
            <div className={css.field}>
              <label>
                {messageLabelName}
              </label>
              <input
                className={css.input}
                type="text"
                placeholder={intl.formatMessage({id: 'ContactUsForm.fullName'})}
                name="fullName"
                id="fullName"
                value={inputNameValue}
                onChange={(e) => habdleChange(e, 'name')}
              />
            </div>

            <div className={css.field}>
              <label>{messageLabelEmail}</label>
              <input
                className={css.input}
                type="email"
                placeholder={intl.formatMessage({id: 'ContactUsForm.email'})}
                name="email"
                id="email"
                value={inputEmailValue}
                onChange={(e) => habdleChange(e, 'email')}
              />
            </div>

            <div className={css.field}>
              <label>{messageLabelSubject}</label>
              <input
                className={css.input}
                type="text"
                placeholder={intl.formatMessage({id: 'ContactUsForm.subject'})}
                name="subject"
                id="subject"
                value={inputSubjectValue}
                onChange={(e) => habdleChange(e, 'subject')}
              />
            </div>

            <div className={css.field}>
              <label>{messageLabelUserText}</label>
              <textarea
                className={css.input}
                type="text"
                placeholder={intl.formatMessage({id: 'ContactUsForm.userText'})}
                name="text"
                id="text"
                value={inputTextValue}
                onChange={(e) => habdleChange(e, 'text')}
              />
            </div>

            {!previewFiles ?
              <div className={css.fieldFileUpload}>
                <label className={css.labelFileUpload} htmlFor="upload">{messageButtonUpload}</label>
                <input
                  className={css.inputFileUpload}
                  type="file"
                  onChange={handleUploadFile}
                  name="uploadFile"
                  multiple id="upload"
                  accept=".jpg, .jpeg, .pdf, .doc, .docx"/>
              </div> :
              <div>
                <ul>
                  {
                    fileList.map(file => {
                      return (
                        <li key={file.name} className={css.containerUploadFile}>
                          <div className={css.containerUploadFileName}>{file.name}</div>
                          <div className={css.containerUploadFileSize}>{`${Math.floor(file.size / 1000)}kb`}</div>
                          <button className={css.containerUploadFileButton} onClick={(e) => deleteFile(file, e)}>X</button>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            }
          </div>
          <Captcha />
          <PrimaryButton
            type="submit"
            disabled={buttonSubmitDisabled}
          >
            {isDataSendError ?
              <FormattedMessage id="ContactUsForm.buttonSubmitError" /> :
              <FormattedMessage id="ContactUsForm.buttonSubmit" />
            }
          </PrimaryButton>
          <p className={css.recaptchaMessage}>
            {captchaMessage()}
          </p>
        </form>
    </div>
  )
}

ContuctUsFormComponent.propTypes = {
  onSubmit: func,
  onClose: func.isRequired,
  onPostContactUsFormData: func.isRequired,
  tokenCaptcha: string,
  sendDataError: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContuctUsForm = compose(
  injectIntl
)(ContuctUsFormComponent);

ContuctUsForm.displayName = 'ContuctUsForm';

export default ContuctUsForm;
