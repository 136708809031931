import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { openContactModal, closeContactModal, postContactUsFormData } from '../../ducks/contactUs.duck';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  ModalContactUs
} from '../../components';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const FooterComponent = props => {
  const {
    rootClassName,
    className,
    intl,
    onManageDisableScrolling,
    modalIsOpen,
    onOpenModalContactUs,
    onCloseModalContactUs,
    onPostContactUsFormData,
    tokenCaptcha,
    sendDataError
  } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const [isOpenModal, setIsOpenModal] = useState(modalIsOpen);

  useEffect(() => {
    setIsOpenModal(modalIsOpen);
  }, [modalIsOpen]);

  return (
    <>
      <div className={classes}>
        <div className={css.topBorderWrapper}>
          <div className={css.content}>
            <div className={css.someLiksMobile}>{socialMediaLinks}</div>
            <div className={css.links}>
              <div className={css.organization} id="organization">
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <span className={css.logo}>
                    <Logo format="desktop" />
                  </span>
                </NamedLink>
                <div className={css.organizationInfo}>
                  <p className={css.organizationDescription}>
                    <FormattedMessage id="Footer.organizationDescription" />
                  </p>
                  <p className={css.organizationCopyright}>
                    <NamedLink name="LandingPage" className={css.copyrightLink}>
                      <FormattedMessage id="Footer.copyright" />
                    </NamedLink>
                  </p>
                </div>
              </div>
              <div className={css.infoLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="AboutPage" className={css.link}>
                      <FormattedMessage id="Footer.toAboutPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toHomePage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="FAQpage" className={css.link}>
                      <FormattedMessage id="Footer.toFAQPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="ReferralSystemPage" className={css.link}>
                      <FormattedMessage id="Footer.toInviteNewMembers" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.searches}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <div className={css.link} onClick={() => onOpenModalContactUs()}>
                      <FormattedMessage id="Footer.toContactPage" />
                    </div>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="HSTutoringPage" className={css.link}>
                      <FormattedMessage id="Footer.toHSTutoringPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="ESTutoringPage" className={css.link}>
                      <FormattedMessage id="Footer.toESTutoringPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="MSTutoringPage" className={css.link}>
                      <FormattedMessage id="Footer.toMSTutoringPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="CTutoringPage" className={css.link}>
                      <FormattedMessage id="Footer.toCTutoringPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="NYTutoringPage" className={css.link}>
                      <FormattedMessage id="Footer.toNYTutoringPage" />
                    </NamedLink>
                  </li>

                  {/*<li className={css.listItem}>*/}
                  {/*  <NamedLink name="AboutPage" to={{ hash: '#resources' }} className={css.link}>*/}
                  {/*    <FormattedMessage id="Footer.toResources" />*/}
                  {/*  </NamedLink>*/}
                  {/*</li>*/}
                </ul>
              </div>

              <div className={css.extraLinks}>
                <div className={css.someLinks}>{socialMediaLinks}</div>
                <div className={css.legalMatters}>
                  <ul className={css.tosAndPrivacy}>
                    <li>
                      <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.privacyPolicy" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                        <FormattedMessage id="Footer.termsOfUse" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={css.copyrightAndTermsMobile}>
              <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
                <FormattedMessage id="Footer.copyright" />
              </NamedLink>
              <div className={css.tosAndPrivacyMobile}>
                <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                  <FormattedMessage id="Footer.privacy" />
                </NamedLink>
                <NamedLink name="TermsOfServicePage" className={css.terms}>
                  <FormattedMessage id="Footer.terms" />
                </NamedLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.modalContainer}>
        <ModalContactUs
          id="MissingInformationReminder"
          onManageDisableScrolling={onManageDisableScrolling}
          isOpenModal={isOpenModal}
          isCloseModal={onCloseModalContactUs}
          onPostContactUsFormData={onPostContactUsFormData}
          tokenCaptcha={tokenCaptcha}
          sendDataError={sendDataError}
        />
      </div>
    </>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    modalIsOpen, tokenCaptcha, sendDataError
  } = state.contactUs;

  return {
    modalIsOpen,
    tokenCaptcha,
    sendDataError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onOpenModalContactUs: () => dispatch(openContactModal()),
  onCloseModalContactUs: () => dispatch(closeContactModal()),
  onPostContactUsFormData: (formData) => dispatch(postContactUsFormData(formData)),
});

const Footer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(FooterComponent);

export default Footer;
