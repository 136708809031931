import React, { Component } from 'react';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  SearchMap,
  ModalInMobile,
  Page,
  LayoutWrapperFooter,
  Footer,
  Modal,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { types as sdkTypes } from '../../util/sdkLoader';

import {
  searchListings,
  searchMapListings,
  setActiveListing,
  saveFavourites,
  queryFavoriteListings,
  resetFavouriteListings,
} from './SearchPage.duck';
import { sendEnquiry } from '../ListingPage/ListingPage.duck';
import { getAllSubjects } from '../AdminPanelPage/AdminPanelPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import { EnquiryForm } from '../../forms';
import MainPanel from './MainPanel';
import css from './SearchPage.css';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.
const { LatLng, LatLngBounds, UUID } = sdkTypes;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      enquiryModalOpen: false,
      listingTitle: '',
      authorDisplayName: '',
      listingId: null,
      favouriteList: [],
      requestInProgress: false
    };

    this.searchMapListingsInProgress = false;

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.setInProgress = this.setInProgress.bind(this);
  }

  getFavoriteListings = (delay) => {
    this.props.onGetFavoriteListings(
      {
        pub_listingFavourite: `has_any:${this.props.currentUser.id.uuid}`,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
          'variants.landscape-crop',
          'variants.landscape-crop2x',
          // Avatars
          'variants.square-small',
          'variants.square-small2x',
        ],
        'limit.images': 1,
        sort: 'pub_rating, createdAt',
      },
      delay
    );
  };

  componentDidMount() {
    this.props.onGetAllSubject();
    if (this.props.currentUser) {
      this.getFavoriteListings(0);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentUser !== prevProps.currentUser &&
      this.props.favouriteListings === null &&
      this.state.requestInProgress !== true
    ) {
      this.getFavoriteListings(0);
    }

    if (
      (this.props.favouriteListings &&
        prevProps.favouriteListings &&
        this.props.favouriteListings !== prevProps.favouriteListings &&
        this.props.favouriteListings.length === 0 &&
        prevProps.favouriteListings.length === 1) ||
      (this.props.favouriteListings &&
        this.props.favouriteListings !== prevProps.favouriteListings &&
        this.props.favouriteListings.length >= 0 &&
        prevProps.favouriteListings === null)
    ) {
      this.setState({ favouriteList: [...this.props.favouriteListings], requestInProgress: false });
    }
  }

  setInProgress() {
    this.setState({ requestInProgress: true });
  }

  filters() {
    const {
      backgroundCheckConfig,
      lessonTypeConfig,
      sessionTypeConfig,
      allSubjects,
      priceFilterConfig,
      keywordFilterConfig,
    } = this.props;

    // Note: "certificate" and "subjectItems" filters are not actually filtering anything by default.
    // Currently, if you want to use them, we need to manually configure them to be available
    // for search queries. Read more from extended data document:
    // https://www.sharetribe.com/docs/references/extended-data/#data-schema

    return {
      backgroundCheckFilter: {
        paramName: 'pub_backgroundCheck',
        options: backgroundCheckConfig.filter(c => !c.hideFromFilters),
      },
      sessionTypeFilter: {
        paramName: 'pub_sessionType',
        options: sessionTypeConfig.filter(l => !l.hideFromFilters),
      },
      lessonTypeFilter: {
        paramName: 'pub_lessonType',
        options: lessonTypeConfig.filter(l => !l.hideFromFilters),
      },
      subjectItemsFilter: {
        paramName: 'pub_subjectItems',
        options: allSubjects,
      },
      priceFilter: {
        paramName: 'pub_minPrice',
        config: priceFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
    };
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, this.filters()),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onContactUser(listingTitle, authorDisplayName, listingId) {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    this.setState({ listingTitle, authorDisplayName, listingId });

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      // callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(this.state.listingId);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      intl,
      listings,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      allSubjects,
      isAuthenticated,
      sendEnquiryInProgress,
      sendEnquiryError,
      currentUser,
      onSaveFavourites,
      saveFavouriteRequest,
      saveFavouriteSuccess,
      saveFavouriteError,
      favoriteListingsRequest,
      viewport,
      favoriteListingsSuccess,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const filters = this.filters();

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const boundsOfTheWorld = new LatLngBounds(new LatLng(85, -180), new LatLng(-85, 180));
    const { address, origin } = searchInURL || {};
    const bounds = searchInURL.bounds !== undefined ? searchInURL.bounds : boundsOfTheWorld;

    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.container}>
          <MainPanel
            urlQueryParams={validQueryParams}
            listings={listings}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            onMapIconClick={onMapIconClick}
            pagination={pagination}
            searchParamsForPagination={parse(location.search)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            primaryFilters={{
              subjectItemsFilter: filters.subjectItemsFilter,
              backgroundCheckFilter: filters.backgroundCheckFilter,
              lessonTypeFilter: filters.lessonTypeFilter,
              sessionTypeFilter: filters.sessionTypeFilter,
              priceFilter: filters.priceFilter,
              keywordFilter: filters.keywordFilter,
            }}
            allSubjects={allSubjects}
            onContactUser={this.onContactUser}
            onSaveFavourites={onSaveFavourites}
            currentUser={currentUser}
            saveFavouriteRequest={saveFavouriteRequest}
            saveFavouriteSuccess={saveFavouriteSuccess}
            saveFavouriteError={saveFavouriteError}
            favouriteListings={this.state.favouriteList}
            favoriteListingsRequest={favoriteListingsRequest}
            getFavoriteListings={this.getFavoriteListings}
            viewport={viewport}
            requestInProgress={this.state.requestInProgress}
            setInProgress={this.setInProgress}
            favoriteListingsSuccess={favoriteListingsSuccess}
          />
          <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.mapWrapper}>
              {shouldShowSearchMap ? (
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                  location={location}
                  listings={mapListings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                  allSubjects={allSubjects}
                />
              ) : null}
            </div>
          </ModalInMobile>
          <Modal
            id="SearchPage.enquiry"
            contentClassName={css.enquiryModalContent}
            isOpen={isAuthenticated && this.state.enquiryModalOpen}
            onClose={() => this.setState({ enquiryModalOpen: false })}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <EnquiryForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              listingTitle={this.state.listingTitle}
              authorDisplayName={this.state.authorDisplayName}
              sendEnquiryError={sendEnquiryError}
              onSubmit={this.onSubmitEnquiry}
              inProgress={sendEnquiryInProgress}
            />
          </Modal>
        </div>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  backgroundCheckConfig: config.custom.backgroundCheck,
  lessonTypeConfig: config.custom.lessonType,
  sessionTypeConfig: config.custom.sessionType,
  priceFilterConfig: config.custom.priceFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  backgroundCheckConfig: array,
  lessonTypeConfig: array,
  sessionTypeConfig: array,
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    saveFavouriteRequest,
    saveFavouriteSuccess,
    saveFavouriteError,
    favouriteListings,
    favoriteListingsRequest,
    favoriteListingsSuccess,
  } = state.SearchPage;

  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const { sendEnquiryInProgress, sendEnquiryError } = state.ListingPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  const recivedSubjects = state.AdminPanelPage.allSubjects;
  const allSubjects = recivedSubjects.filter(subject => subject.key !== 'initialQuiz');

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    allSubjects,
    currentUser,
    isAuthenticated,
    sendEnquiryInProgress,
    sendEnquiryError,
    saveFavouriteRequest,
    saveFavouriteSuccess,
    saveFavouriteError,
    favouriteListings,
    favoriteListingsRequest,
    favoriteListingsSuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onGetAllSubject: () => dispatch(getAllSubjects()),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onSaveFavourites: (listingId, userId, userFavourite, listingFavourite) =>
    dispatch(saveFavourites(listingId, userId, userFavourite, listingFavourite)),
  onGetFavoriteListings: (params, delay) => dispatch(queryFavoriteListings(params, delay)),
  onResetFavouriteListings: () => dispatch(resetFavouriteListings()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: 9,
    include: ['author', 'author.profileImage', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    'limit.images': 1,
  });
};

export default SearchPage;
