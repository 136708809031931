import { storableError } from '../../util/errors';
import { encryptBonus, decryptBonus } from '../../ducks/user.duck'
import axios from 'axios';


// ================ Action types ================ //

export const EMAIL_INVITE_REQUEST = 'app/ReferralSystemPage/EMAIL_INVITE_REQUEST';
export const EMAIL_INVITE_SUCCESS = 'app/ReferralSystemPage/EMAIL_INVITE_SUCCESS';
export const EMAIL_INVITE_ERROR = 'app/ReferralSystemPage/EMAIL_INVITE_ERROR';
export const REFERRALS_REQUEST = 'app/ReferralSystemPage/REFERRALS_REQUEST';
export const REFERRALS_SUCCESS = 'app/ReferralSystemPage/REFERRALS_SUCCESS';
export const REFERRALS_ERROR = 'app/ReferralSystemPage/REFERRALS_ERROR';

// ================ Environment ================ //

const API_URL = process.env.REACT_APP_API_URL;
const RAILS_API_URL = process.env.REACT_APP_RAILS_API_URL;

// ================ Reducer ================ //

const initialState = {
  referrals: [],
  emailInviteSuccess: false,
  emailInviteError: null,
  emailInviteProgress: false,
  referralsSuccess: false,
  referralsError: null,
  referralsProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case EMAIL_INVITE_REQUEST:
      return {
        ...state,
        emailInviteProgress: true,
        emailInviteError: null,
        emailInviteSuccess: false,
      };
    case EMAIL_INVITE_SUCCESS:
      return { ...state, emailInviteProgress: false, emailInviteSuccess: true};
    case EMAIL_INVITE_ERROR:
      return { ...state, emailInviteProgress: false, emailInviteError: payload };
    case REFERRALS_REQUEST:
      return {
        ...state,
        referrals: [],
        referralsProgress: true,
        referralsError: null,
        referralsSuccess: false,
      };
    case REFERRALS_SUCCESS:
      return { ...state, referralsProgress: false, referralsSuccess: true, referrals: payload };
    case REFERRALS_ERROR:
      return { ...state, referralsProgress: false, referralsError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const emailInviteRequest = () => ({ type: EMAIL_INVITE_REQUEST });
export const emailInviteSuccess = () => ({ type: EMAIL_INVITE_SUCCESS });
export const emailInviteError = error => ({
  type: EMAIL_INVITE_ERROR,
  payload: error,
});
export const referralsRequest = () => ({ type: REFERRALS_REQUEST });
export const referralsSuccess = (data) => ({ type: REFERRALS_SUCCESS, payload: data });
export const referralsError = error => ({
  type: REFERRALS_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const inviteMembers = (data) => async (dispatch)=> {
  dispatch(emailInviteRequest());

  return axios.post(`${RAILS_API_URL}/api/v1/users/invite`, data)
    .then(function (response) {
      if (response.status === 200) {
        dispatch(emailInviteError(null));
        dispatch(emailInviteSuccess());
        return true;
      } else {
        throw new Error('response is not ok');
      }
    })
    .catch(e => {
      dispatch(emailInviteError(e));
      return false
    });
};

export const referrals = (data) => async (dispatch)=> {
  const url = `${API_URL}/api/my-referrals?userId=${data}`;
  dispatch(referralsRequest(true));

  return axios.get(url)
    .then((response) => {
      return dispatch(referralsSuccess(response.data));
    })
    .catch(e => dispatch(referralsError(storableError(e))));
};


