import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import {
  editAboutSection,
  deleteAboutSection,
  editAboutReset,
  deleteAboutReset,
} from '../../containers/AboutPage/AboutPage.duck';
import { AboutEditor, Modal } from '../index';
import css from './AboutSectionItem.css';
import { FormattedMessage } from '../../util/reactIntl';

const AboutSectionItemComponent = props => {
  const {
    item,
    isAuthenticated,
    currentUser,
    onManageDisableScrolling,
    onEditAboutSection,
    onDeleteAboutSection,
    editingAboutError,
    editingAboutSuccess,
    onEditAboutReset,
    deletingAboutError,
    deletingAboutSuccess,
    onDeleteAboutReset,
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (editingAboutSuccess) {
      setModalOpen(false);
    }
  }, [editingAboutSuccess]);

  useEffect(() => {
    if (deletingAboutSuccess) {
      setConfirmModalOpen(false);
    }
  }, [deletingAboutSuccess]);

  const handleClose = () => {
    setModalOpen(false);
    onEditAboutReset()
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    onDeleteAboutReset();
    setConfirmModalOpen(false);
  };

  const handleDeleteBtn = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmButton = e => {
    e.persist();
    onDeleteAboutReset();
    const confirm = e.currentTarget.value;
    if (confirm === 'true') {
      onDeleteAboutSection(item.id);
    } else {
      setConfirmModalOpen(false);
    }
  };

  const userType =
    isAuthenticated &&
    currentUser &&
    currentUser.attributes.profile.protectedData.userType.toLowerCase();

  const editBtn = userType === 'superadmin' && (
    <button onClick={handleOpen} className={css.editButton}>
      <FormattedMessage id="AboutPage.editBtnText" />
    </button>
  );
  const deleteBtn = userType === 'superadmin' && (
    <button onClick={handleDeleteBtn} className={css.deleteButton}>
      <FormattedMessage id="AboutPage.deleteBtnText" />
    </button>
  );

  const handleSave = data => {
    onEditAboutReset();
    onEditAboutSection(item.id, data);
  };

  return (
    <>
      <div className={css.editBtnsWrap}>
        {editBtn}
        {deleteBtn}
      </div>
      <div className={css.aboutUsListItem}>
        <span className={css.aboutUsListItemTitle}>{item.title}</span>

        <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
        <Modal
          id="AboutPage.addNewAnnouncement"
          isOpen={isModalOpen}
          onClose={handleClose}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <AboutEditor
            editorTitle="AboutPage.editTitleText"
            makeRequestFunction={data => handleSave(data)}
            item={item}
            editingError={editingAboutError}
          />
        </Modal>
        <Modal
          id="AboutPage.confirmDelete"
          isOpen={isConfirmModalOpen}
          onClose={handleConfirmModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.confirmDeleteWrap}>
            <span className={css.confirmTitle}>
              <FormattedMessage id="AboutPage.confirmDeletePostMessage" />
            </span>
            <div className={css.confirmDeleteBtnsWrap}>
              <button value={true} className={css.deleteButton} onClick={handleConfirmButton}>
                <FormattedMessage id="ConfirmModal.yesBtn" />
              </button>
              <button value={false} className={css.editButton} onClick={handleConfirmButton}>
                <FormattedMessage id="ConfirmModal.noBtn" />
              </button>
            </div>
            {deletingAboutError ? (
              <p className={css.deleteError}>
                <FormattedMessage id="ConfirmModal.errorDeleting" />
              </p>
            ) : null}
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;

  return {
    isAuthenticated,
    currentUser,
  };
  
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onEditAboutSection: (id, data) => {
    dispatch(editAboutSection(id, data));
  },
  onDeleteAboutSection: id => {
    dispatch(deleteAboutSection(id));
  },
  onEditAboutReset: () => {
    dispatch(editAboutReset());
  },
  onDeleteAboutReset: () => {
    dispatch(deleteAboutReset());
  },
});

const AboutSectionItem = compose(connect(mapStateToProps, mapDispatchToProps))(
  AboutSectionItemComponent
);

export default AboutSectionItem;
