import React, { useState, useEffect } from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getUsersReviews } from '../../ducks/Reviews.duck';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionSearchSubject,
  SectionReviews,
  SectionOnlineTeaching,
  SectionBecomingTutor,
  SectionSubscribe,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/wisepalFacebook-1200x630.jpg';
import twitterImage from '../../assets/wisepalTwitter-600x314.jpg';
import css from './HSTutoringPage.css';

import high from '../HSTutoringPage/images/high-school.jpg';
import hstutor from '../HSTutoringPage/images/high-school-tutoring.jpg';
import wisepalComputer from '../../assets/seoteric-pages/high-school-edits/wisepal-computer.png';

export const HSTutoringPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    reviews,
    currentUserListingFetched,
    onGetUsersReviews,
    currentUser,
    isAuthenticated,
  } = props;

  const [recievedReviews, setRecieveReviews] = useState(reviews);

  useEffect(() => {
    onGetUsersReviews()
  }, []);

  useEffect(() => {
    setRecieveReviews(reviews);
  }, [reviews]);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'HStutoring.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'HStutoring.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div class="container">
              <div class="row my-5">
                <div class="col-md-12 text-center">
                    <h1>High School Tutoring</h1>
                </div>
              </div>
              <div class="row my-5">
                  <div class="col-md-12 justify-content-center text-center">
                      <h2>Online Tutoring for High School Students</h2>
                      <p class="my-3 intro-content">High school is not a walk in the park. We’ve been there. Between homework, quizzes, exams, and projects, we know that studying plays a big part in how well students do in their classes. Sometimes, you need a little help and that’s why Wisepal exists. Our expert tutors can help you take full advantage of your study time and earn good grades.</p><br />
                      <a href="https://wisepal.co/signup">
                        <button type="button" class="btn btn-prpl btn-lg rounded-pill my-3 hover-orng">Sign Up Today!</button>
                    </a> 
                  </div>
              </div>
              <div class="row my-5 justify-content-center text-center">
                  <h2>High School Tutoring Services</h2>
                  <p>Our online high school tutoring offers tutoring for all high school subjects. We aim to help you stay on track with your classes. At Wisepal, we understand the importance of education and provide tools that complement your study goals. That is why our expert tutors have different processes to help you understand the material so you can complete homework efficiently and be fully prepared on exam days.</p>
                  <p>To find a tutor, perform a subject search, then simply filter by niche subject, price range, and lesson type, then try an instructor that fits your preference. You don’t have to commit or pay for expensive tutoring packages.</p>
              </div>
              <div class="row my-2 justify-content-center text-center">
                  <div class="col-md-12">
                      <h2>What We Offer</h2>
                      <p>Our vision is to make learning for independent learners and students accessible, convenient, and useful, as well as to provide instructors with a platform to share their invaluable acquired knowledge through individual or small group collaboration.</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6 my-5 d-flex justify-content-center">
                      <img class="rounded-circle" src={high} alt="Student Tutoring" />
                  </div>
                  <div class="col-md-6 my-5">
                      <ol>
                          <li><p><b>Cutting-edge tools:</b> Our learning platform has incorporated screen sharing, multi-user whiteboards, break-out rooms, shared notes, and many more conveniences for higher-quality online instructions.</p></li>
                          <li><p><b>Qualified expert tutors on a wide range of high school subjects:</b>Our high school tutors offer help in many in-demand subjects such as algebra, geometry, chemistry, and other high school subjects.</p></li>
                          <li><p><b>High school subject tailoring:</b>We tailor our learning sessions depending on the specific needs and preferences of our students.</p></li>
                          <li><p><b>High school standardized test preparation:</b>Our qualified tutors have breezed these tests themselves, and they can’t wait to share their tips and winning formulas with you!</p></li>
                          <li><p><b>Group sessions:</b>These sessions allow for collaboration as a group for test preparation, a crash course, or a boot camp. This experience can help boost the learning momentum as high school students learn not only from their tutors but also from their peers.</p></li>
                      </ol>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12 my-5 justify-content-center text-center">
                      <h2>SAT &amp; ACT Tutoring</h2>
                      <p>We understand that preparations for the SATs and ACTs can be overwhelming for high school students. That’s why we have learning sessions tailored for these qualifying exams in mind. Our tutors will provide you with overviews and a plan to help you in areas where you need improvement. Our tutors have scored highly on these tests themselves. You can also create a strategy with your online tutor suited to your learning preferences.</p>
                  </div>
              </div>
          </div>
          <div class="container-fluid">
              <div class="row py-5 btn-prpl">
                  <div class="col-md-12 my-5 text-center">
                      <h2>Book a Lesson with an Expert Tutor!</h2>
                      <a href="https://wisepal.co/signup">
                        <button type="button" class="btn btn-orng btn-lg rounded-pill my-3">Sign Up Today!</button>
                      </a>
                  </div>
              </div>
          </div>
          <div class="container">
              <div class="row">
                  <div class="col-md-12 my-5 justify-content-center text-center">
                      <h2>Wisepal Features &amp; Benefits for High School Students</h2>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6">
                      <ol>
                          <li><p>Tutors are available based on your schedule.</p></li>
                          <li><p>In-person and online tutoring are available depending on your location.</p></li>
                          <li><p>We offer affordable and transparent rates.</p></li>
                          <li><p>We have the option to record sessions, so you can review them later.</p></li>
                          <li><p>One-on-one tutoring or group tutoring sessions are available.</p></li>
                          <li><p>Break-out rooms are used so you can learn alongside your peers. This is perfect for test preparation and in-depth discussions with step-by-step guidance on problem-solving.</p></li>
                      </ol>
                  </div>
                  <div class="col-md-6 my-5 d-flex justify-content-center">
                      <img class="rounded-circle" src={hstutor} alt="high school tutoring online" />
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12 my-5 justify-content-center text-center">
                      <h2>How We Select Our High School Tutors</h2>
                      <p>Our tutor selection is a rigorous process. We screen our tutor applicants by their submitted academic credentials, certifications, grades, and test scores. They are then required to obtain qualifying scores in timed subject assessment tests. After all of these, we run background checks if requested. Not only do we work with only highly-qualified tutors, but we provide the online tools and functionality to have a great learning experience.</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6 my-5 d-flex justify-content-center">
                      <img src={wisepalComputer} alt="Computer Icon" />
                  </div>
                  <div class="col-md-6 my-5">
                      <ol>
                          <li><p><b>Create an account:</b> Visit our website and sign up for a free account.</p></li>
                          <li><p><b>Browse our tutors by subject and grade level:</b>Our platform incorporates a filtering system to help you find the tutor suited for you.</p></li>
                          <li><p><b>Reach out to tutors:</b>Once you find a suitable tutor, simply reach out and communicate with them to see if they’re a good fit for you.</p></li>
                          <li><p><b>Book a session:</b> Booking a tutoring session is easy on our platform. Select a day and time, make payment, and enjoy your session.</p></li>
                      </ol>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <h2>FAQs</h2>
                  </div>
              </div>
              <div class="row my-5">
                  <div class="col-md-12">
                      <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button btn-prpl" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  How Much Does a High School Tutor Cost?
                              </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <span>Tutor rates vary based on their educational level and experience. Our tutors' price range is displayed on our website.</span>.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                              <button class="accordion-button btn-prpl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                What's the Best Approach To Get the Most Out of Tutoring?
                              </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <span>To get the most out of your tutoring, its important to have a good rapport with your tutor. Our tutors are experts in their fields, but will also take the time to help you understand the material. Finding a tutor that will craft lesson plans that meet you where you are and encourage you along the way will no doubt help you improve.</span>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                              <button class="accordion-button btn-prpl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                How Often Should a High School Student Receive Tutoring?
                              </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <span>For starters, at least one session a week is required to establish cadence. Then, depending on the student’s learning process and progress, the number of sessions can be adjusted.</span>
                              </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                              <button class="accordion-button btn-prpl collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                How Long Should You Study for the SAT or Other Standardized Tests?
                              </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <span>Ideally, you should study for the SAT and other standardized tests for at least 10 to 20 weeks before the exams.</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
        </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

HSTutoringPageComponent.defaultProps = {
  currentUserListing: null,
  currentUser: null,
  currentUserListingFetched: false,
  reviews: [],
};

HSTutoringPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  reviews: arrayOf(propTypes.review),
  isAuthenticated: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const {
    reviews,
  } = state.Reviews;

  return {
    reviews,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetUsersReviews: () => dispatch(getUsersReviews()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const HSTutoringPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(HSTutoringPageComponent);

export default HSTutoringPage;