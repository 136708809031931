import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import BreakdownMaybe from './BreakdownMaybe';
import classNames from 'classnames';

import css from './TransactionPanel.css';

const ChainedTransaction = props => {
  const {
    breakdownClassName,
    transaction,
    transactionRole,
    localTimeZone,
    className,
    awaitToCancel,
    updateData
  } = props;

  if (!transaction) {
    return null;
  }

  const additionalId = transaction.attributes.protectedData && transaction.attributes.protectedData.additionalTimeTransactionId;
  const parentId = transaction.attributes.protectedData && transaction.attributes.protectedData.parentTransactionId;
  const chainedId = additionalId || parentId;
  const isAdditional = !!parentId;
  
  const title = <FormattedMessage id={isAdditional ? 'TransactionPanel.chainedTitleAdditional' : 'TransactionPanel.chainedTitleParent'} />; 

  return (
    <div className={classNames(className, css.chainedTransactionWrapper)}>
      <h4 className={css.chainedTransactionTitle}>
        {title}
      </h4>

      <BreakdownMaybe
        className={classNames(breakdownClassName, css.breakdownChained)}
        transaction={transaction}
        transactionRole={transactionRole}
        localTimeZone={localTimeZone}
        short={true}
        updateData={updateData}
        awaitToCancel={awaitToCancel}
      />
    </div>
  );
};

export default ChainedTransaction;
