import axios from 'axios';
import { tutorsAppApprove, tutorsAppDecline } from '../../util/api';

async function makeRequest(method, url, data) {
  const options = {
    baseURL: process.env.REACT_APP_RAILS_API_URL,
    method: method,
    url: url,
    data,
  };

  let res = await axios(options);
  try {
    return res;
  } catch (e) {
    return new Error(e);
  }
}

// ================ Action types ================ //

export const INITIATE_BONUSES_AVAILABILITY_REQUEST =
  'app/AdminPanelPage/INITIATE_BONUSES_AVAILABILITY_REQUEST';
export const INITIATE_BONUSES_AVAILABILITY_SUCCESS =
  'app/AdminPanelPage/INITIATE_BONUSES_AVAILABILITY_SUCCESS';
export const INITIATE_BONUSES_AVAILABILITY_ERROR =
  'app/AdminPanelPage/INITIATE_BONUSES_AVAILABILITY_ERROR';

export const UPDATE_BONUSES_AVAILABILITY_REQUEST =
  'app/AdminPanelPage/UPDATE_BONUSES_AVAILABILITY_REQUEST';
export const UPDATE_BONUSES_AVAILABILITY_SUCCESS =
  'app/AdminPanelPage/UPDATE_BONUSES_AVAILABILITY_SUCCESS';
export const UPDATE_BONUSES_AVAILABILITY_ERROR =
  'app/AdminPanelPage/UPDATE_BONUSES_AVAILABILITY_ERROR';

export const SAVE_LINK_TO_QUIZZ_REQUEST = 'app/AdminPanelPage/SAVE_LINK_TO_QUIZZ_REQUEST';
export const SAVE_LINK_TO_QUIZZ_SUCCESS = 'app/AdminPanelPage/SAVE_LINK_TO_QUIZZ_SUCCESS';
export const SAVE_LINK_TO_QUIZZ_ERROR = 'app/AdminPanelPage/SAVE_LINK_TO_QUIZZ_ERROR';

export const SAVE_SUBJECT_REQUEST = 'app/AdminPanelPage/SAVE_SUBJECT_REQUEST';
export const SAVE_SUBJECT_SUCCESS = 'app/AdminPanelPage/SAVE_SUBJECT_SUCCESS';
export const SAVE_SUBJECT_ERROR = 'app/AdminPanelPage/SAVE_SUBJECT_ERROR';
export const SAVE_SUBJECT_RESET = 'app/AdminPanelPage/SAVE_SUBJECT_RESET';

export const DELETE_SUBJECT_REQUEST = 'app/AdminPanelPage/DELETE_SUBJECT_REQUEST';
export const DELETE_SUBJECT_SUCCESS = 'app/AdminPanelPage/DELETE_SUBJECT_SUCCESS';
export const DELETE_SUBJECT_ERROR = 'app/AdminPanelPage/DELETE_SUBJECT_ERROR';
export const DELETE_SUBJECT_RESET = 'app/AdminPanelPage/DELETE_SUBJECT_RESET';

export const EDIT_SUBJECT_REQUEST = 'app/AdminPanelPage/EDIT_SUBJECT_REQUEST';
export const EDIT_SUBJECT_SUCCESS = 'app/AdminPanelPage/EDIT_SUBJECT_SUCCESS';
export const EDIT_SUBJECT_ERROR = 'app/AdminPanelPage/EDIT_SUBJECT_ERROR';
export const EDIT_SUBJECT_RESET = 'app/AdminPanelPage/EDIT_SUBJECT_RESET';

export const GET_LINK_TO_QUIZZ_REQUEST = 'app/AdminPanelPage/GET_LINK_TO_QUIZZ_REQUEST';
export const GET_LINK_TO_QUIZZ_SUCCESS = 'app/AdminPanelPage/GET_LINK_TO_QUIZZ_SUCCESS';
export const GET_LINK_TO_QUIZZ_ERROR = 'app/AdminPanelPage/GET_LINK_TO_QUIZZ_ERROR';

export const GET_ALL_SUBJECTS_REQUEST = 'app/AdminPanelPage/GET_ALL_SUBJECTS_REQUEST';
export const GET_ALL_SUBJECTS_SUCCESS = 'app/AdminPanelPage/GET_ALL_SUBJECTS_SUCCESS';
export const GET_ALL_SUBJECTS_ERROR = 'app/AdminPanelPage/GET_ALL_SUBJECTS_ERROR';

export const GET_CANCELLATION_REQUEST = 'app/AdminPanelPage/GET_CANCELLATION_REQUEST';
export const GET_CANCELLATION_SUCCESS = 'app/AdminPanelPage/GET_CANCELLATION_SUCCESS';
export const GET_CANCELLATION_ERROR   = 'app/AdminPanelPage/GET_CANCELLATION_ERROR';

export const AGREEMENTS_REQUEST = 'app/AdminPanelPage/AGREEMENTS_REQUEST';
export const AGREEMENTS_SUCCESS = 'app/AdminPanelPage/AGREEMENTS_SUCCESS';
export const AGREEMENTS_ERROR   = 'app/AdminPanelPage/AGREEMENTS_ERROR';

export const GET_TUTORS_APPS_REQUEST = 'app/AdminPanelPage/GET_TUTORS_APPS_REQUEST';
export const GET_TUTORS_APPS_SUCCESS = 'app/AdminPanelPage/GET_TUTORS_APPS_SUCCESS';
export const GET_TUTORS_APPS_ERROR   = 'app/AdminPanelPage/GET_TUTORS_APPS_ERROR';

export const TUTORS_REQUEST = 'app/AdminPanelPage/TUTORS_REQUEST';
export const TUTORS_SUCCESS = 'app/AdminPanelPage/TUTORS_SUCCESS';
export const TUTORS_ERROR   = 'app/AdminPanelPage/TUTORS_ERROR';

export const TUTOR_CHANGE_DATES_REQUEST = 'app/AdminPanelPage/TUTOR_CHANGE_DATES_REQUEST';
export const TUTOR_CHANGE_DATES_SUCCESS = 'app/AdminPanelPage/TUTOR_CHANGE_DATES_SUCCESS';
export const TUTOR_CHANGE_DATES_ERROR   = 'app/AdminPanelPage/TUTOR_CHANGE_DATES_ERROR';

export const GET_TUTOR_APP_BY_DATE_REQUEST = 'app/AdminPanelPage/GET_TUTOR_APP_BY_DATE_REQUEST';
export const GET_TUTOR_APP_BY_DATE_SUCCESS = 'app/AdminPanelPage/GET_TUTOR_APP_BY_DATE_SUCCESS';
export const GET_TUTOR_APP_BY_DATE_ERROR   = 'app/AdminPanelPage/GET_TUTOR_APP_BY_DATE_ERROR';

export const BG_CONSENT_REQUEST = 'app/AdminPanelPage/BG_CONSENT_REQUEST';
export const BG_CONSENT_SUCCESS = 'app/AdminPanelPage/BG_CONSENT_SUCCESS';
export const BG_CONSENT_ERROR   = 'app/AdminPanelPage/BG_CONSENT_ERROR';

export const TUTORS_STATUS_REQUEST = 'app/AdminPanelPage/TUTORS_STATUS_REQUEST';
export const TUTORS_STATUS_SUCCESS = 'app/AdminPanelPage/TUTORS_STATUS_SUCCESS';
export const TUTORS_STATUS_ERROR   = 'app/AdminPanelPage/TUTORS_STATUS_ERROR';
// ================ Reducer ================ //

const initialState = {
  bonuses: false,
  isLoading: false,
  error: null,
  subjectLinkToQuizz: [],
  getTransactions: [],
  saveLinkProgress: false,
  saveLinkError: null,
  saveLinkSuccess: false,
  getLinkProgress: false,
  getLinkError: null,
  getLinkSuccess: false,

  getSubjectProgress: false,
  getSubjectError: null,
  getSubjectSuccess: false,
  allSubjects: [],

  saveSubjectProgress: false,
  saveSubjectError: null,
  saveSubjectSuccess: false,

  deleteSubjectProgress: false,
  deleteSubjectError: null,
  deleteSubjectSuccess: false,

  editSubjectProgress: false,
  editSubjectError: null,
  editSubjectSuccess: false,

  agreements: [],
  agreementsProgress: false,
  agreementsError: false,

  tutorsApps: [],
  tutorsAppsProgress: false,
  tutorsAppsError: false,

  tutor: null,
  tutorProgress: false,
  tutorError: false,

  tutorChangeDates: [],
  tutorChangeDatesProgress: false,
  tutorChangeDatesError: false,

  tutorAppByDate: null,
  tutorAppByDateProgress: false,
  tutorAppByDateError: false,

  bgConsent: null,
  bgConsentProgress: false,
  bgConsentError: false,

  tutorsStatusRequest: false,
  tutorsStatusError: false,
};

export default function adminPagePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_BONUSES_AVAILABILITY_REQUEST:
      return { ...state, isLoading: true };
    case INITIATE_BONUSES_AVAILABILITY_SUCCESS:
      return { ...state, isLoading: false, error: null, bonuses: payload };
    case INITIATE_BONUSES_AVAILABILITY_ERROR:
      return { ...state, isLoading: false, error: payload };
    case UPDATE_BONUSES_AVAILABILITY_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_BONUSES_AVAILABILITY_SUCCESS:
      return { ...state, isLoading: false, error: null };
    case UPDATE_BONUSES_AVAILABILITY_ERROR:
      return { ...state, isLoading: false, error: payload };

    case SAVE_LINK_TO_QUIZZ_REQUEST:
      return { ...state, saveLinkProgress: true, saveLinkError: null, saveLinkSuccess: false };
    case SAVE_LINK_TO_QUIZZ_SUCCESS:
      return { ...state, saveLinkProgress: false, saveLinkError: null, saveLinkSuccess: true };
    case SAVE_LINK_TO_QUIZZ_ERROR:
      return { ...state, saveLinkProgress: false, saveLinkError: payload, saveLinkSuccess: false };

    case SAVE_SUBJECT_REQUEST:
      return {
        ...state,
        saveSubjectProgress: true,
        saveSubjectError: null,
        saveSubjectSuccess: false,
      };
    case SAVE_SUBJECT_SUCCESS:
      return {
        ...state,
        saveSubjectProgress: false,
        saveSubjectError: null,
        saveSubjectSuccess: true,
      };
    case SAVE_SUBJECT_ERROR:
      return {
        ...state,
        saveSubjectProgress: false,
        saveSubjectError: payload,
        saveSubjectSuccess: false,
      };
    case SAVE_SUBJECT_RESET:
      return {
        ...state,
        saveSubjectProgress: false,
        saveSubjectError: null,
        saveSubjectSuccess: false,
      };

    case DELETE_SUBJECT_REQUEST:
      return {
        ...state,
        deleteSubjectProgress: true,
        deleteSubjectError: null,
        deleteSubjectSuccess: false,
      };
    case DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        deleteSubjectProgress: false,
        deleteSubjectError: null,
        deleteSubjectSuccess: true,
      };
    case DELETE_SUBJECT_ERROR:
      return {
        ...state,
        deleteSubjectProgress: false,
        deleteSubjectError: payload,
        deleteSubjectSuccess: false,
      };
    case DELETE_SUBJECT_RESET:
      return {
        ...state,
        deleteSubjectProgress: false,
        deleteSubjectError: null,
        deleteSubjectSuccess: false,
      };

    case EDIT_SUBJECT_REQUEST:
      return {
        ...state,
        editSubjectProgress: true,
        editSubjectError: null,
        editSubjectSuccess: false,
      };
    case EDIT_SUBJECT_SUCCESS:
      return {
        ...state,
        editSubjectProgress: false,
        editSubjectError: null,
        editSubjectSuccess: true,
      };
    case EDIT_SUBJECT_ERROR:
      return {
        ...state,
        editSubjectProgress: false,
        editSubjectError: payload,
        editSubjectSuccess: false,
      };
    case EDIT_SUBJECT_RESET:
      return {
        ...state,
        editSubjectProgress: false,
        editSubjectError: null,
        editSubjectSuccess: false,
      };

    case GET_LINK_TO_QUIZZ_REQUEST:
      return { ...state, getLinkProgress: true, getLinkError: null, getLinkSuccess: false };
    case GET_LINK_TO_QUIZZ_SUCCESS:
      return {
        ...state,
        getLinkProgress: false,
        getLinkError: null,
        getLinkSuccess: true,
        subjectLinkToQuizz: payload,
      };
    case GET_LINK_TO_QUIZZ_ERROR:
      return { ...state, getLinkProgress: false, getLinkError: payload, getLinkSuccess: false };

    case GET_ALL_SUBJECTS_REQUEST:
      return {
        ...state,
        getSubjectProgress: true,
        getSubjectError: null,
        getSubjectSuccess: false,
      };
    case GET_ALL_SUBJECTS_SUCCESS:
      return {
        ...state,
        getSubjectProgress: false,
        getSubjectError: null,
        getSubjectSuccess: true,
        allSubjects: payload,
      };
    case GET_ALL_SUBJECTS_ERROR:
      return {
        ...state,
        getSubjectProgress: false,
        getSubjectError: payload,
        getSubjectSuccess: false,
      };

    case GET_CANCELLATION_REQUEST:
      return { ...state, getTrProgress: true, getTrError: null, getTrSuccess: false };
    case GET_CANCELLATION_SUCCESS:
      return {
        ...state,
        getTrProgress: false,
        getTrError: null,
        getTrSuccess: true,
        getTransactions: payload,
      };
    case GET_CANCELLATION_ERROR:
      return { ...state, getTrProgress: false, getTrError: payload, getTrSuccess: false };

    case AGREEMENTS_REQUEST:
      return {
        ...state,
        agreementsProgress: true,
        agreementsError: false
      };
    case AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements: payload,
        agreementsProgress: false,
      };
    case AGREEMENTS_ERROR:
      return {
        ...state,
        agreementsProgress: false,
        agreementsError: true
      };

    case TUTORS_REQUEST:
      return {
        ...state,
        tutor: null,
        tutorProgress: true,
        tutorError: false
      };
    case TUTORS_SUCCESS:
      return {
        ...state,
        tutor: payload,
        tutorProgress: false,
      };
    case TUTORS_ERROR:
      return {
        ...state,
        tutor: null,
        tutorProgress: false,
        tutorError: true
      };

    case TUTOR_CHANGE_DATES_REQUEST:
      return {
        ...state,
        tutorChangeDates: [],
        tutorChangeDatesProgress: true,
        tutorChangeDatesError: false
      };
    case TUTOR_CHANGE_DATES_SUCCESS:
      return {
        ...state,
        tutorChangeDates: payload,
        tutorChangeDatesProgress: false,
      };
    case TUTOR_CHANGE_DATES_ERROR:
      return {
        ...state,
        tutorChangeDates: [],
        tutorChangeDatesProgress: false,
        tutorChangeDatesError: true
      };

    case GET_TUTOR_APP_BY_DATE_REQUEST:
      return {
        ...state,
        tutorAppByDate: null,
        tutorAppByDateProgress: true,
        tutorAppByDateError: false
      };
    case GET_TUTOR_APP_BY_DATE_SUCCESS:
      return {
        ...state,
        tutorAppByDate: payload,
        tutorAppByDateProgress: false,
      };
    case GET_TUTOR_APP_BY_DATE_ERROR:
      return {
        ...state,
        tutorAppByDate: null,
        tutorAppByDateProgress: false,
        tutorAppByDateError: true
      };

    case GET_TUTORS_APPS_REQUEST:
      return {
        ...state,
        tutorsAppsProgress: true,
        tutorsAppsError: false
      };
    case GET_TUTORS_APPS_SUCCESS:
      return {
        ...state,
        tutorsApps: payload,
        tutorsAppsProgress: false,
      };
    case GET_TUTORS_APPS_ERROR:
      return {
        ...state,
        tutorsAppsProgress: false,
        tutorsAppsError: true
      };

    case BG_CONSENT_REQUEST:
      return {
        ...state,
        bgConsentProgress: true,
        bgConsentError: false
      };
    case BG_CONSENT_SUCCESS:
      return {
        ...state,
        bgConsent: payload,
        bgConsentProgress: false,
      };
    case BG_CONSENT_ERROR:
      return {
        ...state,
        bgConsentProgress: false,
        bgConsentError: true
      };

    case TUTORS_STATUS_REQUEST:
      return {
        ...state,
        tutorsStatusRequest: true,
        tutorsStatusError: false
      };
    case TUTORS_STATUS_SUCCESS:
      return {
        ...state,
        tutorsStatusRequest: false,
        tutorsStatusError: false
      };
    case TUTORS_STATUS_ERROR:
      return {
        ...state,
        tutorsStatusRequest: false,
        tutorsStatusError: true
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const initiateBonusesRequest = () => ({ type: INITIATE_BONUSES_AVAILABILITY_REQUEST });
const initiateBonusesSuccess = response => ({
  type: INITIATE_BONUSES_AVAILABILITY_SUCCESS,
  payload: response,
});
const initiateBonusesError = error => ({
  type: INITIATE_BONUSES_AVAILABILITY_ERROR,
  payload: error,
});

const updateBonusesRequest = () => ({ type: UPDATE_BONUSES_AVAILABILITY_REQUEST });
const updateBonusesSuccess = response => ({
  type: UPDATE_BONUSES_AVAILABILITY_SUCCESS,
  payload: response,
});
const updateBonusesError = error => ({ type: UPDATE_BONUSES_AVAILABILITY_ERROR, payload: error });

const saveLinkToQuizzRequest = () => ({ type: SAVE_LINK_TO_QUIZZ_REQUEST });
const saveLinkToQuizzSuccess = () => ({ type: SAVE_LINK_TO_QUIZZ_SUCCESS });
const saveLinkToQuizzError = error => ({
  type: SAVE_LINK_TO_QUIZZ_ERROR,
  payload: error,
});

// Save Subject

const saveSubjectRequest = () => ({ type: SAVE_SUBJECT_REQUEST });
const saveSubjectSuccess = () => ({ type: SAVE_SUBJECT_SUCCESS });
const saveSubjectError = error => ({
  type: SAVE_SUBJECT_ERROR,
  payload: error,
});
export const saveSubjectReset = () => ({ type: SAVE_SUBJECT_RESET });

// Delete Subject

const deleteSubjectRequest = () => ({ type: DELETE_SUBJECT_REQUEST });
const deleteSubjectSuccess = () => ({ type: DELETE_SUBJECT_SUCCESS });
const deleteSubjectError = error => ({
  type: DELETE_SUBJECT_ERROR,
  payload: error,
});
export const deleteSubjectReset = () => ({ type: DELETE_SUBJECT_RESET });

// Edit Subject

const editSubjectRequest = () => ({ type: EDIT_SUBJECT_REQUEST });
const editSubjectSuccess = () => ({ type: EDIT_SUBJECT_SUCCESS });
const editSubjectError = error => ({
  type: EDIT_SUBJECT_ERROR,
  payload: error,
});
export const editSubjectReset = () => ({ type: EDIT_SUBJECT_RESET });

const getLinkToQuizzRequest = () => ({ type: GET_LINK_TO_QUIZZ_REQUEST });
const getLinkToQuizzSuccess = response => ({
  type: GET_LINK_TO_QUIZZ_SUCCESS,
  payload: response,
});
const getLinkToQuizzError = error => ({ type: GET_LINK_TO_QUIZZ_ERROR, payload: error });

const getAllSubjectsRequest = () => ({ type: GET_ALL_SUBJECTS_REQUEST });
const getAllSubjectsSuccess = response => ({
  type: GET_ALL_SUBJECTS_SUCCESS,
  payload: response,
});
const getAllSubjectsError = error => ({ type: GET_ALL_SUBJECTS_ERROR, payload: error });

const getCancellationRequest = () => ({ type: GET_CANCELLATION_REQUEST });
const getCancellationSuccess = response => ({
  type: GET_CANCELLATION_SUCCESS,
  payload: response,
});
const getCancellationError = error => ({ type: GET_CANCELLATION_ERROR, payload: error });

const agreementsRequest = () => ({ type: AGREEMENTS_REQUEST });
const agreementsSuccess = response => ({
  type: AGREEMENTS_SUCCESS,
  payload: response.data,
});
const agreementsError = error => ({ type: AGREEMENTS_ERROR, payload: error });

const getTutorRequest = () => ({ type: TUTORS_REQUEST });
const getTutorSuccess = response => ({
  type: TUTORS_SUCCESS,
  payload: response.data,
});
const getTutorError = error => ({ type: TUTORS_ERROR, payload: error });

const getTutorChangeDatesRequest = () => ({ type: TUTOR_CHANGE_DATES_REQUEST });
const getTutorChangeDatesSuccess = response => ({
  type: TUTOR_CHANGE_DATES_SUCCESS,
  payload: response.data,
});
const getTutorChangeDatesError = error => ({ type: TUTOR_CHANGE_DATES_ERROR, payload: error });

const getTutorAppByDateRequest = () => ({ type: GET_TUTOR_APP_BY_DATE_REQUEST });
const getTutorAppByDateSuccess = response => ({
  type: GET_TUTOR_APP_BY_DATE_SUCCESS,
  payload: response.data,
});
const getTutorAppByDateError = error => ({ type: GET_TUTOR_APP_BY_DATE_ERROR, payload: error });

const getTutorsAppsRequest = () => ({ type: GET_TUTORS_APPS_REQUEST });
const getTutorsAppsSuccess = response => ({
  type: GET_TUTORS_APPS_SUCCESS,
  payload: response.data,
});
const getTutorsAppsError = error => ({ type: GET_TUTORS_APPS_ERROR, payload: error });

const bgRequest = () => ({ type: BG_CONSENT_REQUEST });
const bgSuccess = response => ({
  type: BG_CONSENT_SUCCESS,
  payload: response.data,
});
const bgError = error => ({ type: BG_CONSENT_ERROR, payload: error });

const statusRequest = () => ({ type: TUTORS_STATUS_REQUEST });
const statusSuccess = () => ({type: TUTORS_STATUS_SUCCESS});
const statusError = error => ({ type: TUTORS_STATUS_ERROR, payload: error });

/* ================ Thunks ================ */

export const getBonuses = () => dispatch => {
  dispatch(initiateBonusesRequest());
  makeRequest('get', `/api/v1/marketplace/referral_system/use_referral_system`, {})
    .then(response => {
      dispatch(initiateBonusesSuccess(response.data));
    })
    .catch(e => {
      dispatch(initiateBonusesError(e));
    });
};

export const updateBonusesStatus = data => dispatch => {
  dispatch(updateBonusesRequest());

  makeRequest('put', '/api/v1/marketplace/referral_system/use_referral_system', data)
    .then(response => {
      dispatch(updateBonusesSuccess());
    })
    .catch(e => {
      dispatch(updateBonusesError(e));
    });
};

export const saveQuizzLink = data => dispatch => {
  dispatch(saveLinkToQuizzRequest());

  makeRequest('post', '/api/v1/marketplace/proprofs/quiz', { data: data })
    .then(response => {
      dispatch(saveLinkToQuizzSuccess());
    })
    .catch(e => {
      dispatch(saveLinkToQuizzError(e));
    });
};

export const getQuizzLink = () => async (dispatch, getState) => {
  dispatch(getLinkToQuizzRequest());

  const data = await dispatch(getSubjectLinksFromApi());

  const quizLink = {};
  if (data) {
    data.map(item => {
      const key = item['name'];
      const value = item['url'];
      quizLink[key] = value;
    });
    dispatch(getLinkToQuizzSuccess(quizLink));
  } else {
    dispatch(getLinkToQuizzError('Cannt get quiz link'));
  }
};

export const getSubjectLinksFromApi = () => dispatch => {
  return makeRequest('get', '/api/v1/marketplace/proprofs/quiz', {})
    .then(response => {
      return response.data;
    })
    .catch(e => {
      dispatch(getLinkToQuizzError(e));
    });
};

export const getAllSubjects = () => dispatch => {
  dispatch(getAllSubjectsRequest());

  return makeRequest('get', '/api/v1/subjects', {})
    .then(response => {
      dispatch(getAllSubjectsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(getAllSubjectsError(e));
    })
}

export const saveSubject = (data) => dispatch => {
  dispatch(saveSubjectRequest());

  return makeRequest('post', '/api/v1/subjects', data)
    .then(response => {
      dispatch(saveSubjectSuccess(response.data));
      dispatch(getAllSubjectsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(saveSubjectError(e));
    });
}

export const deleteSubject = (id) => dispatch => {
  dispatch(deleteSubjectRequest());

  return makeRequest('delete', `/api/v1/subjects/${id}`, {})
    .then(response => {
      dispatch(deleteSubjectSuccess());
      dispatch(getAllSubjectsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(deleteSubjectError(e));
    });
}

export const editSubject = (data, id) => dispatch => {
  dispatch(editSubjectRequest());

  return makeRequest('put', `/api/v1/subjects/${id}`, data)
    .then(response => {
      dispatch(editSubjectSuccess());
      dispatch(getAllSubjectsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(editSubjectError(e));
    });
}

export const getCancellationTransactions = () => dispatch => {
  dispatch(getCancellationRequest());

  makeRequest('get', '/api/v1/transaction/cancellations', {})
    .then(response => {
      dispatch(getCancellationSuccess(response.data));
    })
    .catch(e => {
      dispatch(getCancellationError(e));
    });
}

export const getAgreements = () => dispatch => {
  dispatch(agreementsRequest());

  return makeRequest('get', `api/v1/tutor_application_pages`)
    .then(response => {
      dispatch(agreementsSuccess(response));
      return response.data;
    })
    .catch(e => {
      dispatch(agreementsError(e));
    });
}

export const createAgreements = data => dispatch => {
  dispatch(agreementsRequest());

  return makeRequest('post', `api/v1/tutor_application_pages`, data)
    .then(() => {
      dispatch(getAgreements());
    })
    .catch(e => {
      return dispatch(agreementsError(e));
    });
}

export const updateAgreements = (data, id) => dispatch => {
  dispatch(agreementsRequest());

  return makeRequest('put', `api/v1/tutor_application_pages/${id}`, data)
    .then(() => {
      return dispatch(getAgreements());
    })
    .catch(e => {
      dispatch(agreementsError(e));
    });
}

export const deleteAgreements = id => dispatch => {
  dispatch(agreementsRequest());

  return makeRequest('delete', `api/v1/tutor_application_pages/${id}`)
    .then(() => {
      return dispatch(getAgreements());
    })
    .catch(e => {
      dispatch(agreementsError(e));
    });
}

export const getApps = () => dispatch => {
  dispatch(getTutorsAppsRequest());

  return makeRequest('get', `/api/v1/users`)
    .then(response => {
      dispatch(getTutorsAppsSuccess(response));
      return response.data;
    })
    .catch(e => {
      dispatch(getTutorsAppsError(e));
    });
}

export const getTutor = id => dispatch => {
  dispatch(getTutorRequest());

  return makeRequest('get', `api/v1/users/${id}`)
    .then(response => {
      dispatch(getTutorSuccess(response));
      return response.data;
    })
    .catch(e => {
      dispatch(getTutorError(e));
    });
}

export const getTutorChangeDates = id => dispatch => {
  dispatch(getTutorChangeDatesRequest());

  return makeRequest('get', `api/v1/tutor_application_histories/dates/${id}`)
    .then(response => {
      dispatch(getTutorChangeDatesSuccess(response));
      return response.data;
    })
    .catch(e => {
      dispatch(getTutorChangeDatesError(e));
    });
}

export const getTutorAppByDate = id => dispatch => {
  dispatch(getTutorAppByDateRequest());

  return makeRequest('get', `api/v1/tutor_application_histories/${id}`)
    .then(response => {
      dispatch(getTutorAppByDateSuccess(response));
      return response.data;
    })
    .catch(e => {
      dispatch(getTutorAppByDateError(e));
    });
}

export const getBackgroundCheckConsent = () => dispatch => {
  dispatch(bgRequest());

  return makeRequest('get', `api/v1/static_infos/consent-for-background-check`)
    .then(response => {
      dispatch(bgSuccess(response));
      return response.data;
    })
    .catch(e => {
      dispatch(bgError(e));
    });
}

export const updateBackgroundCheckConsent = data => dispatch => {
  dispatch(bgRequest());

  return makeRequest('put', `api/v1/static_infos/consent-for-background-check`, data)
    .then(() => {
      return dispatch(getBackgroundCheckConsent());
    })
    .catch(e => {
      dispatch(bgError(e));
    });
}

export const approveTutor = data => dispatch => {
  dispatch(statusRequest());

  return tutorsAppApprove(data)
    .then(() => {
      dispatch(getApps())
      return dispatch(statusSuccess());
    })
    .catch(e => {
      dispatch(statusError(e));
    });
}

export const declineTutor = data => dispatch => {
  dispatch(statusRequest());

  return tutorsAppDecline(data)
    .then(() => {
      dispatch(getApps())
      return dispatch(statusSuccess());
    })
    .catch(e => {
      dispatch(statusError(e));
    });
}
