import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import k12Image from './images/search-subject-img1.jpg';
import universityImage from './images/search-subject-img2.jpg';
import graduateImage from './images/search-subject-img3.jpg';

import { NamedLink } from '../../components';

import css from './SectionSearchSubject.css';
import config from '../../config';
import { Link } from 'react-router-dom';

const SectionSearchSubject = props => {
  const { rootClassName, className, currentUser } = props;

  const classes = classNames(rootClassName || css.root, className);

  const k12 = config.custom.k12;
  const university = config.custom.university;
  const graduate = config.custom.graduate;

  const skeletonUrl = '/s?pub_subjectItems=';
  let k12Url = '', universityUrl = '', graduateUrl = '';
  k12.map(item => { k12Url += `${item}%2C` });
  university.map(item => { universityUrl += `${item}%2C` });
  graduate.map(item => { graduateUrl += `${item}%2C` });


  const user = ensureCurrentUser(currentUser);

  const sectionBtn = currentUser === null ? (
    <NamedLink name="SignupPage" className={css.createAccountBtn}>
      <FormattedMessage id="SectionSearchSubject.createAccount" />
    </NamedLink>
  ) : (

    <NamedLink
      className={css.createAccountBtn}
      name="ProfilePage"
      params={{ id: user.id.uuid }}
    >
      <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
    </NamedLink>
    );

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionSearchSubject.titleLineOne" />
      </div>

      <div className={css.steps}>

        <div className={css.step}>
          <Link className={css.stepLink} to={skeletonUrl + k12Url.slice(0, -3)}>
            <h3 className={css.stepTitle}>
              <FormattedMessage id="SectionSearchSubject.k12" />
            </h3>
            <img className={css.stepImg} src={k12Image} alt="K-12" />
          </Link>
        </div>

        <div className={css.step}>
          <Link className={css.stepLink} to={skeletonUrl + universityUrl.slice(0, -3)}>
            <h3 className={css.stepTitle}>
              <FormattedMessage id="SectionSearchSubject.university" />
            </h3>
            <img className={css.stepImg} src={universityImage} alt="University" />
          </Link>
        </div>

        <div className={css.step}>
          <Link className={css.stepLink} to={skeletonUrl + graduateUrl.slice(0, -3)}>
            <h3 className={css.stepTitle}>
              <FormattedMessage id="SectionSearchSubject.graduate" />
            </h3>
            <img className={css.stepImg} src={graduateImage} alt="Graduate" />
          </Link>
        </div>
      </div>

      {sectionBtn}
    </div>
  );
};

SectionSearchSubject.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionSearchSubject.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSearchSubject;
