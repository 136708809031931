/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React, { Component } from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import {
  FieldCheckbox,
  FieldSelect,
  FieldTextInput,
  ValidationError,
  Button,
  PrimaryButton,
} from '..';

import css from './FieldCheckboxGroupSubject.css';
import * as validators from '../../util/validators';
import { FormattedMessage } from '../../util/reactIntl';

const hours = [];
for (let i = 1; i <= 24; i++) hours.push(i);
const minutes = [0, 10, 20, 30, 40, 50];

class FieldCheckboxRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      className,
      rootClassName,
      label,
      twoColumns,
      id,
      fields,
      options,
      meta,
      withPrice,
      listing,
      onChangeTime,
      values,
      disabled,
      setQuiz,
      proProfsProgress,
      infoAllSubjects,
      passedSubjects
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

    const hoursRequired = validators.required(
      <FormattedMessage id="FieldCheckboxGroup.hoursRequired" />
    );
    const minutesRequired = validators.required(
      <FormattedMessage id="FieldCheckboxGroup.minutesRequired" />
    );

    const isPassQuiz = subject => {
      return passedSubjects.includes(subject);
    };

    const isNoPassQuiz = subject => {
      const result =
        infoAllSubjects &&
        infoAllSubjects.length > 0 &&
        infoAllSubjects.filter(index => index.key === subject)[0].status;

      return result === 'Failed';
    };

    const isPendingQuiz = subject => {
      const result =
        infoAllSubjects &&
        infoAllSubjects.length > 0 &&
        infoAllSubjects.filter(index => index.key === subject)[0].status;

      return result === 'Pending';
    };

    return (
      <fieldset className={classes}>
        {label ? <legend>{label}</legend> : null}
        <ul className={listClasses}>
          {options &&
            options.map((option, index) => {
              const currentMinutes =
                values && values[`${option.key}_duration_hours`] == 24 ? [0] : minutes;
              const fieldId = `${id}.${option.key}`;

              return (
                <li key={fieldId} className={css.item}>
                  <span className={css.itemCheckbox}>
                    <FieldCheckbox
                      id={fieldId}
                      name={fields.name}
                      label={option.label}
                      value={option.key}
                      disabled={!isPassQuiz(option.key)}
                    />
                  </span>
                  {(withPrice && fields.value && fields.value.indexOf(option.key) !== -1) ||
                  isPassQuiz(option.key) ? (
                    isPassQuiz(option.key) ? (
                      <>
                        {listing.attributes.publicData.sessionType.length !== 2 ? (
                          <FieldTextInput
                            id={`${option.key}.price`}
                            name={`${option.key}_${listing.attributes.publicData.sessionType[0]}`}
                            className={css.price}
                            type="number"
                            label={`Price per ${
                              listing.attributes.publicData.sessionType[0] === 'group'
                                ? 'session'
                                : 'hour'
                            } (${listing.attributes.publicData.sessionType[0]})`}
                            min={1}
                            required={true}
                            placeholder={`Price`}
                          />
                        ) : (
                          <>
                            <FieldTextInput
                              id={`${option.key}.private.price`}
                              name={`${option.key}_private`}
                              className={css.price}
                              type="number"
                              label="Price per hour (private)"
                              min={1}
                              required={true}
                              placeholder="Price"
                            />
                            <FieldTextInput
                              id={`${option.key}.group.price`}
                              name={`${option.key}_group`}
                              className={css.price}
                              type="number"
                              label="Price per session (group)"
                              min={1}
                              required={true}
                              placeholder="Price"
                            />
                          </>
                        )}
                        {listing.attributes.publicData.sessionType.indexOf('group') !== -1 && (
                          <>
                            <FieldSelect
                              name={`${option.key}_duration_hours`}
                              id={`${option.key}_duration_hours`}
                              className={css.duration}
                              label="Group session duration (hours)"
                              validate={hoursRequired}
                              onChange={value => onChangeTime(parseInt(value), option.key, 'hours')}
                            >
                              {hours.map(c => (
                                <option key={String(c)} value={String(c)}>
                                  {c}
                                </option>
                              ))}
                            </FieldSelect>
                            <FieldSelect
                              name={`${option.key}_duration_minutes`}
                              id={`${option.key}_duration_minutes`}
                              className={css.duration}
                              label="Group session duration (minutes)"
                              validate={minutesRequired}
                              // defaultValue={String(defaultMinutes)}
                              onChange={value =>
                                onChangeTime(parseInt(value), option.key, 'minutes')
                              }
                              disabled={currentMinutes.length === 1}
                            >
                              {currentMinutes.map(c => (
                                <option key={String(c)} value={c}>
                                  {c ? c : '00'}
                                </option>
                              ))}
                            </FieldSelect>
                          </>
                        )}
                      </>
                    ) : null
                  ) : (
                    <div>
                      <PrimaryButton
                        className={
                          isNoPassQuiz(option.key)
                            ? css.buttonToQuizNoPass
                            : isPendingQuiz(option.key)
                            ? css.buttonToQuizPending
                            : css.buttonToQuiz
                        }
                        type="button"
                        inProgress={proProfsProgress && proProfsProgress === option.key}
                        onClick={() => setQuiz(option.key)}
                        value={option.key}
                      >
                        {isNoPassQuiz(option.key) ? (
                          <FormattedMessage id="FieldCheckboxGroup.renewTheQuiz" />
                        ) : isPendingQuiz(option.key) ? (
                          <FormattedMessage id="FieldCheckboxGroup.quizAppointed" />
                        ) : (
                          <FormattedMessage id="FieldCheckboxGroup.passTheQuiz" />
                        )}
                      </PrimaryButton>
                    </div>
                  )}
                </li>
              );
            })}
        </ul>
        <ValidationError fieldMeta={{ ...meta }} />
      </fieldset>
    );
  }
}

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
  disabled: false,
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
  disabled: bool,
};

const FieldCheckboxGroupSubject = props => (
  <FieldArray component={FieldCheckboxRenderer} {...props} />
);

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroupSubject.propTypes = {
  name: string.isRequired,
};

export default FieldCheckboxGroupSubject;
