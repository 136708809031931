import React from 'react';
import { func, string, bool } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal } from '../../components';
import ContactUsForm from '../../forms/ContactUsForm/ContactUsForm';

function ModalContactUs (props) {
    const {
      onManageDisableScrolling,
      onPostContactUsFormData,
      isOpenModal,
      isCloseModal,
      tokenCaptcha,
      sendDataError,
    } = props;

    let content = (
        <ContactUsForm 
          onClose={isCloseModal}
          onPostContactUsFormData={onPostContactUsFormData}
          tokenCaptcha={tokenCaptcha}
          sendDataError={sendDataError}
        />
    );

    const closeButtonMessage = (
      <FormattedMessage id="Modal.close" />
    );

    return (
      <Modal
        id="ModalContactUs"
        isOpen={isOpenModal}
        onClose={() => isCloseModal()}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
      >
        {content}
      </Modal>
    );
}

ModalContactUs.propTypes = {
  id: string.isRequired,
  isOpenModal: bool,
  tokenCaptcha: string,
  sendDataError: bool,

  onManageDisableScrolling: func.isRequired,
  onPostContactUsFormData: func,
  closeButtonMessage: func,
  isCloseModal: func,
};

ModalContactUs.displayName = 'ModalContactUs';

export default ModalContactUs;
