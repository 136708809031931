/**
 * Booking breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the BookingDatesForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React, { Component } from 'react';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import { LINE_ITEM_UNITS, LINE_ITEM_CUSTOMER_COMMISSION } from '../../util/types';
import { unitDivisor, convertToDecimal, convertUnitToSubUnit } from '../../util/currency';
import { BookingBreakdown } from '../../components';

import css from './BookingTimeForm.css';

const { Money, UUID } = sdkTypes;
const CUSTOMER_COMMISSION_PERCENTAGE = process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE;

export class EstimatedBreakdownMaybe extends Component {
  constructor(props) {
    super(props);

    this.estimatedTransaction = this.estimatedTransaction.bind(this);
    this.estimatedTotalPrice = this.estimatedTotalPrice.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedItems.length !== prevProps.selectedItems.length && this.props.selectedItems.length > 0) {
      const { unitPrice, startDate, endDate, quantity } = this.props.bookingData;
      const { bookingData, selectedItems, listing, lessonType, bonusCustomer, onUpdateBonusCustomer } = this.props;
      const duration = this.estimatedDuration(startDate, endDate);
      let totalPrice = this.estimatedTotalPrice(unitPrice, quantity, bookingData, selectedItems, listing, duration, lessonType);
      const percentageSum = totalPrice.amount / 10000 * parseInt(CUSTOMER_COMMISSION_PERCENTAGE);

      if (bonusCustomer < percentageSum) {
        onUpdateBonusCustomer(false, 0)
      } else {
        onUpdateBonusCustomer(true, percentageSum)
      }
    }
  }

  estimatedDuration(bookingStart, bookingEnd){
    const h = bookingEnd.getHours() === 0 ? 24 - bookingStart.getHours() : bookingEnd.getHours() - bookingStart.getHours();
    const m = bookingEnd.getMinutes() - bookingStart.getMinutes();
    return h + ((m * 100 / 60) / 100);
  }

  estimatedTransaction(unitType, bookingStart, bookingEnd, unitPrice, quantity, bookingData,
                                selectedItems, listing, lessonType, bonusCustomer, onUpdateBonusCustomer, useBonusCheckbox) {
    const now = new Date();
    const duration = this.estimatedDuration(bookingStart, bookingEnd);
    let totalPrice = this.estimatedTotalPrice(unitPrice, quantity, bookingData, selectedItems, listing, duration, lessonType);
    const items = [];
    selectedItems && selectedItems.map((item) => {
      items.push({
        code: `line-item/${item}`,
        includeFor: ['customer', 'provider'],
        unitPrice: bookingData[item],
        quantity: convertToDecimal(lessonType === 'private' ? duration.toString() : '1'),
        lineTotal: bookingData[item],
        reversal: false,
      });
    });

    if (!useBonusCheckbox && totalPrice.amount > 0) {
      const percentage = process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE;
      items.push({
        code: LINE_ITEM_CUSTOMER_COMMISSION,
        unitPrice: new Money(totalPrice.amount / 100 * percentage, totalPrice.currency),
        includeFor: ['customer'],
        lineTotal: new Money(totalPrice.amount / 100 * percentage, totalPrice.currency),
        reversal: false
      });
      totalPrice = new Money(totalPrice.amount + (totalPrice.amount / 100 * process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE), totalPrice.currency);
    }

    return {
      id: new UUID('estimated-transaction'),
      type: 'transaction',
      attributes: {
        createdAt: now,
        lastTransitionedAt: now,
        lastTransition: TRANSITION_REQUEST_PAYMENT,
        payinTotal: totalPrice,
        payoutTotal: totalPrice,
        lineItems: [
          ...items,
          {
            code: unitType,
            includeFor: ['customer', 'provider'],
            unitPrice,
            quantity: new Decimal(quantity),
            lineTotal: totalPrice,
            reversal: false,
          },
        ],
        transitions: [
          {
            createdAt: now,
            by: TX_TRANSITION_ACTOR_CUSTOMER,
            transition: TRANSITION_REQUEST_PAYMENT,
          },
        ],
      },
      booking: {
        id: new UUID('estimated-booking'),
        type: 'booking',
        attributes: {
          start: bookingStart,
          end: bookingEnd,
        },
      },
    };
  };

  estimatedTotalPrice(unitPrice, unitCount, bookingData, selectedItems, listing, duration, lessonType) {
    const numericPrices = [];
    const reformatDuration = lessonType === 'private' ? duration / selectedItems.length : 1; // ToDo change this after client response

    selectedItems && selectedItems.map(e => {
      const servicePriceCents = listing.attributes.publicData[e] ? listing.attributes.publicData[e].amount : 0;
      numericPrices.push(servicePriceCents * reformatDuration);
    });
    const numericTotalPrice = numericPrices.reduce((a, b) => a + b, 0);
    return new Money(
      numericTotalPrice,
      unitPrice.currency
    );
  };

  render() {
    const { unitType, unitPrice, startDate, endDate, quantity, timeZone } = this.props.bookingData;
    const { bookingData, selectedItems, listing, lessonType, bonusCustomer, onUpdateBonusCustomer, useBonusCheckbox } = this.props;
    const isUnits = unitType === LINE_ITEM_UNITS;
    const quantityIfUsingUnits = !isUnits || Number.isInteger(quantity);
    const canEstimatePrice = startDate && endDate && unitPrice && quantityIfUsingUnits;
    if (!canEstimatePrice) {
      return null;
    }

    const tx = this.estimatedTransaction(unitType, startDate, endDate, unitPrice, quantity, bookingData,
      selectedItems, listing, lessonType, bonusCustomer, onUpdateBonusCustomer, useBonusCheckbox);

    return (
      <>
        { selectedItems.length > 0 ? <BookingBreakdown
          className={css.receipt}
          userRole="customer"
          unitType={unitType}
          transaction={tx}
          booking={tx.booking}
          timeZone={timeZone}
          lessonType={lessonType}
        /> : null }
      </>

    );
  }
}

export default EstimatedBreakdownMaybe;
