import axios from 'axios';

async function makeRequest(method, url, data) {
  const options = {
    method: method,
    url: url,
    data,
  };

  let res = await axios(options);
  try {
    return res;
  } catch (e) {
    return new Error(e);
  }
}

const RAILS_API_URL = process.env.REACT_APP_RAILS_API_URL;

// ================ Action types ================ //

export const INITIATE_FAQ_REQUEST = 'app/FAQPage/INITIATE_FAQ_REQUEST';
export const INITIATE_FAQ_SUCCESS = 'app/FAQPage/INITIATE_FAQ_SUCCESS';
export const INITIATE_FAQ_ERROR = 'app/FAQPage/INITIATE_FAQ_ERROR';

export const INITIATE_IMAGES_REQUEST = 'app/FAQPage/INITIATE_IMAGES_REQUEST';
export const INITIATE_IMAGES_SUCCESS = 'app/FAQPage/INITIATE_IMAGES_SUCCESS';
export const INITIATE_IMAGES_ERROR = 'app/FAQPage/INITIATE_IMAGES_ERROR';

export const CREATE_FAQ_REQUEST = 'app/FAQPage/CREATE_FAQ_REQUEST';
export const CREATE_FAQ_SUCCESS = 'app/FAQPage/CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_ERROR = 'app/FAQPage/CREATE_FAQ_ERROR';
export const CREATE_FAQ_RESET = 'app/FAQPage/CREATE_FAQ_RESET';

export const EDIT_FAQ_REQUEST = 'app/FAQPage/EDIT_FAQ_REQUEST';
export const EDIT_FAQ_SUCCESS = 'app/FAQPage/EDIT_FAQ_SUCCESS';
export const EDIT_FAQ_ERROR = 'app/FAQPage/EDIT_FAQ_ERROR';
export const EDIT_FAQ_RESET = 'app/FAQPage/EDIT_FAQ_RESET';

export const SAVE_IMAGES_REQUEST = 'app/FAQPage/SAVE_IMAGES_REQUEST';
export const SAVE_IMAGES_SUCCESS = 'app/FAQPage/SAVE_IMAGES_SUCCESS';
export const SAVE_IMAGES_ERROR = 'app/FAQPage/SAVE_IMAGES_ERROR';
export const SAVE_IMAGES_RESET = 'app/FAQPage/SAVE_IMAGES_RESET';

export const EDIT_IMAGES_REQUEST = 'app/FAQPage/EDIT_IMAGES_REQUEST';
export const EDIT_IMAGES_SUCCESS = 'app/FAQPage/EDIT_IMAGES_SUCCESS';
export const EDIT_IMAGES_ERROR = 'app/FAQPage/EDIT_IMAGES_ERROR';
export const EDIT_IMAGES_RESET = 'app/FAQPage/EDIT_IMAGES_RESET';

// ================ Reducer ================ //

const initialState = {
  faqList: [],
  isLoading: false,
  error: null,
  isCreating: false,
  createSuccess: false,
  createError: null,
  isEditing: false,
  editingSuccess: false,
  editingError: null,
  isImagesLoading: false,
  images: [],
  imagesError: null,
  saveImagesInProgress: false,
  saveImagesSuccess: false,
  saveImagesError: null,
  isEditImagesInProgress: false,
  editImagesSuccess: false,
  editImagesError: null,
};

export default function announcementsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_FAQ_REQUEST:
      return { ...state, isLoading: true };
    case INITIATE_FAQ_SUCCESS:
      return { ...state, isLoading: false, error: null, faqList: payload };
    case INITIATE_FAQ_ERROR:
      return { ...state, isLoading: false, error: payload };

    case INITIATE_IMAGES_REQUEST:
      return { ...state, isImagesLoading: true, imagesError: null, images: [] };
    case INITIATE_IMAGES_SUCCESS:
      return { ...state, isImagesLoading: false, imagesError: null, images: payload };
    case INITIATE_IMAGES_ERROR:
      return { ...state, isImagesLoading: false, imagesError: payload, images: [] };

    case CREATE_FAQ_REQUEST:
      return { ...state, isCreating: true, createSuccess: false, createError: null };
    case CREATE_FAQ_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createSuccess: true,
        createError: null,
        faqList: payload,
      };
    case CREATE_FAQ_ERROR:
      return { ...state, isCreating: false, createSuccess: false, createError: payload };
    case CREATE_FAQ_RESET:
      return { ...state, isCreating: false, createSuccess: false, createError: null };

    case EDIT_FAQ_REQUEST:
      return { ...state, isEditing: true, editingSuccess: false };
    case EDIT_FAQ_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editingSuccess: true,
        editingError: null,
      };
    case EDIT_FAQ_ERROR:
      return { ...state, isEditing: false, editingSuccess: false, editingError: payload };
    case EDIT_FAQ_RESET:
      return { ...state, isEditing: false, editingSuccess: false, editingError: null };

    case SAVE_IMAGES_REQUEST:
      return {
        ...state,
        saveImagesInProgress: true,
        saveImagesSuccess: false,
        saveImagesError: null,
      };
    case SAVE_IMAGES_SUCCESS:
      return {
        ...state,
        saveImagesInProgress: false,
        saveImagesSuccess: true,
        saveImagesError: null,
        images: [...payload],
      };
    case SAVE_IMAGES_ERROR:
      return {
        ...state,
        saveImagesInProgress: false,
        saveImagesSuccess: false,
        saveImagesError: payload,
      };
    case SAVE_IMAGES_RESET:
      return {
        ...state,
        saveImagesInProgress: false,
        saveImagesSuccess: false,
        saveImagesError: null,
      };

    case EDIT_IMAGES_REQUEST:
      return {
        ...state,
        isEditImagesInProgress: true,
        editImagesSuccess: false,
        editImagesError: null,
      };
    case EDIT_IMAGES_SUCCESS:
      return {
        ...state,
        isEditImagesInProgress: false,
        editImagesSuccess: true,
        editImagesError: null,
        images: [...payload],
      };
    case EDIT_IMAGES_ERROR:
      return {
        ...state,
        isEditImagesInProgress: false,
        editImagesSuccess: false,
        editImagesError: payload,
      };
    case EDIT_IMAGES_RESET:
      return {
        ...state,
        isEditImagesInProgress: false,
        editImagesSuccess: false,
        editImagesError: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const initiateFaqRequest = () => ({ type: INITIATE_FAQ_REQUEST });
const initiateFaqSuccess = response => ({ type: INITIATE_FAQ_SUCCESS, payload: response.data });
const initiateFaqError = error => ({ type: INITIATE_FAQ_ERROR, payload: error });

const initiateImagesRequest = () => ({ type: INITIATE_IMAGES_REQUEST });
const initiateImagesSuccess = response => ({
  type: INITIATE_IMAGES_SUCCESS,
  payload: response.data,
});
const initiateImagesError = error => ({
  type: INITIATE_IMAGES_ERROR,
  payload: error,
});

const createFaqRequest = () => ({ type: CREATE_FAQ_REQUEST });
const createFaqSuccess = response => ({
  type: CREATE_FAQ_SUCCESS,
  payload: response.data,
});
const createFaqError = error => ({ type: CREATE_FAQ_ERROR, payload: error });
const createFaqReset = () => ({ type: CREATE_FAQ_RESET });

const editFaqRequest = () => ({ type: EDIT_FAQ_REQUEST });
const editFaqSuccess = () => ({ type: EDIT_FAQ_SUCCESS });
const editFaqError = error => ({ type: EDIT_FAQ_ERROR, payload: error });
const editFaqReset = () => ({ type: EDIT_FAQ_RESET });

const saveImagesRequest = () => ({ type: SAVE_IMAGES_REQUEST });
const saveImagesSuccess = response => ({
  type: SAVE_IMAGES_SUCCESS,
  payload: response,
});
const saveImagesError = error => ({ type: SAVE_IMAGES_ERROR, payload: error });
const saveImagesReset = () => ({ type: SAVE_IMAGES_RESET });

const editImagesRequest = () => ({ type: EDIT_IMAGES_REQUEST });
const editImagesSuccess = response => ({ type: EDIT_IMAGES_SUCCESS, payload: response });
const editImagesError = error => ({ type: EDIT_IMAGES_ERROR, payload: error });
const editImagesReset = () => ({ type: EDIT_IMAGES_RESET });

/* ================ Thunks ================ */

export const getFaq = () => dispatch => {
  dispatch(initiateFaqRequest());

  makeRequest('get', `${RAILS_API_URL}/api/v1/faq`)
    .then(response => {
      dispatch(initiateFaqSuccess(response));
    })
    .catch(e => {
      const error = new Error('something went wrong, test error');
      dispatch(initiateFaqError(error));
    });
};

export const createFaq = data => dispatch => {
  dispatch(createFaqRequest());

  makeRequest('post', `${RAILS_API_URL}/api/v1/faq`, data)
    .then(response => {
      dispatch(createFaqSuccess(response));
    })
    .catch(e => {
      dispatch(createFaqError(e));
    });
};

export const editFaq = data => dispatch => {
  dispatch(editFaqRequest());

  makeRequest('post', `${RAILS_API_URL}/api/v1/faq`, data)
    .then(response => {
      dispatch(editFaqSuccess());
      dispatch(initiateFaqSuccess(response));
    })
    .catch(e => {
      dispatch(editFaqError(e));
    });
};

export const createReset = () => dispatch => {
  dispatch(createFaqReset());
};

export const editReset = () => dispatch => {
  dispatch(editFaqReset());
};

export const getImagesData = () => dispatch => {
  dispatch(initiateImagesRequest());

  makeRequest('get', `${RAILS_API_URL}/api/v1/images/faq`)
    .then(response => {
      dispatch(initiateImagesSuccess(response));
    })
    .catch(e => {
      const error = new Error('something went wrong, test error');
      dispatch(initiateImagesError(error));
    });
};

export const saveImages = data => dispatch => {
  dispatch(saveImagesRequest());

  makeRequest('post', `${RAILS_API_URL}/api/v1/images`, data)
    .then(response => {
      dispatch(saveImagesSuccess(response.data));
    })
    .catch(e => {
      dispatch(saveImagesError(e));
    });
};

export const editImages = (id, data) => dispatch => {
  dispatch(editImagesRequest());

  makeRequest('put', `${RAILS_API_URL}/api/v1/images/${id}`, data)
    .then(response => {
      dispatch(editImagesSuccess(response.data));
    })
    .catch(e => {
      dispatch(editImagesError(e));
    });
};

export const saveImagesFaqReset = () => dispatch => {
  dispatch(saveImagesReset());
};

export const editImagesFaqReset = () => dispatch => {
  dispatch(editImagesReset());
};
