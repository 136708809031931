import React from 'react';
import {  bool, func } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import { Field, Form as FinalForm } from 'react-final-form';
import css from './TransactionAttachmentForm.css';
import * as validators from '../../util/validators';
import { compose } from 'redux';
import { uploadAttachments } from '../../ducks/Transactions.duck';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

export class TransactionAttachmentFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      setPreviewFiles: false,
      validationError: false
    };

    this.input = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    this.state.files.map((file, index) => {
      return (
        formData.append(`attach_${index}`, file)
      )
    });
    formData.append('transaction_id', this.props.transaction.id.uuid)
    formData.append('user_id', this.props.currentUser.id.uuid)
    this.props.onUploadAttachments(formData)
    this.input.current.value = null;

    this.setState({files: [], setPreviewFiles: false});
  }

  handleUploadFile(event) {
    const list = event.target.files;
    let data = [...this.state.files];
    const formats = [
      'application/zip',
      'application/x-zip-compressed',
      'application/msword',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/x-png',
      'image/pjpeg',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ];

    if (list.length > 0) {
      if (data.length > 0) {
        for (let i = 0; i < list.length; i++) {
          let newFile = true;
          for (let j = 0; j < data.length; j++) {
            if (data[j].name === list[i].name && data[j].size === list[i].size) {
              newFile = false;
            }
          }
          if (newFile) data.push(list[i]);
          this.setState({setPreviewFiles: true})
        }
      } else {
        this.setState({ setPreviewFiles: true })
      }
      const filesWithoutValidation = data.length === 0 ? Object.values(list) : data;
      const files = filesWithoutValidation.filter(file => formats.includes(file.type))
      const validationError = filesWithoutValidation.length !== files.length;
      this.setState({ files, validationError })
    }
  }

  deleteFile(file, e) {
    e.preventDefault();

    const name = file.name;
    this.setState({files: this.state.files.filter(file => {
      return file.name !== name
    })})

    this.state.files.length === 0 && this.setState({setPreviewFiles: false});
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            inProgress,
            ready,
            className,
            invalid,
            submitInProgress,
            transaction,
            currentUser,
            intl
          } = fieldRenderProps;
          const classes = className ? className : css.form;
          const messageButtonUpload = intl.formatMessage({
            id: 'TransactionAttachmentForm.buttonUpload',
          });

          const validationError = intl.formatMessage({
            id: 'TransactionAttachmentForm.validationError',
          });

          const uploadFilePresent = this.state.files.length > 0;
          const submitDisabled = invalid || submitInProgress || !uploadFilePresent;

          return (
            <Form className={classes} onSubmit={e => this.handleSubmit(e)}>

              <div className={css.fieldFileUpload}>
                <label className={css.labelFileUpload} htmlFor="upload">{messageButtonUpload}</label>
                <input
                  className={css.inputFileUpload}
                  type="file"
                  onChange={this.handleUploadFile}
                  name="uploadFile"
                  multiple id="upload"
                  ref={this.input}
                  accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .ppt, .pptx, .zip"
                />
                { this.state.validationError ? validationError : null }
                {this.state.setPreviewFiles &&
                  <ul className={css.containerUploadList}>
                    {
                      this.state.files.map(file => {
                        return (
                          <li key={file.name} className={css.containerUploadFile}>
                            <div className={css.containerUploadFileName}>{file.name}</div>
                            <button className={css.containerUploadFileButton} onClick={(e) => this.deleteFile(file, e)}>&times;</button>
                          </li>
                        )
                      })
                    }
                  </ul>
                }
              </div>
              <Field
                component="input"
                type="hidden"
                name="transactionId"
                defaultValue={transaction.id.uuid}
              />
              <Field
                component="input"
                type="hidden"
                name="sender"
                defaultValue={transaction.provider.id.uuid === currentUser.id.uuid ? 'provider' : 'customer'}
              />

              <PrimaryButton
                type="submit"
                inProgress={inProgress}
                ready={ready}
                disabled={submitDisabled}
                className={css.submitButton}
              >
                <FormattedMessage id="SectionSubscribe.subscribeFormBtn" />
              </PrimaryButton>
            </Form>
          );
        }}
      />
    );
  }
}

TransactionAttachmentFormComponent.defaultProps = {
  inProgress: false,
  ready: false,
  submitDisabled: false,
};

TransactionAttachmentFormComponent.propTypes = {
  inProgress: bool,
  ready: bool,
  submitDisabled: bool,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    uploadAttachmentRequest,
    uploadAttachmentError,
    getAttachmentRequest,
    getAttachmentError,
    attachments
  } = state.Transactions;

  return {
    uploadAttachmentRequest,
    uploadAttachmentError,
    getAttachmentRequest,
    getAttachmentError,
    attachments
  };
};

const mapDispatchToProps = dispatch => ({
  onUploadAttachments: (data) => { dispatch(uploadAttachments(data)) }
});

const TransactionAttachmentForm = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(TransactionAttachmentFormComponent);

export default TransactionAttachmentForm;
